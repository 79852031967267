import { Directive, InjectionToken, Input } from '@angular/core';

let nextUniqueId = 0;

/**
 * Injection token that can be used to reference instances of `TixHint`. It serves as
 * alternative token to the actual `TixHint` class which could cause unnecessary
 * retention of the class and its directive metadata.
 *
 * *Note*: This is not part of the public API as the MDC-based form-field will not
 * need a lightweight token for `TixHint` and we want to reduce breaking changes.
 */
export const TIX_FORM_FIELD_HINT = new InjectionToken<TixFormFieldHint>(
  'TixFormFieldHint'
);

/** Hint text to be shown underneath the form field control. */
@Directive({
  selector: 'tix-hint',
  host: {
    class: 'tix-hint',
    '[class.tix-form-field-hint-end]': 'align === "end"',
    '[attr.id]': 'id',
    '[attr.align]': 'null'
  },
  providers: [{ provide: TIX_FORM_FIELD_HINT, useExisting: TixFormFieldHint }]
})
export class TixFormFieldHint {
  /** Whether to align the hint label at the start or end of the line. */
  @Input() align: 'start' | 'end';

  uid = `tix-hint-${nextUniqueId++}`;
  /** Unique ID for the hint. Used for the aria-describedby on the form field control. */
  @Input()
  get id(): string {
    return this.inputId;
  }
  set id(value: string) {
    this.inputId = value || this.uid;
  }
  protected inputId: string = this.uid;
}
