import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroup,
  TixBusinessGroupsService
} from '@tix/shared/state';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CAMPAIGN_PIXEL } from '../event-info/event-info.component';

@Component({
  selector: 'tix-campaign-pixel',
  templateUrl: './campaign-pixel.component.html',
  styleUrls: ['./campaign-pixel.component.scss']
})
export class CampaignPixelComponent implements OnInit, OnChanges {
  @Input() campaignType: Maybe<TixBusinessGroup['businessCodes']>;
  @Input() pixel: CAMPAIGN_PIXEL;

  @Output() deleteEvent = new EventEmitter<void>();

  newPixelForm: FormGroup;

  protected edit: boolean;
  get isEdit(): boolean {
    return this.edit;
  }

  set isEdit(value: boolean) {
    this.edit = value;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pixel) {
      this.isEdit = this.pixel?.isEdit!;
      this.newPixelForm = new FormGroup({
        campaignName: new FormControl(
          this.pixel?.campaign_name ?? '',
          Validators.required
        ),
        campaignCode: new FormControl(this.pixel?.campaignCode ?? '', [
          Validators.required
          //this.campaignCodeValidator()
        ]),
        campaignType: new FormControl(
          !this.pixel?.campaignType || this.pixel?.campaignType === ''
            ? 'Meta Pixel'
            : this.pixel?.campaignType,
          Validators.required
        )
      });
    }
    if (!this.isEdit) {
      this.disableFormFields();
    }
  }

  get campaignControlType() {
    return this.newPixelForm.controls['campaignType'];
  }

  get campaignControlCode() {
    return this.newPixelForm.controls['campaignCode'];
  }

  get campaignCodeControlValidation() {
    return this.campaignControlCode.invalid && this.campaignControlCode.touched;
  }

  private campaignCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^\d{15,16}$/.test(control.value);
      return isValid ? null : { invalidCampaignCode: true };
    };
  }

  toggleEdit() {
    if (!this.isEdit) {
      this.isEdit = !this.isEdit;
      this.enableFormFields();
    } else {
      if (this.newPixelForm.valid && this.pixel) {
        const { campaignName, campaignCode, campaignType } =
          this.newPixelForm.value;
        this.pixel.campaign_name = campaignName;
        this.pixel.campaignCode = campaignCode;
        this.pixel.campaignType = campaignType;
        this.pixel.isEdit = false;
        this.disableFormFields();
        this.isEdit = !this.isEdit;
      }
    }
  }

  private enableFormFields() {
    this.newPixelForm.controls['campaignName'].enable();
    this.newPixelForm.controls['campaignCode'].enable();
  }

  private disableFormFields() {
    this.newPixelForm.controls['campaignName'].disable();
    this.newPixelForm.controls['campaignCode'].disable();
  }
}
