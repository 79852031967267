import { Injectable } from '@angular/core';
import {
  TixBuyerProductFragmentFragment,
  TixEventProductSelectGQL
} from '@tix/data-access';
import { HelperDateService } from '@tix/util-common';

@Injectable({ providedIn: 'root' })
export class BuyerProductService {
  constructor(private getProductsQuery: TixEventProductSelectGQL) {}
  async getAddonsForEvent(
    eventInstanceId: string
  ): Promise<TixBuyerProductFragmentFragment[]> {
    const today = new HelperDateService().now();
    const res = await this.getProductsQuery
      .fetch({ eventInstanceId, today })
      .toPromise();

    return res.data.EventProductSelect;
  }
}
