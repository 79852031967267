import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companyAdapter, COMPANY_FEATURE_KEY, State } from '../company.reducer';

// Lookup the 'Company' feature state managed by NgRx
export const getCompanyState =
  createFeatureSelector<State>(COMPANY_FEATURE_KEY);

const { selectAll, selectEntities } = companyAdapter.getSelectors();

export const getCompaniesLoaded = createSelector(
  getCompanyState,
  (state: State) => state.loaded
);

export const getCompaniesError = createSelector(
  getCompanyState,
  (state: State) => state.error
);

export const getAllCompanies = createSelector(getCompanyState, (state: State) =>
  selectAll(state)
);

export const getSearchText = createSelector(
  getCompanyState,
  (state: State) => state.searchText
);

export const getFilteredCompanies = createSelector(
  getAllCompanies,
  getSearchText,
  (companies, searchText) =>
    companies.filter(company =>
      company.name.toLowerCase().includes(searchText.toLowerCase())
    )
);

export const getCompanyEntities = createSelector(
  getCompanyState,
  (state: State) => selectEntities(state)
);

export const getSelectedCompanyId = createSelector(
  getCompanyState,
  (state: State) => state.selectedCompanyId
);
export const getSelectedContactId = createSelector(
  getCompanyState,
  (state: State) => state.selectedContactId
);

export const getSelectedCompany = createSelector(
  getCompanyEntities,
  getSelectedCompanyId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSelectedName = createSelector(
  getSelectedCompany,
  company => company?.name
);
export const getSelectedLoadingProfile = createSelector(
  getCompanyState,
  state => state.loadingProfile
);
export const getSelectedLoadingAdress = createSelector(
  getCompanyState,
  state => state.loadingAdress
);
export const getSelectedLoadingMedia = createSelector(
  getCompanyState,
  state => state.loadingMedia
);
export const getSelectedLoadingMediaFiles = createSelector(
  getCompanyState,
  state => state.loadingMediaFiles
);
export const getSelectedLoadingPhone = createSelector(
  getCompanyState,
  state => state.loadingPhone
);

export const getSelectedUrl = createSelector(
  getSelectedCompany,
  company => company?.url
);

export const getSelectedAddresses = createSelector(
  getSelectedCompany,
  company => company?.addresses.map(e => e.address)
);
export const getSelectedVenues = createSelector(
  getSelectedCompany,
  company => company?.venues
);
export const getSelectedContacts = createSelector(
  getSelectedCompany,
  company => company?.contacts
);

export const getSelectedPhoneNumbers = createSelector(
  getSelectedCompany,
  company => company?.phoneNumbers?.map(phoneNumber => phoneNumber?.phoneNumber)
);

export const getSelectedSocialMedias = createSelector(
  getSelectedCompany,
  company =>
    company?.socialMediaLinks?.map(
      socialMediaLinks => (socialMediaLinks as any)?.socialMedia
    )
);

export const getSelectedWarnings = createSelector(
  getSelectedCompany,
  company => {
    console.log('the company', company);
    return company?.warnings?.map(warnings => warnings?.warning);
  }
);

export const getSelectedMedias = createSelector(getSelectedCompany, company =>
  company?.mediaFiles?.map(mediaFiles => mediaFiles?.mediaFile)
);

export const getSectionsInEdit = createSelector(
  getCompanyState,
  (state: State) => state.editSections
);

export const getSelectedVenuesAggregate = createSelector(
  getSelectedCompany,
  company => company?.venues_aggregate?.aggregate?.count
);
export const getSelectedEventsAggregate = createSelector(
  getSelectedCompany,
  company => company?.companyVenueEvents_aggregate?.aggregate?.count
);
export const getSelectedContactsAggregate = createSelector(
  getSelectedCompany,
  company => company?.contacts_aggregate?.aggregate?.count
);
