<div class="tix-layout-container">
  <tix-header></tix-header>
  <div class="sidebar-container" [class.collapse-sidebar]="!expandSidebar">
    <div
      class="tix-sidebar-expand-btn"
      (click)="expandSidebar = !expandSidebar"
    >
      <tix-icon
        [svgIcon]="expandSidebar ? 'chevron-left' : 'chevron-right'"
      ></tix-icon>
    </div>
    <tix-company-sidebar [expandSidebar]="expandSidebar"></tix-company-sidebar>
  </div>
  <div class="content-container" [class.sidenav-expanded]="!expandSidebar">
    <div class="content-wrapper">
      <tix-breadcrumbs class="breadcrumbs-wrapper"></tix-breadcrumbs>
      <router-outlet></router-outlet>
    </div>
    <tix-footer hideLogo></tix-footer>
  </div>
</div>
