export abstract class OrderItem {
  /// This would be treated as a transaction detail,
  /// and will be the base for all the current and future order items

  constructor(public amount: number, public quantity: number) {}
}

export class TicketOrderItem extends OrderItem {
  constructor(
    amount: number,
    quantity: number,
    public includesFees: boolean,
    public venueFee: number,
    public ticketConfigId: string
  ) {
    super(amount, quantity);
  }
}

export class DiscountOrderItem extends OrderItem {
  constructor(amount: number) {
    super(amount, 1);
  }
}

export class DonationOrderItem extends OrderItem {
  constructor(amount: number) {
    super(amount, 1);
  }
}

export type DiscountType =
  | '% Discount'
  | '$ Discount'
  | 'Buy X Get Y Free'
  | 'Spend $X, Get $Y Off'
  | 'Pre-Sale';
// We can add AddonOrderItem, etc.
export class ProductOrderItem extends OrderItem {
  constructor(
    amount: number,
    quantity: number,
    public taxPercent: number,
    public productId: string
  ) {
    super(amount, quantity);
  }
}

export type Order = {
  items: OrderItem[];
};
