/**
 * This token is used to inject the object whose value should be set into `TixInput`. If none is
 * provided, the native `HTMLInputElement` is used. Directives like `TixDatepickerInput` can provide
 * themselves for this token, in order to make `TixInput` delegate the getting and setting of the
 * value to them.
 */
import { InjectionToken } from '@angular/core';

export const TIX_INPUT_VALUE_ACCESSOR = new InjectionToken<{ value: any }>(
  'TIX_INPUT_VALUE_ACCESSOR'
);
