<tix-card>
  <tix-card-header *ngIf="showHeader">
    <tix-card-title>{{ pages[pageName] ?? 'Phone number' }}</tix-card-title>
  </tix-card-header>

  <tix-card-content
    [ngClass]="{
      '!px-0': !showHeader
    }"
  >
    <form
      [formGroup]="phoneNumbersForm"
      class="profile-content-layout"
      [class.disabled]="sameAsCompany"
    >
      <ng-template [ngIf]="phonesFormArray?.controls?.length ?? 0 > 0">
        <div
          class="mat-form-field-phone"
          formArrayName="phones"
          *ngFor="let group of phonesFormArray.controls; let i = index"
        >
          <mat-form-field [formGroupName]="i" class="inline-input-select">
            <mat-label>{{ pages[pageName] ?? 'Phone number' }}</mat-label>
            <input
              [textMask]="PHONE_NUMBER_INPUT_MASK_CONFIG"
              matInput
              type="text"
              formControlName="phoneNumber"
              [readonly]="keepDisableSaveBtn"
            />
            <mat-select
              formControlName="phoneType"
              [disabled]="keepDisableSaveBtn"
            >
              <mat-option
                *ngFor="let phoneType of phoneTypes"
                [value]="phoneType.name"
                >{{ phoneType.name }}</mat-option
              >
            </mat-select>
            <mat-error>{{ pages[pageName] }} is required</mat-error>
          </mat-form-field>
          <button
            (click)="confirmDelete(group, i)"
            tix-button
            color="light"
            class="mat-form-field-icon"
          >
            <tix-icon svgIcon="trash"></tix-icon>
          </button>
        </div>
      </ng-template>
    </form>
    <button
      tix-flat-button
      (click)="addNewPhoneHandler()"
      *ngIf="!sameAsCompany"
    >
      Add another
    </button>
    <!-- <pre> {{ phoneNumbersForm.value | json }} </pre> -->
  </tix-card-content>

  <tix-card-actions
    [ngClass]="{
      '!px-0 !py-0': !showHeader
    }"
    align="end"
  >
    <div
      *ngIf="sameAsCompany !== undefined"
      class="self-center mr-auto mb-2 md:mb-0"
    >
      <tix-checkbox
        [checked]="sameAsCompany!"
        (change)="checkSameAsCompany($event)"
        [labelPosition]="'before'"
      >
        Is the Phone Numbers the same as the Company Phone Numbers?
      </tix-checkbox>
    </div>
    <button *ngIf="showSkipButton" (click)="skipThisCard()" tix-flat-button>
      Skip
    </button>
    <button
      [disabled]="
        keepDisableSaveBtn ||
        !phoneNumbersForm.valid ||
        phoneNumbersForm.pristine ||
        isloading
      "
      (click)="handleSaveClick()"
      tix-button
    >
      Save
      <span class="ml-1" *tixBtnLoading="isloading"></span>
    </button>
  </tix-card-actions>
</tix-card>
