import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  ViewChild,
  Renderer2,
  SimpleChanges,
  HostListener,
  EventEmitter,
  Output,
  AfterViewInit,
  NgZone,
  ElementRef
} from '@angular/core';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import {
  MatCalendar,
  MatCalendarCellCssClasses
} from '@angular/material/datepicker';
import * as BuyerActions from '@tix/event-buyer/state';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { Maybe, TixPatronFIlteredEventsList } from '@tix/data-access';
import { TixBuyersPartialState } from '@tix/event-buyer/state';
import { Store } from '@ngrx/store';
import { BuyerService } from '../services/buyer.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PortalCalendarDateRangeFilter,
  PortalCalendarFilter,
  PortalCalendarService,
  PortalCalendarSingleDayFilter,
  PortalEventList,
  PortalEventsFilter,
  PortalMonthCalendarFilter,
  PortalMonthEventsFilter,
  TixCalendarDates
} from '@tix/event-buyer/services';
@Component({
  selector: 'tix-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class TixEventCalendarComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  @Input() events: Maybe<PortalEventList>;
  @Input() venueId: string;
  @Input() initialFilter?: PortalCalendarFilter;

  @Output()
  updateCalendarFilter = new EventEmitter<PortalCalendarFilter>();

  selectedDate: Date | null = null;

  constructor(
    private portalCalendarService: PortalCalendarService,
    private element: ElementRef
  ) {}

  subs: Subscription[] = [];

  dateRange = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl()
  });

  mobileSelectedDate = new FormControl(null);

  calendarEventDates: Date[] = [];

  header = TixCalendarHeader;

  listenToCalendarChanges() {
    this.subs.push(
      this.calendar.selectedChange.subscribe(date => {
        if (date) {
          this.selectSingleDay(date);
          this.selectedDate = date;
        }
      })
    );
    this.subs.push(
      this.calendar.monthSelected.subscribe(date => {
        this.selectedDate = null;
        this.selectMonth(date);
      })
    );

    this.subs.push(
      this.dateRange.valueChanges
        .pipe(
          tap(value => {
            if (value.startDate && value.endDate) {
              this.updateCalendarFilter.emit(
                new PortalCalendarDateRangeFilter(
                  value.startDate,
                  value.endDate
                )
              );
            }
          })
        )
        .subscribe()
    );

    this.subs.push(
      this.mobileSelectedDate.valueChanges
        .pipe(
          tap(value => {
            if (value) {
              this.selectSingleDay(value);
            }
          })
        )
        .subscribe()
    );
  }

  selectMonth(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();

    this.updateCalendarFilter.emit(new PortalMonthCalendarFilter(year, month));

    this.fetchCalendarDates(date);
  }

  selectSingleDay(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    this.updateCalendarFilter.emit(
      new PortalCalendarSingleDayFilter(year, month, day)
    );
  }

  async fetchCalendarDates(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();

    const dates = await this.portalCalendarService.getCalendarDatesForFilter(
      this.venueId,
      new PortalMonthCalendarFilter(year, month)
    );

    this.calendarEventDates = dates.map(item => moment(item.date).toDate());

    this.updateCalendarDatesButtonClasses();
  }

  updateCalendarDatesButtonClasses() {
    const dateClass = this.getDateClass();
    (this.element.nativeElement as HTMLElement)
      .querySelectorAll('button.mat-calendar-body-cell')
      .forEach((button, i) => {
        button.classList.remove('blue-dot');
        const toAdd = dateClass(
          moment()
            .set('date', i + 1)
            .toDate()
        ) as string;
        if (toAdd) {
          button.classList.add(toAdd);
        }

        button.classList.remove('mat-calendar-body-active');
      });
  }

  ngOnInit(): void {
    this.fetchCalendarDates(new Date());

    if (
      this.initialFilter &&
      this.initialFilter instanceof PortalCalendarDateRangeFilter
    ) {
      this.dateRange.controls['startDate'].setValue(
        this.initialFilter.startDate
      );
      this.dateRange.controls['endDate'].setValue(this.initialFilter.endDate);
    }

    if (
      this.initialFilter &&
      this.initialFilter instanceof PortalCalendarSingleDayFilter
    ) {
      this.mobileSelectedDate.setValue(
        new Date(
          this.initialFilter.year,
          this.initialFilter.month,
          this.initialFilter.day
        )
      );
    }
  }

  ngAfterViewInit(): void {
    this.listenToCalendarChanges();
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getDateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.calendarEventDates.some(
        d => d.getDate() === date.getDate()
      );
      return highlightDate ? 'blue-dot' : '';
    };
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: 'tix-calendar-header',
  styles: [
    `
      .example-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
        .example-header-label {
          height: 1em;
          font-weight: bold;
          text-align: center;
        }

        .example-double-arrow .mat-icon {
          margin: -22%;
        }
      }
    `
  ],
  template: `
    <div class="example-header">
      <span class="example-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixCalendarHeader<D> implements OnDestroy, OnChanges {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private buyerService: BuyerService,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(data => {
      cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngOnChanges() {}

  get periodLabel() {
    return this._dateAdapter.format(
      this._calendar.activeDate,
      this._dateFormats.display.monthYearLabel
    );
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);

    this._calendar.yearSelected.emit(this._calendar.activeDate);
    this._calendar.monthSelected.emit(this._calendar.activeDate);

    this.buyerService._previousMonthClick.next(this._calendar.activeDate);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);

    this._calendar.yearSelected.emit(this._calendar.activeDate);
    this._calendar.monthSelected.emit(this._calendar.activeDate);

    this.buyerService._nextMonthClick.next(this._calendar.activeDate);
  }
}
