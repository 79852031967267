import { Component, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'tix-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class TixFooterComponent {
  static ngAcceptInputType_hideLogo: BooleanInput;

  year: number = new Date().getFullYear();

  @Input()
  set hideLogo(v: boolean | unknown) {
    this._hideLogo = coerceBooleanProperty(v);
  }

  get hideLogo(): boolean {
    return this._hideLogo;
  }

  private _hideLogo = false;

  empty(): void {}
}
