import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import * as LogRocket from 'logrocket';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tix-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  sub?: Subscription;

  ngOnInit(): void {
    this.sub = this.store.select(UserSelector.getAuthenticatedUser).subscribe({
      next: user => {
        if (!user) return;

        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
