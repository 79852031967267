import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  getSelectedAddresses,
  getSelectedCompany,
  getSelectedCompanyId
} from '@tix/company/state/selectors';
import { Maybe, TixAddress, TixPhoneNumber, TixVenue } from '@tix/data-access';
import { State, venuesAdapter, VENUES_FEATURE_KEY } from '../venues.reducer';
import { getSelectedPhoneNumbers } from '@tix/company/state/selectors';

// Lookup the 'Events' feature state managed by NgRx
export const getVenuesState = createFeatureSelector<State>(VENUES_FEATURE_KEY);

const { selectAll, selectEntities } = venuesAdapter.getSelectors();

export const getVenuesLoaded = createSelector(
  getVenuesState,
  (state: State) => state.loaded
);

export const getVenuesError = createSelector(
  getVenuesState,
  (state: State) => state.error
);

export const getAllVenues = createSelector(getVenuesState, (state: State) =>
  selectAll(state)
);

export const getSearchText = createSelector(
  getVenuesState,
  (state: State) => state.searchText
);

export const getFilteredVenues = createSelector(
  getAllVenues,
  getSearchText,
  (companies, searchText) =>
    companies.filter(company =>
      company.name.toLowerCase().includes(searchText.toLowerCase())
    )
);
export const getVenuesEntities = createSelector(
  getVenuesState,
  (state: State) => selectEntities(state)
);

export const getSelectedVenueId = createSelector(
  getVenuesState,
  (state: State) => state.selectedVenueId
);

export const getSelectedLoadingAdress = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingAdress
);
export const getSelectedLoadingInfo = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingInfo
);
export const getSelectedLoadingMedia = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingMedia
);
export const getSelectedLoadingMediaFiles = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingMediaFiles
);
export const getSelectedLoadingPhone = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingPhone
);
export const getSelectedLoadingTicketConfig = createSelector(
  getVenuesState,
  (state: State) => state.venueLoadingTicketConfig
);

export const getSelectedVenue = createSelector(
  getVenuesState,
  getSelectedVenueId,
  (state: State, venueId?: string) =>
    venueId ? state.entities[venueId] : undefined
);
export const getSelectedWarnings = createSelector(getSelectedVenue, venue => {
  console.log(
    'new Warn',
    venue?.warnings?.map(warnings => warnings?.warning)
  );
  return venue?.warnings?.map(warnings => warnings?.warning);
});
export const getSavingWarnings = createSelector(
  getVenuesState,
  state => state.venueSavingWarnings
);

export const getSelectedVenueName = createSelector(
  getSelectedVenue,
  (venue?: TixVenue) => venue?.name
);

export const getSelectedVenuePhoneNumbers = (sameAsCompany?: Maybe<boolean>) =>
  createSelector(
    getSelectedVenue,
    getSelectedPhoneNumbers,
    (venue: Maybe<TixVenue>, companyPhoneNumbers) => {
      sameAsCompany ??= venue?.phoneNumbers?.[0]?.phoneNumber?.sameAsCompany;

      venue?.phoneNumbers?.map(e => e.phoneNumber);

      if (sameAsCompany && venue?.phoneNumbers) {
        return companyPhoneNumbers?.map(
          e =>
            ({
              ...e,
              sameAsCompany: true
            } as TixPhoneNumber)
        );
      }

      return venue?.phoneNumbers.map(
        e => e.phoneNumber
      ) as Maybe<TixPhoneNumber>[];
    }
  );

export const getSelectedVenueSocialMedias = createSelector(
  getSelectedVenue,
  (venue?: TixVenue) => venue?.socialMedia?.map(e => e.socialMedia)
);

export const getSelectedVenueMediaFiles = createSelector(
  getSelectedVenue,
  (venue?: TixVenue) => venue?.mediaFiles?.map(e => e.mediaFile)
);

export const getSelectedVenueTickets = createSelector(
  getSelectedVenue,
  venue => venue?.tickets
);

export const getSelectedVenueAddresses = (sameAsCompany?: Maybe<boolean>) =>
  createSelector(
    getSelectedVenue,
    getSelectedAddresses,
    (venue: Maybe<TixVenue>, companyAddresses) => {
      const venueAddress = venue?.addresses[0]?.address;

      sameAsCompany ??= venueAddress?.sameAsCompany;

      if (sameAsCompany && venueAddress) {
        return companyAddresses?.map(address => ({
          ...address,
          addressId: venueAddress.addressId,
          sameAsCompany: true
        })) as Maybe<TixAddress>[];
      }

      return venue?.addresses?.map(e => e.address) as Maybe<TixAddress>[];
    }
  );

export const getAddressSameAsCompany = createSelector(
  getSelectedVenueAddresses(),
  addresses => !!addresses?.[0]?.sameAsCompany
);

export const getPhoneNumberSameAsCompany = createSelector(
  getSelectedVenuePhoneNumbers(),
  phoneNumbers => !!phoneNumbers?.[0]?.sameAsCompany
);

export const getVenuesForSelectedCompany = createSelector(
  getAllVenues,
  getSelectedCompanyId,
  (venues: TixVenue[], companyId?: string) =>
    venues.filter(venue => venue.companyId === companyId)
);

export const getSelected = createSelector(
  getVenuesEntities,
  getSelectedVenueId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
