export enum BUSINESS_CODE_GROUP_NAMES {
  'BUSINESS_PHONE_TYPE' = 'business_phone_type',
  'BUSINESS_EVENT_TYPE' = 'event_type',
  'STATUS_TYPE' = 'state',
  'CONTACT_PHONE_TYPE' = 'contact_phone_type',
  'STATE_PROVINCE' = 'state_province',
  'COUNTRY_CODE' = 'country_code',
  'SOCIAL_MEDIA_TYPE' = 'social_media_type',
  'MEDIA_FILE_TYPE' = 'media_file_type',
  'EVENT_MEDIA_FILE_TYPE' = 'event_media_file_type',
  'TICKET_TYPE' = 'ticket_type',
  'WARNING_TYPE' = 'warning_type',
  'VENUE_MEDIA_FILE_TYPE' = 'venue_media_file_type',
  'PORTAL_MESSAGES' = 'portal_messages',
  'DISCOUNT_TYPE' = 'discount_type',
  'DISCOUNT_APPLIED_TO' = 'discount_applied_to',
  'EMAIL_TYPE' = 'email_type',
  'CAMPAIGN_TYPE' = 'campaign_type'
}

export const BUSINESS_CODE_GROUP_FEATURE_KEY = 'business_code_group';
