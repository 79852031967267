export enum UserStatus {
  UNKNOWN = 0,

  LOGGED_OUT = 10,
  LOGGED_IN,

  INIT_FAILED = 50,
  LOGIN_FAILED
}

export class User {
  readonly uid: string;
  readonly displayName: string;
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly email: string;
  readonly profilePicture?: string;
  readonly dateOfBirth: string | undefined;
  constructor(
    uid: string,
    email: string,
    firstName?: string,
    lastName?: string,
    dateOfBirth?: string,
    profilePicture?: string
  ) {
    this.uid = uid;
    this.firstName = firstName;
    this.email = email;
    this.lastName = lastName;
    this.displayName =
      this.firstName && this.lastName
        ? `${this.firstName} ${this.lastName}`
        : this.email;
    this.dateOfBirth = dateOfBirth;
    this.profilePicture = profilePicture;
  }
}

export interface UserCredentials {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
}

export interface UserAddress {
  contactAddressId: string;
  contactId: string;
  addressId: string;
  city: string;
  countryCode: string;
  postalCode: string;
  suiteApartment: string;
  stateProvince: string;
  streetAddress: string;
}

export interface UserPhoneNumbers {
  contactPhoneNumberId: string;
  countryCode: string;
  phoneNumber: string;
  phoneNumberId: string;
  type: string;
  contactId: string;
}
