import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  TixEventCompanyVenue,
  TixVenue,
  TixVenueTicket
} from '@tix/data-access';

import * as EventApiActions from './actions/events-api.actions';
import * as EventAction from './actions/events.actions';

export const EVENTS_FEATURE_KEY = 'events';

/**
 * Needed by {@link createEntityAdapter} to find the primary id of a {@link CompanyEntity}
 * @param {CompanyEntity} company
 * @returns {string}
 */
export function selectEventId(event: TixEventCompanyVenue): string {
  return event?.eventInstanceId;
}

// export function sortEvents(a: TixEventCompanyVenue, b: TixEventCompanyVenue): number {
//   return a.eventName.localeCompare(b.eventName);
// }

export interface State extends EntityState<TixEventCompanyVenue> {
  selectedEventId?: string;
  venues?: TixVenue[];
  venuesTickets?: TixVenueTicket[];
  totalEventsMatchingSearch?: number;
  loaded: boolean; // has the Events list been loaded
  loading: boolean;
  error?: string | null; // last known error (if any),
  loadedVenues: boolean;
  createRecurringEventsLoading?: boolean;
  createRecurringEventsLoaded?: boolean;
  idofClonedEvent?: string;
}

export interface TixEventsPartialState {
  readonly [EVENTS_FEATURE_KEY]: State;
}

export const eventsAdapter: EntityAdapter<TixEventCompanyVenue> =
  createEntityAdapter<TixEventCompanyVenue>({
    selectId: selectEventId
  });

export const initialState: State = eventsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  loadedVenues: false
});

const eventsReducer = createReducer(
  initialState,
  on(EventAction.loadEvents, state => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(EventApiActions.loadEventsSuccess, (state, { events, total }) =>
    eventsAdapter.setAll(events, {
      ...state,
      loaded: true,
      totalEventsMatchingSearch: total
    })
  ),
  on(EventApiActions.loadEventsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(EventAction.selectedEvent, (state, { eventInstanceId }) => {
    return { ...state, selectedEventId: eventInstanceId };
  }),
  on(EventAction.selectIdOfClonedEvent, (state, { idofClonedEvent }) => {
    return { ...state, idofClonedEvent };
  }),

  on(EventAction.deselectEvent, state => {
    delete state.selectedEventId;
    return { ...state };
  }),
  on(EventAction.loadEventById, (state, { eventInstanceId }) => {
    return { ...state, selectedEventId: eventInstanceId };
  }),
  on(EventApiActions.loadEventSuccess, (state, { event }) => {
    return eventsAdapter.setAll(event, { ...state });
  }),

  on(EventAction.getVenueList, state => ({
    ...state,
    loadedVenues: false,
    error: null
  })), // Todo: Check commenting this line effects the events page
  on(EventApiActions.getVenueListSuccess, (state, { venues }) => {
    return { ...state, venues: venues };
  }),
  on(EventApiActions.getVenueListFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(EventAction.getVenueTicketConfigurationList, state => ({
    ...state,
    loadedVenues: false,
    error: null
  })),
  on(
    EventApiActions.getVenueTicketConfigurationListSuccess,
    (state, { venueTickets }) => {
      return { ...state, venuesTickets: venueTickets };
    }
  ),
  on(
    EventApiActions.getVenueTicketConfigurationListFailure,
    (state, { error }) => ({ ...state, error })
  ),

  on(EventAction.addNewEventInCompany, (state, { addEventObj }) => ({
    ...state,
    event: addEventObj,
    loaded: false,
    error: null,
    loading: true
  })),
  on(EventApiActions.addNewEventInCompanySuccess, (state, action) =>
    eventsAdapter.addOne(action.newRecord, {
      ...state,
      loaded: true,
      loading: false
    })
  ),
  on(EventApiActions.addNewEventInCompanyFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: false
  })),
  on(EventAction.addRecurringEventInCompany, (state, action) => ({
    ...state,
    createRecurringEventsLoaded: false,
    createRecurringEventsLoading: true
  })),
  on(EventApiActions.addRecurringEventsSuccess, (state, action) => ({
    ...state,
    createRecurringEventsLoaded: true,
    createRecurringEventsLoading: false
  })),
  on(EventApiActions.addRecurringEventFailure, (state, { error }) => ({
    ...state,
    error,
    createRecurringEventsLoaded: true,
    createRecurringEventsLoading: false
  })),

  on(EventAction.resetLoadStateEvent, state => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(EventAction.updateEventInCompany, (state, { updatedObj }) => ({
    ...state,
    event: updatedObj,
    loaded: false,
    error: null
  })),
  on(
    EventApiActions.updateEventInstanceSuccess,
    (state, action) => (action.updatedRecord, { ...state })
  ),
  on(EventApiActions.updateEventInstanceFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(EventApiActions.deleteEventPerformerFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(EventAction.clearState, () => ({ ...initialState }))
);

export function reducer(state: State | undefined, action: Action) {
  return eventsReducer(state, action);
}
