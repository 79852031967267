import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import {
  TixCreatePayoutDetailGQL,
  TixCreatePayoutGQL,
  TixGetEventByEventInstanceIdGQL,
  TixGetPayoutByEventInstanceIdGQL,
  TixGetReservationByIdGQL
} from '@tix/data-access';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {
  userId$ = this.store
    .select(UserSelector.getAuthenticatedUser)
    .pipe(map(u => u?.uid));

  constructor(
    private store: Store,
    private createPayoutMutation: TixCreatePayoutGQL,
    private createPayoutDetailMutation: TixCreatePayoutDetailGQL,
    private getEventInstanceByIdQuery: TixGetEventByEventInstanceIdGQL,
    private getReservationByIdQuery: TixGetReservationByIdGQL,
    private getPayoutQuery: TixGetPayoutByEventInstanceIdGQL
  ) {}

  async getUserId() {
    return this.userId$.pipe(take(1)).toPromise();
  }

  async createPayout({
    companyId,
    eventInstanceId,
    venueId,
    eventName,
    eventDate,
    eventStartTime,
    uid
  }: {
    companyId: string;
    venueId: string;
    eventInstanceId: string;
    eventName: string;
    eventDate: string;
    eventStartTime: string;
    uid?: string;
  }) {
    const payout = (
      await this.getPayoutQuery
        .fetch({ eventInstanceId }, { fetchPolicy: 'no-cache' })
        .toPromise()
    ).data.Payout[0];

    if (payout) return;

    const userId = await this.getUserId();
    const res = await this.createPayoutMutation
      .mutate({
        companyId,
        venueId,
        eventInstanceId,
        updatedBy: userId || uid,
        createdBy: userId || uid,
        description: `Payout for ${eventName} on ${moment(eventDate).format(
          'MM/DD/YYYY'
        )} at ${moment(eventStartTime, 'HH:mm:ss').format('hh:mm A')}`
      })
      .toPromise();
  }

  async createPayoutDetail({
    eventInstanceId,
    reservationId
  }: {
    reservationId: string;
    eventInstanceId: string;
  }) {
    const payout = (
      await this.getPayoutQuery
        .fetch({ eventInstanceId }, { fetchPolicy: 'no-cache' })
        .toPromise()
    ).data.Payout[0];

    let userId = await this.getUserId();
    if (!userId) {
      const reservation = (
        await this.getReservationByIdQuery.fetch({ reservationId }).toPromise()
      ).data.Reservation[0];
      userId = reservation.contactId;
    }

    if (!payout) {
      const event = (
        await this.getEventInstanceByIdQuery
          .fetch({ eventInstanceId })
          .toPromise()
      ).data.EventInstance[0];

      await this.createPayout({
        companyId: event.venue?.companyId,
        venueId: event.venueId,
        eventDate: event.date,
        eventInstanceId,
        eventName: event.name ?? '',
        eventStartTime: event.startTime,
        uid: userId
      });
      this.createPayoutDetail({ eventInstanceId, reservationId });
      return;
    }

    this.createPayoutDetailMutation
      .mutate({
        payoutId: payout.payoutId,
        reservationId,
        createdBy: userId,
        updatedBy: userId
      })
      .toPromise();
  }
}
