import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ContentChild,
  ElementRef,
  EventEmitter,
  Output,
  HostListener,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, take, switchMapTo, takeUntil } from 'rxjs/operators';
import { EditModeDirective, ViewModeDirective } from './mode';

@Component({
  selector: 'tix-inline-edit',
  template: ` <ng-container *ngTemplateOutlet="currentView"></ng-container> `,
  styleUrls: ['./inline-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditComponent implements OnInit, OnDestroy {
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;

  mode: 'view' | 'edit' = 'view';

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private host: ElementRef, private cd: ChangeDetectorRef) {}

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, 'dblclick')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
        this.cd.markForCheck();
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      takeUntil(this._unsubscribeAll),
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    );

    this.editMode$.pipe(switchMapTo(clickOutside$)).subscribe(() => {
      this.update.next();
      this.mode = 'view';
      this.cd.markForCheck();
    });
  }

  @HostListener('keyup.enter')
  onEnter() {
    this.toViewMode();
  }

  toViewMode() {
    this.update.next();
    this.mode = 'view';
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
