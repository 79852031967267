import { Component, OnInit, ViewEncapsulation, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'tix-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  encapsulation: ViewEncapsulation.Emulated
})
export class StepperComponent extends CdkStepper {
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [StepperComponent],
  exports: [StepperComponent]
})
export class StepperComponentModule {}
