import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TixEventCampaignTracking } from '@tix/data-access';
import { PortalEvent } from './portal-events.service';

export type PURCHASE_EVENT = {
  eventName: string;
  venueName: string;
  localDate: Date;
  totalAmount: string;
  ticketsQuantity: number;
  productsQuantity: number;
  value: number;
  currency: string;
  eventDate: string;
  eventTime: string;
};

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  protected pixelsList: PortalEvent['campaign'];
  set pixels(value: PortalEvent['campaign']) {
    this.pixelsList = value;
  }
  injectMetaPixel() {
    const metaPixelIds = this.pixelsList
      .map((pixel: TixEventCampaignTracking) => {
        if (pixel.campaignType === 'Meta Pixel') {
          return `fbq('init', '${pixel.campaignCode}');`;
        }
        return null;
      })
      .filter(Boolean)
      .join('\n');
    const pixel = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    ${metaPixelIds}
    fbq('track', 'PageView');
    `;
    const metaPixelScript = this.renderer.createElement('script');
    metaPixelScript.text = pixel;
    this.renderer.appendChild(document.head, metaPixelScript);
  }

  trackPurchaseEvent(purchaseEvent: PURCHASE_EVENT) {
    this.pixelsList.forEach(pixel => {
      if (pixel.campaignType === 'Meta Pixel') {
        (window as any).fbq(
          'trackSingle',
          pixel.campaignCode,
          'Purchase',
          purchaseEvent
        );
      }
    });
  }
}
