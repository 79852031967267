import { Injectable } from '@angular/core';
import {
  TICKET_HOLD_TTL_IN_SECONDS,
  TicketHoldService
} from '@tix/ticket-hold';
import {
  TixGetEventTicketConfigurationsGQL,
  TixGetTotalNumberTicketsSoldForEventGQL
} from '@tix/data-access';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserSessionStorage } from '@tix/shared/utils';
import { PortalEvent } from '@tix/event-buyer/services';

export type TicketConfiguration =
  PortalEvent['Tickets'][0]['Configurations'][0]['TicketConfigurations'];
export type TixOrder = Map<TicketConfiguration, number>;

@Injectable({
  providedIn: 'root'
})
export class TicketSelectionManagerService {
  constructor(
    private ticketHoldService: TicketHoldService,
    private getEventTicketConfigurationsQuery: TixGetEventTicketConfigurationsGQL,
    private getTotalNumberTicketsSoldForEventQuery: TixGetTotalNumberTicketsSoldForEventGQL
  ) {}

  private heldTicketsAt?: Date;
  private orderHoldTimeout?: number;
  private orderHoldExpiredBS = new Subject<void>();

  public orderHoldExpired$ = this.orderHoldExpiredBS.asObservable();

  private async getNumberOfTicketsOnHoldForPool(
    parentTicketId: string,
    ticketConfigurations: TicketConfiguration[]
  ): Promise<number> {
    if (!parentTicketId) return 0;
    const configurationsInPool = ticketConfigurations.filter(
      config =>
        config.parentTicketId === parentTicketId ||
        config.ticketConfigId === parentTicketId
    );

    const onHold = await this.ticketHoldService.getTicketsOnHoldByOtherUsers(
      UserSessionStorage.getInstance().getUserSessionId(),
      configurationsInPool.map(c => c.ticketConfigId)
    );

    return onHold.reduce((total, cur) => total + cur.onHold, 0);
  }

  private getEventTicketConfigurations(
    eventId: string,
    cache = false
  ): Promise<TicketConfiguration[]> {
    return this.getEventTicketConfigurationsQuery
      .fetch({ eventInstanceId: eventId }, { fetchPolicy: 'no-cache' })
      .pipe(
        map(res => {
          const ticketConfigurations: TicketConfiguration[] = [];

          for (const ticket of res.data.EventInstanceByPK
            ?.eventInstanceTickets || []) {
            for (const config of ticket.Configurations) {
              ticketConfigurations.push(
                config.TicketConfigurations as TicketConfiguration
              );
            }
          }

          return ticketConfigurations;
        })
      )
      .toPromise();
  }

  async getNumberOfTicketsRemainingForConfiguration(
    ticket: TicketConfiguration,
    order: TixOrder,
    eventInstanceId: string
  ): Promise<number> {
    const tickets = await this.getEventTicketConfigurations(eventInstanceId);

    if (ticket.parentTicketId) {
      const onHoldInPool = await this.getNumberOfTicketsOnHoldForPool(
        ticket.parentTicketId,
        tickets
      );
      const ticketChildrenOnHold = await this.getNumberOfTicketsOnHoldForPool(
        ticket.ticketConfigId,
        tickets
      );
      const ticketsInPool = tickets.filter(
        t =>
          t.parentTicketId === ticket.parentTicketId ||
          t.ticketConfigId === ticket.parentTicketId
      );
      const ticketsSoldFromPool = ticketsInPool
        .filter(t => t.ticketConfigId !== ticket.parentTicketId)
        .reduce((total, t) => total + t.ticketConfigTotals?.ticketsSold, 0);
      const currentlySelected = ticketsInPool
        .filter(t => t.ticketConfigId !== ticket.ticketConfigId)
        .reduce((total, t) => {
          const ticketInOrder = Array.from(order.keys()).find(
            ticket => ticket.ticketConfigId === t.ticketConfigId
          );
          return total + (order.get(ticketInOrder as TicketConfiguration) ?? 0);
        }, 0);

      /*
      (tickets.find(t => t.ticketConfigId === ticket.parentTicketId)
          ?.ticketConfigTotals?.ticketsRemaining ?? 0) -
          (ticket.noOfSeats ?? 0) */

      const parentTicketRemainingTickets = tickets.find(
        t => t.ticketConfigId === ticket.parentTicketId
      )?.ticketConfigTotals?.ticketsRemaining;

      const childTicketRemainingTickets = tickets.find(
        t => t.ticketConfigId === ticket.ticketConfigId
      )?.ticketConfigTotals?.ticketsRemaining;

      const selectableForPool =
        parentTicketRemainingTickets -
        ticketsSoldFromPool -
        currentlySelected -
        onHoldInPool;

      const selectableForTicket =
        childTicketRemainingTickets - ticketChildrenOnHold;

      if (ticket.noOfSeats) {
        return Math.min(selectableForPool, selectableForTicket);
      }
      return Math.max(selectableForPool, 0);
    } else {
      const onHold = await this.getNumberOfTicketsOnHoldForPool(
        ticket.ticketConfigId,
        tickets
      );

      const ticketsInPool = tickets.filter(
        t =>
          t.parentTicketId === ticket.ticketConfigId ||
          t.ticketConfigId === ticket.ticketConfigId
      );
      const ticketsSoldFromPool = ticketsInPool.reduce(
        (total, t) => total + t.ticketConfigTotals?.ticketsSold,
        0
      );
      const currentlySelected = ticketsInPool
        .filter(t => t.ticketConfigId !== ticket.ticketConfigId)
        .reduce((total, t) => {
          const ticketInOrder = Array.from(order.keys()).find(
            ticket => ticket.ticketConfigId === t.ticketConfigId
          );
          return total + (order.get(ticketInOrder as TicketConfiguration) ?? 0);
        }, 0);

      return Math.max(
        (ticket.noOfSeats ?? 0) -
          ticketsSoldFromPool -
          currentlySelected -
          onHold,
        0
      );
    }
  }

  getNumberOfTicketsSelectableForConfiguration(
    ticket: TicketConfiguration,
    remaining: number
  ): number {
    const perOrderMax = ticket.perOrderMax || Infinity;

    return Math.min(perOrderMax, remaining);
  }

  async verifyOrderTicketsAvailable(
    order: TixOrder,
    eventInstanceId: string
  ): Promise<boolean> {
    console.log(order, eventInstanceId);

    const tickets = Array.from(order.keys());
    const promises: Promise<number>[] = tickets.map(ticket =>
      this.getNumberOfTicketsRemainingForConfiguration(
        ticket,
        order,
        eventInstanceId
      )
    );

    const quantitiesAvailable = await Promise.all(promises);

    return quantitiesAvailable.every(
      (quantity, i) =>
        Math.max(quantity, 0) >= (order.get(tickets[i]) as number)
    );
  }

  async reserveOrderToHold(order: TixOrder): Promise<void> {
    const tickets = Array.from(order.keys());

    await this.ticketHoldService.addOrderToHoldForUserSession(
      UserSessionStorage.getInstance().getUserSessionId(),
      tickets.map(t => ({
        onHold: order.get(t) as number,
        ticketConfigId: t.ticketConfigId
      }))
    );

    this.heldTicketsAt = new Date();

    this.orderHoldTimeout = window.setTimeout(() => {
      this.orderHoldExpiredBS.next();
      this.orderHoldTimeout = undefined;
    }, TICKET_HOLD_TTL_IN_SECONDS * 1000);
  }

  async removeOrderFromHold(): Promise<void> {
    await this.ticketHoldService.removeOrderFromHoldForUserSession(
      UserSessionStorage.getInstance().getUserSessionId()
    );

    clearTimeout(this.orderHoldTimeout);
    this.heldTicketsAt = undefined;
    this.orderHoldTimeout = undefined;
  }

  getTimeSinceOrderHeld(): number {
    if (!this.heldTicketsAt) throw new Error('No order is in hold.');

    return new Date().getTime() - this.heldTicketsAt?.getTime();
  }

  async verifyEventOnlineTicketsAvailable(
    eventInstanceId: string
  ): Promise<boolean> {
    const res = await this.getTotalNumberTicketsSoldForEventQuery
      .fetch({ eventInstanceId })
      .toPromise();

    const totalSold = res.data.TicketAggregate.aggregate?.count;

    const configurations = await this.getEventTicketConfigurations(
      eventInstanceId
    );

    const onlineQuantity = configurations
      .filter(
        config =>
          ['online', 'both', ''].includes(
            config.salesChannel?.toLowerCase() ?? ''
          ) && !config.parentTicketId
      )
      .reduce((total, config) => total + (config.noOfSeats ?? 0), 0);

    return onlineQuantity > (totalSold ?? 0);
  }
}
