import * as moment from 'moment-timezone';
import { ENUM_DATE_FORMAT } from '../constants/common.enum.constant';

export class HelperDateService {
  format(value: string | Date, format?: ENUM_DATE_FORMAT) {
    return moment(value).format(format ?? ENUM_DATE_FORMAT.YYYY_MM_DD);
  }

  utc(date: string | Date, format?: ENUM_DATE_FORMAT) {
    return moment
      .utc(date)
      .format(format ?? ENUM_DATE_FORMAT.YYYY_MM_DD_T_HH_MM_SS);
  }

  today(format?: ENUM_DATE_FORMAT) {
    return moment().format(format ?? ENUM_DATE_FORMAT.YYYY_MM_DD_T_HH_MM_SS);
  }

  /**
   * To review: maybe we need one function to handle UTCs!
   */
  getLocalDateAsUTC(date: string | Date) {
    return moment(date).utc().toDate();
  }

  /**
   * Includes date and time
   */
  now() {
    const tz = moment.tz.guess();
    return moment().tz(tz).format(ENUM_DATE_FORMAT.YYYY_MM_DD_T_HH_MM_SS);
  }
}
