<tix-card class="bg-white px-8 py-8 min-w-[35vw]">
  <tix-card-title>
    <h2>Verify Discount Passcode</h2>
  </tix-card-title>

  <form (submit)="handleSubmit($event)">
    <div class="mt-8 mb-5">
      <mat-form-field>
        <mat-label>Passcode</mat-label>
        <input
          autocomplete="off"
          type="password"
          name="passcode"
          matInput
          [formControl]="passcodeControl"
        />
        <mat-error>Invalid discount code</mat-error>
      </mat-form-field>
    </div>

    <div class="flex justify-end flex-col-reverse lg:flex-row">
      <button
        tix-flat-border-button
        (click)="handleCancel()"
        class="lg:mr-4 mt-4 lg:mt-0 w-full lg:w-auto"
        type="button"
      >
        Cancel
      </button>
      <button
        tix-button
        [disabled]="isLoading || passcodeControl.invalid"
        type="submit"
        class="w-full lg:w-auto"
      >
        <span *ngIf="!isLoading">Verify</span>
        <span *tixBtnLoading="isLoading"></span>
      </button>
    </div>
  </form>
</tix-card>
