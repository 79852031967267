<tix-card>
  <h3 class="errorMessage">{{ errorMessage }}</h3>
</tix-card>
<div
  [class]="
    showIsSoldOut || showIsExpired
      ? 'event-card sold-out-card '
      : availableSeatCount <= 0 && !isGenericEvent
      ? 'event-card free-card '
      : isGenericEvent
      ? 'event-card !border-l-blue-500'
      : 'event-card'
  "
>
  <div class="event-card-image">
    <img [src]="eventImage" alt="" class="cursor-pointer" (click)="toggle()" />
  </div>
  <div class="event-card-detail">
    <div class="event_card_half_wrap" (click)="toggle()">
      <h3>{{ event?.eventName }}</h3>
      <div
        *ngIf="socialMediaList.length"
        class="flex items-center flex-wrap space-y-0.5 mb-3"
      >
        <a
          *ngFor="let socialMedia of socialMediaList"
          [href]="socialMedia.socialMedia.url"
          class="mr-2"
          target="_blank"
        >
          <tix-icon
            [svgIcon]="socialMedia.socialMedia.socialMediaType | lowercase"
            class="text-gray-400 hover:text-gray-500 md:!w-6 md:!h-6 !w-5 !h-5"
          ></tix-icon>
        </a>
      </div>
      <p>
        {{ event?.eventDescription }}
      </p>
      <h6>
        <div class="event-time-view">
          <tix-icon svgIcon="clock-icon-solid"></tix-icon><label>Time:</label>
          <span>
            {{ event?.date | date: 'E' | uppercase }} •
            {{ event?.date | date: 'MMM d' | uppercase }}
            <ng-container *ngIf="event?.startTime">
              • {{ formatTime(event?.startTime) }}
            </ng-container>
          </span>
        </div>
        <div class="event-date-view">
          <pre>(</pre>
          <label>Doors Open: </label>
          <span *ngIf="event?.doorsOpen">
            {{ formatTime(event?.doorsOpen) }}</span
          >
          <span *ngIf="!event?.doorsOpen"> N/A</span>
          <pre>)</pre>
        </div>
      </h6>
    </div>
    <div class="cursor-pointer ticket-price" (click)="toggle()">
      <span *ngIf="!isGenericEvent"
        ><b [class]="showIsSoldOut || showIsExpired ? 'sold' : ''"
          >{{ displayPrice }}
        </b>
        <p class="hidden sm:inline-block">
          <ng-container
            *ngIf="!(!ticketMinValue || showIsSoldOut || showIsExpired)"
            >/Ticket</ng-container
          >
        </p>
      </span>
      <div class="mobile_view_image">
        <img [src]="eventImage" alt="" />
      </div>
    </div>
    <ul [class.!ml-0]="isGenericEvent">
      <li>
        <label>{{ event?.eventType }}</label>
      </li>
    </ul>
  </div>
  <div class="event-card-purchase" *ngIf="!showIsSoldOut" (click)="toggle()">
    <button type="button">{{ ctaButtonText }}</button>
  </div>
  <div
    class="event-card-accordian"
    [ngClass]="{ 'open-event-accordian': toggleAccordian }"
  >
    <div class="ticket-accordian-body">
      <ng-container *ngIf="!isGenericEvent">
        <div class="ticket-promo-header-title">
          <h3 *ngIf="!showIsSoldOut; else noSeats">
            Buy Tickets
            <!-- <label>Only {{ event?.Metrics?.available }} seats left</label> -->
          </h3>
          <ng-template #noSeats>
            <h3>Buy Tickets<label>No seats left</label></h3>
          </ng-template>
          <p *ngIf="event?.minimumAge">Minimum Age: {{ event?.minimumAge }}</p>
        </div>
        <div class="ticket-promocode">
          <tix-discountcode-input
            (invalidDiscountApply)="invalidDiscount = $event"
            (applyDiscount)="onApplyDiscount($event)"
            [companyId]="event.venue?.companyId"
            [eventId]="event.eventInstanceId"
            (updateError)="onDiscountErrorUpdate($event)"
            [venueId]="event.venueId"
            [isApplied]="!!appliedDiscount"
            [ticketsSelectedCount]="totalTickets"
          ></tix-discountcode-input>
        </div>
        <button
          type="button"
          [ngClass]="{
            'checkout-btn mb-4 md:mb-0': true,
            'cursor-not-allowed !bg-gray-100 !text-gray-800':
              isValidatingOrder ||
              isDisable ||
              orderEmpty ||
              showIsSoldOut ||
              showIsExpired
          }"
          (click)="checkOut()"
          [disabled]="
            isDisable ||
            isValidatingOrder ||
            orderEmpty ||
            showIsSoldOut ||
            showIsExpired
          "
        >
          <ng-container *ngIf="isValidatingOrder; else defaultCheckoutText"
            >Verifying...</ng-container
          >
          <ng-template #defaultCheckoutText> Checkout </ng-template>
        </button>

        <div
          *ngIf="invalidDiscount || discountError"
          class="hidden w-full px-6 py-3 text-red-500 bg-red-400 rounded-md sm:block bg-opacity-10"
        >
          {{ discountError || 'Invalid Discount Code' }}
        </div>

        <div
          *ngIf="appliedDiscount"
          class="hidden w-full px-6 py-3 text-green-500 bg-green-400 rounded-md sm:block bg-opacity-10"
        >
          Applied discount:
          <ng-container *ngIf="appliedDiscount.type === '$ Discount'">
            {{ appliedDiscount.getAmount | currency }} Discount
            <span *ngIf="appliedDiscount.appliedTo === 'Ticket'"
              >Per Ticket</span
            >
          </ng-container>

          <ng-container *ngIf="appliedDiscount.type === '% Discount'">
            {{ appliedDiscount.getAmount }}% Discount
            <span *ngIf="appliedDiscount.appliedTo === 'Ticket'"
              >Per Ticket</span
            >
          </ng-container>

          <ng-container *ngIf="appliedDiscount.type === 'Buy X Get Y Free'">
            Buy {{ appliedDiscount.buyAmount }}, Get
            {{ appliedDiscount.getAmount }} Free
          </ng-container>

          <ng-container *ngIf="appliedDiscount.type === 'Spend $X, Get $Y Off'">
            Spend {{ appliedDiscount.buyAmount | currency }}, Get
            {{ appliedDiscount.getAmount | currency }} Off
          </ng-container>

          <ng-container *ngIf="appliedDiscount.type === 'Pre-Sale'">
            Pre-Sale Discount
          </ng-container>
        </div>
        <div class="ticket-table" *ngIf="onlineConfigurations">
          <div class="ticket-table-head">
            <div class="ticket-table-head-row">
              <div class="ticket-table-head-item">
                <label>TICKET NAME </label>
              </div>
              <div class="ticket-table-head-item">
                <label>ITEM PRICE </label>
              </div>
              <div class="ticket-table-head-item">
                <label>SRV FEE</label>
              </div>
              <!-- <div class="ticket-table-head-item">
              <label>REMAINING</label>
            </div> -->
              <div class="ticket-table-head-item">
                <label>QTY</label>
              </div>
            </div>
          </div>
          <div
            class="ticket-table-body"
            *ngIf="orderSelectionInitialized; else validatingTicketHold"
          >
            <div
              class="ticket-table-body-row"
              *ngFor="let item of onlineConfigurations; let i = index"
              [ngClass]="{
                'cursor-not-allowed opacity-40':
                  itemIsExpired(item.ticketConfigId) ||
                  itemComingSoon(item.ticketConfigId) ||
                  orderStateManager.getQuantityAvailableForItem(
                    item.ticketConfigId
                  ) <= 0
              }"
            >
              <div class="ticket-table-body-item">
                <div class="flex items-center">
                  <div
                    *ngIf="
                      item.helpText != null &&
                      item.helpTextTitle != null &&
                      item.helpText?.length != 0 &&
                      item.helpTextTitle?.length != 0
                    "
                    class="w-7 flex justify-start items-center"
                  >
                    <tix-icon
                      matTooltip="Click for more info"
                      (click)="
                        openInfoDialog(item.helpTextTitle, item.helpText)
                      "
                      class="w-6 !text-black cursor-pointer"
                      svgIcon="ticket-info-icon"
                    ></tix-icon>
                  </div>
                  <h6
                    matTooltipClass="whitespace-pre-line"
                    [matTooltip]="
                      'Name: ' +
                      item.name +
                      '\n' +
                      'Section: ' +
                      item.section +
                      '\n' +
                      'Type: ' +
                      item.ticketType +
                      '\n'
                    "
                  >
                    {{ item.name }}
                  </h6>
                </div>
              </div>
              <div class="ticket-table-body-item">
                <h6>{{ item.ticketPrice }}</h6>
              </div>
              <div class="ticket-table-body-item">
                <label class="ticket-mobile-label">Srv Fee:</label>
                <h6>
                  {{
                    item.includeFees
                      ? 'Included in price'
                      : (getTotal(
                          item.platformFee,
                          item.processingFee,
                          item.venueFee
                        ) | currency)
                  }}
                </h6>
              </div>

              <!-- <div class="ticket-table-body-item">
              <label class="ticket-mobile-label">Tickets remaining</label>

              <h6>{{ item.ticketConfigTotals.ticketsRemaining - item.ticketQty }}</h6>
            </div> -->
              <div class="ticket-table-body-item md:!mx-auto">
                <div
                  [ngClass]="{
                    'ticket-qty': true
                  }"
                  [matTooltipDisabled]="itemIsSoldOut(item.ticketConfigId)"
                  [matTooltipDisabled]="
                    orderStateManager.getQuantityAvailableForItem(
                      item.ticketConfigId
                    ) < 1
                  "
                  [matTooltip]="getTooltipText(item)"
                >
                  <ng-container
                    *ngIf="
                      itemIsSoldOut(item.ticketConfigId) === null;
                      else afterTicketLoading
                    "
                  >
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </ng-container>
                  <ng-template #afterTicketLoading>
                    <ng-container
                      *ngIf="
                        itemIsSoldOut(item.ticketConfigId) !== null &&
                          !itemIsSoldOut(item.ticketConfigId) &&
                          !itemIsExpired(item.ticketConfigId) &&
                          !itemComingSoon(item.ticketConfigId);
                        else soldOutOrExpiredOrComingSoon
                      "
                    >
                      <button
                        type="button"
                        class="ticket-qty-btn-minus"
                        [disabled]="
                          !orderStateManager.canDecrementQuantity(
                            item.ticketConfigId
                          )
                        "
                        (click)="itemDecrementClick(item.ticketConfigId)"
                      >
                        -
                      </button>
                      <label>{{
                        orderStateManager.getQuantitySelectedForItem(
                          item.ticketConfigId
                        )
                      }}</label>
                      <button
                        type="button"
                        [ngClass]="{
                          'ticket-qty-btn-plus': true,
                          'opacity-70 cursor-not-allowed':
                            !orderStateManager.canIncrementQuantity(
                              item.ticketConfigId
                            )
                        }"
                        (click)="itemIncrementClick(item.ticketConfigId)"
                        [disabled]="
                          !orderStateManager.canIncrementQuantity(
                            item.ticketConfigId
                          )
                        "
                      >
                        +
                      </button>
                    </ng-container>
                  </ng-template>
                  <ng-template #soldOutOrExpiredOrComingSoon>
                    <span
                      class="text-red-500"
                      *ngIf="
                        itemIsSoldOut(item.ticketConfigId) &&
                        !itemIsExpired(item.ticketConfigId) &&
                        !itemComingSoon(item.ticketConfigId)
                      "
                    >
                      SOLD OUT
                    </span>
                    <span
                      class="text-green-500"
                      *ngIf="
                        itemComingSoon(item.ticketConfigId) &&
                        !itemIsExpired(item.ticketConfigId)
                      "
                    >
                      COMING SOON
                    </span>
                    <span
                      class="text-red-500"
                      *ngIf="itemIsExpired(item.ticketConfigId)"
                    >
                      EXPIRED
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <ng-template #validatingTicketHold>
            <div class="flex items-center justify-center my-6 w-full">
              <mat-spinner></mat-spinner>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <div class="occasion-info w-100">
        <ng-container *ngIf="!isGenericEvent">
          <div
            *ngIf="invalidDiscount"
            class="w-full px-6 py-3 mb-2 text-red-500 bg-red-400 rounded-md sm:hidden bg-opacity-10"
          >
            Invalid discount code
          </div>

          <div
            *ngIf="appliedDiscount"
            class="w-full px-6 py-3 mb-2 text-green-500 bg-green-400 rounded-md sm:hidden bg-opacity-10"
          >
            Applied discount:
            <ng-container *ngIf="appliedDiscount.type === '$ Discount'">
              {{ appliedDiscount.getAmount | currency }} Discount
            </ng-container>

            <ng-container *ngIf="appliedDiscount.type === '% Discount'">
              {{ appliedDiscount.getAmount }}% Discount
            </ng-container>

            <ng-container
              *ngIf="appliedDiscount.type === 'Spend $X, Get $Y Off'"
            >
              Spend {{ appliedDiscount.buyAmount | currency }}, Get
              {{ appliedDiscount.getAmount | currency }} Off
            </ng-container>

            <ng-container
              *ngIf="appliedDiscount.type === 'Spend $X, Get $Y Off'"
            >
              Spend {{ appliedDiscount.buyAmount | currency }}, Get
              {{ appliedDiscount.getAmount | currency }} Off
            </ng-container>

            <ng-container *ngIf="appliedDiscount.type === 'Pre-Sale'">
              Pre-Sale Discount
            </ng-container>
          </div>
        </ng-container>
        <div class="additional-ticket-header ticket-header">
          <h3 class="ticket-header-title">Additional Event Info</h3>
        </div>
        <div class="additional-event-info !order-8">
          <quill-view-html
            [content]="event.eventAdditionalInfo ?? ''"
          ></quill-view-html>
        </div>

        <div class="ticket-notes" *ngIf="eTicketMessage | async as message">
          <p *ngIf="!isGenericEvent">
            {{ message }}
          </p>

          <tix-event-youtube-video
            *ngIf="youtubeVideoUrl"
            class="block w-full mt-4"
            [youtubeVideoUrl]="youtubeVideoUrl"
          ></tix-event-youtube-video>
        </div>
        <div class="w-full venue-warnings" *ngIf="warningsLength > 0">
          <h3
            class="mt-2 mb-2 text-sm font-semibold sm:text-2xl text-zinc-900 lg:mt-0"
          >
            Venue Message
          </h3>

          <tix-alert
            *ngFor="let warn of venueDetail?.warnings"
            [alertType]="warn?.warning?.type === 'Important' ? 'warn' : 'info'"
            [alertMessage]="warn?.warning?.message ?? 'no message to display'"
          ></tix-alert>
        </div>
      </div>
    </div>
  </div>
</div>
