import { Injectable } from '@angular/core';
import {
  TixDeleteScheduledEventReportGQL,
  TixGetScheduledEventReportGQL,
  TixInsertScheduledEventReportGQL,
  TixScheduledReportInput,
  TixUpdateScheduledEventReportGQL
} from '@tix/data-access';
import { map, take } from 'rxjs/operators';
import { v4 } from 'uuid';

@Injectable()
export class EventReportSubscriptionFacade {
  constructor(
    private _getScheduledEventReport: TixGetScheduledEventReportGQL,
    private _insertScheduledEventReport: TixInsertScheduledEventReportGQL,
    private _updateScheduledEventReport: TixUpdateScheduledEventReportGQL,
    private _deleteScheduledEventReport: TixDeleteScheduledEventReportGQL
  ) {}

  get$(eventId: string) {
    return this._getScheduledEventReport
      .fetch(
        {
          eventId
        },
        { fetchPolicy: 'no-cache' }
      )
      .pipe(
        map(res => {
          const eventInstanceConfigValue =
            res.data.EventInstanceConfigurationValue;
          const configValue = res.data.ConfigurationItem;
          // init form
          const reportForm: Record<number, any> = {};

          for (let idx = 0; idx < configValue.length; idx++) {
            reportForm[idx] = {
              ...configValue[idx]
            };
          }
          // dont try to use one loop
          if (eventInstanceConfigValue.length > 0) {
            for (let idx = 0; idx < eventInstanceConfigValue.length; idx++) {
              const _config = eventInstanceConfigValue[idx];
              reportForm[idx] = {
                ...reportForm[idx], // same as configValue[idx]
                value: _config?.value?.value,
                valueId: _config?.value?.configurationValueId
              };
            }
          }

          return reportForm;
        }),
        take(1)
      );
  }

  save(
    values: {
      configurationItemId: string;
      value: string | number | boolean;
    }[],
    eventInstanceId: string,
    updatedBy: string,
    scheduledEventReport: TixScheduledReportInput
  ) {
    const updatedAt = 'now';
    const state = 'Active';
    const configurationValues: any[] = [];
    const eventInstanceConfigurationValues = [];

    for (let i = 0; i < values.length; i++) {
      const { value, configurationItemId } = values[i];
      // 0
      const configurationValueId = this.#uuid();
      const configurationValue = {
        value,
        configurationItemId,
        configurationValueId,
        state,
        updatedAt,
        updatedBy
      };
      configurationValues.push(configurationValue);

      // 1
      const eventInstanceConfigurationId = this.#uuid();
      const eventInstanceConfigurationValue = {
        configurationValueId,
        eventInstanceConfigurationId,
        eventInstanceId,
        state,
        updatedAt,
        updatedBy,
        createdAt: updatedAt,
        createdBy: updatedBy
      };
      eventInstanceConfigurationValues.push(eventInstanceConfigurationValue);
    }

    return this._insertScheduledEventReport
      .mutate({
        configurationValues,
        eventInstanceConfigurationValues,
        scheduledReport: scheduledEventReport
      })
      .pipe(
        map(res => {
          return {
            ...res,
            values: configurationValues.map(v => ({
              value: v.value,
              valueId: v.configurationValueId
            }))
          };
        }),
        take(1)
      );
  }

  /**
   * Only values table is updated
   */
  update(
    updated: { id: string; value: string }[],
    updatedBy: string,
    oldNew: Record<string, TixScheduledReportInput>
  ) {
    const updatedValues = updated.map(_value => {
      return {
        where: {
          configurationValueId: { _eq: _value.id }
        },
        _set: {
          value: _value.value,
          updatedBy,
          updatedAt: 'now()'
        }
      };
    });
    return this._updateScheduledEventReport
      .mutate({
        updatedValues,
        old: oldNew.old,
        newValue: oldNew.newValue
      })
      .pipe(take(1));
  }

  softDelete(
    idsValues: string[],
    eventInstanceId: string,
    updatedBy: string,
    scheduledEventReport: TixScheduledReportInput
  ) {
    //
    const deletedValues = idsValues.map(id => {
      return {
        where: {
          configurationValueId: { _eq: id }
        },
        _set: {
          state: 'Deleted',
          updatedBy,
          updatedAt: 'now()'
        }
      };
    });

    const deletedEventConfigLinks = idsValues.map(id => {
      return {
        where: {
          configurationValueId: { _eq: id },
          eventInstanceId: { _eq: eventInstanceId }
        },
        _set: {
          state: 'Deleted',
          updatedBy,
          updatedAt: 'now()'
        }
      };
    });

    return this._deleteScheduledEventReport
      .mutate({
        deletedValues,
        deletedEventConfigLinks,
        scheduledReport: scheduledEventReport
      })
      .pipe(take(1));
  }

  #uuid() {
    return v4();
  }
}
