import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCompany from './+state/company.reducer';
import { TixCompanyEffects } from './+state/effects/company.effects';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature(
      fromCompany.COMPANY_FEATURE_KEY,
      fromCompany.reducer
    ),
    EffectsModule.forFeature([TixCompanyEffects])
  ]
})
export class SharedCompanyStateModule {}
