import { createAction, props } from '@ngrx/store';
import {
  TixContact,
  TixPhoneNumber,
  TixUser,
  TixVenue
} from '@tix/data-access';

export const loadContactsForSelectedCompany = createAction(
  '[Staff List Page] Load Contacts for the selected Company'
);

export const fetchUserRoles = createAction(
  '[Staff Edit Page] Fetch users available roles'
);

export const selectStaff = createAction(
  '[Staff List Page] Select staff',
  props<{ staffId: string }>()
);

export const deselectStaff = createAction(
  '[Staff List Page] Deselect staff selected staff'
);

export const loadStaffById = createAction(
  '[Staff List Page] Load staff by id',
  props<{ staffId: string }>()
);

export const saveUserForCurrentCompany = createAction(
  '[Staff Edit Page] Save user for current company',
  props<{ contactInfo: Partial<TixContact> }>()
);

export const insertCompanyContactUser = createAction(
  '[Staff Edit Page] Create New Company Contact',
  props<{ userInfo: any }>()
);
export const insertExistingUserToCompanyStaff = createAction(
  '[Staff Edit Page] Add Existing User To Company Contact',
  props<{ userId: string; contactId: string; rolesIds: string[] }>()
);

export const saveUserPhoneNumbers = createAction(
  '[Staff Edit Page] Save Phone Numbers',
  props<{ phoneNumbers: TixPhoneNumber[] }>()
);

export const deleteContactPhoneNumber = createAction(
  '[Staff Edit Page] Delete Contact Phone Number',
  props<{ phoneNumber: TixPhoneNumber }>()
);

export const saveUserPermission = createAction(
  '[Staff Edit Page] Save User Permission',
  props<{ roleIdList: string[] }>()
);
export const deleteStaffRole = createAction(
  "[Staff Edit Page] delete user's role",
  props<{ userRoleId: string }>()
);
