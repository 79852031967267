<div class="p-4 overflow-hidden">
  <div
    class="relative shadow-[0_5px_15px_rgba(0,0,0,0.35)] box-content sm:max-w-[35rem] w-[70vw] h-fit bg-white rounded-xl break-words flex-wrap overflow-hidden"
  >
    <div class="w-full border-b-2 p-4 border-gray-100 box-content">
      <h1 class="text-2xl font-bold break-words">{{ header }}</h1>
    </div>
    <div
      class="w-full max-h-[20rem] overflow-x-hidden overflow-y-auto border-b-2 p-4 border-gray-100"
    >
      <p [innerHTML]="formattedText" class="text-gray-400 break-words"></p>
    </div>
    <div class="w-full flex justify-end p-2 sm:p-3">
      <button
        tix-flat-border-button
        color="primary"
        (click)="closeDialogHandler()"
        class="w-full lg:w-auto"
      >
        Close
      </button>
    </div>
  </div>
</div>
