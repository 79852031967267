import { createAction, props } from '@ngrx/store';
import {
  TixAddress,
  TixCompanyWarning,
  TixMediaFile,
  TixPhoneNumber,
  TixSocialMedia,
  TixVenue,
  TixVenueTicket,
  TixVenueWarning
} from '@tix/data-access';

export const loadVenuesForSelectedCompanySuccess = createAction(
  '[Venues/API] Load Venues Success',
  props<{ venues: TixVenue[] }>()
);

export const loadVenuesForSelectedCompanyFailure = createAction(
  '[Venues/API] Load Venues Failure',
  props<{ error: any }>()
);

export const reloadVenueTicketById = createAction(
  '[Venues/API] Load Venue Ticket by ID',
  props<{ venueTicketId: string }>()
);

export const reloadVenueTicketByIdSuccess = createAction(
  '[Venues/API] Load Venue Ticket by ID Success',
  props<{ venueTicket: TixVenueTicket }>()
);

export const reloadVenueTicketByIdFailure = createAction(
  '[Venues/API] Load Venue Ticket by ID Failure',
  props<{ error: any }>()
);

export const insertVenueTicketSuccess = createAction(
  '[Venues/API] Upsert VenueTicket Success',
  props<{ venueTicket: TixVenueTicket }>()
);

export const insertVenueTicketFailure = createAction(
  '[Venues/API] Upsert VenueTicket Failure',
  props<{ error: any }>()
);

export const deleteVenueTicketSuccess = createAction(
  '[Venue API] Delete Venue Ticket Success',
  props<{ venueTicketId: string }>()
);

export const deleteVenueTicketConfigSuccess = createAction(
  '[Venue API] Delete Ticket Config Success',
  props<{ venueTicketConfigId: string }>()
);
export const deleteVenueTicketFailure = createAction(
  '[Venue API] Delete Venue Ticket Failure',
  props<{ error: any }>()
);

export const deleteVenueTicketConfigFailure = createAction(
  '[Venue API] Delete Ticket Config Failure',
  props<{ error: any }>()
);

/* #endregion */
