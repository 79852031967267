<div>
  <form
    *ngFor="let group of templateGroupArrays; let i = index"
    [formGroup]="group.formGroup"
    class="grid lg:grid-cols-9 gap-x-6"
  >
    <mat-form-field class="lg:col-span-4">
      <mat-label>Template type</mat-label>
      <mat-select formControlName="templateType" name="templateType">
        <ng-container *ngFor="let template of group.templateTypes">
          <mat-option
            (click)="onTemplateTypeChange(template.name, group)"
            [value]="template.name"
            >{{ template.name }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error>this template is already selected</mat-error>
    </mat-form-field>

    <mat-form-field class="lg:col-span-4">
      <mat-label>Template name</mat-label>
      <mat-select formControlName="templateName" name="templateName">
        <mat-option
          (click)="onTemplateNameChange(template.emailContentId, group)"
          *ngFor="let template of group.templateNames"
          [value]="template.emailContentId"
          >{{ template.name }}</mat-option
        >
      </mat-select>
      <mat-error>something went wrong</mat-error>
    </mat-form-field>
    <button
      (click)="confirmGroupFormDelete(i, group.formGroup)"
      tix-button
      color="light"
      class="mat-form-field-icon"
    >
      <tix-icon svgIcon="trash"></tix-icon>
    </button>
  </form>

  <button
    *ngIf="templateTypes.length > templateGroupArrays.length"
    (click)="addNewTemplate()"
    tix-flat-button
    class="block -mt-2 text-primary hover:underline"
  >
    Add another
  </button>
</div>
