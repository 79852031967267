<div class="grid grid-cols-3 gap-4">
  <div class="w-full space-y-3" *ngFor="let step of steps; let i = index">
    <div
      [ngClass]="{
        'h-1.5 rounded-full w-full': true,
        'bg-primary': currentStep === i,
        'bg-[#000814] bg-opacity-60': currentStep > i,
        'bg-gray-300': currentStep < i
      }"
    ></div>
    <div
      [ngClass]="{
        'flex items-center': true,
        'opacity-80': currentStep < i
      }"
    >
      <div
        [ngClass]="{
          'w-4 h-4 mr-3 rounded-full sm:flex items-center justify-center hidden ': true,
          'border-[5px] border-primary': currentStep === i,
          'bg-[#000814] bg-opacity-60': currentStep > i,
          'border-2 border-gray-700': currentStep < i
        }"
      >
        <tix-icon
          *ngIf="currentStep > i"
          svgIcon="check"
          class="!w-3/4 text-white !stroke-[4px] fill-white"
        ></tix-icon>
      </div>
      <span
        [ngClass]="{
          'font-medium': true,
          'text-gray-700': currentStep < i,
          'text-[#000814] text-opacity-60': currentStep > i
        }"
      >
        {{ step }}
      </span>
    </div>
  </div>
</div>
