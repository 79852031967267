import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TixIsValidDiscountCodeGQL } from '@tix/data-access';

@Component({
  selector: 'tix-check-discount-passcode',
  templateUrl: './check-discount-passcode.component.html'
})
export class TixCheckDiscountPasscodeComponent {
  @Input()
  discountCode: string;
  @Input()
  eventId: string;
  @Input()
  venueId: string;
  @Input()
  companyId: string;

  @Output()
  passcodeVerified = new EventEmitter<boolean>();

  passcodeControl = new FormControl('', Validators.required);

  isLoading = false;

  constructor(
    private snackbar: MatSnackBar,
    private isValidDiscountCodeQuery: TixIsValidDiscountCodeGQL
  ) {}

  async verifyPasscode(passcode: string) {
    if (!passcode) return false;
    const res = await this.isValidDiscountCodeQuery
      .fetch(
        {
          data: {
            code: this.discountCode,
            eventId: this.eventId,
            venueId: this.venueId,
            companyId: this.companyId,
            passcode
          }
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    return !!res.data.isValidDiscountCode;
  }

  async handleSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (this.passcodeControl.invalid) return;

    this.isLoading = true;

    try {
      const isValidPasscode = await this.verifyPasscode(
        this.passcodeControl.value
      );
      if (!isValidPasscode) {
        this.snackbar.open('Invalid passcode');
        this.passcodeControl.setErrors(
          { invalid: true },
          {
            emitEvent: true
          }
        );
        console.log(this.passcodeControl.errors);
      } else {
        this.passcodeControl.setErrors(null);
        this.passcodeControl.updateValueAndValidity();
        this.passcodeVerified.emit(true);
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open('Something went wrong!');
    }

    this.isLoading = false;
  }

  handleCancel() {
    this.passcodeVerified.emit(false);
  }
}
