<p *ngIf="youtubeVideoId" class="mb-1 text-sm md:text-base">
  Click Below to watch a video about this event.
</p>
<div class="w-full lg:hidden" *ngIf="youtubeVideoId">
  <iframe
    width="100%"
    height="250"
    type="text/html"
    [src]="safeYoutubeVideoEmbedUrl"
    frameborder="0"
  ></iframe>
</div>
<div class="w-full hidden lg:block" *ngIf="youtubeVideoId">
  <iframe
    width="100%"
    height="360"
    type="text/html"
    [src]="safeYoutubeVideoEmbedUrl"
    frameborder="0"
  ></iframe>
</div>
