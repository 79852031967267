import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import { TixMarkCompanyAsDeletedGQL } from '@tix/data-access';
import { take } from 'rxjs/operators';

const errorMessage = 'An error has ocurred';

@Injectable({
  providedIn: 'root'
})
export class TixCompanyService {
  constructor(
    private snackbar: MatSnackBar,
    private deleteCompanyMutation: TixMarkCompanyAsDeletedGQL,
    private store: Store
  ) {}

  public async deleteCompany(companyId: string): Promise<void> {
    try {
      await this.deleteCompanyMutation
        .mutate({
          companyId,
          updatedBy: (
            await this.store
              .select(UserSelector.getAuthenticatedUser)
              .pipe(take(1))
              .toPromise()
          )?.uid
        })
        .toPromise()
        .then(() => {
          this.snackbar.open(
            'Company deleted successfully! redirect to companies list'
          );
        });
    } catch (error) {
      this.snackbar.open(errorMessage);
    }
  }
}
