import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  TixBusinessCodeGroup,
  TixGetBusinessCodesByGroupNameQuery,
  TixSocialMedia
} from '@tix/data-access';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SocialMediaValue } from '../event-multi-dialog/event-multi-dialog.component';
import {
  FileUploadDialogComponent,
  TixConfirmDialogService,
  TixDialog
} from '@tix/shared/ui/components';
import {
  createMediaFormGroup,
  createSocialMediaFormGroup
} from '../media.utils';

@Component({
  selector: 'tix-event-multi-media',
  templateUrl: './event-multi-media.component.html',
  styleUrls: ['./event-multi-media.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EventMultiMediaComponent implements OnInit {
  @Input() separate = false;
  @Input() mediaInfoForm: FormGroup;
  @Input() socialMediaBusinessCodeGroup: Maybe<
    TixGetBusinessCodesByGroupNameQuery['BusinessCodeGroup'][0]
  >;
  @Input() mediaFilesBusinessCodeGroup: Maybe<
    TixGetBusinessCodesByGroupNameQuery['BusinessCodeGroup'][0]
  >;

  socialMediasForm: FormGroup;
  mediaFilesForm: FormGroup;

  constructor(
    private dialog: TixDialog,
    private confirmDialogService: TixConfirmDialogService
  ) {}

  ngOnInit(): void {
    this.socialMediasForm = this.mediaInfoForm?.controls
      .socialMediasForm as FormGroup;
    this.mediaFilesForm = this.mediaInfoForm?.controls.mediasForm as FormGroup;
  }

  get socialMediasFormArray() {
    return this.socialMediasForm.get('socialMedia') as FormArray;
  }
  get mediaFilesFormArray() {
    return this.mediaFilesForm?.get('media') as FormArray;
  }

  get socialMediaTypes() {
    if (this.socialMediaBusinessCodeGroup) {
      return this.socialMediaBusinessCodeGroup.businessCodes;
    }
    return [];
  }

  addNewSocialMedia() {
    this.socialMediasFormArray.push(createSocialMediaFormGroup());
  }

  async confirmDeleteSocialMedia(formGroup: AbstractControl, index: number) {
    const confirmText = formGroup.value.url
      ? `Are you sure you want to delete the social media "${formGroup.value.url}"?`
      : `Are you sure you want to delete this social media?`;
    const result = await this.confirmDialogService.promptConfirm({
      confirmText,
      title: 'Confirm Delete',
      icon: 'exclamation',
      titleTheme: 'danger',
      cancelButton: {
        theme: 'danger'
      },
      confirmButton: {
        theme: 'danger'
      }
    });

    if (result) {
      this.socialMediasFormArray.removeAt(index);
      if (this.socialMediasFormArray.length === 0) {
        this.addNewSocialMedia();
      }
    }
  }
  async confirmDeleteMedia(formGroup: AbstractControl, index: number) {
    const confirmText = formGroup.value.mediaFile
      ? `Are you sure you want to delete the media "${formGroup.value.mediaFile}"?`
      : `Are you sure you want to delete this media?`;
    const result = await this.confirmDialogService.promptConfirm({
      confirmText,
      title: 'Confirm Delete',
      icon: 'exclamation',
      titleTheme: 'danger',
      cancelButton: {
        theme: 'danger'
      },
      confirmButton: {
        theme: 'danger'
      }
    });

    if (result) {
      this.mediaFilesFormArray.removeAt(index);
      if (this.mediaFilesFormArray.length === 0) {
        this.addNewMediaFile();
      }
    }
  }

  get mediaFilesTypes() {
    if (this.mediaFilesBusinessCodeGroup) {
      return this.mediaFilesBusinessCodeGroup.businessCodes;
    }
    return [];
  }

  getMediaFileTypes(index: number) {
    if (
      this.mediaFilesFormArray.controls
        .filter((_, i) => i !== index)
        .some(control => control.value?.fileType === 'Event Image')
    ) {
      return this.mediaFilesTypes.filter(
        mediaFile => mediaFile.name !== 'Event Image'
      );
    }
    return this.mediaFilesTypes;
  }

  addNewMediaFile() {
    this.mediaFilesFormArray.push(createMediaFormGroup(null));
  }

  openUploadDialog(index: number) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '800px',
      data: {}
    });
    dialogRef.componentInstance.imageWidth = 400;
    dialogRef.componentInstance.imageHeight = 400;

    dialogRef.afterClosed().subscribe(files => {
      if (files) {
        this.mediaFilesFormArray
          .get(index.toString())
          ?.patchValue({ location: files[0] });
        this.mediaFilesForm.markAsDirty();
      }
    });
  }
}
