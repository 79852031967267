import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { TixConfirmDialogService } from '../dialog';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

const message =
  'WARNING: You have unsaved changes. \nPress Cancel to go back and save these changes, or OK to lose these changes.';

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(private confirmDialogService: TixConfirmDialogService) {}

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return component.canDeactivate()
      ? true
      : from(
          this.confirmDialogService.promptConfirm({
            confirmText: message,
            title: 'Unsaved Changes',
            titleTheme: 'danger',
            confirmButton: {
              label: 'OK'
            }
          })
        );
  }
}
