import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  TixGetBusinessCodesByGroupNameGQL,
  TixGetBusinessCodesByGroupNameQuery
} from '@tix/data-access';
import { BUSINESS_CODE_GROUP_NAMES } from './business-groups.model';

export type TixBusinessGroup =
  TixGetBusinessCodesByGroupNameQuery['BusinessCodeGroup'][0];

@Injectable({
  providedIn: 'root'
})
export class TixBusinessGroupsService {
  // Cache
  private businessGroupsCache: Map<
    BUSINESS_CODE_GROUP_NAMES,
    TixBusinessGroup
  > = new Map();

  // Subjects
  private loadingBusinessGroupsBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // Observables
  public readonly loadingBusinessGroups$ =
    this.loadingBusinessGroupsBS.asObservable();

  constructor(
    private loadBusinessGroupGQL: TixGetBusinessCodesByGroupNameGQL
  ) {}

  getBusinessGroupByName(
    name: BUSINESS_CODE_GROUP_NAMES
  ): Observable<TixBusinessGroup | undefined> {
    if (this.businessGroupsCache.get(name))
      return of(this.businessGroupsCache.get(name));

    this.loadingBusinessGroupsBS.next(true);
    return this.loadBusinessGroupGQL
      .fetch({
        codegroup: name
      })
      .pipe(
        map(res => res.data.BusinessCodeGroup[0]),
        tap(codeGroup => {
          this.loadingBusinessGroupsBS.next(false);

          this.businessGroupsCache.set(name, codeGroup);
        }),
        catchError((error, ...args) => {
          console.error(error);
          this.loadingBusinessGroupsBS.next(false);

          return throwError(error);
        })
      );
  }
}
