import { v4 as uuid } from 'uuid';

const USER_SESSION_ID_KEY_NAME = 'userSessionId';

export class UserSessionStorage {
  private static instance?: UserSessionStorage;

  private constructor() {}

  public static getInstance(): UserSessionStorage {
    if (!UserSessionStorage.instance) {
      UserSessionStorage.instance = new UserSessionStorage();
    }

    return UserSessionStorage.instance;
  }

  getUserSessionId(): string {
    if (!sessionStorage.getItem(USER_SESSION_ID_KEY_NAME)) {
      sessionStorage.setItem(USER_SESSION_ID_KEY_NAME, uuid());
    }

    return sessionStorage.getItem(USER_SESSION_ID_KEY_NAME) as string;
  }
}
