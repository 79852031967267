import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from './default';

export const expandCollapse = trigger('expandCollapse', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
  state('true', style({ height: '0', visibility: 'hidden' })),

  // Transition
  transition('false<=> true', animate('{{timings}}'), {
    params: {
      timings: `${AnimationDurations.entering} ${AnimationCurves.deceleration}`
    }
  })
]);
