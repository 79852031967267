import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TixEventCampaignTracking } from '@tix/data-access';
import { PortalEvent } from './portal-events.service';
import { dollarFigureToNumber } from '@tix/shared/utils';

export type PURCHASE_EVENT_TAG = {
  eventName: string;
  venueName: string;
  localDate: Date;
  totalAmount: string;
  ticketsQuantity: number;
  productsQuantity: number;
  value: number;
  currency: string;
};

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  protected pixelsList: PortalEvent['campaign'];
  set pixels(value: PortalEvent['campaign']) {
    this.pixelsList = value;
  }
  injectGTM(purchaseEvent: PURCHASE_EVENT_TAG) {
    const dataLayerTagScript = this.renderer.createElement('script');
    console.log('purchase event', purchaseEvent);
    dataLayerTagScript.id = 'dataLayerScriptId';
    const dataLayerContent = `
      dataLayer=[{
       'eventName':'${purchaseEvent.eventName}',
       'venueName':'${purchaseEvent.venueName}',
       'localDate':'${purchaseEvent.localDate}',
       'totalAmount':'${purchaseEvent.totalAmount}',
       'ticketsQuantity':'${purchaseEvent.ticketsQuantity}',
       'productsQuantity':'${purchaseEvent.productsQuantity}',
       'value':'${purchaseEvent.value}',
       'currency':'${purchaseEvent.currency}'
      }]
       `;
    dataLayerTagScript.innerHTML = dataLayerContent;

    this.renderer.appendChild(document.head, dataLayerTagScript);
    console.log('pixel list', this.pixelsList);
    this.pixelsList
      .map((pixel: TixEventCampaignTracking) => {
        if (pixel.campaignType === 'GTM') {
          return pixel.campaignCode;
        }
        return null;
      })
      .filter(Boolean)
      .map(gtmTag => {
        const pixel = `
        (function (w, d, s, l, i) {
             w[l] = w[l] || [];
             w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
             var f = d.getElementsByTagName(s)[0],
               j = d.createElement(s),
               dl = l != 'dataLayer' ? '&l=' + l : '';
             j.async = true;
             j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
             f.parentNode.insertBefore(j, f);
           })(window, document, 'script', 'dataLayer', '${gtmTag}');
         `;

        const gtmTagScript = this.renderer.createElement('script');
        gtmTagScript.classList.add('CustomGoogleManagerScript-A1B2C3');
        const gtmTagNoScript = this.renderer.createElement('noscript');
        gtmTagNoScript.classList.add('CustomGoogleManagerNoScript-X9Y8Z7');
        const iframeTag = this.renderer.createElement('iframe');
        gtmTagScript.text = pixel;
        iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${gtmTag}`;
        iframeTag.height = '0';
        iframeTag.width = '0';
        iframeTag.style = 'display: none; visibility: hidden';
        this.renderer.appendChild(gtmTagNoScript, iframeTag);
        this.renderer.appendChild(document.head, gtmTagScript);
        this.renderer.appendChild(document.body, gtmTagNoScript);
      });
  }
}
