import { Component, OnInit } from '@angular/core';
import { Maybe, TixVenue } from '@tix/data-access';
import { TixBuyersPartialState } from '@tix/event-buyer/state';
import * as BuyerActions from '@tix/event-buyer/state';
import * as BuyerSelectors from '@tix/event-buyer/state';
import * as AuthSelectors from '@tix/auth/state/selectors';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TixVenuesService } from '@tix/venues/state';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'tix-buyer-layout',
  templateUrl: './buyer-layout.component.html',
  styleUrls: ['./buyer-layout.component.scss']
})
export class TixBuyerLayoutComponent implements OnInit {
  readonly venueInfo$: Observable<Maybe<TixVenue>> = this.store.select(
    BuyerSelectors.getVenuesInfo
  );

  userState$ = this.store.select(AuthSelectors.getUserStatus);

  constructor(
    private readonly store: Store<TixBuyersPartialState>,
    private router: Router,
    public route: ActivatedRoute,
    private venuesService: TixVenuesService
  ) {}
  venueId: string;
  ngOnInit(): void {
    const params = this.router.url.split('/');
    const validParams = ['venue', 'checkout', 'success'];

    if (validParams.includes(params[1])) {
      this.venueId = params[2].split('?')[0] || params[2];
      this.store.dispatch(BuyerActions.getVenueInfo({ venueId: this.venueId }));
    } else {
      const venueSlug = params[1].split('?')[0];
      this.venuesService
        .getVenueBySlug(venueSlug)
        .pipe(
          map(venue => {
            if (venue) return venue.venueId ?? '';
          })
        )
        .subscribe(value => {
          if (value) {
            this.venueId = value;
            this.store.dispatch(BuyerActions.getVenueInfo({ venueId: value }));
          }
        });
    }
  }
}
