import { Injectable } from '@angular/core';
import {
  TixGetContactByEmailAddressSearchGQL,
  TixGetContactByEmailAddressSearchQuery,
  TixInsertCompanyContactUserRolesGQL,
  TixInsertExistingUserToStaffGQL
} from '@tix/data-access';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(
    private readonly getContactByEmailAddressSearch: TixGetContactByEmailAddressSearchGQL
  ) {}

  getEmailList(
    email: string
  ): Observable<TixGetContactByEmailAddressSearchQuery['Contact']> {
    return this.getContactByEmailAddressSearch
      .fetch({ emailAddress: email })
      .pipe(
        map(({ data }) => {
          return data.Contact;
        })
      );
  }
}
