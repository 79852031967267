import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TixPublicLayoutComponent, TixBuyerLayoutComponent } from '@tix/layout';
import { TixAuthorizedGuard } from '@tix/auth/guards';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: { svgIcon: 'home-solid' } },
    loadChildren: () =>
      import('@tix/company/pages').then(module => module.CompanyPagesModule),
    canActivate: [TixAuthorizedGuard]
  },
  {
    path: '',
    component: TixPublicLayoutComponent,
    loadChildren: () =>
      import('@tix/legal').then(module => module.TixLegalModule)
  },

  {
    path: '',
    component: TixPublicLayoutComponent,
    loadChildren: () =>
      import('@tix/auth/pages').then(module => module.TixAuthPagesModule)
  },
  {
    path: '',
    component: TixBuyerLayoutComponent,
    loadChildren: () =>
      import('@tix/event-buyer/pages').then(
        module => module.TixEventBuyerPagesModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always'
      // scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
