import { FormControl, Validators } from '@angular/forms';
import {
  TixTicketConfig,
  TixVenueTicket,
  TixVenueTicketConfig
} from '@tix/data-access';
import { TicketConfigurationExpandContent } from './../ticket-configuration-expand/ticket-configuration-expand.component';
import { createConfigurationSectionFormGroup } from './ticket-configuration-admin.helpers';
import * as moment from 'moment';

export function createTicketConfigurationExpandContentForTicketConfiguration(
  ticketConfiguration: TixVenueTicket
) {
  const config = {
    originalValue: {
      ticketConfiguration
    },
    mainSection: {
      label: 'Name',
      value: ticketConfiguration.name ?? '',
      quantity:
        ticketConfiguration.Configurations_aggregate.aggregate?.count ?? 0,
      formControl: new FormControl(ticketConfiguration.name, [
        Validators.required
      ])
    },
    secondarySection: {
      label: 'Total Tickets',
      value:
        (ticketConfiguration.Configurations.reduce(
          (total, config) =>
            total +
            (config.TicketConfigurations?.parentTicketId
              ? 0
              : config.TicketConfigurations?.noOfSeats ?? 0),
          0
        ) ?? 0) + ''
    }
  } as TicketConfigurationExpandContent;

  config.children = ticketConfiguration.Configurations.map(
    sectionConfiguration =>
      createSectionConfigFromTicketConfigurations(sectionConfiguration, config)
  );

  return config;
}

function createSectionConfigFromTicketConfigurations(
  sectionConfiguration: TixVenueTicketConfig,
  parent: TicketConfigurationExpandContent
) {
  return {
    id: sectionConfiguration.TicketConfigurations?.ticketConfigId,
    parent,
    originalValue: {
      sectionConfiguration
    },
    mainSection: {
      label: 'Section',
      value: sectionConfiguration.TicketConfigurations?.section ?? ''
    },
    extraSection: {
      label: 'Name',
      value: sectionConfiguration.TicketConfigurations?.name ?? ''
    },
    secondarySection: {
      label: '# of tickets',
      value: (sectionConfiguration.TicketConfigurations?.noOfSeats ?? 0) + ''
    },
    formGroup: createConfigurationSectionFormGroup(
      sectionConfiguration.TicketConfigurations,
      parent.originalValue?.ticketConfiguration?.Configurations
    )
  } as TicketConfigurationExpandContent;
}

export function createVenueTicketFromTicketExpandContent(
  expandContent: TicketConfigurationExpandContent,
  updatedBy: string
): TixVenueTicket {
  return {
    state: 'Active',
    ...(expandContent.originalValue?.ticketConfiguration as any),
    name: expandContent.mainSection.formControl?.value ?? 'empty name',
    totalSeats: expandContent.secondarySection.value,
    Configurations:
      expandContent.children?.map(
        config =>
          ({
            ...config.originalValue?.sectionConfiguration,
            isNew: !config.originalValue,
            TicketConfigurations: {
              includeFees: config.formGroup?.get('includeFees')?.value,
              venueFee: config.formGroup?.get('venueFee')?.value ?? '$0.00',
              platformFee:
                config.formGroup?.get('platformFee')?.value ?? '$0.00',
              processingFee:
                config.formGroup?.get('processingFee')?.value ?? '$0.00',
              row: config.formGroup?.get('row')?.value ?? '',
              section: config.formGroup?.get('section')?.value ?? '',
              name: config.formGroup?.get('name')?.value ?? '',
              ticketType: config.formGroup?.get('type')?.value ?? '',
              noOfSeats: parseInt(
                config.formGroup?.get('quantity')?.value ?? ''
              ),
              salesChannel:
                config.formGroup?.get('salesChannel')?.value.length > 1
                  ? 'both'
                  : config.formGroup?.get('salesChannel')?.value[0] ?? 'online',
              seat: config.formGroup?.get('seat')?.value ?? '',
              ticketPrice: config.formGroup?.get('price')?.value ?? '',
              perOrderMin: parseInt(
                config.formGroup?.get('perOrderMin')?.value ?? '0'
              ),
              perOrderMax: parseInt(
                config.formGroup?.get('perOrderMax')?.value ?? '0'
              ),
              startSellingDate: moment(
                config.formGroup?.get('startSellingDate')?.value
              ).isValid()
                ? moment(
                    config.formGroup?.get('startSellingDate')?.value
                  ).format('YYYY-MM-DD')
                : null,
              stopSellingDate: moment(
                config.formGroup?.get('stopSellingDate')?.value
              ).isValid()
                ? moment(
                    config.formGroup?.get('stopSellingDate')?.value
                  ).format('YYYY-MM-DD')
                : null,
              helpText: config.formGroup?.get('helpText')?.value,
              helpTextTitle: config.formGroup?.get('helpTextTitle')?.value,
              startSellingTime:
                config.formGroup?.get('startSellingTime')?.value,
              stopSellingTime: config.formGroup?.get('stopSellingTime')?.value,
              updatedAt: 'now()',
              updatedBy,
              ticketConfigId:
                config.originalValue?.sectionConfiguration?.TicketConfigurations
                  ?.ticketConfigId || config.id,
              parentTicketId:
                config.formGroup?.get('parentTicketId')?.value || undefined
            } as TixTicketConfig
          } as TixVenueTicketConfig)
      ) ?? []
  };
}
