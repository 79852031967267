import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Maybe,
  TixGetRolesQuery,
  TixRole,
  TixUserRole
} from '@tix/data-access';
import {
  TixCheckboxChange,
  TixConfirmDialogService
} from '@tix/shared/ui/components';
import * as StaffAction from '@tix/staff/state';
import {
  TixFullStaffMember,
  TixStaffPartialState,
  TixStaffService
} from '@tix/staff/state';
import { pipe } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

type RoleType = {
  key: string;
  title: string;
  roleId: string;
};

@Component({
  selector: 'tix-contact-permissions',
  templateUrl: './contact-permissions.component.html',
  styleUrls: ['./contact-permissions.component.scss']
})
export class TixContactPermissionsComponent implements OnChanges, OnInit {
  roleList: RoleType[] = [];
  @Input() keepDisableSaveBtn = false;
  @Input() isloading?: boolean | undefined | null;

  userRolesForm: FormGroup;
  @Input() userAssignedRoles: NonNullable<
    TixFullStaffMember['user']
  >['userRoles'];
  @Input() userRoleList: TixGetRolesQuery['Role'];

  isSaving$ = this.staffService.isSavingStaffRoles$;

  availableRoles: { [key: string]: string } = {};

  selectedRoles = new Set<string>();

  companyId: string;
  staffId: string;
  isPreviousRoleAdmin: boolean = false;

  @Input()
  userId: any;

  constructor(
    private route: ActivatedRoute,
    private staffService: TixStaffService
  ) {}

  get isEvenAdminRoleSelected(): boolean {
    return this.selectedRoles.has('event_admin');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.staffId = params['staffId'];
      this.companyId = params['companyId'];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userRoleList']?.currentValue.length) {
      const roles = changes['userRoleList'].currentValue;
      this.userRolesForm = new FormGroup({});
      roles?.forEach((role: Maybe<TixRole>) => {
        if (role?.role && role.title) {
          role?.role &&
            this.userRolesForm.addControl(role?.role, new FormControl(false));
          this.roleList.push({
            key: role.role,
            title: role.title,
            roleId: role.roleId
          });
          this.availableRoles[role.role] = role.roleId;
        }
      });
    }
    if (
      changes['userAssignedRoles']?.currentValue?.length &&
      this.userRolesForm
    ) {
      changes['userAssignedRoles']?.currentValue?.forEach((user: any) => {
        if (user?.role?.role) {
          this.userRolesForm.get(user?.role?.role)?.patchValue(true);
          this.selectedRoles.add(user?.role?.role);
        }
      });
      if (this.isEvenAdminRoleSelected) {
        this.updateCheckboxStates();
        this.isPreviousRoleAdmin = true;
      }
      this.userRolesForm.markAsPristine();
    }
  }

  getControl(key: string): FormControl {
    return this.userRolesForm.get(key) as FormControl;
  }

  onSave(): void {
    const roleIdList: string[] = [];
    for (const key in this.userRolesForm.controls) {
      if (this.getControl(key).value && this.getControl(key).dirty) {
        roleIdList.push(this.availableRoles[key]);
      }
    }

    this.staffService
      .saveStaffMemberRoles(this.companyId, this.staffId, roleIdList)
      .subscribe(() => {
        if (this.isPreviousRoleAdmin) {
          this.staffService
            .removeEventAdminFromRelatedEvents(this.userId)
            .then(() => {
              this.userRolesForm.markAsPristine();
            });
        } else {
          this.userRolesForm.markAsPristine();
        }
      });
  }

  onChange(event: TixCheckboxChange, roleItem: RoleType): void {
    if (event.checked) {
      this.selectedRoles.add(roleItem.key);
    } else {
      this.selectedRoles.delete(roleItem.key);
    }
    this.updateCheckboxStates();
  }

  updateCheckboxStates(): void {
    this.roleList.forEach(role => {
      const control = this.userRolesForm.get(role.key);
      if (
        this.isEvenAdminRoleSelected &&
        control !== this.userRolesForm.get('event_admin')
      ) {
        control?.patchValue(false);
        control?.disable();
      } else {
        control?.enable();
      }
    });
  }

  // get userRoleId by role id for staff
  getUserRoleId(roleId: string): string | undefined {
    return this.userAssignedRoles?.find(role => role?.role?.roleId === roleId)
      ?.userRoleId;
  }
}
