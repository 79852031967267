import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TixVenue } from '@tix/data-access';
import * as VenuesApiActions from './actions/venues-api.actions';
import * as VenuesActions from './actions/venues.actions';

export const VENUES_FEATURE_KEY = 'venues';

export function selectVenueId(venue: TixVenue): string {
  return venue.venueId;
}

export interface State extends EntityState<TixVenue> {
  loaded: boolean; // has the Venues List been loaded
  selectedVenueId?: string;
  error?: string | null; // last known error (if any)
  venueLoadingInfo: boolean;
  venueLoadingPhone: boolean;
  venueLoadingAdress: boolean;
  venueLoadingMedia: boolean;
  venueLoadingMediaFiles: boolean;
  venueLoadingTicketConfig: boolean;
  venueSavingWarnings: boolean;
  searchText: string;
}

export interface TixVenuesPartialState {
  readonly [VENUES_FEATURE_KEY]: State;
}

export const venuesAdapter: EntityAdapter<TixVenue> =
  createEntityAdapter<TixVenue>({
    selectId: selectVenueId
  });

export const initialState: State = venuesAdapter.getInitialState({
  loaded: false,
  venueLoadingInfo: false,
  venueLoadingPhone: false,
  venueLoadingAdress: false,
  venueLoadingMedia: false,
  venueLoadingMediaFiles: false,
  venueLoadingTicketConfig: false,
  venueSavingWarnings: false,
  searchText: ''
});

export const reducer = createReducer(
  initialState,
  on(VenuesActions.loadVenuesForSelectedCompany, state => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(VenuesActions.selectVenue, (state, { venueId }) => ({
    ...state,
    selectedVenueId: venueId
  })),
  on(VenuesActions.deselectVenue, state => {
    return { ...state, selectedVenueId: undefined };
  }),
  on(VenuesActions.filterCompanies, (state, { searchText }) => ({
    ...state,
    searchText
  })),
  on(
    VenuesApiActions.loadVenuesForSelectedCompanySuccess,
    (state, { venues }) =>
      venuesAdapter.setAll(venues, { ...state, loaded: true })
  ),
  on(
    VenuesApiActions.loadVenuesForSelectedCompanyFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(VenuesActions.saveVenueTicketConfiguration, state => ({
    ...state,
    venueLoadingTicketConfig: true
  })),
  on(VenuesApiActions.insertVenueTicketSuccess, (state, { venueTicket }) => {
    const newTickets =
      state.entities[state.selectedVenueId ?? -1]?.tickets?.map(e => ({
        ...e
      })) ?? [];

    if (newTickets) {
      const newTicketIndex = newTickets.findIndex(
        e => e.venueTicketId === venueTicket.venueTicketId
      );

      if (newTicketIndex !== -1) {
        newTickets[newTicketIndex] = venueTicket;
      } else {
        newTickets.push(venueTicket);
      }
    }

    const update: Update<TixVenue> = {
      id: state.selectedVenueId,
      changes: {
        tickets: newTickets
      }
    } as Update<TixVenue>;

    return venuesAdapter.updateOne(update, {
      ...state,
      venueLoadingTicketConfig: false
    });
  }),

  on(VenuesApiActions.insertVenueTicketFailure, (state, { error }) => ({
    ...state,
    error,
    venueLoadingTicketConfig: false
  })),

  /* #endregion */
  on(VenuesApiActions.deleteVenueTicketSuccess, (state, { venueTicketId }) => {
    const update: Update<TixVenue> = {
      id: state.selectedVenueId,
      changes: {
        tickets: (state.entities[state.selectedVenueId ?? '']?.tickets ?? [])
          // When we have more than one phone number, we'll update only the changed one
          .filter(stateTickets => {
            return stateTickets.venueTicketId !== venueTicketId;
          })
      }
    } as Update<TixVenue>;

    return venuesAdapter.updateOne(update, state);
  }),
  on(VenuesApiActions.deleteVenueTicketFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(VenuesApiActions.deleteVenueTicketConfigFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(
    VenuesApiActions.reloadVenueTicketByIdSuccess,
    (state, { venueTicket }) => {
      const newTickets =
        state.entities[state.selectedVenueId ?? -1]?.tickets.map(e => ({
          ...e
        })) ?? [];

      if (newTickets) {
        const newTicketIndex = newTickets.findIndex(
          e => e.venueTicketId === venueTicket.venueTicketId
        );

        if (newTicketIndex !== -1) {
          newTickets[newTicketIndex] = venueTicket;
        } else {
          newTickets.push(venueTicket);
        }
      }

      const update: Update<TixVenue> = {
        id: state.selectedVenueId,
        changes: {
          tickets: newTickets
        }
      } as Update<TixVenue>;

      return venuesAdapter.updateOne(update, state);
    }
  ),

  on(VenuesApiActions.reloadVenueTicketByIdFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(VenuesActions.clearState, () => initialState)
  /* #endregion */
);
