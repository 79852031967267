import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { TixDialog } from '../dialog';
import { TixConfirmDialogComponent } from './confirm-dialog.component';
import { TixConfirmDialogConfig } from './confirm-dialog.config';

@Injectable()
export class TixConfirmDialogService {
  constructor(private dialog: TixDialog) {}

  promptConfirm(confirmDialogConfig?: TixConfirmDialogConfig) {
    return new Promise<boolean>(resolve => {
      const dialog = this.dialog.open(TixConfirmDialogComponent);
      dialog.componentInstance.config = confirmDialogConfig;
      dialog
        .afterClosed()
        .pipe(take(1))
        .subscribe({
          next: () => resolve(false)
        });
      dialog.componentInstance.confirm.pipe(take(1)).subscribe({
        next: confirmed => {
          resolve(confirmed);
          dialog.close();
        }
      });
    });
  }
}
