<!-- container -->
<div
  class="flex h-full border items-center rounded-md cursor-pointer bg-light shadow-md ring-2 ring-transparent focus:ring-primary hover:ring-primary"
>
  <!-- image: todo: refactor it (only one div) -->
  <ng-container *ngIf="!isPulling">
    <div
      *ngIf="
        addon?.EventProductMedia?.length === 0 || !addon?.EventProductMedia
      "
      class="w-28 h-28 bg-[#F8F9FB] rounded-l-md"
    >
      <div class="h-28 w-28 flex items-center justify-center">
        <mat-icon class="opacity-75">scatter_plot</mat-icon>
      </div>
    </div>

    <img
      *ngIf="addon?.EventProductMedia?.length > 0"
      class="w-28 h-28 bg-cover rounded-l-md"
      [src]="addon?.EventProductMedia[0]?.MediaFile.location"
      [alt]="addon.name"
    />
  </ng-container>

  <ng-container *ngIf="isPulling">
    <div
      *ngIf="addon.productMedia.length === 0"
      class="h-full w-32 flex items-center justify-center bg-[#F8F9FB] rounded-l-md"
    >
      <mat-icon class="opacity-75">scatter_plot</mat-icon>
    </div>

    <div
      *ngIf="addon.productMedia.length > 0"
      class="h-full w-32 bg-cover rounded-l-md"
      [ngStyle]="{
        'background-image':
          'url(' + addon.productMedia[0].mediaFile.location + ')',
        'aspect-ratio': '1 / 1 !important'
      }"
    ></div>
  </ng-container>

  <!-- info -->
  <div class="flex w-full flex-col items-start px-5 py-3">
    <!-- title -->
    <div
      class="text-base w-full flex justify-between leading-6 font-semibold line-clamp-1 tracking-wide hover:text-primary"
    >
      <span class="cursor-pointer w-full">{{ addon.name | titlecase }}</span>
    </div>

    <!-- price + quantity -->
    <div class="flex flex-row items-center justify-start w-full">
      <!-- price -->
      <div class="text-primary">{{ addon.price | currency }}</div>
      <div *ngIf="showQuantity" class="text-xs mx-2">&bull;</div>
      <div *ngIf="showQuantity" class="text-xs text-secondary">
        {{ addon.quantityRemaining }} Remaining
      </div>
    </div>

    <!-- state -->
    <div class="flex flex-row items-center justify-start">
      <span
        [ngClass]="
          (addon.state | titlecase) === 'Active'
            ? 'text-success'
            : 'text-secondary'
        "
        class="font-extrabold text-xl mr-3"
        >&bull;</span
      >
      <div class="text-xs text-secondary text-center">
        {{ addon.state | titlecase }}
      </div>
    </div>
  </div>
</div>
