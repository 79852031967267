import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  TixBusinessCodeGroup,
  TixGetBusinessCodesByGroupNameQuery,
  TixVenue,
  TixVenueTicket
} from '@tix/data-access';
import { Store } from '@ngrx/store';
import { TixCompanyPartialState } from '@tix/company/state';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as TixEventsActions from '@tix/events/state';

@Component({
  selector: 'tix-event-multi-info',
  templateUrl: './event-multi-info.component.html',
  styleUrls: ['./event-multi-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EventMultiInfoComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() eventTypeList: Maybe<
    TixGetBusinessCodesByGroupNameQuery['BusinessCodeGroup'][0]
  >;
  @Input() statusLists: Maybe<
    TixGetBusinessCodesByGroupNameQuery['BusinessCodeGroup'][0]
  >;
  @Input() eventVenueList: Maybe<TixVenue[]>;
  @Input() eventTicketLists: Maybe<TixVenueTicket[]>;
  venueErrorMessage = 'Venue is required. ';

  constructor(private readonly store: Store<TixCompanyPartialState>) {}

  ngOnInit(): void {}

  get statusList() {
    if (this.statusLists) {
      return this.statusLists.businessCodes;
    }
    return [];
  }

  get eventTypes() {
    if (this.eventTypeList) {
      return this.eventTypeList.businessCodes;
    }
    return [];
  }

  get isTicketConfigDisabled() {
    return this.formGroup.get('ticketConfigurationID')?.disabled;
  }
  public onSelectVenue(venue: TixVenue): void {
    this.store.dispatch(
      TixEventsActions.getVenueTicketConfigurationList({
        venueId: venue?.venueId
      })
    );
  }

  public onChangeStatus(name: string): void {
    if (name === 'Active') {
      this.formGroup?.get('venueId')?.setErrors(Validators.required);
      if (this.formGroup?.get('venueId')?.value) {
        this.formGroup?.get('venueId')?.setErrors(null);
      } else {
        if (this.eventVenueList?.length === 0) {
          this.venueErrorMessage =
            'Venue is required. Please save Status as Pending and set up Venue.';
        } else {
          this.venueErrorMessage = 'Venue is required';
        }
      }
    } else {
      this.formGroup?.get('venueId')?.setErrors(null);
    }
  }
}
