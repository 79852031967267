import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { BtnLoadingDirectiveModule } from '../../btn-loading/btn-loading.directive';
import { TixButtonModule } from '../../button';
import { TixCardModule } from '../../card';
import { TixDialogModule } from '../../dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TixIconModule } from '../../icon';
import { TixInputModule } from '../../input';

import { TixCheckboxModule } from './../../checkbox/checkbox.module';
import { TixAddressEditComponent } from './address.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [TixAddressEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TixInputModule,
    TixCheckboxModule,
    TixIconModule,
    TixButtonModule,
    RouterModule,
    TixCardModule,
    MatSelectModule,
    TextMaskModule,
    TixDialogModule,
    BtnLoadingDirectiveModule,
    MatAutocompleteModule
  ],
  exports: [TixAddressEditComponent],
  providers: []
})
export class TixAddressAdminModule {}
