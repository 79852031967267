<div
  class="bg-opacity-50 rounded px-3 py-2 flex items-center justify-start mb-2"
  [class]="getAlertColor()"
  role="alert"
>
  <div class="mr-2 h-fit flex items-center justify-center">
    <tix-icon [svgIcon]="getAlertIcon()"></tix-icon>
  </div>
  <p class="text-sm">{{ alertMessage || 'there is no message to display' }}</p>
</div>
