/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TixBuyerProductFragmentFragment,
  TixEventProductSelectGQL,
  TixGetItemsAmountsSoldGQL
} from '@tix/data-access';
import { OrderStateManagerService } from '@tix/event-buyer/services';
import { fadeInRight400ms, fadeInUp250ms } from '@tix/ui-common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderSelectBaseComponent } from '../../order-select-base/order-select-base.component';
import { BuyerProductSwiperComponent } from '../buyer-product-swiper/buyer-product-swiper.component';
import { HelperDateService } from '@tix/util-common';
import { debounce } from 'lodash';

/**
 * Algorithm (addons page): hold product - summary page has a high priority
 *
 * . Query event products.
 * . Remove whats in hold for current session.
 * . Remove whats expired for other sessions (Auto removed from API).
 * . Get hold from others.
 * . Check if current session already increased any addon quantity.
 * . `Quantity remaining` = eventProduct.quantityRemaining - hold by other sessions.
 * . Update addons quantity ui if its greater than `Quantity remaining`.
 * . Checkout to summary page: means add current session to hold products.
 */
export type ItemInHold = { id: string; quantity: number };

@Component({
  selector: 'tix-buyer-product-card',
  templateUrl: './buyer-product-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUp250ms, fadeInRight400ms],
  providers: [OrderStateManagerService],
  styles: [
    `
      .ql-editor {
        @apply px-1 pt-1;
      }
    `
  ]
})
export class BuyerProductCardComponent
  extends OrderSelectBaseComponent
  implements OnInit
{
  //
  #helperDateService = new HelperDateService();
  //
  addons: Array<TixBuyerProductFragmentFragment> = [];
  idx: { [key: number]: number } = {};
  isLoading = true;
  isHolding = false;
  isNoImages = true;

  //
  #destroyed = new Subject<void>();
  #venueId = '';

  // responsive
  currentScreenSize: string;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ]);

  orderChangedHandler = debounce(() => {
    this.orderStateManager.fetchHoldInformation();
    this.orderStateManager.updateAvailability();
    this.orderStateManager.updateOrderOnHold();
  }, 800);

  get use2ColumnLayout() {
    return this.addons.length === 2 || this.addons.length === 4;
  }

  get use1ColumnLayout() {
    return this.addons.length === 1;
  }

  get use3ColumnLayout() {
    console.log(this.use1ColumnLayout, this.use2ColumnLayout);
    return !this.use1ColumnLayout && !this.use2ColumnLayout;
  }

  get productSelectionEmpty() {
    return (
      this.addons.reduce((total, addon) => {
        return (
          total +
          this.orderStateManager.getQuantitySelectedForItem(
            addon.eventProductId
          )
        );
      }, 0) === 0
    );
  }

  get proceedButtonText() {
    if (this.productSelectionEmpty) return 'Skip';
    return 'Checkout';
  }
  //
  constructor(
    private _router: Router,
    private location: Location,
    private _activateRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private eventProductSelectQuery: TixEventProductSelectGQL,
    breakpointObserver: BreakpointObserver,
    orderStateManager: OrderStateManagerService,
    getItemsAmountsQuery: TixGetItemsAmountsSoldGQL
  ) {
    super(orderStateManager, getItemsAmountsQuery, _changeDetectorRef);
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .pipe(takeUntil(this.#destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? 'Unknown';
            this._changeDetectorRef.markForCheck();
          }
        }
      });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.init();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.orderStateManager.removeOrderFromHold();
  }

  async proceed() {
    const canProceed = await this.validateOrder();

    if (!canProceed) {
      alert('The products that have been selected are no longer available');
      this.orderStateManager.resetToAvailable();

      return;
    }
    await this.orderStateManager.persistOrder();
    this._router.navigateByUrl(`/checkout/${this.#venueId}`);
  }

  back() {
    this.location.back();
  }

  toPlainText(html: string): string {
    const plaintext = html.replace(/<(?:.|\n)*?>/g, '');

    return plaintext;
  }

  openDialog(addon: Record<string, any>) {
    const dialogRef = this._dialog.open(BuyerProductSwiperComponent, {
      data: addon
    });

    dialogRef.afterClosed();
  }

  next(idx: number) {
    if (!this.addons[idx]['EventProductMedia']) return;
    const filesLength = this.addons[idx]['EventProductMedia'].length ?? 0;
    this.idx[idx] = this.idx[idx] == filesLength - 1 ? 0 : (this.idx[idx] += 1);
  }

  override async onOrderChanged(): Promise<void> {
    await super.onOrderChanged();

    this.orderChangedHandler();
  }

  async init() {
    this.orderStateManager.loadPersistedOrder();
    this.#scrollToTop();
    this.isLoading = true;

    const { eventId, venueId } = this._activateRoute.snapshot.params;
    this.#venueId = venueId;
    const today = this.#helperDateService.now();
    const res = await this.eventProductSelectQuery
      .fetch({
        eventInstanceId: eventId,
        today
      })
      .toPromise();

    this.addons = res.data.EventProductSelect;

    // c
    this.isNoImages =
      this.addons.filter(addon => addon?.['EventProductMedia'].length === 0)
        .length === this.addons.length;

    this.isLoading = false;

    this.orderStateManager.addOrderItems(
      this.addons.map(a => ({
        id: a.eventProductId,
        quantitySold: a.quantitySold ?? 0,
        totalQuantity: a.quantity ?? 0,
        type: 'product',
        isPoolParent: true
      }))
    );
    await this.orderStateManager.loadOrderFromHold();
    await this.startTrackingAvailability();

    this.orderSelectionInitialized = true;
    this._changeDetectorRef.markForCheck();
  }

  #scrollToTop() {
    window.scrollTo(0, 0);
  }
}
