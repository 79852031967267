import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserPartialState, UserSelector } from '@tix/auth/state';
import { TixUserRole } from '@tix/data-access';
import { ROLE_TYPES } from 'libs/auth/state/src/lib/+state/user.model';

@Component({
  selector: 'tix-company-layout',
  templateUrl: './company-layout.component.html',
  styleUrls: ['./company-layout.component.scss']
})
export class TixCompanyLayoutComponent implements OnInit {
  readonly UserRoles$: Observable<any> = this.store.select(
    UserSelector.getUserRolesInfo
  );
  userRoles: TixUserRole[] = [];
  companyId = '';

  expandSidebar = true;

  constructor(
    private router: Router,
    private readonly store: Store<UserPartialState>
  ) {}

  ngOnInit(): void {
    this.companyId = this.router.url.split('/')[2];
    this.UserRoles$.subscribe(userRoles => {
      this.userRoles = userRoles?.userRoles;
    });
    const companyIdList: TixUserRole[] = this.userRoles.filter(
      (user: TixUserRole) => user.role?.role === ROLE_TYPES.ROLE_ADMIN
    );
    // const userCompany = companyIdList.filter((data: any) => data.companyId === this.companyId);
    // if (userCompany.length === 0) {
    //   this.router.navigate(['/']);
    // }
  }
}
