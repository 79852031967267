<ng-container *ngIf="isEdit">
  <tix-event-info
    *ngIf="
      (selectedEventData$ | async) && (eventVenueList$ | async)?.length;
      else loading
    "
    [selectedEventData]="selectedEventData$ | async"
    [eventTypeList]="businessEventTypes$ | async"
    [statusLists]="businessStatusLists$ | async"
    [campaignType]="campaignType$ | async"
    [eventTicketLists]="eventTicketConfigurationList$ | async"
    [eventVenueList]="eventVenueList$ | async"
    [userData]="userState$ | async"
  ></tix-event-info>

  <ng-template #loading>
    <div class="flex justify-center mt-11">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</ng-container>

<tix-event-info
  *ngIf="!isEdit"
  [selectedEventData]="selectedEventData$ | async"
  [eventTypeList]="businessEventTypes$ | async"
  [statusLists]="businessStatusLists$ | async"
  [campaignType]="campaignType$ | async"
  [eventTicketLists]="eventTicketConfigurationList$ | async"
  [eventVenueList]="eventVenueList$ | async"
  [userData]="userState$ | async"
></tix-event-info>
