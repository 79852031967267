import { v4 } from 'uuid';

export class HelperStringService {
  uuid(): string {
    return v4();
  }

  /**
   * Transform number to currency string
   * @param currency default is "$"
   */
  money(number: number, currency = 'USD'): string {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(number);
  }

  /**
   * From currency string to number
   * @param value pure number
   */
  moneyToNumber(money?: string): number {
    if (!money) return 0;
    return Number(money.replace(/[^0-9.-]+/g, ''));
  }
}
