import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, navigation, pessimisticUpdate } from '@nrwl/angular';
import { State as UserState, UserActions } from '@tix/auth/state';
import * as UserSelectors from '@tix/auth/state/selectors';
import {
  TixCompany,
  TixCompanyMediaFile,
  TixCompanyPhoneNumber,
  TixCompanySocialMedia,
  TixCompanyWarning,
  TixDeleteCompanyGQL,
  TixDeleteCompanyMediaFileByMediaFilePkGQL,
  TixDeleteCompanyPhoneNumberByPhoneNumberPkGQL,
  TixDeleteCompanySocialMediaBySocialMediaPkGQL,
  TixDeleteCompanyWarningByWarningPkGQL,
  TixGetCompaniesByCompanyIdsUserIdGQL,
  TixGetCompanyByCompanyIdGQL,
  TixInsertCompanyContactMutation,
  TixInsertStaffUserMutation,
  TixInsertWarningGQL,
  TixMediaFileInsertInput,
  TixMutationRootUpdateWarningByPkArgs,
  TixPhoneNumberInsertInput,
  TixRole,
  TixSocialMediaInsertInput,
  TixUpdateAddressGQL,
  TixUpdateCompanyByIdGQL,
  TixUpdateMediaFileByPkGQL,
  TixUpdatePhoneNumberByPkGQL,
  TixUpdateSocialMediaByPkGQL,
  TixUpdateWarningByPkGQL,
  TixUser,
  TixUserRole,
  TixUserRoleInsertInput,
  TixWarningInsertInput
} from '@tix/data-access';
import { TixCompanyLayoutComponent } from '@tix/layout';
import { MutationResult } from 'apollo-angular';
import { Maybe } from 'graphql/jsutils/Maybe';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as CompanyApiActions from '../actions/company-api.actions';
import * as CompanyPageActions from '../actions/company.actions';
import {
  convertMediaFileInsertResultToTixMediaFile,
  convertMediaFileUpdateResultToTixMediaFile,
  convertPhoneNumberInsertResultToTixPhoneNumber,
  convertPhoneNumberUpdateResultToTixPhoneNumber,
  convertSocialMediaInsertResultToTixSocialMedia,
  convertSocialMediaUpdateResultToTixSocialMedia,
  convertCompanyWarningInsertResultToTixWarning,
  convertCompanyWarningUpdateResultToTixWarning,
  createTixCompanyAddressFromInsertResponse,
  createTixCompanyAddressFromUpdateResponse
} from '../company.effects-helpers';
import { TixCompanyPartialState } from '../company.reducer';
import * as CompanySelectors from '../selectors/company.selectors';
import {
  TixAddressInsertInput,
  TixInsertAddressGQL,
  TixInsertAddressMutationVariables,
  TixInsertCompanyContactGQL,
  TixInsertCompanyProfileGQL,
  TixInsertCompanyStaffRoleGQL,
  TixInsertMediaFileGQL,
  TixInsertPhoneNumberGQL,
  TixInsertSocialMediaGQL,
  TixInsertStaffUserGQL
} from './../../../../../../data-access/src/lib/generated/generated';
import {
  deleteCompanyWarningFailure,
  deleteCompanyWarningSuccess,
  upsertCompanyWarningSuccess
} from '../actions/company-api.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { user } from 'rxfire/auth';

@Injectable()
export class TixCompanyEffects {
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.loadCompanies),
      withLatestFrom(
        combineLatest([
          this.store.select(CompanySelectors.getCompaniesLoaded),
          this.store.select(UserSelectors.getUserRolesInfo),
          this.store.select(UserSelectors.getAdminRole)
        ])
      ),
      fetch({
        run: (action, [loaded, userRoles, adminRole]) => {
          if (loaded) {
            return CompanyApiActions.loadCompaniesSuccess({});
          }

          if (!userRoles) {
            return CompanyApiActions.loadCompaniesFailure({
              error: 'Not authorized user!'
            });
          }
          const companyIdList: string[] = userRoles.userRoles
            .filter((user: TixUserRole) => user.role?.role !== 'event_admin')
            .map((user: TixUserRole) => user.companyId);
          const variables = {
            companyId: [...new Set(companyIdList)],
            userId: userRoles.userId
          };
          return this.getCompaniesByCompanyIdsUserId
            .fetch({ ...variables })
            .pipe(
              map(({ data }) =>
                CompanyApiActions.loadCompaniesSuccess({
                  companies: data.Company as unknown as TixCompany[]
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return CompanyApiActions.loadCompaniesFailure({ error });
        }
      })
    )
  );

  deleteCompanyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.deleteCompany),
      withLatestFrom(
        combineLatest([this.store.select(UserSelectors.getAuthenticatedUser)])
      ),
      fetch({
        run: ({ companyId }, [user]) => {
          return this.deleteCompanyById
            .mutate({ id: companyId, updatedBy: user?.uid })
            .pipe(
              map(() => CompanyApiActions.deleteCompanySuccess({ companyId }))
            );
        },
        onError: (a, error) => {
          console.error({ error });

          return CompanyApiActions.deleteCompanyFailure({ error });
        }
      })
    )
  );

  loadCompanyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.loadCompanyById),
      fetch({
        run: ({ companyId }) => {
          return this.getCompanyById
            .fetch(
              {
                companyId
              },
              { fetchPolicy: 'no-cache' }
            )
            .pipe(
              map(({ data }) => {
                if (!data.Company && !data.Company[0]) {
                  return CompanyApiActions.loadCompanyFailure({
                    error: 'No matching company found.'
                  });
                }
                return CompanyApiActions.loadCompanySuccess({
                  company: data.Company[0] as TixCompany
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return CompanyApiActions.loadCompanyFailure({ error });
        }
      })
    )
  );

  updateCompanyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanyProfile),
      withLatestFrom(
        combineLatest([this.store.select(UserSelectors.getAuthenticatedUser)])
      ),
      pessimisticUpdate({
        run: ({ profile }, [user]) => {
          return this.updateCompanyById
            .mutate({
              companyId: profile.companyId,
              name: profile.name,
              url: profile.url ?? '',
              state: profile.state,
              updatedBy: user?.uid
            })
            .pipe(
              map(({ data }) => {
                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );
                return CompanyApiActions.updateCompanyProfileSuccess({
                  name: data?.companyProfile?.name ?? '',
                  url: data?.companyProfile?.url ?? '',
                  state: data?.companyProfile?.url ?? '',
                  updatedAt: data?.companyProfile?.updatedAt,
                  updatedBy: data?.companyProfile?.updatedBy
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return null;
        }
      })
    )
  );

  /* #region  Upsert Effects */

  upsertCompanyPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanyPhoneNumbers),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getUserState),
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: ({ phoneNumbers }, state) => {
          const [userState, companyId] = state as [UserState, string];

          // Convert phone numbers with ID to companyPhoneNumbers
          const newPhoneNumbers = phoneNumbers
            .filter(
              phoneNumber =>
                phoneNumber.phoneNumberId == '' || !phoneNumber.phoneNumberId
            )
            .map(
              phoneNumber =>
                ({
                  phoneNumber: phoneNumber.phoneNumber,
                  type: phoneNumber.type,
                  updatedBy:
                    userState.authenticatedUser?.uid ??
                    '00fda0cf-5a5d-4821-9e05-d995720212ab',
                  companyPhoneNumbers: {
                    data: [
                      {
                        companyId: companyId,
                        updatedAt: 'now()',
                        updatedBy:
                          userState.authenticatedUser?.uid ??
                          '00fda0cf-5a5d-4821-9e05-d995720212ab'
                      }
                    ]
                  }
                } as TixPhoneNumberInsertInput)
            );

          // Convert phone numbers without ID to updatePhoneNumber Mutation
          const updatingPhoneNumbers = phoneNumbers
            .filter(
              phoneNumber =>
                phoneNumber.phoneNumberId && phoneNumber.phoneNumberId !== ''
            )
            .map(phoneNumber =>
              this.updatePhoneNumber.mutate({
                phoneNumber: phoneNumber.phoneNumber,
                phoneNumberId: phoneNumber.phoneNumberId,
                type: phoneNumber.type,
                updatedAt: 'now()',
                updatedBy:
                  userState.authenticatedUser?.uid ??
                  '00fda0cf-5a5d-4821-9e05-d995720212ab'
              })
            );

          return combineLatest(
            [
              newPhoneNumbers &&
                this.insertCompanyPhoneNumber.mutate({
                  objects: newPhoneNumbers
                }),
              ...updatingPhoneNumbers
            ],
            (insertedPhoneNumbers, ...updatedPhoneNumbers) => {
              return {
                insertedPhoneNumbers,
                updatedPhoneNumbers
              };
            }
          ).pipe(
            map(result => {
              this.snackbar.open('Your changes have been saved successfully!');
              return CompanyApiActions.upsertCompanyPhoneNumbersSuccess({
                companyPhoneNumbers: [
                  ...(result.updatedPhoneNumbers
                    .map(updatedPhoneNumbers =>
                      convertPhoneNumberUpdateResultToTixPhoneNumber(
                        updatedPhoneNumbers
                      )
                    )
                    .filter(
                      phoneNumber => !!phoneNumber
                    ) as TixCompanyPhoneNumber[]), //This filtering eliminates undefined values
                  ...convertPhoneNumberInsertResultToTixPhoneNumber(
                    result.insertedPhoneNumbers
                  )
                ]
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.upsertCompanyPhoneNumbersFailure({ error });
        }
      })
    )
  );

  upsertCompanySocialMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanySocialMedias),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getUserState),
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: ({ socialMedias }, state) => {
          const [userState, companyId] = state as [UserState, string];

          // Convert phone numbers with ID to companyPhoneNumbers
          const newSocialMedias = socialMedias
            .filter(
              socialMedia =>
                socialMedia.socialMediaId == '' || !socialMedia.socialMediaId
            )
            .map(
              socialMedia =>
                ({
                  url: socialMedia.url,
                  socialMediaType: socialMedia.socialMediaType,
                  state: 'Active',
                  updatedBy: userState.authenticatedUser?.uid,
                  companySocialMedia: {
                    data: [
                      {
                        companyId: companyId,
                        updatedBy: userState.authenticatedUser?.uid
                      }
                    ]
                  }
                } as TixSocialMediaInsertInput)
            );

          // Convert social medias without ID to updateSocialMedia Mutation
          console.log(socialMedias);

          const updatingSocialMedias = socialMedias
            .filter(
              socialMedia =>
                socialMedia.socialMediaId && socialMedia.socialMediaId !== ''
            )
            .map(socialMedia =>
              this.updateSocialMedias.mutate({
                url: socialMedia.url,
                socialMediaId: socialMedia.socialMediaId,
                socialMediaType: socialMedia.socialMediaType,
                updatedBy: userState.authenticatedUser?.uid
              })
            );

          return combineLatest(
            [
              newSocialMedias &&
                this.insertCompanySocialMedia.mutate({
                  objects: newSocialMedias
                }),
              ...updatingSocialMedias
            ],
            (insertedSocialMedias, ...updatedSocialMedias) => {
              return {
                insertedSocialMedias,
                updatedSocialMedias
              };
            }
          ).pipe(
            map(result => {
              this.snackbar.open('Your changes have been saved successfully!');

              return CompanyApiActions.upsertCompanySocialMediaSuccess({
                companySocialMedias: [
                  ...(result.updatedSocialMedias
                    .map(updatedSocialMedias =>
                      convertSocialMediaUpdateResultToTixSocialMedia(
                        updatedSocialMedias
                      )
                    )
                    .filter(
                      socialMedia => !!socialMedia
                    ) as TixCompanySocialMedia[]), //This filtering eliminates undefined values
                  ...(convertSocialMediaInsertResultToTixSocialMedia(
                    result.insertedSocialMedias
                  ) as TixCompanySocialMedia[])
                ]
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.upsertCompanySocialMediaFailure({ error });
        }
      })
    )
  );
  upsertCompanyWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanyWarnings),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getUserState),
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: ({ warnings }, state) => {
          const [userState, companyId] = state as [UserState, string];

          const newWarnings = warnings
            .filter(warning => warning.warningId == '' || !warning.warningId)
            .map(
              warning =>
                ({
                  message: warning.message,
                  type: warning.type,
                  updatedBy: userState.authenticatedUser?.uid,
                  company_warning: {
                    data: [
                      {
                        companyId: companyId,
                        updatedBy: userState.authenticatedUser?.uid
                      }
                    ]
                  }
                } as TixWarningInsertInput)
            );

          // Convert social medias without ID to updateSocialMedia Mutation

          const newUpdatedWarnings = warnings
            .filter(warning => warning.warningId && warning.warningId !== '')
            .map(warning =>
              this.updateCompanyWarning.mutate({
                message: warning.message,
                warningId: warning.warningId,
                type: warning.type,
                updatedBy: userState.authenticatedUser?.uid
              })
            );

          return combineLatest(
            [
              newWarnings &&
                this.insertCompanyWarning.mutate({
                  objects: newWarnings
                }),
              ...newUpdatedWarnings
            ],
            (insertedWarnings, ...updatedWarnings) => {
              return {
                insertedWarnings: insertedWarnings,
                updatedWarnings: updatedWarnings
              };
            }
          ).pipe(
            map(result => {
              console.log('my result', result);
              this.snackbar.open('Your changes have been saved successfully!');

              return CompanyApiActions.upsertCompanyWarningSuccess({
                companyWarnings: [
                  ...(result.updatedWarnings
                    .map(updatedWarnings =>
                      convertCompanyWarningUpdateResultToTixWarning(
                        updatedWarnings
                      )
                    )
                    .filter(
                      socialMedia => !!socialMedia
                    ) as TixCompanyWarning[]), //This filtering eliminates undefined values
                  ...(convertCompanyWarningInsertResultToTixWarning(
                    result.insertedWarnings
                  ) as TixCompanyWarning[])
                ]
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.upsertCompanyWarningFailure({ error });
        }
      })
    )
  );

  upsertCompanyMediaFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanyMediaFiles),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getUserState),
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: ({ mediaFiles }, state) => {
          const [userState, companyId] = state as [UserState, string];

          // Convert media file with ID to companyMediaFile
          const newMediaFiles = mediaFiles
            .filter(
              mediaFile => mediaFile.mediaFileId == '' || !mediaFile.mediaFileId
            )
            .map(
              mediaFile =>
                ({
                  location: mediaFile.location,
                  fileType: mediaFile.fileType,
                  name: '',
                  state: 'Active',
                  updatedBy: userState.authenticatedUser?.uid,
                  companyMediaFile: {
                    data: [
                      {
                        companyId: companyId,
                        updatedBy: userState.authenticatedUser?.uid
                      }
                    ]
                  }
                } as TixMediaFileInsertInput)
            );

          // Convert media file without ID to updatePhoneNumber Mutation

          const updatingMediaFiles = mediaFiles
            .filter(
              mediaFile => mediaFile.mediaFileId && mediaFile.mediaFileId !== ''
            )
            .map(mediaFile =>
              this.updateMediaFiles.mutate({
                location: mediaFile.location,
                mediaFileId: mediaFile.mediaFileId,
                fileType: mediaFile.fileType,
                updatedBy: userState.authenticatedUser?.uid
              })
            );

          return combineLatest(
            [
              newMediaFiles &&
                this.InsertMediaFile.mutate({
                  objects: newMediaFiles
                }),
              ...updatingMediaFiles
            ],
            (insertedMediaFiles, ...updatedMediaFiles) => {
              return {
                insertedMediaFiles,
                updatedMediaFiles
              };
            }
          ).pipe(
            map(result => {
              this.snackbar.open('Your changes have been saved successfully!');

              return CompanyApiActions.upsertCompanyMediaFileSuccess({
                companyMediaFiles: [
                  ...(result.updatedMediaFiles
                    .map(updatedMediaFiles =>
                      convertMediaFileUpdateResultToTixMediaFile(
                        updatedMediaFiles
                      )
                    )
                    .filter(mediaFile => !!mediaFile) as TixCompanyMediaFile[]), //This filtering eliminates undefined values
                  ...(convertMediaFileInsertResultToTixMediaFile(
                    result.insertedMediaFiles
                  ) as TixCompanyMediaFile[])
                ]
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.upsertCompanyMediaFileFailure({ error });
        }
      })
    )
  );

  insertNewCompanyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.insertCompanyProfile),
      withLatestFrom(
        combineLatest([this.store.select(UserSelectors.getAuthenticatedUser)])
      ),
      pessimisticUpdate({
        run: (action, [user]) => {
          console.log('action ==>', action);
          return this.insertCompanyProfile
            .mutate({
              name: action.name,
              url: action.url,
              updatedBy: user?.uid
            })
            .pipe(
              map(({ data }) => {
                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );
                return CompanyApiActions.insertCompanyProfileSuccess({
                  company: data?.InsertCompany?.returning[0] as TixCompany
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');
          return CompanyApiActions.insertCompanyProfileFailure({
            error: error
          });
        }
      })
    )
  );
  /* #endregion */

  /* #region  Delete Effects */
  deleteCompanyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.deleteCompanyPhoneNumber),
      pessimisticUpdate({
        run: ({ phoneNumber }) => {
          return this.deleteCompanyPhoneNumberByPhoneNumberId
            .mutate({
              phoneNumberId: phoneNumber.phoneNumberId
            })
            .pipe(
              map(({ data }) =>
                CompanyApiActions.deleteCompanyPhoneNumberSuccess({
                  companyPhoneNumberId:
                    data?.DeleteCompanyPhoneNumber?.returning[0]
                      .companyPhoneNumberId || '',
                  companyId:
                    data?.DeleteCompanyPhoneNumber?.returning[0].companyId || ''
                })
              )
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.deleteCompanyPhoneNumberFailure({
            error
          });
        }
      })
    )
  );
  deleteCompanySocialMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.deleteCompanySocialMedia),
      pessimisticUpdate({
        run: ({ socialMedia }) => {
          return this.deleteCompanySocialMediaBySocialMediaId
            .mutate({
              socialMediaId: socialMedia.socialMediaId
            })
            .pipe(
              map(({ data }) =>
                CompanyApiActions.deleteCompanySocialMediaSuccess({
                  companySocialMediaId:
                    data?.DeleteCompanySocialMedia?.returning[0]
                      .companySocialMediaId || '',
                  companyId:
                    data?.DeleteCompanySocialMedia?.returning[0].companyId || ''
                })
              )
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.deleteCompanySocialMediaFailure({
            error
          });
        }
      })
    )
  );

  deleteCompanyWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.deleteCompanyWarning),
      pessimisticUpdate({
        run: ({ warning }) => {
          return this.deleteCompanyWarningByWarningId
            .mutate({
              warningId: warning.warningId
            })
            .pipe(
              map(({ data }: any) =>
                CompanyApiActions.deleteCompanyWarningSuccess({
                  companyWarningId:
                    data?.DeleteCompanyWarning?.returning[0].companyWarningId ||
                    '',
                  companyId:
                    data?.DeleteCompanyWarning?.returning[0].companyId || ''
                })
              )
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.deleteCompanyWarningFailure({
            error
          });
        }
      })
    )
  );
  deleteCompanyMediaFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.deleteCompanyMediaFile),
      pessimisticUpdate({
        run: ({ mediaFile }) => {
          return this.deleteCompanyMediaFileByMediaFileId
            .mutate({
              mediaFileId: mediaFile.mediaFileId
            })
            .pipe(
              map(({ data }) =>
                CompanyApiActions.deleteCompanyMediaFileSuccess({
                  companyMediaFileId:
                    data?.DeleteCompanyMediaFile?.returning[0]
                      .companyImageFileId || '',
                  companyId:
                    data?.DeleteCompanyMediaFile?.returning[0].companyId || ''
                })
              )
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.deleteCompanyMediaFileFailure({
            error
          });
        }
      })
    )
  );
  /* #endregion */

  selectCompany$ = createEffect(() =>
    this.actions$.pipe(
      navigation(TixCompanyLayoutComponent, {
        run: (route: ActivatedRouteSnapshot) => {
          // console.log('There was a grave success...');
          const companyId: string = route.params['companyId'];
          if (!companyId || companyId === 'add')
            return CompanyPageActions.deselectCompany();
          return CompanyPageActions.loadCompanyById({ companyId });
        },
        onError: (route: ActivatedRouteSnapshot, error: any) => {
          console.error('There was a grave error...', error);
          // we can log and error here and return null
          // we can also navigate back
          return null;
        }
      })
    )
  );

  saveCompanyAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPageActions.saveCompanyAddress),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getUserState),
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: ({ address }, state) => {
          const [userState, companyId] = state as [UserState, string];

          let isUpdate = false;

          const insertObject = {
            objects: {
              addressId: address.addressId,
              city: address.city,
              countryCode: address.countryCode,
              state: address.state,
              postalCode: address.postalCode,
              sameAsCompany: address.sameAsCompany,
              stateProvince: address.stateProvince,
              streetAddress: address.streetAddress,
              suiteApartment: address.suiteApartment,
              updatedBy:
                userState.authenticatedUser?.uid ??
                '00fda0cf-5a5d-4821-9e05-d995720212ab',
              updatedAt: 'now()',
              companyAddress: {
                data: {
                  companyId,
                  updatedAt: 'now()',
                  updatedBy:
                    userState.authenticatedUser?.uid ??
                    '00fda0cf-5a5d-4821-9e05-d995720212ab'
                }
              }
            }
          } as TixInsertAddressMutationVariables;

          // If it has an ID it means it will be an update
          if ((insertObject.objects as TixAddressInsertInput).addressId) {
            isUpdate = true;
            delete (insertObject.objects as TixAddressInsertInput)
              .companyAddress;
          }

          if (isUpdate) {
            console.log(
              'updating address, since id is provided: ',
              address.addressId
            );
            return this.updateCompanyAddress
              .mutate({
                ...insertObject.objects,
                updatedBy: userState.authenticatedUser?.uid
              })
              .pipe(
                map(({ data }) => {
                  this.snackbar.open(
                    'Your changes have been saved successfully!'
                  );
                  return CompanyApiActions.updateCompanyAddressSuccess(
                    createTixCompanyAddressFromUpdateResponse(data)
                  );
                })
              );
          }

          delete (insertObject.objects as TixAddressInsertInput).addressId;
          return this.InsertAddress.mutate(insertObject).pipe(
            map(({ data }) => {
              this.snackbar.open('Your changes have been saved successfully!');

              return CompanyApiActions.updateCompanyAddressSuccess(
                createTixCompanyAddressFromInsertResponse(data)
              );
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return CompanyApiActions.updateCompanyAddressFailure({ error });
        }
      })
    )
  );
  insertCompanyProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyApiActions.insertCompanyProfileSuccess),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelectors.getAuthenticatedUser),
          this.store.select(UserSelectors.getUserRolesInfo),
          this.store.select(UserSelectors.getAdminRole)
        ])
      ),
      pessimisticUpdate({
        run: ({ company }, [user, userRoles, adminRole]) => {
          const variables = {
            contactId: user?.uid,
            updatedBy: user?.uid
          };

          // const queryObservables = [this.insertCompanyContact.mutate({ objects: { ...variables, companyId: company.companyId } })]
          const queryObservables: Observable<MutationResult<unknown>>[] = [
            this.insertCompanyContact.mutate({
              objects: { ...variables, companyId: company.companyId }
            })
          ];

          if (!userRoles?.userId) {
            console.log(`userRoles`, userRoles);
            queryObservables.push(
              this.insertStaffUser.mutate({ objects: variables })
            );
          }

          return combineLatest(queryObservables).pipe(
            mergeMap(responses => {
              const [{ data }, user] = responses as [
                MutationResult<TixInsertCompanyContactMutation>,
                MutationResult<TixInsertStaffUserMutation>
              ];

              const companyDetails = data?.InsertCompanyContact?.returning[0];
              const userDetails = user?.data?.InsertUser?.returning[0];
              const variables: TixUserRoleInsertInput = {
                companyId: companyDetails?.companyId,
                updatedBy: userRoles?.contactId ?? userDetails?.contactId, // Logged-in user id
                userId: userRoles?.userId ?? userDetails?.userId, // Selected staff userId
                roleId: adminRole?.roleId
              };
              return this.insertCompanyStaffRole.mutate({ objects: variables });
            }),
            map(({ data }) => {
              const roleInfo = data?.InsertUserRole?.returning[0];
              const roles = {
                description: adminRole?.description,
                role: adminRole?.role,
                roleId: adminRole?.roleId,
                title: adminRole?.title
              };
              const userRoles: TixUserRole = {
                companyId: roleInfo?.companyId,
                role: roles as Maybe<TixRole>,
                userRoleId: roleInfo?.userRoleId,
                venueId: '00000000-0000-0000-0000-000000000001',
                __typename: 'UserRole'
              };
              if (!data)
                return UserActions.assignedAdminRoleFailure({
                  error: 'Something went wrong'
                });
              window.location.href = '/company/' + company.companyId;

              return UserActions.assignedAdminRoleSuccess({
                userRoles: userRoles as unknown as TixUser,
                userId: roleInfo?.userId as string
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return UserActions.assignedAdminRoleFailure({
            error: 'Something went wrong'
          });
        }
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<TixCompanyPartialState>,
    private readonly router: Router,
    /* #region  Get GQLs */
    private readonly getCompanyById: TixGetCompanyByCompanyIdGQL,
    private readonly getCompaniesByCompanyIdsUserId: TixGetCompaniesByCompanyIdsUserIdGQL,
    /* #endregion */
    /* #region  Insert GQLs */
    private readonly insertCompanyPhoneNumber: TixInsertPhoneNumberGQL,
    private readonly insertCompanySocialMedia: TixInsertSocialMediaGQL,
    private readonly InsertMediaFile: TixInsertMediaFileGQL,
    private readonly InsertAddress: TixInsertAddressGQL,
    private readonly insertCompanyProfile: TixInsertCompanyProfileGQL,
    private readonly insertCompanyContact: TixInsertCompanyContactGQL,
    private readonly insertStaffUser: TixInsertStaffUserGQL,
    private readonly insertCompanyStaffRole: TixInsertCompanyStaffRoleGQL,
    private readonly insertCompanyWarning: TixInsertWarningGQL,
    /* #endregion */
    /* #region  Update GQLs */
    private readonly updateCompanyById: TixUpdateCompanyByIdGQL,
    private readonly updatePhoneNumber: TixUpdatePhoneNumberByPkGQL,
    private readonly updateSocialMedias: TixUpdateSocialMediaByPkGQL,
    private readonly updateMediaFiles: TixUpdateMediaFileByPkGQL,
    private readonly updateCompanyAddress: TixUpdateAddressGQL,
    private readonly updateCompanyWarning: TixUpdateWarningByPkGQL,
    // private readonly tixUpdateCompanyWarningMessage: TixMutationRootUpdateCompanyWarningMessageByPkArgs,
    /* #endregion */
    /* #region  Delete GQLs */
    private readonly deleteCompanyPhoneNumberByPhoneNumberId: TixDeleteCompanyPhoneNumberByPhoneNumberPkGQL,
    private readonly deleteCompanyById: TixDeleteCompanyGQL,
    private readonly deleteCompanySocialMediaBySocialMediaId: TixDeleteCompanySocialMediaBySocialMediaPkGQL,
    private readonly deleteCompanyWarningByWarningId: TixDeleteCompanyWarningByWarningPkGQL,
    private readonly deleteCompanyMediaFileByMediaFileId: TixDeleteCompanyMediaFileByMediaFilePkGQL /* #endregion */,

    private readonly snackbar: MatSnackBar
  ) {}
}
