<div>
  <div class="flex items-center justify-end">
    <button
      (click)="toggleEdit()"
      [matTooltip]="isEdit ? 'Save' : 'Edit'"
      class="flex items-center justify-center rounded-md h-9 w-9 hover:bg-gray-100"
      [disabled]="isEdit && newPixelForm.invalid"
    >
      <tix-icon
        [svgIcon]="isEdit ? 'check' : 'hero-edit'"
        class="icon-btn__icon"
      ></tix-icon>
    </button>
    <button
      (click)="deleteEvent.emit()"
      matTooltip="Delete"
      class="flex items-center justify-center rounded-md h-9 w-9 hover:bg-gray-100"
    >
      <tix-icon svgIcon="trash" class="icon-btn__icon"></tix-icon>
    </button>
  </div>
  <form
    [formGroup]="newPixelForm"
    class="grid lg:grid-cols-3 gap-x-4"
    first-letter:
    (keyboard.enter)="$event.preventDefault()"
  >
    <mat-form-field>
      <mat-label>Campaign name</mat-label>
      <input
        matInput
        type="text"
        formControlName="campaignName"
        [readOnly]="!isEdit"
      />
      <mat-error>The Campaign name is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{
        'Campaign Code (' + campaignControlType.value + ')'
      }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="campaignCode"
        [readOnly]="!isEdit"
      />

      <mat-error *ngIf="campaignControlCode.errors?.required">
        Campaign Code is required.
      </mat-error>
      <mat-error *ngIf="campaignControlCode.errors?.invalidCampaignCode">
        Campaign Code must be 15 or 16 digits long and contain only numbers.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Type of Campaign</mat-label>
      <mat-select formControlName="campaignType" [disabled]="!isEdit">
        <mat-option *ngFor="let type of campaignType" [value]="type.name">{{
          type.name
        }}</mat-option>
      </mat-select>
      <mat-error>The Campaign type is required</mat-error>
    </mat-form-field>
  </form>
</div>
