import { Injectable } from '@angular/core';

import {
  TixInsertEmailTemplatesOfEventGQL,
  TixInsertVenueEmailGQL,
  TixInsertCompanyEmailGQL,
  TixGetBusinessCodesByGroupNameGQL,
  TixGetAllEmailVariablesGQL,
  TixGetEmailTemplateByCompanyidGQL,
  TixGetEmailTemplateOfEventGQL,
  TixDeleteEventEmailGQL,
  TixEventEmailInsertInput,
  TixGetEventsOfTemplateByIdGQL,
  TixGetCompanyEmailGQL,
  TixGetVenueEmailGQL,
  TixCommunicationsVenueEmailInsertInput,
  TixGetEmailTemplateByidGQL,
  TixCommunicationsCompanyEmailInsertInput,
  TixDeleteVenueEmailGQL,
  TixDeleteCompanyEmailGQL,
  TixGetVenuesOfTemplatesByIdGQL,
  TixGetCompaniesOfTemplatesByIdGQL
} from '@tix/data-access';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {
  constructor(
    private getBusinessCodesByGroupMutation: TixGetBusinessCodesByGroupNameGQL,
    private getEmailTemplatesByCompanyIdQuery: TixGetEmailTemplateByCompanyidGQL,
    private getCommunicationEmailByCompanyIdQuery: TixGetCompanyEmailGQL,
    private getCommunicationEmailByVenueIdQuery: TixGetVenueEmailGQL,
    private getEmailTemplateOfEventQuery: TixGetEmailTemplateOfEventGQL,
    private getAllEmailVariablesQuery: TixGetAllEmailVariablesGQL,
    private getEmailContentByIdQuery: TixGetEmailTemplateByidGQL,
    private getEventsOfTemplatesByIdQuery: TixGetEventsOfTemplateByIdGQL,
    private getVenuesOfTemplatesByIdQuery: TixGetVenuesOfTemplatesByIdGQL,
    private getCompaniesOfTemplatesByIdQuery: TixGetCompaniesOfTemplatesByIdGQL,
    private insertEmailTemplateOfEventMutation: TixInsertEmailTemplatesOfEventGQL,
    private insertVenueEmailMutation: TixInsertVenueEmailGQL,
    private insertCompanyEmailMutation: TixInsertCompanyEmailGQL,
    private deleteEventEmailMutation: TixDeleteEventEmailGQL,
    private deleteVenueEmailMutation: TixDeleteVenueEmailGQL,
    private deleteCompanyEmailMutation: TixDeleteCompanyEmailGQL,
    private sanitizier: DomSanitizer
  ) {}

  getEventsOfTemplates(id: string) {
    return this.getEventsOfTemplatesByIdQuery.fetch(
      { templateId: id },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  getVenuesOfTemplates(id: string) {
    return this.getVenuesOfTemplatesByIdQuery.fetch(
      { emailContentId: id },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  getCompaniesOfTemplates(id: string) {
    return this.getCompaniesOfTemplatesByIdQuery.fetch(
      { emailContentId: id },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  cleanHtml(htmlString: string): string {
    return `${this.sanitizier.bypassSecurityTrustHtml(htmlString)}`;
  }
  // used to get template type ex:"Ticket confirmation"
  getBusinessCodesOfEmail() {
    return this.getBusinessCodesByGroupMutation.fetch(
      {
        codegroup: 'email_type'
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  getAllEmailvariables() {
    return this.getAllEmailVariablesQuery.fetch(
      {},
      { fetchPolicy: 'no-cache' }
    );
  }

  getEmailTemplateById(id: string) {
    return this.getEmailContentByIdQuery.fetch({
      emailContentId: id
    });
  }

  getCommunicationVenueEmailbyVenueId(venueId: string) {
    return this.getCommunicationEmailByVenueIdQuery
      .fetch(
        {
          venueId: venueId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(data => data.data.communications_venue_email),
        switchMap(emails =>
          forkJoin(
            emails.map(email =>
              this.getEmailTemplateById(email.emailContentId).pipe(
                map(templateData => ({
                  emailContentId: email.emailContentId,
                  type: templateData.data.EmailContentSelect[0].type
                }))
              )
            )
          )
        )
      );
  }
  //getCommunicationEventEmailById(emailTemplate:string){
  //return this.get
  //}
  getCommunicationCompanyEmailbyCompanyId(companyId: string) {
    return this.getCommunicationEmailByCompanyIdQuery
      .fetch(
        {
          companyId: companyId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(data => data.data.communications_company_email),
        switchMap(emails =>
          forkJoin(
            emails.map(email =>
              this.getEmailTemplateById(email.emailContentId).pipe(
                map(templateData => ({
                  emailContentId: email.emailContentId,
                  type: templateData.data.EmailContentSelect[0].type
                }))
              )
            )
          )
        )
      );
  }

  getEventEmailContentById(id: string) {
    return this.getEmailTemplateOfEventQuery
      .fetch(
        {
          eventInstanceId: id
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(data => data.data.EventEmailSelect),
        switchMap(emails =>
          forkJoin(
            emails.map(email =>
              this.getEmailTemplateById(email.emailContentId).pipe(
                map(templateData => ({
                  emailContentId: email.emailContentId,
                  type: templateData.data.EmailContentSelect[0].type
                }))
              )
            )
          )
        )
      );
  }

  getCommunicationEmailByIdAndType(id: string, type: string): Observable<any> {
    if (type === 'venue') {
      return this.getCommunicationVenueEmailbyVenueId(id);
    } else if (type == 'company') {
      return this.getCommunicationCompanyEmailbyCompanyId(id);
    } else {
      return this.getEventEmailContentById(id);
    }
  }

  getTemplatesByBusinessCode(businessCode: string, companyId: string) {
    return this.getEmailTemplatesByCompanyIdQuery.fetch(
      {
        companyId,
        type: businessCode
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  deleteEventEmail(eventInstanceId: string | null) {
    return this.deleteEventEmailMutation
      .mutate({
        eventInstanceId
      })
      .toPromise();
  }

  deleteVenueEmail(venueId: string | null) {
    return this.deleteVenueEmailMutation
      .mutate({
        venueId
      })
      .toPromise();
  }

  deleteCompanyEmail(companyId: string | null) {
    return this.deleteCompanyEmailMutation
      .mutate({
        companyId
      })
      .toPromise();
  }
  insertEmailTemplatesOfEvent(templateArr: TixEventEmailInsertInput[]) {
    return this.insertEmailTemplateOfEventMutation
      .mutate({
        objects: templateArr
      })
      .toPromise();
  }

  insertVenueEmail(templateArr: TixCommunicationsVenueEmailInsertInput[]) {
    return this.insertVenueEmailMutation
      .mutate({
        objects: templateArr
      })
      .toPromise();
  }

  insertCompanyEmail(templateArr: TixCommunicationsCompanyEmailInsertInput[]) {
    return this.insertCompanyEmailMutation
      .mutate({
        objects: templateArr
      })
      .toPromise();
  }

  getInitMentions(data: any) {
    const atValues: any[] = data?.map((item: any, index: number) => {
      return { id: index + 1, value: `{${item.name}}}` };
    });
    return {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['{'],
        source: function (searchTerm: any, renderList: any, mentionChar: any) {
          let values: any[] = [];

          if (mentionChar === '{') {
            values = atValues;
          }

          if (searchTerm?.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            console.log('values', values);
            for (let i = 0; i < values?.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        }
      }
    };
  }
}
