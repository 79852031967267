import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  TixGetEventCalendarDatesQuery,
  TixGetVenuePortalEventGQL,
  TixGetVenuePortalEventsGQL,
  TixGetVenuePortalEventsWithSlugGQL,
  TixVenuePortalEventFragment
} from '@tix/data-access';
import { Observable } from 'rxjs';
import { map, switchMap, take, takeLast } from 'rxjs/operators';
import {
  PortalEventsDateAndSearchFilter,
  PortalEventsFilter,
  PortalSingleEventFilter
} from './PortalEventFilter';
import * as moment from 'moment';

export type PortalEventDates =
  TixGetEventCalendarDatesQuery['calendarEventDates'];

export type PortalEvent = TixVenuePortalEventFragment;
export type PortalEventList = PortalEvent[];

export const EVENTS_PER_PAGE = 5;

@Injectable({ providedIn: 'root' })
export class PortalEventsService {
  constructor(
    private getPortalEventsQuery: TixGetVenuePortalEventsGQL,
    private getSinglePortalEventQuery: TixGetVenuePortalEventGQL,
    private getPortalEventQueryWithSlug: TixGetVenuePortalEventsWithSlugGQL
  ) {}

  async getEventsForVenueWithFilter(
    venueId: string,
    filter: PortalEventsFilter
  ): Promise<PortalEventList> {
    if (filter instanceof PortalSingleEventFilter) {
      return this.getSinglePortalEventQuery
        .fetch({ eventInstanceId: filter.eventInstanceId })
        .pipe(map(res => res.data.EventInstance))
        .toPromise();
    } else {
      const { searchQuery, startDate, endDate, pageNumber, filterKeyword } =
        filter as PortalEventsDateAndSearchFilter;
      if (filterKeyword === '')
        return this.getPortalEventsQuery
          .fetch({
            searchQuery: `%${searchQuery}%`,
            startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
            endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
            offset: (pageNumber - 1) * EVENTS_PER_PAGE,
            limit: EVENTS_PER_PAGE,
            currentDate: moment().format('YYYY-MM-DD'),
            currentTime: moment().format('HH:mm:ss'),
            venueId
          })
          .pipe(map(res => res.data.EventInstance))
          .toPromise();
      return this.getPortalEventQueryWithSlug
        .fetch({
          searchQuery: `%${searchQuery}%`,
          filterKeyword: `%${filterKeyword}%`,
          startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
          offset: (pageNumber - 1) * EVENTS_PER_PAGE,
          limit: EVENTS_PER_PAGE,
          currentDate: moment().format('YYYY-MM-DD'),
          currentTime: moment().format('HH:mm:ss'),
          venueId
        })
        .pipe(map(res => res.data.EventInstance))
        .toPromise();
    }

    throw new Error('Invalid filter type');
  }

  getEventById(eventInstanceId: string): Observable<PortalEvent> {
    return this.getSinglePortalEventQuery
      .fetch({
        eventInstanceId
      })
      .pipe(map(res => res.data.EventInstance[0]));
  }
}
