import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { TixDialog } from '../dialog';
import { TixConfirmDeleteDialogComponent } from './confirm-delete-dialog.component';

interface ConfirmDeleteDialogConfig {
  confirmationText: string;
}

@Injectable()
export class TixConfirmDeleteDialogService {
  constructor(private dialog: TixDialog) {}

  confirmDelete({
    confirmationText
  }: ConfirmDeleteDialogConfig): Promise<boolean> {
    const confirmDeleteDialog = this.dialog.open(
      TixConfirmDeleteDialogComponent
    );
    confirmDeleteDialog.componentInstance.confirmationText = confirmationText;

    return new Promise<boolean>(resolve => {
      confirmDeleteDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe({
          next: () => resolve(false)
        });

      confirmDeleteDialog.componentInstance.confirm.pipe(take(1)).subscribe({
        next: confirmed => {
          resolve(confirmed);
          confirmDeleteDialog.close();
        }
      });
    });
  }
}
