import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, startWith, take } from 'rxjs/operators';
import { EventProduct } from '../models/event-product.model';
import { EventProductsService } from '../services/event-products.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { HelperStringService } from '@tix/util-common';

@Component({
  selector: 'tix-ui-event-products-pull',
  templateUrl: './event-products-pull.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EventProductsPullComponent implements OnInit {
  //
  title = 'Pull add-ons';

  //
  #helperStringService = new HelperStringService();

  //
  eventProducts: Array<EventProduct> = [];
  searchCtrl = new FormControl('');
  filteredEventProduct$: Observable<EventProduct[]> = of([]);

  //
  selectedProducts: EventProduct[] = [];
  selectedIdx = -1;

  //
  constructor(
    private _eventProductsService: EventProductsService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EventProductsPullComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  //
  ngOnInit(): void {
    this._eventProductsService
      .products(this.data.companyId, this.data.eventId, this.data.productIds)
      .pipe(take(1))
      .subscribe(v => {
        this.eventProducts = v.data.ProductSelect.map((p: any) => ({
          ...p,
          price: this.#helperStringService.moneyToNumber(p.price),
          isSelected: false
        }));
        this._changeDetectorRef.markForCheck();
      });

    this.filteredEventProduct$ = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((search: string) => {
        return this.eventProducts.filter(eventProduct =>
          eventProduct.name.toLowerCase().includes(search?.toLowerCase())
        );
      })
    );
  }

  //
  selectProduct(index: number) {
    this.selectedIdx == index ? -1 : index;
    this.eventProducts[index]['isSelected'] =
      !this.eventProducts[index]['isSelected'];
    this.selectedProducts = this.eventProducts.filter(p => p.isSelected);
  }

  pullProducts() {
    this.selectedProducts = this.selectedProducts.map(product => ({
      ...product,
      additionalInfo: product.additionalInformation
    }));
    this._eventProductsService
      .insertMany(this.selectedProducts, this.data.eventId)
      .subscribe();
    this.dialogRef.close();
  }

  //
  closeDialog() {
    this.dialogRef.close();
  }

  //
  trackBy(index: number, prop: any) {
    return prop.name;
  }
}
