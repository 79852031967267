import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'tix-event-product-card',
  templateUrl: './event-product-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EventProductCardComponent {
  @Input()
  addon: any;

  @Input()
  showQuantity = false;

  @Input()
  isPulling = false;
}
