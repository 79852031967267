// @ts-nocheck

import { Injectable } from '@angular/core';
import {
  TixGetCompanyByCompanyIdGQL,
  TixGetEventByEventInstanceIdGQL,
  TixGetVenueByIdGQL
} from '@tix/data-access';

@Injectable({
  providedIn: 'root'
})
export class TixHiveService {
  constructor(
    private getVenueByIdQuery: TixGetVenueByIdGQL,
    private getEventByIdQuery: TixGetEventByEventInstanceIdGQL,
    private getCompanyByIdQuery: TixGetCompanyByCompanyIdGQL
  ) {}

  private loadSdk() {
    if (window.HIVE_SDK) return;

    console.log('Loading Hive SDK...');

    try {
      (function (h, i, v, e, s, d, k) {
        h.HiveSDKObject = s;
        (h[s] =
          h[s] ||
          function () {
            (h[s].q = h[s].q || []).push(arguments);
          }),
          (d = i.createElement(v)),
          (k = i.getElementsByTagName(v)[0]);
        d.async = 1;
        d.id = s;
        d.src = e + '?r=' + parseInt(new Date() / 60000);
        k.parentNode.insertBefore(d, k);
      })(
        window,
        document,
        'script',
        'https://cdn-prod.hive.co/static/js/sdk-loader.js',
        'HIVE_SDK'
      );

      console.log('Hive SDK loaded successfully.');
    } catch (error) {
      console.error('Error loading Hive SDK:', error);
    }
  }

  private getTimeInISOFormat(time: string | undefined, date: string) {
    const d = new Date(date);

    if (time) {
      const splitTime = time.split(':');
      d.setHours(parseInt(splitTime[0]));
      d.setMinutes(parseInt(splitTime[1]));
    }

    return d.toISOString();
  }

  public init(hiveBrandId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loadSdk();
      console.log(hiveBrandId);

      window.HIVE_SDK(
        'init',
        hiveBrandId,
        function (data) {
          // Initialization success callback
          // data.user contains info about the currently user (if available)
          console.log('Init success', data);
          resolve();
        }
        // function (error) {
        //   console.log('Init error', error);
        //   reject();
        // }
      );
    });
  }

  public logCustomer(data: {
    email: string;
    didOptIn: boolean;
    phoneNumber: string;
    didSmsOptIn: boolean;
    firstName: string;
    lastName: string;
    birthday?: string;
    location?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    state?: string;
    mailingAddress?: string;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window.HIVE_SDK(
        'emailSignup',
        {
          ...data
        },
        function (data) {
          // Success Callback
          // data.user contains info about the currently auth'ed user
          console.log(data);
          resolve();
        },
        function (data) {
          // Error Callback
          // data contains error information
          console.log(data);
          reject(data);
        }
      );
    });
  }

  public async logOrder({
    orderId,
    venueId,
    eventId,
    totalPaid
  }: {
    orderId: string;
    venueId: string;
    eventId: string;
    totalPaid: number;
  }): Promise<void> {
    const event = (
      await this.getEventByIdQuery
        .fetch({ eventInstanceId: eventId }, { fetchPolicy: 'cache-first' })
        .toPromise()
    ).data.EventInstance[0];
    const venue = (
      await this.getVenueByIdQuery
        .fetch({ venueId }, { fetchPolicy: 'cache-first' })
        .toPromise()
    ).data.VenueByPK;

    let address: {
      city: string;
      stateProvince: string;
      countryCode: string;
    } | null = null;

    if (venue?.addresses.length) {
      if (venue.addresses[0].address?.sameAsCompany) {
        const company = (
          await this.getCompanyByIdQuery
            .fetch(
              { companyId: venue.companyId },
              { fetchPolicy: 'cache-first' }
            )
            .toPromise()
        ).data.Company[0];

        address = company.addresses[0].address;
      } else {
        address = venue.addresses[0].address;
      }
    }

    console.log({
      id: orderId, // unique id for this order as saved into your own database
      status: 'completed', // one of: "started" (user has started order), "completed" (user has completed order)
      total_paid: totalPaid,

      event: {
        id: eventId, // unique id for this event as saved into your own database
        name: event.name,
        description: event.description, // (optional)
        start_time: this.getTimeInISOFormat(event.startTime, event.date), // when does the event begin? in ISO 8601 format
        end_time: this.getTimeInISOFormat(event.endTime, event.date), // when does the event end? in ISO 8601 format
        url: 'https://asylumtix.com/venue/' + venueId, // a public url to buy tickets for this event
        image_url: event.media.find(
          m => m.mediaFile.fileType?.toLowerCase() === 'event image'
        )?.mediaFile.location, // (optional) a banner image or other public image for this event

        artist_names: undefined, // (optional) comma separated values
        genre_names: event.type, // (optional) comma separated values
        tag_names: undefined // (optional) comma separated values
      },

      venue: {
        name: venue.name,
        city: address?.city,
        state: address?.stateProvince,
        country: address?.countryCode
      }
    });

    const promise = new Promise<void>((resolve, reject) => {
      HIVE_SDK(
        'ticketingOrder',
        'create',
        {
          id: orderId, // unique id for this order as saved into your own database
          status: 'completed', // one of: "started" (user has started order), "completed" (user has completed order)
          total_paid: totalPaid,

          event: {
            id: eventId, // unique id for this event as saved into your own database
            name: event.name,
            description: event.description, // (optional)
            start_time: this.getTimeInISOFormat(event.startTime, event.date), // when does the event begin? in ISO 8601 format
            end_time: this.getTimeInISOFormat(event.endTime, event.date), // when does the event end? in ISO 8601 format
            url: 'https://asylumtix.com/venue/' + venueId, // a public url to buy tickets for this event
            image_url: event.media.find(
              m => m.mediaFile.fileType?.toLowerCase() === 'event image'
            )?.mediaFile.location, // (optional) a banner image or other public image for this event

            artist_names: undefined, // (optional) comma separated values
            genre_names: event.type, // (optional) comma separated values
            tag_names: undefined // (optional) comma separated values
          },

          venue: {
            name: venue.name,
            city: address?.city,
            state: address?.stateProvince,
            country: address?.countryCode
          }
        },
        function (data) {
          console.log('success');
          // success callback, called after data is saved
          console.log(data);
          resolve();
        },
        function (data) {
          // failure callback, called if something goes wrong
          // error information is provided in the "data" param
          console.error(data);
          reject(data);
        }
      );
    });

    await promise;
  }
}
