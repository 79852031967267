import { Component, OnInit } from '@angular/core';
import { Maybe, TixVenue } from '@tix/data-access';
import { TixBuyersPartialState } from '@tix/event-buyer/state';
import * as BuyerActions from '@tix/event-buyer/state';
import * as BuyerSelectors from '@tix/event-buyer/state';
import * as AuthSelectors from '@tix/auth/state/selectors';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'tix-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class TixHomeLayoutComponent implements OnInit {
  readonly venueInfo$: Observable<Maybe<TixVenue>> = this.store.select(
    BuyerSelectors.getVenuesInfo
  );

  userState$ = this.store.select(AuthSelectors.getUserStatus);

  constructor(
    private readonly store: Store<TixBuyersPartialState>,
    private router: Router
  ) {}
  venueId: string;

  ngOnInit(): void {
    this.venueId = this.router.url.split('/')[2].split('?')[0];
    if (this.venueId) {
      this.store.dispatch(BuyerActions.getVenueInfo({ venueId: this.venueId }));
    }
  }
}
