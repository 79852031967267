import { W } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TixGetItemsAmountsSoldGQL } from '@tix/data-access';
import {
  OrderItem,
  OrderSelectionItem,
  OrderStateManagerService
} from '@tix/event-buyer/services';
import { debounce } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tix-order-select-base',
  template: ''
})
export class OrderSelectBaseComponent implements OnDestroy {
  showIsSoldOut = false;
  orderSelectionInitialized = false;
  isValidatingOrder = false;

  availability: { itemId: string; quantityRemaining: number }[];

  subs: Subscription[] = [];

  constructor(
    public orderStateManager: OrderStateManagerService,
    private getItemsAmountsSoldQuery: TixGetItemsAmountsSoldGQL,
    private _changeDetector: ChangeDetectorRef
  ) {}

  getOrderItem(itemId: string): OrderSelectionItem {
    const item = this.orderStateManager
      .getOrderItems()
      .find(i => i.id === itemId);
    if (!item) throw new Error('No order item with id: ' + itemId);

    return item;
  }

  async fetchQuantitiesSold(): Promise<
    { quantitySold: number; itemId: string; totalQuantity: number }[]
  > {
    const response = await this.getItemsAmountsSoldQuery
      .fetch(
        {
          itemIds: this.orderStateManager.getOrderItems().map(item => item.id)
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .toPromise();

    return [
      ...response.data.TicketConfig.map(config => ({
        itemId: config.ticketConfigId,
        quantitySold: config.totalTicketsByType?.ticketsSold ?? 0,
        totalQuantity: config.noOfSeats ?? 0
      })),
      ...response.data.EventProductSelect.map(product => ({
        itemId: product.eventProductId,
        quantitySold: product.quantitySold,
        totalQuantity: product.quantity ?? 0
      }))
    ];
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.subs.push(
      this.orderStateManager.orderChanged$.subscribe(() => {
        this.onOrderChanged();
      })
    );
  }

  itemIsSoldOut(itemId: string) {
    const quantityAvailableForItem =
      this.orderStateManager.getQuantityAvailableForItem(itemId);
    if (quantityAvailableForItem === -1) {
      return null;
    }

    return this.showIsSoldOut || quantityAvailableForItem <= 0;
  }

  async validateOrder(): Promise<boolean> {
    this.isValidatingOrder = true;

    try {
      await this.orderStateManager.loadOrderFromHold();
      await this.orderStateManager.fetchHoldInformation();
      this.orderStateManager.updateAvailability();

      this.isValidatingOrder = false;

      return this.orderStateManager.getOrderItems().every(item => {
        const available = this.orderStateManager.getQuantityAvailableForItem(
          item.id
        );
        const selected = this.orderStateManager.getQuantitySelectedForItem(
          item.id
        );

        return available >= selected;
      });
    } catch (e) {
      console.error(e);
      this.isValidatingOrder = false;

      return false;
    }
  }

  itemIncrementClick(itemId: string) {
    if (!this.orderStateManager.canIncrementQuantity(itemId)) return;
    this.orderStateManager.incrementQuantity(itemId);

    this.onOrderChanged();
  }

  itemDecrementClick(itemId: string) {
    if (!this.orderStateManager.canDecrementQuantity(itemId)) return;
    this.orderStateManager.decrementQuantity(itemId);

    this.onOrderChanged();
  }

  private _orderChangedHandler = debounce(async () => {
    // We don't need to update the order on hold when still on the events page
    // However this should take affect in the order summary page, so we'd need to override
    // this method there

    // Persist the order

    this.orderStateManager.persistOrder();

    const items = await this.fetchQuantitiesSold();
    for (const item of items) {
      this.orderStateManager.updateOrderItemAmounts(
        item.itemId,
        item.quantitySold,
        item.totalQuantity
      );
    }
    this.orderStateManager.updateAvailability();
  }, 800);
  async onOrderChanged() {
    this._orderChangedHandler;
  }

  async startTrackingAvailability() {
    await this.orderStateManager.startTrackingHold(async () => {
      const items = await this.fetchQuantitiesSold();
      for (const item of items) {
        this.orderStateManager.updateOrderItemAmounts(
          item.itemId,
          item.quantitySold,
          item.totalQuantity
        );
      }

      this._changeDetector.detectChanges();
    });
  }

  stopTrackingAvailability(): void {
    this.orderStateManager.stopTrackingHold();
  }

  onOrderAvailabilityUpdate(): void {}

  itemIsExpired(itemId: string): boolean {
    const item = this.getOrderItem(itemId);

    if (!item.stopSellingDate) return false;

    const currentTime = moment();
    const stopSellingDateTime = moment(
      `${item.stopSellingDate} ${item.stopSellingTime}`,
      'YYYY-MM-DD hh:mm:ss A'
    );
    return stopSellingDateTime.isBefore(currentTime);
  }
  itemComingSoon(itemId: string): boolean {
    const item = this.getOrderItem(itemId);

    if (!item.startSellingDate) return false;

    const currentTime = moment();
    const startSellingDateTime = moment(
      `${item.startSellingDate} ${item.startSellingTime}`,
      'YYYY-MM-DD hh:mm:ss A'
    );
    return startSellingDateTime.isAfter(currentTime);
  }
}
