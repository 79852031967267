import { NgModule } from '@angular/core';
import { TixIconComponent } from './icon.component';
import { TixIconRegistry } from './icon-registry.service';

@NgModule({
  providers: [TixIconRegistry],
  declarations: [TixIconComponent],
  exports: [TixIconComponent]
})
export class TixIconModule {}
