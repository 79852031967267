import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EventState,
  BUYER_FEATURE_KEY,
  eventBuyerAdapter
} from '../reducers/event-buyer.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getBuyerState =
  createFeatureSelector<EventState>(BUYER_FEATURE_KEY);

const { selectAll } = eventBuyerAdapter.getSelectors();
export const getBuyerEventList = createSelector(
  getBuyerState,
  (state: EventState) => selectAll(state)
);

export const getTotalCount = createSelector(
  getBuyerState,
  (state: EventState) => state.totalCount
);
export const getVenuesInfo = createSelector(
  getBuyerState,
  (state: EventState) => state.venueInfo
);

export const getCalendarEventDates = createSelector(
  getBuyerState,
  (state: EventState) => state.calendarEventsDates
);

export const getOrderItems = createSelector(
  getBuyerState,
  (state: EventState) => state.orderItems
);
export const getSelectedVenueId = createSelector(
  getBuyerState,
  (state: EventState) => state?.venueId
);

export const getLoadingVenueEvents = createSelector(
  getBuyerState,
  state => state.loadingEvents
);
