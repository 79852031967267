<form [formGroup]="mediaFilesForm" class="media-files-content-layout">
  <tix-card-title *ngIf="separate" class="mb-5 !font-medium">
    Media
  </tix-card-title>
  <ng-template [ngIf]="mediaFilesFormArray?.controls?.length ?? 0 > 0">
    <div
      class="mat-form-field-media"
      formArrayName="media"
      *ngFor="let group of mediaFilesFormArray.controls; let i = index"
    >
      <mat-form-field [formGroupName]="i" class="relative">
        <mat-label>Media Files</mat-label>
        <input matInput type="text" formControlName="location" />
        <mat-select
          formControlName="fileType"
          class="border-l pl-4 border-gray-300 absolute h-[58px] right-0 top-[-8px] !w-32 !flex !items-center !justify-end"
        >
          <mat-option
            *ngFor="let mediaFilesType of getMediaFileTypes(i)"
            [value]="mediaFilesType.name"
            >{{ mediaFilesType.name }}</mat-option
          >
        </mat-select>
        <mat-error>Invalid media file URL</mat-error>
      </mat-form-field>

      <button
        (click)="openUploadDialog(i)"
        tix-button
        color="light"
        class="mat-form-field-icon !ring-0"
      >
        <tix-icon svgIcon="upload" class="upload-icon"></tix-icon>
      </button>
      <button
        (click)="confirmDeleteMedia(group, i)"
        tix-button
        color="light"
        class="mat-form-field-icon"
      >
        <tix-icon svgIcon="trash"></tix-icon>
      </button>
    </div>
  </ng-template>
</form>

<button
  (click)="addNewMediaFile()"
  class="block -mt-2 text-primary hover:underline"
>
  Add another
</button>

<form [formGroup]="socialMediasForm" class="social-media-content-layout mt-9">
  <tix-card-title *ngIf="separate" class="mb-5 !font-medium">
    Social Media
  </tix-card-title>
  <ng-template [ngIf]="socialMediasFormArray?.controls?.length ?? 0 > 0">
    <div
      class="mat-form-field-social-media"
      formArrayName="socialMedia"
      *ngFor="let group of socialMediasFormArray.controls; let i = index"
    >
      <mat-form-field [formGroupName]="i" class="relative">
        <mat-label>Social Media</mat-label>
        <input matInput type="text" formControlName="url" />

        <mat-select
          formControlName="socialMediaType"
          class="border-l pl-4 border-gray-300 absolute h-[58px] right-0 top-[-8px] !w-32 !flex !items-center !justify-end"
        >
          <mat-option
            *ngFor="let socialMediaType of socialMediaTypes"
            [value]="socialMediaType.name"
            >{{ socialMediaType.name }}</mat-option
          >
        </mat-select>
        <mat-error>Invalid social media URL</mat-error>
      </mat-form-field>

      <button
        (click)="confirmDeleteSocialMedia(group, i)"
        tix-button
        color="light"
        class="mat-form-field-icon"
      >
        <tix-icon svgIcon="trash"></tix-icon>
      </button>
    </div>
  </ng-template>
</form>
<button
  (click)="addNewSocialMedia()"
  class="block -mt-2 text-primary hover:underline"
>
  Add another
</button>
