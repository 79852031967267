import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'tix-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: {
    class: 'tix-card'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixCardComponent {}

/**
 * Component intended to be used within the `<tix-card>` component. It adds styles for a
 * preset header section (i.e. a title, subtitle, and avatar layout).
 */
@Component({
  selector: 'tix-card-header',
  templateUrl: 'card-header.component.html',
  host: {
    class: 'tix-card-header'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixCardHeaderComponent {}

/**
 * Content of a card.
 */
@Directive({
  selector: 'tix-card-content, [tix-card-content], [tixCardContent]',
  host: {
    class: 'tix-card-content'
  }
})
export class TixCardContent {}

/**
 * Title of a card.
 */
@Directive({
  selector: `tix-card-title, [tix-card-title], [tixCardTitle]`,
  host: {
    class: 'tix-card-title'
  }
})
export class TixCardTitle {}

/**
 * Sub-title of a card.
 */
@Directive({
  selector: `tix-card-subtitle, [tix-card-subtitle], [tixCardSubtitle]`,
  host: {
    class: 'tix-card-subtitle'
  }
})
export class TixCardSubtitle {}

/**
 * Action section of a card.
 */
@Directive({
  selector: 'tix-card-actions',
  exportAs: 'tixCardActions',
  host: {
    class: 'tix-card-actions',
    '[class.tix-card-actions-align-end]': 'align === "end"'
  }
})
export class TixCardActions {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}

/**
 * Footer of a card.
 */
@Directive({
  selector: 'tix-card-footer'
})
export class TixCardFooter {
  @HostBinding('class') class = 'tix-card-footer';
}
