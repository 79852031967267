<form [formGroup]="formGroup">
  <div class="profile-content-layout">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error>name is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option
          *ngFor="let status of statusList"
          (click)="onChangeStatus(status.name)"
          [value]="status.name"
          >{{ status.name }}</mat-option
        >
      </mat-select>
      <mat-error>Status is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Type of Event</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of eventTypes" [value]="type.name">{{
          type.name
        }}</mat-option>
      </mat-select>
      <mat-error>Event type is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Minimum Age</mat-label>
      <input matInput type="number" formControlName="minAge" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput type="text" formControlName="description" />
      <mat-error>Description is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Additional Information</mat-label>
      <input matInput type="text" formControlName="additionalInfo" />
    </mat-form-field>
    <div class="quill-editor-field">
      <label for="editor">Additional Information</label>
      <quill-editor
        id="editor"
        style="height: 150px"
        formControlName="additionalInfo"
      ></quill-editor>
    </div>
    <mat-form-field>
      <mat-label>Venue</mat-label>
      <mat-select formControlName="venueId" name="country">
        <mat-option
          *ngFor="let venue of eventVenueList"
          (click)="onSelectVenue(venue)"
          [value]="venue.venueId"
          >{{ venue.name }} ({{
            venue.tickets_aggregate.aggregate?.count
          }})</mat-option
        >
      </mat-select>
      <mat-error>{{ venueErrorMessage }} </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Ticket Configuration</mat-label>
      <mat-select formControlName="ticketConfigurationID" name="country">
        <mat-option
          *ngFor="let ticket of eventTicketLists"
          [value]="ticket.venueTicketId"
          >{{ ticket.name }}</mat-option
        >
      </mat-select>
      <mat-hint *ngIf="isTicketConfigDisabled" class="text-[0.85rem]"
        >Not available for this event type</mat-hint
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Passcode</mat-label>
      <input matInput type="text" formControlName="passcode" />
    </mat-form-field>
    <div class="hidden-container">
      <span>Hidden</span>
      <tix-switch formControlName="hidden"></tix-switch>
    </div>
  </div>
</form>
