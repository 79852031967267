import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Maybe } from 'graphql/jsutils/Maybe';

export function setupFormForArray(
  name: string,
  items: Array<Maybe<FormStruct>>
) {
  const form = new FormGroup({
    [name]: createFormArray(items)
  });

  if (items.length > 0) {
    (form.get(name) as FormArray).clear();
    items.forEach(item => {
      (form.get(name) as FormArray).push(createFormGroup(item));
    });
  }

  return form;
}

function createFormArray(items: Array<Maybe<FormStruct>>) {
  if (!items || items.length === 0)
    return new FormArray([createFormGroup(null)]);
  return new FormArray(items.map(item => createFormGroup(item)));
}

export function createFormGroup(parameters: Maybe<FormStruct>): FormGroup {
  return new FormGroup({
    value: new FormControl(parameters?.value ?? '', [Validators.required]),
    type: new FormControl(parameters?.type ?? '', [Validators.required]),
    id: new FormControl(parameters?.id ?? '')
  });
}

export type FormStruct = {
  value: string;
  id: string;
  type: string;
};
