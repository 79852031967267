<tix-card>
  <tix-card-header>
    <div class="header-container">
      <span>Upload a file</span>
      <!--      <button tix-icon-button color="primary" (click)="dialogRef.close()">-->
      <!--        <tix-icon svgIcon="close"></tix-icon>-->
      <!--      </button>-->
    </div>
  </tix-card-header>
  <tix-card-content class="card-content">
    <file-pond #myPond [options]="pondOptions"> </file-pond>
  </tix-card-content>
  <tix-card-actions align="end">
    <button tix-button color="dark" (click)="dialogRef.close()">Cancel</button>
    <button
      tix-button
      color="primary"
      [disabled]="!isValid()"
      (click)="onSubmit()"
    >
      Save
    </button>
  </tix-card-actions>
</tix-card>
