import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tix-event-youtube-video',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-youtube-video.component.html'
})
export class EventYoutubeVideoComponent {
  constructor(private sanitizer: DomSanitizer) {}

  @Input()
  youtubeVideoUrl: string;

  get youtubeVideoId() {
    if (!this.youtubeVideoUrl) return '';
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = this.youtubeVideoUrl.match(regExp);
    return match && match[7].length == 11 ? match[7] : '';
  }

  get safeYoutubeVideoEmbedUrl() {
    if (!this.youtubeVideoId) return;
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + this.youtubeVideoId
    );
  }
}
