import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BtnLoadingDirectiveModule,
  HeroCheck,
  HeroCheckCircleSolid,
  HeroClock,
  HeroClose,
  HeroDate,
  HeroInformationCircle,
  HeroLocationMarkerSolid,
  HeroPencil,
  HeroPlus,
  HeroSearch,
  HeroShoppingCartSolid,
  HeroSpecialPen,
  HeroTicket,
  HeroTicketSolid,
  StepperComponentModule,
  SwitchComponentModule,
  TixButtonModule,
  TixCardModule,
  TixCheckboxModule,
  TixDialogModule,
  TixIconModule,
  TixIconRegistry,
  TixInputModule,
  HeroEdit,
  HeroSave,
  HeroTrash
} from '@tix/shared/ui/components';
import { TextMaskModule } from 'angular2-text-mask';
import { DatesItemComponent } from './dates-item/dates-item.component';
import { TixEventCardComponent } from './event-card/event-card.component';
import { EventDialogComponent } from './event-dialog/event-dialog.component';
import { CloneDialogComponent } from './event-info/clone-dialog/clone-dialog.component';
import { TixEventInfoComponent } from './event-info/event-info.component';
import { WeekSelectComponent } from './week-select/week-select.component';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { EventMultiDatesComponent } from './event-multi-dates/event-multi-dates.component';
import { EventMultiDialogComponent } from './event-multi-dialog/event-multi-dialog.component';
import { EventMultiInfoComponent } from './event-multi-info/event-multi-info.component';
import { EventMultiMediaComponent } from './event-multi-media/event-multi-media.component';
import { EventMultiStepperComponent } from './event-multi-stepper/event-multi-stepper.component';
import { TixCommunicationsAdminModule } from '@tix/shared/ui/components';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TixVenuesComponentsModule } from '@tix/venues/components';
import { QuillModule } from 'ngx-quill';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { FileModule } from '@tix/ui-common';
import { EventReportSubscriptionComponent } from './event-report-subscription/event-report-subscription.component';
import { EventProductCardComponent } from './product/event-product-card/event-product-card.component';
import { EventProductsUpdateComponent } from './product/event-product-update/event-products-update.component';
import { EventProductsPullComponent } from './product/event-products-pull/event-products-pull.component';
import { EventProductsComponent } from './product/event-products/event-products.component';

import { CampaignPixelComponent } from './campaign-pixel/campaign-pixel.component';
import { EventEmailTemplateComponent } from './event-email-template/event-email-template.component';

const angularMaterialModules = [
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatInputModule,
  MatChipsModule,
  MatIconModule,
  MatDialogModule
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TixCommunicationsAdminModule,
    ReactiveFormsModule,
    TixButtonModule,
    TixIconModule,
    MatInputModule,
    TixInputModule,
    TixCardModule,
    MatSelectModule,
    MatInputModule,
    TixDialogModule,
    TextMaskModule,
    TixCheckboxModule,
    SwitchComponentModule,
    ...angularMaterialModules,
    CdkStepperModule,
    StepperComponentModule,
    MatStepperModule,
    BtnLoadingDirectiveModule,
    MatSnackBarModule,
    TixVenuesComponentsModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    FileModule,
    QuillModule,
    FormsModule
  ],
  declarations: [
    TixEventCardComponent,
    TixEventInfoComponent,
    CloneDialogComponent,
    DatesItemComponent,
    EventDialogComponent,
    WeekSelectComponent,
    EventMultiDialogComponent,
    EventMultiInfoComponent,
    EventMultiDatesComponent,
    EventMultiMediaComponent,
    EventMultiStepperComponent,
    EventReportSubscriptionComponent,
    EventEmailTemplateComponent,
    // event product
    EventProductsComponent,
    EventProductsPullComponent,
    EventProductCardComponent,
    EventProductsUpdateComponent,
    CampaignPixelComponent
    // TicketConfigEditComponent
  ],
  exports: [
    TixEventCardComponent,
    TixEventInfoComponent,
    EventMultiDialogComponent,
    EventMultiInfoComponent,
    EventMultiDatesComponent,
    EventMultiMediaComponent
  ]
})
export class TixEventsComponentsModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([
      HeroCheckCircleSolid,
      HeroLocationMarkerSolid,
      HeroShoppingCartSolid,
      HeroTicketSolid,
      HeroInformationCircle,
      HeroShoppingCartSolid,
      HeroTicketSolid,
      HeroInformationCircle,
      HeroPencil,
      HeroClock,
      HeroTicket,
      HeroDate,
      HeroSpecialPen,
      HeroSearch,
      HeroClose,
      HeroCheck,
      HeroPlus,
      HeroInformationCircle,
      HeroEdit,
      HeroSave,
      HeroCheck,
      HeroTrash
    ]);
  }
}
