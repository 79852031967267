import { Injectable } from '@angular/core';
import {
  TixGetFeesForCompanyGQL,
  TixGetFeesForVenueGQL,
  TixGetGlobalFeesGQL,
  TixGetGlobalFeesQuery,
  TixOrder
} from '@tix/data-access';

export type TixFeeType = 'Platform' | 'Stripe Processing';
export type TixFee = TixGetGlobalFeesQuery['fees'][0];

@Injectable()
export class TixFeesService {
  constructor(
    private getFeesForVenueQuery: TixGetFeesForVenueGQL,
    private getFeesForCompanyQuery: TixGetFeesForCompanyGQL,
    private getGlobalFeesQuery: TixGetGlobalFeesGQL
  ) {}

  public dollarFigureToNumber(dollarFigure?: string): number {
    if (!dollarFigure) return 0;

    return Number(dollarFigure.replace(/[^0-9.-]+/g, ''));
  }

  public formatAsDollarFigure(price: number): string {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  }

  public async getPlatformFee(
    price: number,
    {
      venueId,
      companyId,
      venueFee,
      quantity
    }: {
      venueId: string;
      companyId: string;
      venueFee: number;
      quantity?: number;
    }
  ): Promise<number> {
    let q = quantity ?? 1;
    if (price === 0 && venueFee === 0) return 0;

    const fees = await this.getFeesOfType('Platform', {
      venueId,
      companyId
    });

    let total = 0;

    fees.forEach(fee => {
      if (fee.typeOfFee === '$') {
        total += fee.amount * q;
      }
      if (fee.typeOfFee === '%') {
        total += q * price * (fee.amount / 100);
      }
    });

    return total;
  }

  async calculateProcessingFeeForAmount(
    amount: number,
    {
      venueId,
      companyId,
      disableEnhancementFee
    }: { venueId: string; companyId: string; disableEnhancementFee?: boolean }
  ) {
    const fees = await this.getFeesOfType('Stripe Processing', {
      venueId,
      companyId
    });

    const staticFee = fees
      .filter(f => f.typeOfFee === '$')
      .reduce((acc, fee) => acc + fee.amount, 0);

    const percentageFee = fees.find(fee => fee.typeOfFee === '%');

    if (amount === 0) {
      return 0;
    }

    if (!percentageFee) {
      return staticFee;
    }

    const feeBeforeRevision = staticFee + (amount * percentageFee.amount) / 100;

    if (disableEnhancementFee) return feeBeforeRevision;

    const additionalEnhancementFee =
      feeBeforeRevision * (percentageFee.amount / 100);

    return feeBeforeRevision + additionalEnhancementFee;
  }

  private async getFeesOfType(
    type: TixFeeType,
    { companyId, venueId }: { companyId: string; venueId: string }
  ): Promise<TixFee[]> {
    let fees: TixFee[] = [];
    // Fetch the fees for the venue
    const vRes = await this.getFeesForVenueQuery
      .fetch({
        venueId
      })
      .toPromise();

    fees = vRes.data.fees.filter(f => f.feeType === type);
    if (fees.length) return fees;

    // Fetch the fees for the company
    const cRes = await this.getFeesForCompanyQuery
      .fetch({
        companyId
      })
      .toPromise();

    fees = cRes.data.fees.filter(f => f.feeType === type);
    if (fees.length) return fees;

    // Fetch the global fees
    const gRes = await this.getGlobalFeesQuery.fetch().toPromise();
    fees = gRes.data.fees.filter(f => f.feeType === type);

    return fees;
  }
}
