import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { TixTicketConfig } from '@tix/data-access';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TicketConfigurationExpandContent } from '../ticket-configuration-expand/ticket-configuration-expand.component';
import { minMaxMatcher } from './ticket-configuration-admin.validators';

import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

let counter = 0;
let sectionCounter = 0;

export function createTicketConfigurationBlank() {
  const config = {
    mainSection: {
      label: 'Name',
      value: 'New Configuration ' + ++counter,
      formControl: new FormControl('New Configuration ' + counter, [
        Validators.required
      ]),
      quantity: 0
    },
    secondarySection: {
      label: 'Total tickets',
      value: '0'
    }
  } as TicketConfigurationExpandContent;

  config.children = [createSectionConfigurationBlank(config)];

  return config;
}

export function createSectionConfigurationBlank(
  parent: TicketConfigurationExpandContent
) {
  return {
    id: uuid(),
    parent,
    mainSection: {
      label: 'Section',
      value: 'New section ' + ++sectionCounter
    },
    extraSection: {
      label: 'Name',
      value: 'New section ' + sectionCounter
    },
    secondarySection: {
      label: '# of tickets',
      value: '0'
    },
    formGroup: createConfigurationSectionFormGroupBlank()
  } as TicketConfigurationExpandContent;
}

export function createConfigurationSectionFormGroupBlank() {
  return createConfigurationSectionFormGroup({
    section: 'New section ' + sectionCounter,
    name: 'New section ' + sectionCounter
  } as TixTicketConfig);
}

export function getTouchedForms(
  ticketConfigs: TicketConfigurationExpandContent[]
) {
  return ticketConfigs.filter(config => config.formGroup?.touched);
}

export function checkForErrorInside(
  configuration: TicketConfigurationExpandContent
) {
  if (configuration.mainSection.formControl?.invalid) return true;
  if (configuration.formGroup?.invalid) return true;
  if (configuration.children?.some(e => e.formGroup?.invalid)) return true;
  return false;
}

function QuantityValidator(parent: any, parentTickId: any): ValidatorFn[] {
  let Quantityparent;
  if (parentTickId) {
    let ticketParent = parent?.filter((item: any) => {
      return item.TicketConfigurations.ticketConfigId == parentTickId;
    });

    if (ticketParent.length > 0 && ticketParent[0].totalTicketsByType) {
      return [
        Validators.max(
          ticketParent[0].totalTicketsByType?.ticketsRemaining ?? Infinity
        )
      ];
    }
  }

  return [];
}

export function createConfigurationSectionFormGroup(
  sectionConfig: Maybe<TixTicketConfig>,
  parent?: any
) {
  const configurationSectionFormGroup = new FormGroup(
    {
      includeFees: new FormControl(sectionConfig?.includeFees ?? false),
      parentTicketId: new FormControl(sectionConfig?.parentTicketId),
      startSellingDate: new FormControl(
        getLocalDateAsUTC(sectionConfig?.startSellingDate)
      ),
      stopSellingDate: new FormControl(
        getLocalDateAsUTC(sectionConfig?.stopSellingDate),
        [validateStopSellingDate()]
      ),
      helpText: new FormControl(sectionConfig?.helpText),
      helpTextTitle: new FormControl(sectionConfig?.helpTextTitle),
      startSellingTime: new FormControl(sectionConfig?.startSellingTime),
      stopSellingTime: new FormControl(sectionConfig?.stopSellingTime, [
        validateStopSellingTime()
      ]),
      section: new FormControl(sectionConfig?.section ?? '', [
        Validators.required
      ]),
      name: new FormControl(sectionConfig?.name ?? '', [Validators.required]),
      type: new FormControl(sectionConfig?.ticketType ?? '', [
        Validators.required
      ]),
      quantity: new FormControl(sectionConfig?.noOfSeats ?? '', [
        ...QuantityValidator(parent, sectionConfig?.parentTicketId)
      ]),
      salesChannel: new FormControl(
        sectionConfig?.salesChannel === 'both' || !sectionConfig?.salesChannel
          ? ['online', 'boxoffice']
          : [sectionConfig.salesChannel],
        [Validators.required]
      ),
      row: new FormControl(sectionConfig?.row ?? ''),
      seat: new FormControl(sectionConfig?.seat ?? ''),
      price: new FormControl(sectionConfig?.ticketPrice ?? '$0.00', [
        Validators.required
      ]),
      platformFee: new FormControl({
        value: sectionConfig?.platformFee ?? '$0.00',
        disabled: true
      }),
      processingFee: new FormControl({
        value: sectionConfig?.processingFee ?? '$0.00',
        disabled: true
      }),
      venueFee: new FormControl(sectionConfig?.venueFee ?? '$0.00'),
      perOrderMin: new FormControl(sectionConfig?.perOrderMin ?? ''),
      perOrderMax: new FormControl(sectionConfig?.perOrderMax ?? '')
    },
    { validators: [minMaxMatcher] }
  );
  const parentTicketIdFormControl =
    configurationSectionFormGroup.get('parentTicketId');
  const quantityFormControl = configurationSectionFormGroup.get('quantity');
  if (parentTicketIdFormControl?.value) {
    const ticketParent = parent?.find((item: any) => {
      return (
        item.TicketConfigurations.ticketConfigId ==
        parentTicketIdFormControl.value
      );
    });

    if (
      ticketParent &&
      ticketParent.totalTicketsByType &&
      (quantityFormControl?.value >
        ticketParent.totalTicketsByType?.ticketsRemaining ??
        Infinity)
    ) {
      quantityFormControl?.setValue(
        ticketParent.totalTicketsByType?.ticketsRemaining ?? Infinity
      );
    }
  }

  parentTicketIdFormControl?.valueChanges.subscribe(parentTickId => {
    if (parentTickId) {
      const ticketParent = parent?.find((item: any) => {
        return item.TicketConfigurations.ticketConfigId == parentTickId;
      });

      if (ticketParent) {
        const ticketWithParentValidator = [
          Validators.max(
            ticketParent.totalTicketsByType?.ticketsRemaining ?? Infinity
          )
        ];

        quantityFormControl?.setValidators(ticketWithParentValidator);
        quantityFormControl?.updateValueAndValidity();

        if (
          quantityFormControl?.value >
            ticketParent.totalTicketsByType?.ticketsRemaining ??
          Infinity
        ) {
          quantityFormControl?.setValue(
            ticketParent.totalTicketsByType?.ticketsRemaining ?? Infinity
          );
        }
      }
    }
  });

  return configurationSectionFormGroup;
}
function getLocalDateAsUTC(date: any) {
  if (!date) {
    return null;
  }
  return moment(date).utc().toDate();
}
function validateStopSellingDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startSellingDate = control.parent?.get('startSellingDate')?.value;
    const stopSellingDate = control.value;

    if (
      startSellingDate &&
      stopSellingDate &&
      moment(stopSellingDate).isBefore(startSellingDate, 'day')
    ) {
      return { invalidStopSellingDate: true };
    }

    return null;
  };
}

function validateStopSellingTime(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startSellingDate = moment(
      control.parent?.get('startSellingDate')?.value
    );
    const stopSellingDate = moment(
      control.parent?.get('stopSellingDate')?.value
    );
    const startSellingTime = moment(
      control.parent?.get('startSellingTime')?.value,
      'HH:mm'
    );
    const stopSellingTime = moment(control.value, 'HH:mm');

    if (
      !stopSellingTime ||
      !startSellingDate ||
      !stopSellingDate ||
      !startSellingTime
    ) {
      return null;
    }

    if (
      startSellingDate.isSame(stopSellingDate, 'day') &&
      stopSellingTime.isBefore(startSellingTime)
    ) {
      return { invalidStopSellingDate: true };
    }

    return null;
  };
}

export function calculateTotalTicketsForConfigurationContent(
  content: TicketConfigurationExpandContent
) {
  return (
    content.children
      ?.map(child => child.formGroup)
      .reduce(
        (total, form) =>
          total +
          (form?.get('parentTicketId')?.value
            ? 0
            : parseInt(form?.get('quantity')?.value)),
        0
      ) ?? 0
  );
}
