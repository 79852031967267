<div
  class="
    rounded{{ roundness ? '-' + roundness : '' }}
    {{ theme === 'primary' && 'bg-primary' }}
    {{ theme === 'danger' && 'bg-danger' }}
    {{ theme === 'warn' && 'bg-warn' }}
    {{ theme === 'info' && 'bg-info' }}
    {{ theme === 'success' && 'bg-success' }}
    {{ theme === 'secondary' && 'bg-secondary' }}
    {{ theme === 'dark' && 'bg-dark' }}
    {{ theme === 'light' && 'bg-light text-dark' }}
  "
  [ngStyle]="{ minHeight: size, minWidth: size }"
>
  <ng-content></ng-content>
</div>
