import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TixFormFieldAppend } from './form-field-append.directive';
import { TixFormFieldError } from './form-field-error.directive';
import { TixFormFieldHint } from './form-field-hint.directive';
import { TixFormFieldLabel } from './form-field-label.directive';
import { TixFormFieldPrefix } from './form-field-prefix.directive';
import { TixFormFieldSuffix } from './form-field-suffix.directive';
import { TixFormFieldComponent } from './form-field.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TixFormFieldComponent,
    TixFormFieldError,
    TixFormFieldHint,
    TixFormFieldLabel,
    TixFormFieldPrefix,
    TixFormFieldSuffix,
    TixFormFieldAppend
  ],
  exports: [
    TixFormFieldComponent,
    TixFormFieldError,
    TixFormFieldHint,
    TixFormFieldLabel,
    TixFormFieldPrefix,
    TixFormFieldSuffix,
    TixFormFieldAppend
  ]
})
export class TixFormFieldModule {}
