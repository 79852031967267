import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineEditComponent } from './inline-edit.component';
import { ViewModeDirective } from './mode/view-mode-directive.directive';
import { EditModeDirective } from './mode/edit-mode-directive.directive';

@NgModule({
  declarations: [InlineEditComponent, ViewModeDirective, EditModeDirective],
  imports: [CommonModule],
  exports: [InlineEditComponent, ViewModeDirective, EditModeDirective]
})
export class InlineEditModule {}
