<form [formGroup]="formGroup">
  <div class="profile-content-layout">
    <mat-form-field floatLabel="always" appearance="outline">
      <mat-label>Start Date</mat-label>
      <input
        formControlName="startDate"
        class="!pl-0"
        matInput
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error class="mt-1.5 -ml-2">Event Date is required</mat-error>
    </mat-form-field>

    <mat-form-field
      class="mt-3 lg:mt-0"
      floatLabel="always"
      appearance="outline"
    >
      <mat-label>End Date</mat-label>
      <input
        formControlName="endDate"
        matInput
        class="!pl-0"
        [matDatepicker]="pickerEnd"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
      <mat-error class="mt-1.5 -ml-2">Event Date is required</mat-error>
    </mat-form-field>
  </div>

  <div class="repeats-on-container">
    <span class="repeats-on-label">Repeats on *:</span>
    <tix-week-select
      selectionMode="multi"
      [useSansSerif]="true"
      (selectionChange)="handleSelectionChange($event)"
    ></tix-week-select>
  </div>

  <div formArrayName="dayTimes">
    <form
      class="flex flex-col lg:flex-row"
      *ngFor="let group of dayTimesFormArray.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="flex-grow mr-1 three-cols-line">
        <div>
          <mat-form-field>
            <mat-label>Start Time</mat-label>
            <input
              matInput
              type="time"
              formControlName="startTime"
              value="startTime"
              step="60"
              (change)="onStartTimeUpdate($event, i)"
              class="mat-time-input"
            />
            <mat-error>Start time is required</mat-error>
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>End Time</mat-label>
          <input
            matInput
            type="time"
            formControlName="endTime"
            step="60"
            class="mat-time-input"
          />
          <mat-error>End time is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Doors Open</mat-label>
          <input
            matInput
            type="time"
            formControlName="doorsOpen"
            step="60"
            class="mat-time-input"
          />
          <mat-error>Doors open time is required</mat-error>
        </mat-form-field>
      </div>
      <button
        type="button"
        (click)="removeDayTimes(i)"
        tix-button
        color="light"
        class="mat-form-field-icon"
      >
        <tix-icon svgIcon="trash"></tix-icon>
      </button>
    </form>

    <button
      (click)="addDayTimes()"
      type="button"
      class="block -mt-2 text-primary hover:underline"
    >
      Add another
    </button>
  </div>
</form>
