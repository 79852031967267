<tix-contact-profile
  [profileViewModel]="profileViewModel$ | async"
  [isEditMode]="isEditMode"
  [userAssignedRoles]="(userAssignedRoles$ | async) || []"
></tix-contact-profile>

<tix-phone-numbers-edit
  *ngIf="isEditMode && (isLoadingStaffMember$ | async) === false"
  [pageName]="'staffScreen'"
  [phoneNumbers]="phoneNumbers$ | async"
  [phoneTypesBusinessCodeGroup]="businessPhoneTypes$ | async"
  [isloading]="isSavingPhoneNumbers$ | async"
  (delete)="deletePhoneNumber($event)"
  (save)="savePhoneNumbers($event)"
>
</tix-phone-numbers-edit>
<div *ngIf="isEditMode">
  <tix-contact-permissions
    [ngClass]="{
      'pointer-events-none opacity-50': (canEditRole$ | async) === false
    }"
    *ngIf="userRolesList$ | async as roleList"
    [userAssignedRoles]="(userAssignedRoles$ | async) || []"
    [isloading]="isLoadingRolesList$ | async"
    [userRoleList]="roleList"
    [userId]="userId"
  ></tix-contact-permissions>
</div>
