import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { TixPhoneNumbersComponent } from '..';
import { BtnLoadingDirectiveModule } from '../../btn-loading/btn-loading.directive';
import { TixButtonModule } from '../../button';
import { TixDialogModule } from '../../dialog';
import { HeroTrash, TixIconModule, TixIconRegistry } from '../../icon';
import { TixInputModule } from '../../input';
import { TixCardModule } from './../../card/card.module';
import { TixCheckboxModule } from './../../checkbox/checkbox.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HeroExclamation } from './../../icon/heroicons/outline';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [TixPhoneNumbersComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TixInputModule,
    TixCheckboxModule,
    TixIconModule,
    TixButtonModule,
    RouterModule,
    TixCardModule,
    MatSelectModule,
    TextMaskModule,
    TixDialogModule,
    BtnLoadingDirectiveModule
  ],
  exports: [TixPhoneNumbersComponent],
  providers: []
})
export class TixPhoneNumbersAdminModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([HeroTrash, HeroExclamation]);
  }
}
