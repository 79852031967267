import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromStaff from './+state/staff.reducer';
import { TixStaffEffects } from './+state/effects/staff.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature(fromStaff.STAFF_FEATURE_KEY, fromStaff.reducer),
    EffectsModule.forFeature([TixStaffEffects])
  ]
})
export class StaffStateModule {}
