<div
  class="w-full my-4 mx-auto max-w-sm"
  [ngClass]="{
    'md:max-w-screen-lg': !use1ColumnLayout,
    'md:max-w-screen-sm': use1ColumnLayout
  }"
>
  <tix-card
    class="bg-light w-full rounded-md p-3 border-2 border-primary border-l-4 border-l-success"
  >
    <div class="w-full px-1 mb-4 flex justify-between flex-row">
      <div class="text-xl font-semibold">Add-Ons:</div>
      <div class="flex flex-row items-center">
        <button
          class="px-3 mr-2 h-full flex items-center py-1 border rounded-md md:py-2 hover:bg-matprimary-50"
          (click)="back()"
          [disabled]="isHolding"
        >
          <mat-icon>keyboard_backspace</mat-icon>
          <div class="ml-1">Back</div>
        </button>
        <button
          class="px-3 py-1 text-light bg-primary rounded-md md:py-2 hover:bg-matprimary-700"
          (click)="proceed()"
          [disabled]="isHolding"
        >
          {{ proceedButtonText }}
        </button>
      </div>
    </div>
    <div *ngIf="isLoading" class="w-full h-96 grid place-items-center">
      <mat-spinner [diameter]="64"></mat-spinner>
    </div>
    <div
      *ngIf="!isLoading && orderSelectionInitialized"
      class="grid gap-6"
      [ngClass]="{
        'grid-cols-1': use1ColumnLayout,
        'md:grid-cols-2': use2ColumnLayout || use3ColumnLayout,
        'xl:grid-cols-3': use3ColumnLayout
      }"
    >
      <div *ngFor="let addon of addons; index as i">
        <div
          *ngIf="addon"
          class="w-full flex items-center justify-center rounded-md shadow-md ring-2 ring-transparent focus:ring-primary hover:ring-primary"
        >
          <div class="mx-auto w-full">
            <div class="grid w-full flex-col">
              <!-- no image provided -->
              <div
                *ngIf="addon?.['EventProductMedia']?.length === 0"
                class="h-0 w-full"
                [ngClass]="isNoImages ? 'h-0' : 'md:h-80'"
              ></div>
              <!-- image present -->
              <img
                *ngIf="addon?.['EventProductMedia']?.length ??0 > 0"
                (click)="openDialog(addon)"
                [src]="addon?.['EventProductMedia']?.[0]?.MediaFile?.location"
                class="h-80 w-full rounded-t-md object-cover cursor-pointer"
              />

              <div class="p-6 grid z-10 w-full bg-[#F8F9FB] rounded-b-md">
                <div
                  class="cursor-pointer hover:underline hover:text-primary font-bold sm:text-xl line-clamp-1"
                  (click)="openDialog(addon)"
                  [matTooltip]="addon['name'] ?? ''"
                  [matTooltipPosition]="'above'"
                >
                  {{ addon['name'] }}
                </div>
                <div class="h-3">
                  <div
                    *ngIf="
                      (addon['quantityRemaining'] ?? 0 <= 20) &&
                      (addon['quantityRemaining'] ?? 0 > 0)
                    "
                    class="text-xs flex flex-col"
                  >
                    <span @fadeInUp class="text-yellow-500"
                      >{{
                        orderStateManager.getMaxSelectableForItem(
                          addon.eventProductId
                        )
                      }}
                      Item<span
                        *ngIf="
                          orderStateManager.getMaxSelectableForItem(
                            addon.eventProductId
                          )
                        "
                        >s</span
                      >
                      Remaining</span
                    >
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="pt-2 text-primary font-semibold">
                    {{ addon['price'] }}
                  </div>
                  <div
                    *ngIf="
                      orderStateManager.getMaxSelectableForItem(
                        addon.eventProductId
                      ) === 0
                    "
                  >
                    <span @fadeInUp class="text-danger font-semibold"
                      >SOLD OUT</span
                    >
                  </div>
                  <div
                    *ngIf="
                      orderStateManager.getMaxSelectableForItem(
                        addon.eventProductId
                      ) > 0
                    "
                    class="flex flex-row items-center text-sm font-bold space-x-1 md:space-x-3"
                  >
                    <button
                      (click)="itemDecrementClick(addon.eventProductId)"
                      class="border rounded-md px-2 pb-1 text-base cursor-pointer hover:border-primary bg-light"
                      [disabled]="
                        !orderStateManager.canDecrementQuantity(
                          addon.eventProductId
                        )
                      "
                    >
                      -
                    </button>
                    <div class="text-center">
                      {{
                        orderStateManager.getQuantitySelectedForItem(
                          addon.eventProductId
                        )
                      }}
                    </div>
                    <button
                      (click)="itemIncrementClick(addon.eventProductId)"
                      class="border rounded-md px-2 pb-1 text-base cursor-pointer hover:border-primary bg-light"
                      [disabled]="
                        !orderStateManager.canIncrementQuantity(
                          addon.eventProductId
                        )
                      "
                    >
                      +
                    </button>
                  </div>
                </div>
                <div>
                  <span
                    class="line-clamp-3 h-20 py-2 text-sm font-light leading-relaxed scroll-auto overflow-y-auto"
                  >
                    {{ toPlainText(addon['additionalInfo'] ?? '') }}
                  </span>
                  <span
                    class="cursor-pointer underline border-b-primary hover:text-primary"
                    (click)="openDialog(addon)"
                    >Show more</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full px-1 mt-4 flex justify-end flex-row">
      <div class="flex flex-row items-center space-x-2">
        <button
          class="px-3 h-full flex items-center py-1 border rounded-md md:py-2 hover:bg-matprimary-50"
          (click)="back()"
          [disabled]="isHolding"
        >
          <mat-icon>keyboard_backspace</mat-icon>
          <div class="ml-1">Back</div>
        </button>
        <button
          class="px-3 py-1 text-light bg-primary rounded-md md:py-2 hover:bg-matprimary-700"
          (click)="proceed()"
          [disabled]="isHolding"
        >
          {{ proceedButtonText }}
        </button>
      </div>
    </div>
  </tix-card>
</div>
