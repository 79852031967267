import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TixThemes } from '@tix/shared/models';

@Component({
  selector: 'tix-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixBadgeComponent {
  @Input() theme: TixThemes = 'primary';
  @Input() roundness: '' | 'md' | 'lg' | 'full' = '';
  @Input() size = '28px';
}
