import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROLE_TYPES } from '../user.model';
import { State, USER_FEATURE_KEY } from '../user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<State>(USER_FEATURE_KEY);

export const getUserStatus = createSelector(
  getUserState,
  (state: State) => state.userStatus
);

export const getAuthenticatedUser = createSelector(
  getUserState,
  (state: State) => state.authenticatedUser
);

export const getUserError = createSelector(
  getUserState,
  (state: State) => state.error
);
export const getUserLoaddingInfo = createSelector(
  getUserState,
  (state: State) => state.userLoaddingInfo
);
export const getUserLoadingPhone = createSelector(
  getUserState,
  (state: State) => state.userLoaddingPhone
);
export const getUserLoaddingAddress = createSelector(
  getUserState,
  (state: State) => state.userLoaddingAddress
);

export const getUserPhoneNumbers = createSelector(
  getUserState,
  (state: State) => state.userPhoneNumbers
);

export const getUserAddress = createSelector(
  getUserState,
  (state: State) => state.userAddress
);

export const getUserRolesInfo = createSelector(
  getUserState,
  (state: State) => state.userRoles
);

export const getAdminRole = createSelector(getUserState, (state: State) => {
  return state.usersRole?.find(n => n?.role === ROLE_TYPES.ROLE_ADMIN);
});
