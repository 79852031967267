import { createAction, props } from '@ngrx/store';
import { CompanyEditSection, TixCompanyProfile } from '@tix/shared/models';
import {
  Maybe,
  TixAddress,
  TixCompanyWarning,
  TixMediaFile,
  TixPhoneNumber,
  TixSocialMedia,
  TixWarning
} from '@tix/data-access';

export const loadCompanies = createAction('[Company List Page] Load Companies');

export const deleteCompany = createAction(
  '[Company List Page] Delete Company',
  props<{ companyId: string }>()
);

export const filterCompanies = createAction(
  '[Company List Page] Filter Companies',
  props<{ searchText: string }>()
);

export const loadVenuesForSelectedCompany = createAction(
  '[Venues List Page] Load Venues for the selected Company'
);

export const loadCompanyById = createAction(
  '[Company Page] Load Company',
  props<{ companyId: string }>()
);

export const deselectCompany = createAction('[Company Page] deselect company');

export const editCompanySection = createAction(
  '[Company Detail Page] Edit Section',
  props<{ section: CompanyEditSection }>()
);

/* #region  Save Actions */
export const insertCompanyProfile = createAction(
  '[Company Admin Page] insert New Company Profile',
  props<{ name: string; url: Maybe<string> }>()
);

export const saveCompanyProfile = createAction(
  '[Company Admin Page] Save Profile Section',
  props<{ profile: TixCompanyProfile }>()
);

export const saveCompanyPhoneNumbers = createAction(
  '[Company Admin Page] Save Phone Numbers',
  props<{ phoneNumbers: TixPhoneNumber[] }>()
);

export const saveCompanyMediaFiles = createAction(
  '[Company Admin Page] Save Media Files',
  props<{ mediaFiles: Partial<TixMediaFile>[] }>()
);

export const saveCompanySocialMedias = createAction(
  '[Company Admin Page] Save Social medias',
  props<{ socialMedias: Partial<TixSocialMedia>[] }>()
);

export const saveCompanyWarnings = createAction(
  '[Company Admin Page] Save Warnings',
  props<{ warnings: Partial<TixWarning>[] }>()
);

export const saveCompanyAddress = createAction(
  '[Company Admin Page] Save Address',
  props<{ address: TixAddress }>()
);

/* #endregion */

/* #region  Delete Actions */

export const deleteCompanyPhoneNumber = createAction(
  '[Company Admin Page] Delete Phone Number',
  props<{ phoneNumber: TixPhoneNumber }>()
);

export const deleteCompanyMediaFile = createAction(
  '[Company Admin Page] Delete Media File',
  props<{ mediaFile: TixMediaFile }>()
);

export const deleteCompanySocialMedia = createAction(
  '[Company Admin Page] Delete Social media',
  props<{ socialMedia: TixSocialMedia }>()
);

export const deleteCompanyWarning = createAction(
  '[Company Admin Page] Delete Warning',
  props<{ warning: TixWarning }>()
);

/* #endregion */

// Selection actions
export const selectCompany = createAction(
  '[Company List Page] Select Company',
  props<{ companyId: string }>()
);
export const selectContact = createAction(
  '[Company Staff Page] Select contact',
  props<{ staffId: string }>()
);
export const selectVenue = createAction(
  '[Company Venues Page] Select venue',
  props<{ venueId: string }>()
);
export const selectEvent = createAction(
  '[Company Events Page] Select event',
  props<{ eventId: string }>()
);
