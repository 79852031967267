import {
  Component,
  OnInit,
  ViewEncapsulation,
  NgModule,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FilePondErrorDescription,
  FilePondFile,
  FilePondOptions
} from 'filepond';
import { MatDialogRef } from '../dialog';
import { TixCardModule } from '../card';
import { HeroClose, TixIconModule, TixIconRegistry } from '../icon';
import { TixButtonModule } from '../button';
import { FilePondModule } from 'ngx-filepond';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'apps/purchasing-portal/src/environments/environment';

@Component({
  selector: 'tix-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  // @ViewChild('myPond') myPond: FilePondComponent;

  @Output() submitClick = new EventEmitter<any[]>();

  @Input()
  imageWidth: number | null = null;
  @Input()
  imageHeight: number | null = null;

  pondFiles: string[] = [];
  public pondOptions: FilePondOptions;
  public numberOfLoads = 0;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.pondOptions = {
      className: 'my-filepond',
      allowMultiple: false,
      labelIdle: 'Drop files here',
      name: 'file',
      onaddfile: (error: FilePondErrorDescription | null, file: FilePondFile) =>
        this.pondHandleAddFile(file),
      onremovefile: (
        error: FilePondErrorDescription | null,
        file: FilePondFile
      ) => this.pondHandleRemoveFile(file),
      server: {
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options
        ) => {
          const formData = new FormData();
          formData.append(
            'operations',
            JSON.stringify({
              query:
                'mutation UploadMedia($file: Upload!, $width: Float, $height: Float) {\n  uploadMedia(file: $file, width: $width, height: $height) {url}\n}',
              variables: {
                file: null,
                height: this.imageHeight,
                width: this.imageWidth
              }
            })
          );
          formData.append('map', JSON.stringify({ file: ['variables.file'] }));
          formData.append('file', file, file.name);
          const subscription = this.httpClient
            .post(environment.customApiUrl + '/graphql', formData, {
              reportProgress: true,
              observe: 'events',
              responseType: 'json',
              headers: {
                Accept: '*/*',
                'x-hasura-admin-secret':
                  'L3er5wbpOzmkJ20BpJFuL4l5C61upnhsLA1Rh2Obj2ljqpJpQv06NnTQAavTsQef'
              }
            })
            .subscribe(
              event => {
                if (event.type === HttpEventType.UploadProgress) {
                  progress(!!event.total, event.loaded, event.total || 0);
                }
                if (event.type === HttpEventType.Response) {
                  this.numberOfLoads = this.numberOfLoads - 1;
                  if (event.status >= 200 && event.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load((event.body as any)?.data?.uploadMedia?.url);
                    this.pondFiles.push(
                      (event.body as any)?.data?.uploadMedia?.url
                    );
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                  }
                }
              },
              err => {
                this.numberOfLoads = this.numberOfLoads - 1;
                error(err);
              }
            );
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              subscription.unsubscribe();

              // Let FilePond know the request has been cancelled
              abort();
            }
          };
        }
      }
    };
  }

  isValid() {
    if (this.numberOfLoads > 0 || this.pondFiles.length === 0) {
      return false;
    }
    if (this.pondFiles.findIndex(file => !file) >= 0) {
      return false;
    }
    return true;
  }

  pondHandleAddFile(file: FilePondFile) {
    this.numberOfLoads = this.numberOfLoads + 1;
    // this.pondFiles.push({title: file.filename});
  }

  pondHandleRemoveFile(file: FilePondFile) {
    this.pondFiles = this.pondFiles.filter(
      listFile => !listFile?.split('%20').join(' ').includes(file.filename)
    );
  }

  onSubmit() {
    this.dialogRef.close(this.pondFiles);
  }
}

@NgModule({
  imports: [
    CommonModule,
    TixCardModule,
    TixIconModule,
    FilePondModule,
    TixButtonModule
  ],
  declarations: [FileUploadDialogComponent],
  exports: [FileUploadDialogComponent]
})
export class FileUploadDialogComponentModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([HeroClose]);
  }
}
