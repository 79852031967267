import { CommonModule } from '@angular/common';
import {
  Directive,
  Input,
  NgModule,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';

@Directive({
  selector: '[tixBtnLoading]'
})
export class BtnLoadingDirective {
  color: ThemePalette = 'accent';
  diameter = 18;
  isSpinning: boolean | null | undefined = null; // unset by default, this forces it to evaluate upon first render.
  spinner: MatProgressSpinner | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set appSpinOnColor(color: ThemePalette) {
    this.color = color;
    if (this.spinner) {
      this.spinner.color = color;
    }
  }

  @Input()
  set appSpinOnDiameter(diameter: number) {
    this.diameter = diameter;
    if (this.spinner) {
      this.spinner.diameter = diameter;
    }
  }

  @Input() set tixBtnLoading(condition: boolean | undefined | null) {
    if (!!condition !== this.isSpinning) {
      this.spinner = null;
      this.viewContainer.clear();
      this.isSpinning = condition;
      if (!condition) {
        // Render the template
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (condition) {
        this.addSpinner();
      }
    }
  }

  private addSpinner() {
    const { instance } =
      this.viewContainer.createComponent<MatProgressSpinner>(
        MatProgressSpinner
      );
    instance.diameter = this.diameter;
    instance.color = this.color;
    instance.mode = 'indeterminate';
    instance._elementRef.nativeElement.classList.add('spin-on-instance');
    this.spinner = instance;
  }
}

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [BtnLoadingDirective],
  exports: [BtnLoadingDirective]
})
export class BtnLoadingDirectiveModule {}
