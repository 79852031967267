<!-- start event products -->
<div class="w-full px-6 my-3 rounded-md bg-light text-lg font-medium">
  Add-Ons
</div>
<div class="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 px-6">
  <tix-event-product-card
    *ngFor="let eventProduct of eventProducts$ | async; trackBy: trackByFn"
    [addon]="eventProduct"
    [showQuantity]="true"
    (click)="updateProduct(eventProduct)"
  ></tix-event-product-card>
  <div
    class="w-full h-full flex justify-center border items-center text-secondary text-3xl rounded-md shadow-md bg-light cursor-pointer ring-2 ring-transparent focus:ring-primary hover:ring-primary hover:text-primary"
    (click)="pullProducts()"
    [matTooltip]="'Pull from Add-Ons'"
    [matTooltipPosition]="'above'"
  >
    +
  </div>
</div>

<!-- end event products -->
