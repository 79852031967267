import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { BaseIfDirective } from '../../../../shared/ui/components/src/lib/core/base';
import { NgIfContext } from '@angular/common';
import { UserPartialState } from '@tix/auth/state';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserStatus } from '@tix/shared/models';

@Directive({
  selector: '[tixIfAuth],[tixIfNoAuth]'
})
export class TixAuthDirective extends BaseIfDirective implements OnDestroy {
  static ngTemplateGuard_tixIfAuth: 'binding';
  static ngTemplateGuard_tixIfNoAuth: 'binding';

  private userStatus$: Subscription;
  private userStatus: UserStatus;
  private requireAuth: boolean;

  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<any>>,
    private readonly store: Store<UserPartialState>
  ) {
    super(viewContainer, templateRef);

    this.userStatus$ = this.store
      .select(AuthSelectors.getUserStatus)
      .subscribe(status => {
        this.userStatus = status;
        this.updateStatus();
      });
  }

  ngOnDestroy(): void {
    this.userStatus$?.unsubscribe();
  }

  private updateStatus(): void {
    this.context.$implicit = this.context.ngIf = this.requireAuth
      ? this.userStatus === UserStatus.LOGGED_IN
      : this.userStatus !== UserStatus.LOGGED_IN;
    this.updateView();
  }

  /**
   * Require user to be logged in to show the template.
   */
  @Input()
  set tixIfAuth(_: unknown) {
    this.requireAuth = true;
    this.updateStatus();
  }

  /**
   * Require user to be logged out to show the template.
   */
  @Input()
  set tixIfNoAuth(_: unknown) {
    this.requireAuth = false;
    this.updateStatus();
  }

  /**
   * Template to show if the user is(tixIfAuth) or is not(tixIfNoAuth) logged in.
   *
   * When tixIfAuth, UserStatus === LOGGED_IN
   *
   * When tixIfNoAuth, UserStatus === LOGGED_OUT
   */
  @Input()
  set tixAuthThen(templateRef: TemplateRef<NgIfContext<any>> | null) {
    this.setThenRef('tixAuthThen', templateRef);
  }

  /**
   * Template to show if the user is not(tixIfAuth) or is (tixIfNoAuth) logged in.
   *
   * When tixIfAuth, UserStatus !== LOGGED_IN
   *
   * When tixIfNoAuth, UserStatus !== LOGGED_OUT
   */
  @Input()
  set tixAuthElse(templateRef: TemplateRef<NgIfContext<any>> | null) {
    this.setElseRef('tixAuthElse', templateRef);
  }
}
