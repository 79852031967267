import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './actions/user.actions';
import { UserAddress, UserPhoneNumbers, UserStatus } from '@tix/shared/models';
import { User } from '@tix/shared/models';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  TixContactAddress,
  TixContactPhoneNumber,
  TixRole,
  TixUser,
  TixUserRole
} from '@tix/data-access';
export const USER_FEATURE_KEY = 'user';

export interface State {
  authenticatedUser: User | null;
  userStatus: UserStatus; // has the User list been loaded
  error?: string; // last known error (if any)
  userRoles?: Maybe<TixUser>;
  usersRole?: Maybe<TixRole>[] | undefined;
  userPhoneNumbers: Maybe<UserPhoneNumbers>[] | null | undefined; // User phone number list
  userAddress: Maybe<UserAddress> | null | undefined; // User's address
  userLoaddingPhone: boolean;
  userLoaddingAddress: boolean;
  userLoaddingInfo: boolean;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: State;
}

export const initialState: State = {
  authenticatedUser: null,
  userStatus: UserStatus.UNKNOWN,
  userPhoneNumbers: null,
  userAddress: null,
  userLoaddingPhone: false,
  userLoaddingAddress: false,
  userLoaddingInfo: false
};

const userReducer = createReducer(
  initialState,
  // on(UserActions.loginAction, (state) => {
  //   console.log('[User] Login Action hit...');
  //   return { ...state, userStatus: UserStatus.LOGGED_OUT };
  // }),
  on(UserActions.initializeSuccessAction, (state, { user }) => ({
    ...state,
    authenticatedUser: user,
    userStatus: user ? UserStatus.LOGGED_IN : UserStatus.LOGGED_OUT,
    userLoaddingInfo: false
  })),
  on(UserActions.initializeFailureAction, (state, { error }) => ({
    ...state,
    error,
    userStatus: UserStatus.INIT_FAILED
  })),
  on(UserActions.logoutAction, state => ({
    ...state,
    authenticatedUser: null,
    userStatus: UserStatus.LOGGED_OUT,
    userPhoneNumbers: null,
    userAddress: null,
    userRoles: null
  })),
  on(UserActions.updateUserInfoAction, state => ({
    ...state,
    userLoaddingInfo: true
  })),
  on(UserActions.loginSuccessAction, (state, { user }) => ({
    ...state,
    authenticatedUser: user,
    userStatus: UserStatus.LOGGED_IN
  })),
  on(UserActions.loginFailureAction, (state, { error }) => ({
    ...state,
    error,
    userStatus: UserStatus.LOGIN_FAILED
  })),
  on(UserActions.saveUserPhoneNumbers, state => ({
    ...state,
    userLoaddingPhone: true
  })),
  on(
    UserActions.upsertContactPhoneNumbersSuccess,
    (state, { contactPhoneNumbers }) => {
      return {
        ...state,
        userLoaddingPhone: false,
        userPhoneNumbers: [
          ...(state.userPhoneNumbers ?? []),
          ...(contactPhoneNumbers ?? [])
        ]
      };
    }
  ),
  on(UserActions.deleteContactPhoneNumberSuccess, (state, action) => {
    const updatedPhoneList = state.userPhoneNumbers?.filter(
      phone => phone?.contactPhoneNumberId !== action.phoneNumberId
    );
    return { ...state, userPhoneNumbers: updatedPhoneList };
  }),
  on(UserActions.saveUserAddress, state => ({
    ...state,
    userLoaddingAddress: true
  })),
  on(UserActions.updateUserAddressSuccess, (state, { address }) => {
    return { ...state, userAddress: address, userLoaddingAddress: false };
  }),
  on(UserActions.setUserRolesSuccess, (state, { userRoles }) => {
    return { ...state, userRoles };
  }),
  on(UserActions.fetchRoleSuccess, (state, action) => {
    return {
      ...state,
      usersRole: action.roles
    };
  }),
  on(UserActions.assignedAdminRoleSuccess, (state, action): State => {
    const user = {
      userId: action.userId,
      userRoles: [
        ...(state.userRoles?.userRoles ?? []),
        action.userRoles
      ] as TixUserRole[],
      __typename: 'User'
    };

    return { ...state, userRoles: user as Maybe<TixUser> };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
