import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetch, navigation, pessimisticUpdate } from '@nrwl/angular';
import { getSelectedCompanyId } from '@tix/company/state/selectors';

import {
  TixGetContactsByCompanyIdGQL,
  TixCompanyContact,
  TixGetStaffByPkGQL,
  Maybe,
  TixContactPhoneNumber,
  TixContact,
  TixInsertCompanyContactGQL,
  TixGetRolesGQL,
  TixRole,
  TixInsertStaffUserGQL,
  TixPhoneNumberInsertInput,
  TixInsertContactPhoneNumberGQL,
  TixDeleteContactPhoneNumberByPhoneNumberPkGQL,
  TixPhoneNumber,
  TixDeleteCompanyStaffRoleGQL,
  TixInsertCompanyStaffRoleGQL,
  TixUserRoleInsertInput,
  TixUserRole,
  TixInsertStaffUserMutation,
  TixInsertCompanyContactMutation,
  TixUser,
  TixInsertUserCompanyContactGQL,
  TixUpdateContactByPkGQL,
  TixSendMailByEmailGQL,
  TixInsertExistingUserToStaffGQL,
  TixInsertCompanyContactUserRolesGQL
} from '@tix/data-access';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import * as StaffAction from '../actions/staff.actions';
import * as StaffApiAction from '../actions/staff-api.actions';
import { TixStaffPartialState } from '../staff.reducer';
import { TixCompanyStaffAdminPage } from 'libs/staff/pages/src/lib/company-staff-admin/company-staff-admin.component';
import { combineLatest, EMPTY } from 'rxjs';
import { getAuthenticatedUser } from '@tix/auth/state/selectors';
import * as StaffSelector from '../selectors/staff.selectors';
import { Update } from '@ngrx/entity';
import * as CompanySelectors from '../../../../../../company/state/src/lib/+state/selectors/company.selectors';
import { insertCompanyContactUser } from '../actions/staff.actions';
import { sendEmailSuccess } from '../actions/staff-api.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TixStaffEffects {
  loadContactsForSelectedCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.loadContactsForSelectedCompany),
      withLatestFrom(this.store.select(getSelectedCompanyId)),
      fetch({
        run: (action, companyId) => {
          return this.getContactsByCompanyId
            .fetch({ companyId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                return StaffApiAction.loadContactsForSelectedCompanySuccess({
                  contacts: data.CompanyContact.map(staff => {
                    return {
                      ...staff,
                      companyId: companyId
                    } as TixCompanyContact;
                  })
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return StaffApiAction.loadContactsForSelectedCompanyFailure({
            error
          });
        }
      })
    )
  );

  fetchUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.fetchUserRoles),
      fetch({
        run: () => {
          return this.getRoles.fetch().pipe(
            map(({ data }) =>
              StaffApiAction.fetchRoleSuccess({
                roles: data.Role as Maybe<TixRole>[]
              })
            )
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          StaffApiAction.fetchRoleFailure({ error: error });
        }
      })
    )
  );

  selectStaffContact$ = createEffect(() =>
    this.actions$.pipe(
      navigation(TixCompanyStaffAdminPage, {
        run: (route: ActivatedRouteSnapshot, state) => {
          const staffId: string = route.params['staffId'];
          if (staffId) {
            this.store.dispatch(StaffAction.fetchUserRoles()); // Fetch available user's role
            if (staffId === 'add') return StaffAction.deselectStaff(); // Deselect selected staff from staff store for add new staff
            this.store.dispatch(StaffAction.loadStaffById({ staffId }));
            return StaffAction.selectStaff({ staffId });
          }
          return EMPTY;
        },
        onError: (route: ActivatedRouteSnapshot, error: any) => {
          console.error({ error });

          console.error('error', error);
        }
      })
    )
  );

  loadStaffById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.loadStaffById),
      withLatestFrom(this.store.select(getSelectedCompanyId)),
      fetch({
        run: ({ staffId }, companyId) => {
          return this.getStaffByPk
            .fetch({ contactId: staffId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                const contactPhoneNumbers: Maybe<TixContactPhoneNumber>[] = [];
                data.ContactByPK?.phoneNumbers.forEach(p =>
                  p.phoneNumber?.contactPhoneNumbers.forEach(p =>
                    contactPhoneNumbers.push(p as Maybe<TixContactPhoneNumber>)
                  )
                );
                const userInfo = {
                  ...data.ContactByPK,
                  phoneNumbers: contactPhoneNumbers
                } as Maybe<TixContact>;

                return StaffApiAction.loadStaffByIdSuccess({
                  contact: {
                    __typename: 'CompanyContact',
                    contact: userInfo,
                    contactId: userInfo?.contactId,
                    companyId: companyId
                  } as TixCompanyContact
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return StaffApiAction.loadStaffByIdFailure({ error });
        }
      })
    )
  );

  saveUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.saveUserForCurrentCompany),
      withLatestFrom(
        combineLatest([
          this.store.select(getSelectedCompanyId),
          this.store.select(getAuthenticatedUser),
          this.store.select(StaffSelector.getSelectedContactProfileViewModel)
        ])
      ),
      pessimisticUpdate({
        run: ({ contactInfo }, state) => {
          const [companyId, { uid }, selectedStaff] = state as [
            string,
            { uid: string },
            TixCompanyContact
          ];
          const variables = {
            contactId: contactInfo.contactId, // Id of staff member
            updatedBy: uid, // Id of logged-in user,
            ...contactInfo,
            dob: new Date()
          };

          return this.updateContactById.mutate({ ...variables }).pipe(
            map(updateContact => {
              this.snackbar.open('Your changes have been saved successfully!');

              return StaffApiAction.insertUserForCurrentCompanySuccess({
                updatedContact: updateContact.data as Update<TixContact>
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          return StaffApiAction.insertUserForCurrentCompanyFailure({
            error: error
          });
        }
      })
    )
  );

  SavedUserDatasuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StaffApiAction.insertUserForCurrentCompanySuccess),
        withLatestFrom(this.store.select(getSelectedCompanyId)),
        tap(([action, companyId]) => {
          if (companyId && action.updatedContact.id) {
            this.router.navigate([
              'company',
              companyId,
              'staff',
              action.updatedContact.id
            ]);
          }
        })
      ),
    { dispatch: false }
  );

  upsertContactPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.saveUserPhoneNumbers),
      withLatestFrom(
        combineLatest([
          this.store.select(StaffSelector.getSelectedContactProfileViewModel),
          this.store.select(getAuthenticatedUser)
        ])
      ),
      pessimisticUpdate({
        run: ({ phoneNumbers }, [selectedStaff, authUser]) => {
          const newPhoneNumbers = (phoneNumbers as TixPhoneNumber[])
            .filter(
              phoneNumber =>
                phoneNumber.phoneNumberId === '' || !phoneNumber.phoneNumberId
            )
            .map(
              phoneNumber =>
                ({
                  phoneNumber: phoneNumber.phoneNumber,
                  type: phoneNumber.type,
                  updatedBy:
                    authUser?.uid || '00fda0cf-5a5d-4821-9e05-d995720212ab',
                  contactPhoneNumbers: {
                    data: [
                      {
                        contactId: selectedStaff?.contactId
                      }
                    ]
                  }
                } as TixPhoneNumberInsertInput)
            );

          if (newPhoneNumbers.length)
            return this.insertPhoneNumber
              .mutate({ objects: newPhoneNumbers })
              .pipe(
                map(result => {
                  const temp = result.data?.InsertPhoneNumber?.returning;
                  const contactPhoneNumbers =
                    temp &&
                    temp.map(
                      insertedPhoneNumber =>
                        ({
                          __typename: 'ContactPhoneNumber',
                          contactPhoneNumberId:
                            insertedPhoneNumber?.contactPhoneNumbers[0]
                              .contactPhoneNumberId,
                          phoneNumber: {
                            __typename: 'PhoneNumber',
                            phoneNumberId: insertedPhoneNumber.phoneNumberId,
                            countryCode: insertedPhoneNumber.countryCode,
                            type: insertedPhoneNumber.type,
                            phoneNumber: insertedPhoneNumber.phoneNumber
                          }
                        } as TixContactPhoneNumber)
                    );
                  if (!selectedStaff?.contact) {
                    this.snackbar.open('An error has ocurred');

                    return StaffApiAction.upsertContactPhoneNumbersFailure({
                      error: 'No selected user'
                    });
                  }
                  const contact = {
                    ...selectedStaff.contact
                  } as Maybe<TixContact>;
                  if (contact?.phoneNumbers && contactPhoneNumbers?.length)
                    contact.phoneNumbers = [
                      ...contact.phoneNumbers,
                      ...contactPhoneNumbers
                    ];
                  const updatedContact: Update<TixCompanyContact> = {
                    id: selectedStaff?.contactId,
                    changes: { ...selectedStaff, contact }
                  };

                  this.snackbar.open(
                    'Your changes have been saved successfully!'
                  );

                  return StaffApiAction.upsertContactPhoneNumbersSuccess({
                    updatedContact
                  });
                })
              );

          this.snackbar.open('An error has ocurred');

          return StaffApiAction.upsertContactPhoneNumbersFailure({
            error: 'There is no number to update or insert'
          });
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');

          return StaffApiAction.upsertContactPhoneNumbersFailure(error);
        }
      })
    )
  );

  insertExistingUserToCompanyStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.insertExistingUserToCompanyStaff),
      withLatestFrom(
        combineLatest([
          this.store.select(CompanySelectors.getSelectedCompanyId)
        ])
      ),
      pessimisticUpdate({
        run: (data, [companyId]) => {
          const { rolesIds, userId, contactId } = data;
          return this.insertExistingUserToStaff
            .mutate({
              companyId,
              contactId
            })
            .pipe(
              mergeMap(data => {
                return this.insertUserCompanyContactUserRoles
                  .mutate({
                    objects: rolesIds.map(roleId => ({
                      companyId,
                      userId,
                      roleId
                    }))
                  })
                  .pipe(
                    map(userRolesData => ({
                      userRolesData,
                      contactData: data
                    }))
                  );
              }),
              tap(() =>
                this.router.navigate(['company', companyId, 'staff', contactId])
              ),
              map(data => {
                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );

                return StaffApiAction.insertCompanyContactUserSuccess({
                  user: data.contactData.data?.InsertCompanyContactOne as any
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('Your changes have been saved successfully!');

          return StaffApiAction.insertCompanyContactUserFailure(error);
        }
      })
    )
  );

  sendUserMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiAction.insertCompanyContactUserSuccess),
      pessimisticUpdate({
        run: data => {
          return this.sendMailByEmailGQL
            .mutate({ email: data?.user?.contact?.emailAddress })
            .pipe(map(d => StaffApiAction.sendEmailSuccess()));
        },
        onError(a, e): any {
          console.error({ action: a, error: e });
          return StaffApiAction.sendEmailFailure({ error: null });
        }
      })
    )
  );

  deleteContactPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.deleteContactPhoneNumber),
      withLatestFrom(
        this.store.select(StaffSelector.getSelectedContactProfileViewModel)
      ),
      pessimisticUpdate({
        run: (
          { phoneNumber },
          selectedStaff: TixCompanyContact | undefined
        ) => {
          return this.deleteContactPhoneNumber
            .mutate({ phoneNumberId: phoneNumber.phoneNumberId })
            .pipe(
              map(({ data }) => {
                const contactDetails =
                  data?.DeleteContactPhoneNumber?.returning[0];
                if (!selectedStaff || !contactDetails)
                  return StaffApiAction.deleteContactPhoneNumberFailure({
                    error: 'No selected user'
                  });
                const contact = {
                  ...selectedStaff.contact
                } as Maybe<TixContact>;
                const updatedPhoneNumbers = contact?.phoneNumbers.filter(
                  p =>
                    p.contactPhoneNumberId !==
                    contactDetails.contactPhoneNumberId
                );
                if (contact?.phoneNumbers)
                  contact.phoneNumbers = updatedPhoneNumbers || [];
                const updatedContact: Update<TixCompanyContact> = {
                  id: contactDetails.contactId,
                  changes: { ...selectedStaff, contact }
                };
                return StaffApiAction.deleteContactPhoneNumberSuccess({
                  updatedContact
                });
              })
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          StaffApiAction.deleteContactPhoneNumberFailure({ error: error });
        }
      })
    )
  );

  deleteStaffRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.deleteStaffRole),
      withLatestFrom(
        this.store.select(StaffSelector.getSelectedContactProfileViewModel)
      ),
      pessimisticUpdate({
        run: ({ userRoleId }, selectedStaff: TixCompanyContact | undefined) => {
          return this.deleteCompanyStaffRole.mutate({ userRoleId }).pipe(
            map(({ data }) => {
              const newRoles = selectedStaff?.contact?.user?.userRoles.filter(
                role =>
                  role.userRoleId !==
                  data?.DeleteUserRole?.returning[0].userRoleId
              );
              const contact = {
                ...selectedStaff?.contact,
                user: { ...selectedStaff?.contact?.user, userRoles: newRoles }
              } as Maybe<TixContact>;
              const updatedContact: Update<TixCompanyContact> = {
                id: contact?.contactId,
                changes: { ...selectedStaff, contact }
              };
              return StaffApiAction.deleteStaffRoleSuccess({ updatedContact });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return StaffApiAction.deleteStaffRoleFailure({ error });
        }
      })
    )
  );

  saveUserPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAction.saveUserPermission),
      withLatestFrom(
        combineLatest([
          this.store.select(getSelectedCompanyId),
          this.store.select(getAuthenticatedUser),
          this.store.select(StaffSelector.getSelectedContactProfileViewModel),
          this.store.select(StaffSelector.getUserRolesList)
        ])
      ),
      pessimisticUpdate({
        run: (action, state) => {
          const [companyId, { uid }, selectedStaff, roleList] = state as [
            string,
            { uid: string },
            TixCompanyContact,
            TixRole[]
          ];
          if (!selectedStaff.contact?.user?.userId) {
            this.snackbar.open('An error has ocurred');

            return StaffApiAction.saveStaffRoleFailure({
              error: 'No user id found'
            });
          }
          const roleTemplate: TixUserRoleInsertInput = {
            companyId: companyId,
            updatedBy: uid, // Logged-in user id
            userId: selectedStaff.contact?.user?.userId // Selected staff Id
          };
          const variables: TixUserRoleInsertInput[] = [];
          action.roleIdList.forEach(roleId =>
            variables.push({ ...roleTemplate, roleId })
          );
          return this.insertCompanyStaffRole
            .mutate({ objects: variables })
            .pipe(
              map(({ data }) => {
                const newRoleList =
                  data?.InsertUserRole?.returning.map(role => {
                    const roleDetails = roleList.find(
                      r => r.roleId === role.roleId
                    );
                    return {
                      __typename: 'UserRole',
                      userRoleId: role.userRoleId,
                      companyId: role.companyId,
                      venueId: '00000000-0000-0000-0000-000000000000',
                      role: roleDetails
                    } as TixUserRole;
                  }) || [];

                const oldRoles = selectedStaff?.contact?.user?.userRoles || [];

                const contact = {
                  ...selectedStaff?.contact,
                  user: {
                    ...selectedStaff?.contact?.user,
                    userRoles: [...newRoleList, ...oldRoles]
                  }
                } as Maybe<TixContact>;
                const updatedContact: Update<TixCompanyContact> = {
                  id: selectedStaff?.contactId,
                  changes: { ...selectedStaff, contact }
                };
                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );

                return StaffApiAction.saveStaffRoleSuccess({ updatedContact });
              })
            );
        },
        onError: (action, error) => {
          this.snackbar.open('An error has ocurred');
          console.error({ action, error });

          return null;
        }
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly getContactsByCompanyId: TixGetContactsByCompanyIdGQL,
    private readonly getStaffByPk: TixGetStaffByPkGQL,
    private readonly insertStaffUser: TixInsertStaffUserGQL,
    private readonly getRoles: TixGetRolesGQL,
    private readonly insertCompanyContact: TixInsertCompanyContactGQL,
    private readonly updateContactById: TixUpdateContactByPkGQL,
    private readonly insertPhoneNumber: TixInsertContactPhoneNumberGQL,
    private readonly deleteContactPhoneNumber: TixDeleteContactPhoneNumberByPhoneNumberPkGQL,
    private readonly deleteCompanyStaffRole: TixDeleteCompanyStaffRoleGQL,
    private readonly insertCompanyStaffRole: TixInsertCompanyStaffRoleGQL,
    private readonly router: Router,
    private readonly store: Store<TixStaffPartialState>,
    private readonly insertUserCompanyContact: TixInsertUserCompanyContactGQL,
    private readonly sendMailByEmailGQL: TixSendMailByEmailGQL,
    private insertExistingUserToStaff: TixInsertExistingUserToStaffGQL,
    private insertUserCompanyContactUserRoles: TixInsertCompanyContactUserRolesGQL,
    private readonly snackbar: MatSnackBar
  ) {}
}
