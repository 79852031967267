import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TixAlertComponent } from './alert.component';
// import { MatIconModule } from '@angular/material/icon';
import { HeroUpload, TixIconModule, TixIconRegistry } from '../icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule, TixIconModule],
  declarations: [TixAlertComponent],
  exports: [TixAlertComponent],
  providers: []
})
export class TixAlertModule {
  constructor(
    private iconRegistry: TixIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'error-circle',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/alerts/exclamation-circle.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'error-triangle',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/alerts/exclamation-triangle.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'check-circle',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/alerts/check-circle.svg'
      )
    );
    iconRegistry.addSvgIconConfig(HeroUpload);
  }
}
