import { NgModule } from '@angular/core';
import { TixInput } from './input.directive';
import { TixFormFieldModule } from '../form-field';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TixInput],
  imports: [TixFormFieldModule, CommonModule],
  exports: [TixInput]
})
export class TixInputModule {}
