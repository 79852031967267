import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixBusinessCodes,
  TixMediaFile
} from '@tix/data-access';
import {
  createFormGroup,
  FormStruct,
  setupFormForArray
} from '@tix/shared/forms';
import { TixConfirmDialogService } from '../../dialog';
import { TixDialog } from '../../dialog';
import { FileUploadDialogComponent } from '../../file-upload-dialog/file-upload-dialog.component';

@Component({
  selector: 'tix-media-files-edit',
  templateUrl: './media-files.component.html',
  styleUrls: ['./media-files.component.scss']
})
export class TixMediaFilesEditComponent implements OnInit, OnChanges {
  @Input() mediaFiles: Maybe<TixMediaFile>[] | null | undefined;
  @Input() mediaFilesBusinessCodeGroup: Maybe<
    Pick<TixBusinessCodeGroup, 'description' | 'name' | 'busCodeGroupId'> & {
      businessCodes: Pick<TixBusinessCodes, 'name' | 'description'>[];
    }
  >;
  @Input() isloading?: boolean | undefined | null;

  @Output() save: EventEmitter<Partial<TixMediaFile>[]> = new EventEmitter();
  @Output() delete = new EventEmitter<TixMediaFile>();

  @Input() keepDisableSaveBtn = false;

  @Input() showHeader = true;

  constructor(
    private confirmDialogService: TixConfirmDialogService,
    private dialog: TixDialog
  ) {}

  readonly MEDIA_FILES_FORM_NAME = 'mediaFiles';

  mediaFilesForm: FormGroup;

  openUploadDialog(index: number) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '800px',
      data: {}
    });
    dialogRef.componentInstance.imageWidth = 400;
    dialogRef.componentInstance.imageHeight = 400;

    dialogRef.afterClosed().subscribe(files => {
      if (files) {
        this.mediaFilesFormArray
          .get(index.toString())
          ?.patchValue({ value: files[0] });
        this.mediaFilesForm.markAsDirty();
        // this.emitMediaFiles();
      }
    });
  }
  get mediaFilesAsFormStruct() {
    if (this.mediaFiles === null || !this.mediaFiles?.length) {
      return [];
    }
    return this.mediaFiles.map(convertMediaFileTypeToFormGroup);
  }

  get mediaFilesFormArray() {
    return this.mediaFilesForm?.get(this.MEDIA_FILES_FORM_NAME) as FormArray;
  }

  get mediaFilesTypes() {
    if (this.mediaFilesBusinessCodeGroup) {
      return this.mediaFilesBusinessCodeGroup.businessCodes;
    }
    return [];
  }

  ngOnInit(): void {
    this.mediaFilesForm = setupFormForArray(
      this.MEDIA_FILES_FORM_NAME,
      this.mediaFilesAsFormStruct
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const mediaFilesChanges: Maybe<Array<Maybe<TixMediaFile>>> = changes[
      'mediaFiles'
    ]?.currentValue as Maybe<TixMediaFile[]>;
    if (mediaFilesChanges) {
      this.mediaFilesForm = setupFormForArray(
        this.MEDIA_FILES_FORM_NAME,
        this.mediaFilesAsFormStruct
      );
    }
  }

  addNewMediaFile() {
    this.mediaFilesFormArray.push(createFormGroup(null));
  }

  emitMediaFiles() {
    const mediaFilesFormValue = this.mediaFilesForm.value as {
      mediaFiles: FormStruct[];
    };

    // Send only the dirty values
    mediaFilesFormValue.mediaFiles = mediaFilesFormValue.mediaFiles.filter(
      (_e, index) => {
        if (this.mediaFilesFormArray.at(index).dirty) {
          return true;
        }
        return false;
      }
    );

    const formattedPhoneNumbers =
      mediaFilesFormValue.mediaFiles.map(formatFormStruct);

    this.save.emit(formattedPhoneNumbers);
  }

  async confirmDelete(formGroup: AbstractControl, index: number) {
    // const mediaFileId = formGroup.get('id')?.value;
    // if (mediaFileId) {
    //   const mediaFile = this.mediaFiles?.find(mediaFile => mediaFile?.mediaFileId === mediaFileId);
    //   if (!mediaFile) {
    //     return;
    //   }
    //   const confirmText = mediaFile
    //     ? `Are you sure you want to delete the media "${mediaFile.location}" ?`
    //     : `Are you sure you want to delete this media?`;
    //   const result = await this.confirmDialogService.promptConfirm({
    //     confirmText,
    //     title: 'Confirm Delete',
    //     icon: 'exclamation',
    //     titleTheme: 'danger',
    //     cancelButton: {
    //       theme: 'danger'
    //     },
    //     confirmButton: {
    //       theme: 'danger'
    //     }
    //   });

    //   if (result) {
    //     this.delete.emit(mediaFile);
    //   }
    //   return;
    // }
    this.mediaFilesFormArray.removeAt(index);
    if (this.mediaFilesFormArray.length === 0) {
      this.save.emit([]);

      this.addNewMediaFile();
    }
  }
}

function convertMediaFileTypeToFormGroup(
  mediaFile: Maybe<TixMediaFile>
): FormStruct {
  return {
    id: mediaFile?.mediaFileId ?? '',
    type: mediaFile?.fileType ?? '',
    value: mediaFile?.location ?? ''
  };
}

function formatFormStruct(formGroup: FormStruct) {
  return {
    mediaFileId: formGroup.id,
    location: formGroup.value,
    fileType: formGroup.type
  } as Partial<TixMediaFile>;
}
