<tix-card
  class="bg-white w-[1024px] max-w-full !flex flex-col !overflow-auto h-full"
>
  <div class="p-8 border-b border-gray-200 bg-primary bg-opacity-10">
    <div class="flex items-center justify-between mb-7">
      <h2 class="!mb-0 font-medium">Multiple Event</h2>
      <button
        tix-flat-button
        class="tix-icon-button !text-gray-900"
        (click)="dialogRef.close()"
      >
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.75 8.25L8.25 24.75"
            stroke="#000814"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.25 8.25L24.75 24.75"
            stroke="#000814"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <tix-event-multi-stepper
      [currentStep]="currentStep"
      [steps]="['Event Info', 'Event Dates', 'Media']"
    ></tix-event-multi-stepper>
  </div>

  <div class="p-8">
    <ng-container *ngIf="currentStep === 0">
      <tix-event-multi-info
        [eventTypeList]="businessEventTypes$ | async"
        [statusLists]="businessStatusLists$ | async"
        [formGroup]="firstFormGroup"
        [eventTicketLists]="eventTicketConfigurationList$ | async"
        [eventVenueList]="eventVenueList$ | async"
      ></tix-event-multi-info>
    </ng-container>

    <ng-container *ngIf="currentStep === 1">
      <form [formGroup]="secondFormGroup">
        <tix-event-multi-dates
          [formGroup]="secondFormGroup"
        ></tix-event-multi-dates>
      </form>
    </ng-container>

    <ng-container *ngIf="currentStep === 2">
      <tix-event-multi-media
        [mediaInfoForm]="thirdFormGroup"
        [socialMediaBusinessCodeGroup]="businessSocialMediaTypes$ | async"
        [mediaFilesBusinessCodeGroup]="businessMediaFilesTypes$ | async"
      ></tix-event-multi-media>
    </ng-container>
  </div>

  <tix-card-content class="!py-0" *ngIf="isLoading && showLoadingCallout">
    <div class="flex justify-end">
      <div class="py-4 pl-5 rounded-md pr-11 bg-primary bg-opacity-20">
        This may take a while, bear with us :)
      </div>
    </div>
  </tix-card-content>

  <tix-card-actions align="end">
    <button
      tix-flat-border-button
      class="!px-8 mr-3"
      color="dark"
      [disabled]="currentStep === 0"
      (click)="goPrevious()"
      [disabled]="isFirstStep"
    >
      Back
    </button>
    <button
      tix-button
      (click)="isLastStep ? submit() : goNext()"
      class="!px-8"
      [disabled]="
        (getRecurringEventsLoading$ | async) === true ||
        !forms[currentStep].valid
      "
    >
      {{ isLastStep ? 'Create' : 'Next' }}
      <span
        class="ml-1"
        *tixBtnLoading="getRecurringEventsLoading$ | async"
      ></span>
      <span></span>
    </button>
  </tix-card-actions>
</tix-card>
