import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STAFF_FEATURE_KEY, staffAdapter, State } from '../staff.reducer';

import {
  Maybe,
  TixCompanyContact,
  TixContact,
  TixPhoneNumber,
  TixUserRole
} from '@tix/data-access';
import { COMPANY_FEATURE_KEY } from '@tix/company/state';
import { getSelectedCompanyId } from '@tix/company/state/selectors';

export const getStaffState = createFeatureSelector<State>(STAFF_FEATURE_KEY);

export const getCompanyState =
  createFeatureSelector<State>(COMPANY_FEATURE_KEY);

const { selectAll } = staffAdapter.getSelectors();

export const getAllStaff = createSelector(getStaffState, (state: State) =>
  selectAll(state)
);

export const getUserRolesList = createSelector(
  getStaffState,
  (state: State) => state.usersRole
);

export const getSatffLoaded = createSelector(
  getStaffState,
  (state: State) => state.loaded
);
export const getSatffLoadingPhone = createSelector(
  getStaffState,
  (state: State) => state.staffLoadingPhone
);
export const getSatffLoadingPremissions = createSelector(
  getStaffState,
  (state: State) => state.staffLoadingPremissions
);

export const getSelectedStaffId = createSelector(
  getStaffState,
  (state: State) => state.selectedStaffId ?? ''
);

export const getSelectedContactProfileViewModel = createSelector(
  getStaffState,
  getSelectedStaffId,
  (state: State, staffId: string) => {
    return staffId ? state.entities[staffId] : undefined;
  }
);

export const getSelectedStaffName = createSelector(
  getSelectedContactProfileViewModel,
  (staff?: TixCompanyContact) =>
    staff?.contact?.firstName &&
    `${staff?.contact?.firstName} ${staff?.contact?.lastName}`
);

export const getStaffForSelectedCompany = createSelector(
  getAllStaff,
  getSelectedCompanyId,
  (staffList: TixCompanyContact[], companyId?: string) => {
    return staffList
      .filter(staff => staff.companyId === companyId)
      .map(staff => staff.contact as TixContact);
  }
);

export const getSelectedStaffPhoneNumbers = createSelector(
  getSelectedContactProfileViewModel,
  (staff: Maybe<TixCompanyContact>) => {
    return staff?.contact?.phoneNumbers?.map(
      e => e.phoneNumber
    ) as Maybe<TixPhoneNumber>[];
  }
);

export const getSelectedStaffRoles = createSelector(
  getSelectedContactProfileViewModel,
  getSelectedCompanyId,
  (staff: Maybe<TixCompanyContact>, companyId?: string) => {
    return staff?.contact?.user?.userRoles.filter(
      role => role.companyId === companyId
    ) as TixUserRole[];
  }
);
