import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TixCompanyPartialState } from '@tix/company/state';
import {
  TixBusinessCodes,
  TixEmailContent,
  TixEventInsertInput,
  TixEventInstanceArrRelInsertInput
} from '@tix/data-access';
import * as eventActions from '@tix/events/state';
import { MatDialogRef, TIX_DIALOG_DATA } from '@tix/shared/ui/components';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'tix-clone-dialog',
  templateUrl: './clone-dialog.component.html',
  styleUrls: ['./clone-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloneDialogComponent implements OnInit {
  cloneForm: FormGroup;
  statusList: TixBusinessCodes[];
  readonly loaded$ = this.store.select(eventActions.getEventsLoaded);
  readonly loading$ = this.store.select(eventActions.getEventsLoading);

  eventIdToNavigate: string;
  companyId: any;

  constructor(
    @Inject(TIX_DIALOG_DATA)
    public data: {
      statusList: TixBusinessCodes[];
      name: string;
      state: any;
      eventInputData: TixEventInsertInput;
      products: any;
      templatesList: TixEmailContent[];
    },
    private fb: FormBuilder,
    private readonly store: Store<TixCompanyPartialState>,
    private router: Router,
    public dialogRef: MatDialogRef<CloneDialogComponent>
  ) {
    this.cloneForm = this.fb.group({
      name: [this.data.name, Validators.required],
      status: [this.data.state, Validators.required]
    });
    this.statusList = this.data.statusList;
  }

  ngOnInit(): void {
    this.store
      .select(eventActions.getEvent)
      .pipe(
        filter(event => !!event),
        tap((createdEvent: any) => {
          this.companyId = createdEvent?.companyId;
        })
      )
      .subscribe();
    this.store
      .select(eventActions.getClonedId)
      .pipe(
        filter(event => !!event),
        tap((eventIdToNavigate: any) => {
          this.eventIdToNavigate = eventIdToNavigate;
        })
      )
      .subscribe();
  }

  onClone() {
    const { eventInstances } = this.data.eventInputData;
    const { data } = eventInstances as TixEventInstanceArrRelInsertInput;
    let [instance] = data;
    let campaignWithInstanceId = undefined;
    if (instance.campaign && instance.campaign.data.length) {
      const data = instance.campaign.data.map(pixel => {
        return { ...pixel, eventInstanceId: this.eventIdToNavigate };
      });
      campaignWithInstanceId = { data };
    }
    instance = {
      ...instance,
      name: this.cloneForm.get('name')?.value,
      state: this.cloneForm.get('status')?.value,
      campaign: campaignWithInstanceId
    };

    const eventInstanceObj = {
      data: [instance]
    };

    this.store.dispatch(
      eventActions.addNewEventInCompany({
        addEventObj: {
          ...this.data.eventInputData,

          eventInstances: eventInstanceObj,
          name: this.cloneForm.get('name')?.value,
          state: this.cloneForm.get('status')?.value
        },
        products: this.data.products,
        addTemplateArr: this.data?.templatesList
      })
    );
  }
  navigate() {
    window.location.href =
      '/company/' + this.companyId + '/events/' + this.eventIdToNavigate;
    this.store.dispatch(
      eventActions.loadEventById({ eventInstanceId: this.eventIdToNavigate })
    );
    this.dialogRef.close();
  }
  cancel() {
    this.store.dispatch(eventActions.resetLoadStateEvent());
    this.dialogRef.close();
  }
}
