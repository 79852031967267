<form (keydown.enter)="submit()" [formGroup]="formGroup" class="content-layout">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" type="text" name="name" />
    <mat-error>Name is required</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Section</mat-label>
    <input matInput formControlName="section" name="section" type="text" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Ticket Type</mat-label>
    <mat-select formControlName="type" name="type">
      <mat-option
        *ngFor="let businessCode of ticketTypeBusinessCodeGroup?.businessCodes"
        [value]="businessCode.name"
      >
        {{ businessCode.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    [matTooltip]="
      disablePools
        ? 'You have to save the event first to set up ticket pools'
        : ''
    "
  >
    <mat-label>Ticket Pool</mat-label>
    <mat-select formControlName="parentTicketId" name="parentTicketId">
      <mat-option [value]="undefined">None</mat-option>
      <mat-option
        *ngFor="let config of availableTicketPoolConfigs"
        [value]="config?.id"
      >
        {{ config?.formGroup?.value.name }} ({{
          config?.formGroup?.value.quantity
        }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label># of Tickets</mat-label>
    <input
      matInput
      formControlName="quantity"
      type="number"
      [min]="
        configTicketType && configTicketType.ticketsSold > 0
          ? configTicketType.ticketsSold
          : 1
      "
      max="100000"
      name="quantity"
    />
    <mat-error>
      <ng-container
        *ngIf="formGroup.get('quantity')?.hasError('min'); else defaultError"
      >
        The # of Tickets cannot be less than the # of Tickets sold
      </ng-container>
      <ng-template #defaultError>
        The maximum number of tickets for a child must be less than the
        remaining tickets of its parent and more than zero.
      </ng-template>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Ticket Sales Channel</mat-label>
    <mat-select formControlName="salesChannel" name="salesChannel" multiple>
      <mat-option *ngFor="let config of availableSaleChannels" [value]="config">
        {{ config }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <mat-form-field>
    <mat-label>Row</mat-label>
    <input matInput formControlName="row" type="text" name="row" />
    <mat-error>Row is invalid</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Seat</mat-label>
    <input matInput formControlName="seat" type="text" name="seat" />
    <mat-error>Seat is invalid</mat-error>
  </mat-form-field> -->

  <mat-form-field>
    <mat-label>Per order minimum</mat-label>
    <input
      matInput
      formControlName="perOrderMin"
      min="1"
      max="50"
      type="number"
      name="perOrderMin"
    />
    <mat-error
      *ngIf="perOrderMinFormControl?.errors?.perOrderMin; else defaultMinError"
    >
      Per order minimum shouldn't be more than Per order maximum
    </mat-error>
    <ng-template #defaultMinError>
      <mat-error>Per order minimum is invalid</mat-error>
    </ng-template>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Per order maximum</mat-label>
    <input
      matInput
      formControlName="perOrderMax"
      min="1"
      max="50"
      type="number"
      name="perOrderMax"
    />
    <mat-error
      *ngIf="perOrderMaxFormControl?.errors?.perOrderMax; else defaultMaxError"
      >Per order maximum shoudn't be less than Pre order minimum
    </mat-error>
    <ng-template #defaultMaxError>
      <mat-error>Per order maximum is invalid</mat-error>
    </ng-template>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="outline">
    <mat-label>Start Selling Date</mat-label>
    <input
      formControlName="startSellingDate"
      matInput
      [matDatepicker]="pickerStart"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="pickerStart"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerStart></mat-datepicker>
    <mat-error class="mt-1.5 -ml-2">Start selling date is required</mat-error>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="outline">
    <mat-label>Stop Selling Date</mat-label>
    <input
      formControlName="stopSellingDate"
      matInput
      [matDatepicker]="pickerEnd"
    />
    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    <mat-datepicker #pickerEnd></mat-datepicker>
    <mat-error class="mt-1.5 -ml-2"
      >Stop selling date can't precede start selling date</mat-error
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label>Start Selling Time</mat-label>
    <div class="flex w-full">
      <input
        matInput
        type="time"
        formControlName="startSellingTime"
        step="60"
      />
      <button
        mat-icon-button
        class="mt-[-15px] ml-2"
        color="primary"
        aria-label="Clear Start time"
        (click)="clearStartTime()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Stop Selling Time</mat-label>
    <div class="flex w-full">
      <input matInput type="time" formControlName="stopSellingTime" step="60" />
      <button
        mat-icon-button
        class="mt-[-15px] ml-2"
        aria-label="Clear Stop time"
        (click)="clearStopTime()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <mat-error
      >Stop selling time can't precede start selling time for the same
      date</mat-error
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label>Ticket Price</mat-label>
    <input
      [textMask]="{ mask: currencyMask }"
      matInput
      formControlName="price"
      type="text"
      name="price"
      [readonly]="configTicketType && configTicketType.ticketsSold > 0"
    />
    <mat-error>Ticket Price is required</mat-error>
  </mat-form-field>

  <ng-container *ngIf="!includeFeesFormControl.value">
    <mat-form-field>
      <mat-label>Venue Fee</mat-label>
      <input
        [textMask]="{ mask: currencyMask }"
        matInput
        formControlName="venueFee"
        type="text"
        name="venueFee"
      />
      <mat-error>Venue Fee is invalid</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Platform Fee</mat-label>
      <input
        [textMask]="{ mask: currencyMask }"
        matInput
        formControlName="platformFee"
        type="text"
        name="platformFee"
        [readonly]="true"
      />
      <mat-error>Platform Fee is invalid</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Processing Fee</mat-label>
      <input
        [textMask]="{ mask: currencyMask }"
        matInput
        formControlName="processingFee"
        type="text"
        name="processingFee"
        [readonly]="true"
      />
      <mat-error>Processing Fee is invalid</mat-error>
    </mat-form-field>
  </ng-container>

  <mat-form-field
    [ngClass]="includeFeesFormControl.value ? '' : 'col-span-1 lg:col-span-2'"
  >
    <mat-label>Include fees in the ticket price</mat-label>
    <mat-select
      formControlName="includeFees"
      name="includeFees"
      (valueChange)="onIncludeFeesChange($event)"
    >
      <mat-option [value]="true">Yes</mat-option>
      <mat-option [value]="false">No</mat-option>
    </mat-select>

    <mat-hint>This will affect all the other ticket configurations</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-span-1 lg:col-span-2">
    <mat-label>Help Text Title</mat-label>
    <input
      matInput
      formControlName="helpTextTitle"
      type="text"
      name="helpTextTitle"
    />
    <mat-hint
      *ngIf="
        helpTextFormControl?.value?.length > 0 &&
        helpTextTitleFormControl?.value?.length == 0
      "
      >You need to set a value for help text and help text title
    </mat-hint>
  </mat-form-field>
  <mat-form-field id="custom-help-text">
    <mat-label>Help Text</mat-label>
    <textarea
      matInput
      formControlName="helpText"
      name="helpText"
      class="overflow-x-hidden"
    ></textarea>
    <mat-hint
      *ngIf="
        helpTextFormControl?.value?.length > 0 &&
        helpTextTitleFormControl?.value?.length == 0
      "
      >You need to set a value for help text and help text title
    </mat-hint>
  </mat-form-field>
</form>

<ul *ngIf="configTicketType" class="config-type-list">
  <li class="config-type-list__item">
    Remaining tickets:
    <span>
      {{ configTicketType.ticketsRemaining }}
    </span>
  </li>
  <li class="config-type-list__item">
    Sold tickets:
    <span>
      {{ configTicketType.ticketsSold }}
    </span>
  </li>
</ul>
