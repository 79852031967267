import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@nrwl/angular';

import * as UserActions from './actions/user.actions';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { combineLatest, EMPTY, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TixAuthService } from '@tix/auth/services';
import { User, UserAddress, UserPhoneNumbers } from '@tix/shared/models';
import { Action, Store } from '@ngrx/store';
import {
  TixDeleteContactPhoneNumberByPhoneNumberPkGQL,
  TixGetContactByEmailAddressGQL,
  TixGetRolesGQL,
  TixInsertContactAddressGQL,
  TixInsertContactGQL,
  TixInsertContactPhoneNumberGQL,
  TixPhoneNumberInsertInput,
  TixRole,
  TixUser,
  TixUpdateAddressByPkGQL,
  TixUpdateContactByPkGQL
} from '@tix/data-access';
import { Maybe } from '@tix/data-access';
import { User as AfUser } from '@angular/fire/auth';
import { UserSelector } from '../..';

const ERROR_MAP: { [key: string]: string } = {
  'auth/user-not-found': 'The provided account does not exist.',
  'auth/user-disabled': 'The provided account is blocked.',
  'auth/wrong-password': 'The provided email/password is incorrect.',
  'auth/weak-password': 'The provided password is not strong enough.',
  'auth/invalid-email': 'The provided email is not valid.',
  'auth/email-already-in-use': 'The provided email is already in use.',
  'auth/operation-not-allowed': 'Email and Password accounts are disabled.',
  'auth/too-many-requests': 'Too many failed login attempts. Try again later.'
};

@Injectable()
export class UserEffects implements OnInitEffects {
  registrationUserData: User | null;
  aFUser: AfUser;

  init = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.initAction),
      fetch({
        run: () => {
          return this.authService.loadUser().pipe(
            mergeMap(aFUser => {
              if (
                this.registrationUserData &&
                aFUser?.email === this.registrationUserData.email.toLowerCase()
              ) {
                const variables = {
                  firstName: this.registrationUserData?.firstName,
                  lastName: this.registrationUserData?.lastName,
                  emailAddress: this.registrationUserData?.email,
                  dob: this.registrationUserData?.dateOfBirth
                };
                console.log({ aFUser });
                return this.insertContact.mutate(variables).pipe(
                  map(({ data }) => {
                    this.#storeConnectedUserId(
                      data?.InsertContact?.returning[0].contactId
                    );

                    const temp = data?.InsertContact?.returning[0];
                    this.registrationUserData = null;
                    if (!temp || !temp.contactId) return null;
                    return new User(
                      temp?.contactId,
                      temp?.emailAddress ?? '',
                      temp?.firstName,
                      temp?.lastName,
                      temp?.dob
                    );
                  }),
                  catchError(this.handleError)
                );
              } else {
                if (aFUser?.email) {
                  return this.getContactByEmail
                    .fetch(
                      { emailAddress: aFUser?.email },
                      { fetchPolicy: 'no-cache' }
                    )
                    .pipe(
                      tap(({ data }) => {
                        this.#storeConnectedUserId(data.Contact[0].contactId);
                        this.store.dispatch(
                          UserActions.setUserRolesSuccess({
                            userRoles: data.Contact[0].user as TixUser
                          })
                        );
                        const tempAddress = data.Contact[0]?.addresses[0];
                        if (tempAddress) {
                          const address: UserAddress = {
                            addressId: tempAddress.address?.addressId ?? '',
                            city: tempAddress.address?.city ?? '',
                            countryCode: tempAddress.address?.countryCode ?? '',
                            postalCode: tempAddress.address?.postalCode ?? '',
                            suiteApartment:
                              tempAddress.address?.suiteApartment ?? '',
                            stateProvince:
                              tempAddress.address?.stateProvince ?? '',
                            streetAddress:
                              tempAddress.address?.streetAddress ?? '',
                            contactAddressId:
                              tempAddress?.address?.contactAddress
                                ?.contactAddressId,
                            contactId: data.Contact[0].contactId
                          };
                          this.store.dispatch(
                            UserActions.updateUserAddressSuccess({ address })
                          );
                        }

                        const contactPhoneNumbers =
                          data.Contact[0]?.phoneNumbers?.map(phone => {
                            const number =
                              phone.phoneNumber?.contactPhoneNumbers[0];
                            return {
                              contactId: data.Contact[0].contactId,
                              contactPhoneNumberId:
                                number?.contactPhoneNumberId,
                              phoneNumber:
                                number?.phoneNumber?.phoneNumber ?? '',
                              type: number?.phoneNumber?.type,
                              phoneNumberId: number?.phoneNumber?.phoneNumberId,
                              countryCode: number?.phoneNumber?.countryCode
                            } as UserPhoneNumbers;
                          });

                        if (contactPhoneNumbers && contactPhoneNumbers.length) {
                          this.store.dispatch(
                            UserActions.upsertContactPhoneNumbersSuccess({
                              contactPhoneNumbers
                            })
                          );
                        }
                      }),
                      map(({ data }) => {
                        const temp = data.Contact[0];
                        if (!temp || !temp.contactId) return null;
                        return new User(
                          temp?.contactId,
                          temp?.emailAddress,
                          temp?.firstName,
                          temp?.lastName,
                          temp?.dob
                        );
                      }),
                      catchError(this.handleError)
                    );
                } else return of(null);
              }
            }),
            map((user: User | null) => {
              if (!user || !user.uid) {
                return UserActions.logoutAction();
              }
              return UserActions.initializeSuccessAction({ user });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });
          return UserActions.initializeFailureAction({
            error: ERROR_MAP[error.code] ?? error.code
          });
        }
      })
    )
  );

  login = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginAction),
      fetch({
        run: action => {
          return this.authService.emailPasswordLogin(action.credentials).pipe(
            map(({ user: aFUser }) => {
              if (!aFUser) {
                return UserActions.loginFailureAction({
                  error: ERROR_MAP['auth/user-not-found']
                });
              }
              const user = new User(aFUser.uid, aFUser.email ?? '');
              return UserActions.loginSuccessAction({
                user,
                returnUrl: action.returnUrl
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', JSON.stringify(error));
          return UserActions.loginFailureAction({
            error: ERROR_MAP[error.code] ?? error.code
          });
        }
      })
    )
  );

  loginWithMedia = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginWihMediaAction),
      fetch({
        run: action => {
          return this.authService.signInMethod(action.media).pipe(
            tap(user => (this.aFUser = user?.user)),
            switchMap(({ user }) => {
              return this.getContactByEmail
                .fetch(
                  { emailAddress: user.email },
                  { fetchPolicy: 'no-cache' }
                )
                .pipe(
                  map(u => {
                    this.#storeConnectedUserId(u.data.Contact[0].contactId);
                    return u;
                  })
                );
            }),

            switchMap(({ data }) => {
              if (data.Contact[0]?.contactId) {
                this.router.navigate(['/']);
                return EMPTY;
              }
              if (this.aFUser?.email) {
                const variables = {
                  emailAddress: this.aFUser?.email,
                  dob: null
                };
                console.log(this.aFUser);
                return this.insertContact.mutate(variables).pipe(
                  map(({ data }) => {
                    const temp = data?.InsertContact?.returning[0];
                    this.registrationUserData = null;
                    if (!temp || !temp.contactId) return null;
                    return new User(
                      temp?.contactId,
                      temp?.emailAddress ?? '',
                      temp?.firstName,
                      temp?.lastName,
                      temp?.dob
                    );
                  }),
                  tap(() => this.router.navigate(['/'])),
                  catchError(this.handleError)
                );
              } else return of(null);
            }),
            map((user: User | null) => {
              if (!user || !user.uid) {
                return UserActions.logoutAction();
              }
              return UserActions.initializeSuccessAction({ user });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return UserActions.initializeFailureAction({
            error: ERROR_MAP[error.code] ?? error.code
          });
        }
      })
    )
  );

  register = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerAction),
      fetch({
        run: action => {
          return this.authService.registerByEmail(action.credentials).pipe(
            map(userCredential => {
              if (!userCredential.user) {
                return UserActions.registerFailureAction({
                  error: ERROR_MAP['auth/operation-not-allowed']
                });
              }
              const newUser = new User(
                userCredential.user.uid,
                action.credentials.email,
                action.credentials.firstName ?? '',
                action.credentials.lastName ?? ''
              );
              this.insertContact.mutate(action.credentials).pipe(
                map(({ data }) => {
                  const temp = data?.InsertContact?.returning[0];
                  this.registrationUserData = null;
                  if (!temp || !temp.contactId) return null;
                  return new User(
                    temp?.contactId,
                    temp?.emailAddress ?? '',
                    temp?.firstName,
                    temp?.lastName,
                    temp?.dob
                  );
                }),
                catchError(this.handleError)
              );
              return UserActions.registerSuccessAction({
                user: newUser,
                returnUrl: action.returnUrl
              });
            }),
            catchError(this.handleError)
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return UserActions.registerFailureAction({
            error: ERROR_MAP[error.code] ?? error.code
          });
        }
      })
    )
  );

  updateUserInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserInfoAction),
      withLatestFrom(
        combineLatest([this.store.select(UserSelector.getAuthenticatedUser)])
      ),
      pessimisticUpdate({
        run: (action, [user]) => {
          const variables = {
            contactId: action.user.contactId,
            dob: action.user.dateOfBirth,
            firstName: action.user.firstName,
            lastName: action.user.lastName,
            profilePicture: action.user.profilePicture
          };
          return this.updateContactByPk
            .mutate({ ...variables, updatedBy: user?.uid })
            .pipe(
              map(({ data }) => {
                if (data?.UpdateContactByPK) {
                  const newUserDetails = data.UpdateContactByPK;
                  const user: User = new User(
                    newUserDetails.contactId,
                    newUserDetails.emailAddress,
                    newUserDetails.firstName,
                    newUserDetails.lastName,
                    newUserDetails.dob,
                    newUserDetails.profilePicture || ''
                  );
                  this.snackbar.open(
                    'Your changes have been saved successfully!'
                  );
                  return UserActions.initializeSuccessAction({ user });
                } else
                  return UserActions.updateUserInfoFailure({
                    error: 'User not found!'
                  });
              }),
              catchError(this.handleError)
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred', 'Close');
          return UserActions.updateUserInfoFailure({ error });
        }
      })
    )
  );

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccessAction),
        filter(({ returnUrl }) => !!returnUrl),
        tap(action => this.router.navigateByUrl(action.returnUrl ?? '/'))
      ),
    { dispatch: false }
  );

  registerSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerSuccessAction),
      map(({ user }) => {
        this.registrationUserData = user;
        return UserActions.initializeSuccessAction({
          user
        });
      })
    )
  );

  initializeUserSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.initializeSuccessAction),
        filter(({ returnUrl }) => !!returnUrl),
        tap(action => this.router.navigateByUrl(action.returnUrl ?? '/'))
      ),
    { dispatch: false }
  );

  fetchUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUserRoles),
      fetch({
        run: () => {
          return this.getRoles.fetch().pipe(
            map(({ data }) => {
              console.log(`data`, data);
              return UserActions.fetchRoleSuccess({
                roles: data.Role as Maybe<TixRole>[]
              });
            })
          );
        },
        onError: (action, error) => {
          console.error({ action, error });

          UserActions.fetchRoleFailure({ error: error });
        }
      })
    )
  );

  upsertContactPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserPhoneNumbers),
      pessimisticUpdate({
        run: ({ phoneNumbers, userId }) => {
          const newPhoneNumbers = phoneNumbers
            .filter(
              phoneNumber =>
                phoneNumber.phoneNumberId === '' || !phoneNumber.phoneNumberId
            )
            .map(
              phoneNumber =>
                ({
                  phoneNumber: phoneNumber.phoneNumber,
                  type: phoneNumber.type,
                  updatedBy: userId,
                  updatedAt: 'now()',
                  contactPhoneNumbers: {
                    data: [
                      {
                        contactId: userId
                      }
                    ]
                  }
                } as TixPhoneNumberInsertInput)
            );

          return this.insertPhoneNumber
            .mutate({ objects: newPhoneNumbers })
            .pipe(
              map(result => {
                const temp = result.data?.InsertPhoneNumber?.returning;
                const contactPhoneNumbers =
                  temp &&
                  temp.map(
                    insertedPhoneNumber =>
                      ({
                        contactId:
                          insertedPhoneNumber.contactPhoneNumbers[0].contactId,
                        contactPhoneNumberId:
                          insertedPhoneNumber?.contactPhoneNumbers[0]
                            .contactPhoneNumberId,
                        phoneNumber: insertedPhoneNumber.phoneNumber,
                        type: insertedPhoneNumber.type,
                        phoneNumberId: insertedPhoneNumber.phoneNumberId,
                        countryCode: insertedPhoneNumber.countryCode
                      } as UserPhoneNumbers)
                  );

                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );
                return UserActions.upsertContactPhoneNumbersSuccess({
                  contactPhoneNumbers
                });
              }),
              catchError(this.handleError)
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          this.snackbar.open('An error has ocurred');
          return UserActions.upsertContactPhoneNumbersFailure(error);
        }
      })
    )
  );

  saveCompanyAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserAddress),
      withLatestFrom(
        combineLatest([this.store.select(UserSelector.getAuthenticatedUser)])
      ),
      pessimisticUpdate({
        run: ({ address, userId }, [user]) => {
          const variables: any = {
            city: address.city,
            countryCode: address.countryCode,
            postalCode: address.postalCode,
            stateProvince: address.stateProvince,
            streetAddress: address.streetAddress,
            suiteApartment: address.suiteApartment,
            updatedBy: user?.uid,
            contactId: user?.uid,
            updatedByContact: user?.uid
          };

          function getUserAddress(address: any): UserAddress {
            return {
              contactAddressId: address.contactAddress?.contactAddressId,
              contactId: address.contactAddress?.contactId,
              addressId: address.addressId,
              city: address.city ?? '',
              countryCode: address.countryCode ?? '',
              postalCode: address.postalCode ?? '',
              suiteApartment: address.suiteApartment ?? '',
              stateProvince: address.stateProvince ?? '',
              streetAddress: address.streetAddress ?? ''
            };
          }

          if (address.addressId) {
            variables.addressId = address.addressId;
            return this.updateAddressByPk
              .mutate({ ...variables, updatedBy: user?.uid })
              .pipe(
                map(({ data }) => {
                  if (data?.UpdateAddressByPK) {
                    this.snackbar.open(
                      'Your changes have been saved successfully!'
                    );
                    return UserActions.updateUserAddressSuccess({
                      address: getUserAddress(data.UpdateAddressByPK)
                    });
                  } else {
                    this.snackbar.open('An error has ocurred!');
                    return UserActions.updateUserAddressFailure({
                      error: 'Address not found!'
                    });
                  }
                }),
                catchError(this.handleError)
              );
          } else {
            return this.insertContactAddress
              .mutate({ ...variables, updatedBy: user?.uid })
              .pipe(
                map(({ data }) => {
                  if (data?.InsertAddress?.returning[0]) {
                    this.snackbar.open(
                      'Your changes have been saved successfully!'
                    );

                    return UserActions.updateUserAddressSuccess({
                      address: getUserAddress(data?.InsertAddress?.returning[0])
                    });
                  } else {
                    this.snackbar.open('An error has ocurred');

                    return UserActions.updateUserAddressFailure({
                      error: 'Address not found!'
                    });
                  }
                }),
                catchError(this.handleError)
              );
          }
        },
        onError: (action: any, error: any) => {
          console.error({ action, error });

          return UserActions.updateUserAddressFailure(error);
        }
      })
    )
  );

  deleteContactPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteContactPhoneNumber),
      pessimisticUpdate({
        run: ({ phoneNumber }) => {
          return this.deleteContactPhoneNumber
            .mutate({ phoneNumberId: phoneNumber.phoneNumberId })
            .pipe(
              map(({ data }) => {
                const contactDetails =
                  data?.DeleteContactPhoneNumber?.returning[0];
                return UserActions.deleteContactPhoneNumberSuccess({
                  contactId: contactDetails?.contactId,
                  phoneNumberId: contactDetails?.contactPhoneNumberId
                });
              }),
              catchError(this.handleError)
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          return null;
        }
      })
    )
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutAction),
        tap(() => {
          this.#freeLocalStorage();
          console.log('logged out ==>');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: TixAuthService,
    private readonly router: Router,
    private readonly insertContact: TixInsertContactGQL,
    private readonly insertPhoneNumber: TixInsertContactPhoneNumberGQL,
    private readonly insertContactAddress: TixInsertContactAddressGQL,
    private readonly getContactByEmail: TixGetContactByEmailAddressGQL,
    private readonly deleteContactPhoneNumber: TixDeleteContactPhoneNumberByPhoneNumberPkGQL,
    private readonly updateContactByPk: TixUpdateContactByPkGQL,
    private readonly updateAddressByPk: TixUpdateAddressByPkGQL,
    private readonly getRoles: TixGetRolesGQL,
    private readonly store: Store,
    private readonly snackbar: MatSnackBar
  ) {}

  ngrxOnInitEffects(): Action {
    return UserActions.initAction();
  }

  private handleError(error: any) {
    this.snackbar.open('An error has ocurred');
    console.log('handleError error --> ', error);
    return throwError(error || 'Server error');
  }

  #storeConnectedUserId(id: string) {
    localStorage.setItem('id', id);
  }

  #freeLocalStorage() {
    localStorage.clear();
  }
}
