import { FetchResult } from '@apollo/client';
import {
  TixCompanyAddress,
  TixCompanyMediaFile,
  TixCompanyPhoneNumber,
  TixCompanySocialMedia,
  TixCompanyWarning,
  TixInsertAddressMutation,
  TixInsertMediaFileMutation,
  TixInsertPhoneNumberMutation,
  TixInsertSocialMediaMutation,
  TixInsertWarningMutation,
  TixMediaFile,
  TixSocialMedia,
  TixUpdateAddressMutation,
  TixUpdateMediaFileByPkMutation,
  TixUpdatePhoneNumberByPkMutation,
  TixUpdateSocialMediaByPkMutation,
  TixUpdateWarningByPkMutation,
  TixWarning
} from '@tix/data-access';

export function convertPhoneNumberInsertResultToTixPhoneNumber(
  insertedPhoneNumbersFetchResult: FetchResult<TixInsertPhoneNumberMutation>
) {
  const insertedPhoneNumbers =
    insertedPhoneNumbersFetchResult.data?.InsertPhoneNumber?.returning;

  if (insertedPhoneNumbers)
    return insertedPhoneNumbers.map(insertedPhoneNumber => ({
      __typename: insertedPhoneNumber.companyPhoneNumbers[0].__typename,
      companyPhoneNumberId:
        insertedPhoneNumber.companyPhoneNumbers[0].companyPhoneNumberId,
      phoneNumberId: insertedPhoneNumber.phoneNumberId,
      phoneNumber: {
        __typename: insertedPhoneNumber.__typename,
        phoneNumber: insertedPhoneNumber.phoneNumber,
        phoneNumberId: insertedPhoneNumber.phoneNumberId,
        state: insertedPhoneNumber.state,
        type: insertedPhoneNumber.type,
        updatedAt: insertedPhoneNumber.updatedAt,
        updatedBy: insertedPhoneNumber.updatedBy,
        countryCode: insertedPhoneNumber.countryCode,
        sameAsCompany: insertedPhoneNumber.sameAsCompany
      },
      state: insertedPhoneNumber.companyPhoneNumbers[0].state,
      updatedBy: insertedPhoneNumber.companyPhoneNumbers[0].updatedBy,
      updatedAt: insertedPhoneNumber.companyPhoneNumbers[0].updatedAt,
      companyId: insertedPhoneNumber.companyPhoneNumbers[0].companyId
    })) as TixCompanyPhoneNumber[];

  return [];
}

export function convertPhoneNumberUpdateResultToTixPhoneNumber(
  insertedPhoneNumbersFetchResult: FetchResult<TixUpdatePhoneNumberByPkMutation>
) {
  const updatedPhoneNumber =
    insertedPhoneNumbersFetchResult.data?.UpdatePhoneNumberByPK;

  if (updatedPhoneNumber)
    return {
      __typename: updatedPhoneNumber.companyPhoneNumbers[0].__typename,
      companyPhoneNumberId:
        updatedPhoneNumber.companyPhoneNumbers[0].companyPhoneNumberId,
      phoneNumberId: updatedPhoneNumber.phoneNumberId,
      phoneNumber: {
        __typename: updatedPhoneNumber.__typename,
        phoneNumber: updatedPhoneNumber.phoneNumber,
        phoneNumberId: updatedPhoneNumber.phoneNumberId,
        state: updatedPhoneNumber.state,
        type: updatedPhoneNumber.type,
        updatedAt: updatedPhoneNumber.updatedAt,
        updatedBy: updatedPhoneNumber.updatedBy,
        countryCode: updatedPhoneNumber.countryCode,
        sameAsCompany: updatedPhoneNumber.sameAsCompany
      },
      state: updatedPhoneNumber.companyPhoneNumbers[0].state,
      updatedBy: updatedPhoneNumber.companyPhoneNumbers[0].updatedBy,
      updatedAt: updatedPhoneNumber.companyPhoneNumbers[0].updatedAt,
      companyId: updatedPhoneNumber.companyPhoneNumbers[0].companyId
    } as TixCompanyPhoneNumber;

  return;
}

export function createTixCompanyAddressFromInsertResponse(
  data: TixInsertAddressMutation | null | undefined
): {
  address: TixCompanyAddress;
} {
  return {
    address: {
      // Company Component
      __typename:
        data?.InsertAddress?.returning[0].companyAddress?.__typename ??
        'CompanyAddress',
      addressId:
        data?.InsertAddress?.returning[0].companyAddress?.addressId ?? '',
      companyAddressId:
        data?.InsertAddress?.returning[0].companyAddress?.companyAddressId ??
        '',
      companyId:
        data?.InsertAddress?.returning[0].companyAddress?.companyId ?? '',
      state: data?.InsertAddress?.returning[0].companyAddress?.state ?? '',
      updatedAt:
        data?.InsertAddress?.returning[0].companyAddress?.updatedAt ?? '',
      updatedBy:
        data?.InsertAddress?.returning[0].companyAddress?.updatedBy ?? '',
      // Address Component
      address: {
        __typename: data?.InsertAddress?.returning[0].__typename ?? 'Address',
        addressId: data?.InsertAddress?.returning[0].addressId ?? '',
        city: data?.InsertAddress?.returning[0].city ?? '',
        countryCode: data?.InsertAddress?.returning[0].countryCode ?? '',
        postalCode: data?.InsertAddress?.returning[0].postalCode ?? '',
        suiteApartment: data?.InsertAddress?.returning[0].suiteApartment ?? '',
        state: data?.InsertAddress?.returning[0].state ?? '',
        stateProvince: data?.InsertAddress?.returning[0].stateProvince ?? '',
        streetAddress: data?.InsertAddress?.returning[0].streetAddress ?? '',
        updatedAt: data?.InsertAddress?.returning[0].updatedAt ?? '',
        updatedBy: data?.InsertAddress?.returning[0].updatedBy ?? '',
        sameAsCompany: data?.InsertAddress?.returning[0].sameAsCompany ?? false
      }
    }
  };
}

export function createTixCompanyAddressFromUpdateResponse(
  data: TixUpdateAddressMutation | null | undefined
): {
  address: TixCompanyAddress;
} {
  return {
    address: {
      // Company Component
      __typename:
        data?.UpdateAddressByPK?.companyAddress?.__typename ?? 'CompanyAddress',
      addressId: data?.UpdateAddressByPK?.companyAddress?.addressId ?? '',
      companyAddressId:
        data?.UpdateAddressByPK?.companyAddress?.companyAddressId ?? '',
      companyId: data?.UpdateAddressByPK?.companyAddress?.companyId ?? '',
      state: data?.UpdateAddressByPK?.companyAddress?.state ?? '',
      updatedAt: data?.UpdateAddressByPK?.companyAddress?.updatedAt ?? '',
      updatedBy: data?.UpdateAddressByPK?.companyAddress?.updatedBy ?? '',
      // Address Component
      address: {
        __typename: data?.UpdateAddressByPK?.__typename ?? 'Address',
        addressId: data?.UpdateAddressByPK?.addressId ?? '',
        city: data?.UpdateAddressByPK?.city ?? '',
        countryCode: data?.UpdateAddressByPK?.countryCode ?? '',
        postalCode: data?.UpdateAddressByPK?.postalCode ?? '',
        suiteApartment: data?.UpdateAddressByPK?.suiteApartment ?? '',
        state: data?.UpdateAddressByPK?.state ?? '',
        stateProvince: data?.UpdateAddressByPK?.stateProvince ?? '',
        streetAddress: data?.UpdateAddressByPK?.streetAddress ?? '',
        updatedAt: data?.UpdateAddressByPK?.updatedAt ?? '',
        updatedBy: data?.UpdateAddressByPK?.updatedBy ?? '',
        sameAsCompany: data?.UpdateAddressByPK?.sameAsCompany ?? false
      }
    }
  };
}
export function convertCompanyWarningInsertResultToTixWarning(
  insertedWarningsFetchResult: FetchResult<TixInsertWarningMutation>
) {
  const insertedWarnings =
    insertedWarningsFetchResult.data?.InsertWarning?.returning;

  if (insertedWarnings)
    return insertedWarnings.map(
      insertedWarning =>
        ({
          __typename: insertedWarning.company_warning[0]?.__typename,
          companyWarningId:
            insertedWarning.company_warning[0]?.companyWarningId,
          warning: {
            type: insertedWarning.type,
            warningId: insertedWarning.warningId,
            message: insertedWarning.message,
            updatedAt: insertedWarning.updatedAt,
            updatedBy: insertedWarning.updatedBy
          } as Partial<TixWarning>,
          updatedBy: insertedWarning.company_warning[0]?.updatedBy,
          updatedAt: insertedWarning.company_warning[0]?.updatedAt,
          companyId: insertedWarning.company_warning[0]?.companyId
        } as Partial<TixCompanyWarning>)
    );

  return [];
}
export function convertCompanyWarningUpdateResultToTixWarning(
  insertedWarningFetchResult: FetchResult<TixUpdateWarningByPkMutation>
) {
  const updatedWarning = insertedWarningFetchResult.data?.UpdateWarningByPK;
  console.log('updatedWarning', updatedWarning);
  if (updatedWarning)
    return {
      __typename: updatedWarning.company_warning[0]?.__typename,
      companyWarningId: updatedWarning.company_warning[0]?.companyWarningId,
      warning: {
        type: updatedWarning.type,
        warningId: updatedWarning.warningId,
        message: updatedWarning.message,
        updatedAt: updatedWarning.updatedAt,
        updatedBy: updatedWarning.updatedBy
      } as Partial<TixWarning>,
      updatedBy: updatedWarning.company_warning[0]?.updatedBy,
      updatedAt: updatedWarning.company_warning[0]?.updatedAt,
      companyId: updatedWarning.company_warning[0]?.companyId
    } as Partial<TixCompanyWarning>;

  return;
}
export function convertSocialMediaInsertResultToTixSocialMedia(
  insertedSocialMediasFetchResult: FetchResult<TixInsertSocialMediaMutation>
) {
  const insertedSocialMedias =
    insertedSocialMediasFetchResult.data?.InsertSocialMedia?.returning;

  if (insertedSocialMedias)
    return insertedSocialMedias.map(
      insertedSocialMedia =>
        ({
          __typename: insertedSocialMedia.companySocialMedia[0].__typename,
          companySocialMediaId:
            insertedSocialMedia.companySocialMedia[0].companySocialMediaId,
          socialMedia: {
            socialMediaType: insertedSocialMedia.socialMediaType,
            socialMediaId: insertedSocialMedia.socialMediaId,
            url: insertedSocialMedia.url,
            state: insertedSocialMedia.state,
            updatedAt: insertedSocialMedia.updatedAt,
            updatedBy: insertedSocialMedia.updatedBy
          } as Partial<TixSocialMedia>,
          updatedBy: insertedSocialMedia.companySocialMedia[0].updatedBy,
          updatedAt: insertedSocialMedia.companySocialMedia[0].updatedAt,
          companyId: insertedSocialMedia.companySocialMedia[0].companyId
        } as Partial<TixCompanySocialMedia>)
    );

  return [];
}

export function convertSocialMediaUpdateResultToTixSocialMedia(
  insertedSocialMediasFetchResult: FetchResult<TixUpdateSocialMediaByPkMutation>
) {
  const updatedSocialMedia =
    insertedSocialMediasFetchResult.data?.UpdateSocialMediaByPK;
  if (updatedSocialMedia)
    return {
      __typename: updatedSocialMedia.companySocialMedia[0].__typename,
      companySocialMediaId:
        updatedSocialMedia.companySocialMedia[0].companySocialMediaId,
      socialMedia: {
        socialMediaType: updatedSocialMedia.socialMediaType,
        socialMediaId: updatedSocialMedia.socialMediaId,
        url: updatedSocialMedia.url,
        state: updatedSocialMedia.state,
        updatedAt: updatedSocialMedia.updatedAt,
        updatedBy: updatedSocialMedia.updatedBy
      } as Partial<TixSocialMedia>,
      updatedBy: updatedSocialMedia.companySocialMedia[0].updatedBy,
      updatedAt: updatedSocialMedia.companySocialMedia[0].updatedAt,
      companyId: updatedSocialMedia.companySocialMedia[0].companyId
    } as Partial<TixCompanySocialMedia>;

  return;
}

export function convertMediaFileInsertResultToTixMediaFile(
  insertedMediaFilesFetchResult: FetchResult<TixInsertMediaFileMutation>
) {
  const insertedMediaFiles =
    insertedMediaFilesFetchResult.data?.InsertMediaFile?.returning;

  if (insertedMediaFiles)
    return insertedMediaFiles.map(
      insertedMediaFile =>
        ({
          __typename: insertedMediaFile.companyMediaFile[0].__typename,
          companyImageFileId:
            insertedMediaFile.companyMediaFile[0].companyImageFileId,
          mediaFile: {
            name: insertedMediaFile.name,
            location: insertedMediaFile.location,
            mediaFileId: insertedMediaFile.mediaFileId,
            state: insertedMediaFile.state,
            fileType: insertedMediaFile.fileType,
            updatedAt: insertedMediaFile.updatedAt,
            updatedBy: insertedMediaFile.updatedBy
          } as Partial<TixMediaFile>,
          updatedBy: insertedMediaFile.companyMediaFile[0].updatedBy,
          updatedAt: insertedMediaFile.companyMediaFile[0].updatedAt,
          companyId: insertedMediaFile.companyMediaFile[0].companyId
        } as Partial<TixCompanyMediaFile>)
    );

  return [];
}

export function convertMediaFileUpdateResultToTixMediaFile(
  insertedMediaFilesFetchResult: FetchResult<TixUpdateMediaFileByPkMutation>
) {
  const updatedMediaFile =
    insertedMediaFilesFetchResult.data?.UpdateMediaFileByPK;

  if (updatedMediaFile)
    return {
      __typename: updatedMediaFile.companyMediaFile[0].__typename,
      companyImageFileId:
        updatedMediaFile.companyMediaFile[0].companyImageFileId,
      mediaFile: {
        name: updatedMediaFile.name,
        location: updatedMediaFile.location,
        mediaFileId: updatedMediaFile.mediaFileId,
        state: updatedMediaFile.state,
        fileType: updatedMediaFile.fileType,
        updatedAt: updatedMediaFile.updatedAt,
        updatedBy: updatedMediaFile.updatedBy
      } as Partial<TixMediaFile>,
      updatedBy: updatedMediaFile.companyMediaFile[0].updatedBy,
      updatedAt: updatedMediaFile.companyMediaFile[0].updatedAt,
      companyId: updatedMediaFile.companyMediaFile[0].companyId
    } as Partial<TixCompanyMediaFile>;

  return;
}
