import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TixCustomerTableComponent } from './customers-table/customers-table.component';
import { RouterModule } from '@angular/router';
import {
  BtnLoadingDirectiveModule,
  HeroArrowRight,
  TixButtonModule,
  TixCardModule,
  TixCheckboxModule,
  TixIconRegistry,
  TixInputModule
} from '@tix/shared/ui/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { TixIconModule } from '../../../../shared/ui/components/src/lib/icon/icon.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [TixCustomerTableComponent],
  exports: [TixCustomerTableComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    TixCardModule,
    TixButtonModule,
    TixInputModule,
    TixCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    BtnLoadingDirectiveModule,
    TixIconModule,
    MatIconModule
  ]
})
export class CustomerComponentsModule {
  constructor(iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([HeroArrowRight]);
  }
}
