import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
  Maybe,
  TixCompanyContact,
  TixContact,
  TixRole,
  TixUser
} from '@tix/data-access';

export const loadContactsForSelectedCompanySuccess = createAction(
  '[Staff API] Fetch Contacts for selected Company success',
  props<{ contacts?: TixCompanyContact[] }>()
);
export const loadContactsForSelectedCompanyFailure = createAction(
  '[Staff API] Fetch Contacts for selected Company failure',
  props<{ error: any }>()
);

export const loadStaffByIdSuccess = createAction(
  '[Staff API] Fetch Single Contact by contact Id success',
  props<{ contact: TixCompanyContact }>()
);
export const loadStaffByIdFailure = createAction(
  '[Staff API] Fetch Single Contact by contact Id failure',
  props<{ error: any }>()
);

export const fetchRoleSuccess = createAction(
  '[Staff Api] fetch roles of user success',
  props<{ roles: Maybe<TixRole>[] }>()
);
export const fetchRoleFailure = createAction(
  '[Staff Api] fetch roles of user Failure',
  props<{ error: any }>()
);

export const insertUserForCurrentCompanySuccess = createAction(
  '[Staff Api] Adding user to current company success',
  props<{ updatedContact: Update<TixContact> }>()
);
export const insertUserForCurrentCompanyFailure = createAction(
  '[Staff Api] Adding user to current company failure',
  props<{ error: any }>()
);

export const upsertContactPhoneNumbersSuccess = createAction(
  '[Staff Api] Update Phone Numbers Success',
  props<{ updatedContact: Update<TixCompanyContact> }>()
);
export const upsertContactPhoneNumbersFailure = createAction(
  '[Staff Api] Update Phone Numbers Failure',
  props<{ error: any }>()
);
export const deleteContactPhoneNumberSuccess = createAction(
  '[Staff Api] Delete Contact Phone Number Success',
  props<{ updatedContact: Update<TixCompanyContact> }>()
);

export const insertCompanyContactUserFailure = createAction(
  '[Staff Api] Insert Company Contact Failure',
  props<{ error: any }>()
);
export const insertCompanyContactUserSuccess = createAction(
  '[Staff Api] Insert Company Contact Success',
  props<{ user: Partial<TixUser> }>()
);

export const sendEmailSuccess = createAction(
  '[Staff Api] Send Company User Email Success'
);
export const sendEmailFailure = createAction(
  '[Staff Api] Send Company User Email Failure',
  props<{ error: any }>()
);

export const deleteContactPhoneNumberFailure = createAction(
  '[Staff Api] Delete Contact Phone Number Failure',
  props<{ error: any }>()
);

export const deleteStaffRoleSuccess = createAction(
  '[Staff Api] Delete staff role Success',
  props<{ updatedContact: Update<TixCompanyContact> }>()
);
export const deleteStaffRoleFailure = createAction(
  '[Staff Api] Delete staff role Failure',
  props<{ error: any }>()
);

export const saveStaffRoleSuccess = createAction(
  '[Staff Api] Save staff role Success',
  props<{ updatedContact: Update<TixCompanyContact> }>()
);
export const saveStaffRoleFailure = createAction(
  '[Staff Api] Save staff role Failure',
  props<{ error: any }>()
);
