import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  HeroExclamation,
  HeroPencil,
  HeroPlusSolid,
  HeroTrash,
  TixBadgeModule,
  TixButtonModule,
  TixCardModule,
  TixExpandChevronModule,
  TixIconModule,
  TixIconRegistry,
  TixInputModule,
  InlineEditModule,
  BtnLoadingDirectiveModule
} from '@tix/shared/ui/components';
import { TextMaskModule } from 'angular2-text-mask';
import { TicketConfigurationAdminComponent } from './ticket-configuration-admin/ticket-configuration-admin.component';
import { TicketConfigurationExpandComponent } from './ticket-configuration-expand/ticket-configuration-expand.component';
import { TicketConfigurationSectionConfigComponent } from './ticket-configuration-section-config/ticket-configuration-section-config.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { MatSelectModule } from '@angular/material/select';
import { TixFeesModule } from '@tix/shared/utils';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    TicketConfigurationExpandComponent,
    TicketConfigurationAdminComponent,
    TicketConfigurationSectionConfigComponent
  ],
  imports: [
    TixFeesModule,
    CommonModule,
    RouterModule,
    TixIconModule,
    TixButtonModule,
    CdkAccordionModule,
    TixCardModule,
    TixExpandChevronModule,
    TixBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    TixInputModule,
    ReactiveFormsModule,
    TextMaskModule,
    InlineEditModule,
    BtnLoadingDirectiveModule,
    MatTooltipModule
  ],
  exports: [
    TicketConfigurationExpandComponent,
    TicketConfigurationAdminComponent,
    TicketConfigurationSectionConfigComponent
  ],
  providers: []
})
export class TicketConfigurationModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([
      HeroTrash,
      HeroPencil,
      HeroPlusSolid,
      HeroExclamation
    ]);
  }
}
