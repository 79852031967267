import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  inject,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { TixIconRegistry } from './icon-registry.service';

/**
 * Injection token used to provide the current location to `TixIcon`.
 * Used to handle server-side rendering and to stub out during unit tests.
 * @docs-private
 */
export const TIX_ICON_LOCATION = new InjectionToken<TixIconLocation>(
  'tix-icon-location',
  {
    providedIn: 'root',
    factory: TIX_ICON_LOCATION_FACTORY
  }
);

/**
 * Stubbed out location for `TixIcon`.
 * @docs-private
 */
export interface TixIconLocation {
  getPathname: () => string;
}

/** @docs-private */
export function TIX_ICON_LOCATION_FACTORY(): TixIconLocation {
  const document = inject(DOCUMENT);
  const location = document ? document.location : null;

  return {
    // Note that this needs to be a function, rather than a property, because Angular
    // will only resolve it once, but we want the current path on each call.
    getPathname: () => (location ? location.pathname + location.search : '')
  };
}

/** SVG attributes that accept a FuncIRI (e.g. `url(<something>)`). */
const funcIriAttributes = [
  'clip-path',
  'color-profile',
  'src',
  'cursor',
  'fill',
  'filter',
  'marker',
  'marker-start',
  'marker-mid',
  'marker-end',
  'mask',
  'stroke'
];

/** Selector that can be used to find all elements that are using a `FuncIRI`. */
const funcIriAttributeSelector = funcIriAttributes
  .map(attr => `[${attr}]`)
  .join(', ');

/** Regex that can be used to extract the id out of a FuncIRI. */
const funcIriPattern = /^url\(['"]?#(.*?)['"]?\)$/;

/**
 * Component to display an icon. It can be used in the following ways:
 *
 * - Specify the svgIcon input to load an SVG icon from a URL previously registered with the
 *   addSvgIcon, addSvgIconInNamespace, addSvgIconSet, or addSvgIconSetInNamespace methods of
 *   TixIconRegistry. If the svgIcon value contains a colon it is assumed to be in the format
 *   "[namespace]:[name]", if not the value will be the name of an icon in the default namespace.
 *   Examples:
 *     `<tix-icon svgIcon="left-arrow"></tix-icon>
 *     <tix-icon svgIcon="animals:cat"></tix-icon>`
 *
 * - Use a font ligature as an icon by putting the ligature text in the content of the `<tix-icon>`
 *   component. By default the Tailwind icons font is used as described at
 *   http://google.github.io/material-design-icons/#icon-font-for-the-web. You can specify an
 *   alternate font by setting the fontSet input to either the CSS class to apply to use the
 *   desired font, or to an alias previously registered with TixIconRegistry.registerFontClassAlias.
 *   Examples:
 *     `<tix-icon>home</tix-icon>
 *     <tix-icon fontSet="myfont">sun</tix-icon>`
 *
 * - Specify a font glyph to be included via CSS rules by setting the fontSet input to specify the
 *   font, and the fontIcon input to specify the icon. Typically the fontIcon will specify a
 *   CSS class which causes the glyph to be displayed via a :before selector, as in
 *   https://fortawesome.github.io/Font-Awesome/examples/
 *   Example:
 *     `<tix-icon fontSet="fa" fontIcon="alarm"></tix-icon>`
 */
@Component({
  template: '<ng-content></ng-content>',
  selector: 'tix-icon',
  exportAs: 'tixIcon',
  styleUrls: ['icon.component.scss'],
  host: {
    role: 'img',
    class: 'tix-icon notranslate',
    '[attr.data-tix-icon-type]': 'usingFontIcon() ? "font" : "svg"',
    '[attr.data-tix-icon-name]': 'svgName || fontIcon',
    '[attr.data-tix-icon-namespace]': 'svgNamespace || fontSet',
    '[class.tix-icon-inline]': 'inline'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixIconComponent implements OnInit, AfterViewChecked, OnDestroy {
  static ngAcceptInputType_inline: BooleanInput;

  /**
   * Whether the icon should be inlined, automatically sizing the icon to match the font size of
   * the element the icon is contained in.
   */
  @Input()
  get inline(): boolean {
    return this.isInline;
  }
  set inline(inline: boolean) {
    this.isInline = coerceBooleanProperty(inline);
  }
  private isInline = false;

  /** Name of the icon in the SVG icon set. */
  @Input()
  get svgIcon(): string {
    return this.svgIconName;
  }
  set svgIcon(value: string) {
    if (value !== this.svgIconName) {
      if (value) {
        this.updateSvgIcon(value);
      } else if (this.svgIconName) {
        this.clearSvgElement();
      }
      this.svgIconName = value;
    }
  }
  private svgIconName: string;

  /** Font set that the icon is a part of. */
  @Input()
  get fontSet(): string {
    return this.fontSetName;
  }
  set fontSet(value: string) {
    const newValue = cleanupFontValue(value);

    if (newValue !== this.fontSetName) {
      this.fontSetName = newValue;
      this.updateFontIconClasses();
    }
  }
  private fontSetName: string;

  /** Name of an icon within a font set. */
  @Input()
  get fontIcon(): string {
    return this.fontIconName;
  }
  set fontIcon(value: string) {
    const newValue = cleanupFontValue(value);

    if (newValue !== this.fontIconName) {
      this.fontIconName = newValue;
      this.updateFontIconClasses();
    }
  }
  private fontIconName: string;

  private previousFontSetClass: string;
  private previousFontIconClass: string;

  svgName: string | null;
  svgNamespace: string | null;

  /** Keeps track of the current page path. */
  private previousPath?: string;

  /** Keeps track of the elements and attributes that we've prefixed with the current path. */
  private elementsWithExternalReferences?: Map<
    Element,
    { name: string; value: string }[]
  >;

  /** Subscription to the current in-progress SVG icon request. */
  private currentIconFetch = Subscription.EMPTY;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private iconRegistry: TixIconRegistry,
    @Attribute('aria-hidden') ariaHidden: string,
    @Inject(TIX_ICON_LOCATION) private location: TixIconLocation,
    private readonly errorHandler: ErrorHandler
  ) {
    // If the user has not explicitly set aria-hidden, mark the icon as hidden, as this is
    // the right thing to do for the majority of icon use-cases.
    if (!ariaHidden) {
      elementRef.nativeElement.setAttribute('aria-hidden', 'true');
    }
  }

  /**
   * Splits an svgIcon binding value into its icon set and icon name components.
   * Returns a 2-element array of [(icon set), (icon name)].
   * The separator for the two fields is ':'. If there is no separator, an empty
   * string is returned for the icon set and the entire value is returned for
   * the icon name. If the argument is falsy, returns an array of two empty strings.
   * Throws an error if the name contains two or more ':' separators.
   * Examples:
   *   `'social:cake' -> ['social', 'cake']
   *   'penguin' -> ['', 'penguin']
   *   null -> ['', '']
   *   'a:b:c' -> (throws Error)`
   */
  private splitIconName(iconName: string): [string, string] {
    if (!iconName) {
      return ['', ''];
    }
    const parts = iconName.split(':');
    switch (parts.length) {
      case 1:
        return ['', parts[0]]; // Use default namespace.
      case 2:
        return <[string, string]>parts;
      default:
        throw Error(`Invalid icon name: "${iconName}"`);
    }
  }

  ngOnInit() {
    // Update font classes because ngOnChanges won't be called if none of the inputs are present,
    // e.g. <tix-icon>arrow</tix-icon> In this case we need to add a CSS class for the default font.
    this.updateFontIconClasses();
  }

  ngAfterViewChecked() {
    const cachedElements = this.elementsWithExternalReferences;

    if (cachedElements && cachedElements.size) {
      const newPath = this.location.getPathname();

      // We need to check whether the URL has changed on each change detection since
      // the browser doesn't have an API that will let us react on link clicks and
      // we can't depend on the Angular router. The references need to be updated,
      // because while most browsers don't care whether the URL is correct after
      // the first render, Safari will break if the user navigates to a different
      // page and the SVG isn't re-rendered.
      if (newPath !== this.previousPath) {
        this.previousPath = newPath;
        this.prependPathToReferences(newPath);
      }
    }
  }

  ngOnDestroy() {
    this.currentIconFetch.unsubscribe();

    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }
  }

  usingFontIcon(): boolean {
    return !this.svgIcon;
  }

  private setSvgElement(svg: SVGElement) {
    this.clearSvgElement();

    // Workaround for IE11 and Edge ignoring `style` tags inside dynamically-created SVGs.
    // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10898469/
    // Do this before inserting the element into the DOM, in order to avoid a style recalculation.
    const styleTags = svg.querySelectorAll(
      'style'
    ) as NodeListOf<HTMLStyleElement>;

    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }

    // Note: we do this fix here, rather than the icon registry, because the
    // references have to point to the URL at the time that the icon was created.
    const path = this.location.getPathname();
    this.previousPath = path;
    this.cacheChildrenWithExternalReferences(svg);
    this.prependPathToReferences(path);
    this.elementRef.nativeElement.appendChild(svg);
  }

  private clearSvgElement() {
    const layoutElement: HTMLElement = this.elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }

  private updateFontIconClasses() {
    if (!this.usingFontIcon()) {
      return;
    }

    const elem: HTMLElement = this.elementRef.nativeElement;
    const fontSetClass = this.fontSet
      ? this.iconRegistry.classNameForFontAlias(this.fontSet)
      : this.iconRegistry.getDefaultFontSetClass();

    if (fontSetClass != this.previousFontSetClass) {
      if (this.previousFontSetClass) {
        elem.classList.remove(this.previousFontSetClass);
      }
      if (fontSetClass) {
        elem.classList.add(fontSetClass);
      }
      this.previousFontSetClass = fontSetClass;
    }

    if (this.fontIcon != this.previousFontIconClass) {
      if (this.previousFontIconClass) {
        elem.classList.remove(this.previousFontIconClass);
      }
      if (this.fontIcon) {
        elem.classList.add(this.fontIcon);
      }
      this.previousFontIconClass = this.fontIcon;
    }
  }

  /**
   * Prepends the current path to all elements that have an attribute pointing to a `FuncIRI`
   * reference. This is required because WebKit browsers require references to be prefixed with
   * the current path, if the page has a `base` tag.
   */
  private prependPathToReferences(path: string) {
    const elements = this.elementsWithExternalReferences;

    if (elements) {
      elements.forEach((attrs, element) => {
        attrs.forEach(attr => {
          element.setAttribute(attr.name, `url('${path}#${attr.value}')`);
        });
      });
    }
  }

  /**
   * Caches the children of an SVG element that have `url()`
   * references that we need to prefix with the current path.
   */
  private cacheChildrenWithExternalReferences(element: SVGElement) {
    const elementsWithFuncIri = element.querySelectorAll(
      funcIriAttributeSelector
    );
    const elements = (this.elementsWithExternalReferences =
      this.elementsWithExternalReferences || new Map());

    for (let i = 0; i < elementsWithFuncIri.length; i++) {
      funcIriAttributes.forEach(attr => {
        const elementWithReference = elementsWithFuncIri[i];
        const value = elementWithReference.getAttribute(attr);
        const match = value ? value.match(funcIriPattern) : null;

        if (match) {
          let attributes = elements.get(elementWithReference);

          if (!attributes) {
            attributes = [];
            elements.set(elementWithReference, attributes);
          }

          attributes.push({ name: attr, value: match[1] });
        }
      });
    }
  }

  /** Sets a new SVG icon with a particular name. */
  private updateSvgIcon(rawName: string | undefined) {
    this.svgNamespace = null;
    this.svgName = null;
    this.currentIconFetch.unsubscribe();

    if (rawName) {
      const [namespace, iconName] = this.splitIconName(rawName);

      if (namespace) {
        this.svgNamespace = namespace;
      }

      if (iconName) {
        this.svgName = iconName;
      }

      this.currentIconFetch = this.iconRegistry
        .getNamedSvgIcon(iconName, namespace)
        .pipe(take(1))
        .subscribe(
          svg => this.setSvgElement(svg),
          (err: Error) => {
            const errorMessage = `Error retrieving icon ${namespace}:${iconName}! ${err.message}`;
            this.errorHandler.handleError(new Error(errorMessage));
          }
        );
    }
  }
}

/**
 * Cleans up a value to be used as a fontIcon or fontSet.
 * Since the value ends up being assigned as a CSS class, we
 * have to trim the value and omit space-separated values.
 */
function cleanupFontValue(value: any) {
  return typeof value === 'string' ? value.trim().split(' ')[0] : value;
}
