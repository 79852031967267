import * as moment from 'moment';

export abstract class PortalCalendarFilter {}

export class PortalMonthCalendarFilter extends PortalCalendarFilter {
  public get startDate() {
    return moment()
      .set('year', this.year)
      .set('month', this.month)
      .set('date', 1)
      .toDate();
  }
  public get endDate() {
    return moment()
      .set('year', this.year)
      .set('month', this.month + 1)
      .set('date', 1)
      .subtract(1, 'day')
      .toDate();
  }
  constructor(public year: number, public month: number) {
    super();
  }
}

export class PortalCalendarSingleDayFilter extends PortalCalendarFilter {
  constructor(public year: number, public month: number, public day: number) {
    super();
  }
}

export class PortalCalendarDateRangeFilter extends PortalCalendarFilter {
  constructor(public startDate: Date, public endDate: Date) {
    super();
  }
}
