import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TixProductMediaFileDeleteByPkGQL,
  TixProductMediaFileInsertOneGQL
} from '@tix/data-access';
import { HelperStringService } from '@tix/util-common';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
/**
 * When uploading a file with an existing name the new one will replace the existing file.
 * todo: this service should be global
 */
@Injectable()
export class MediaService {
  // [store]
  #files$ = new BehaviorSubject<Record<string, any>[]>([]);
  #progress$ = new BehaviorSubject<number>(100);

  // global
  #baseEndpoint = 'https://asylumtix-be.herokuapp.com/graphql';
  #secret = 'L3er5wbpOzmkJ20BpJFuL4l5C61upnhsLA1Rh2Obj2ljqpJpQv06NnTQAavTsQef';
  #mutationUploadFile =
    'mutation UploadMedia($file: Upload!, $width: Float, $height: Float) {\n  uploadMedia(file: $file, width: $width, height: $height) {url}\n}';

  // helpers
  #_helperStringService = new HelperStringService();

  // getters
  get progress$() {
    return this.#progress$.asObservable();
  }

  get files$() {
    return this.#files$.asObservable();
  }

  constructor(
    private _uploadFile: TixProductMediaFileInsertOneGQL,
    private _deleteFile: TixProductMediaFileDeleteByPkGQL,
    private _httpClient: HttpClient
  ) {}

  // public
  uploadMedia(files: File[], productId: string): void {
    const file = files[0];
    const fileName = `___${Date.now()}_${file.name.replace(
      /[^a-zA-Z0-9]+/g,
      '_'
    )}`;
    this.#files$.next([...this.#files$.getValue(), file]);
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: this.#mutationUploadFile,
        variables: {
          file
        }
      })
    );

    formData.append('map', JSON.stringify({ file: ['variables.file'] }));
    formData.append('file', file, fileName);

    const getImageUrl$ = this._httpClient
      .post(this.#baseEndpoint, formData, {
        reportProgress: true,
        observe: 'events',
        responseType: 'json',
        headers: {
          Accept: '*/*',
          'x-hasura-admin-secret': this.#secret
        }
      })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.#progress$.next(
              Math.round((response.loaded / (response.total ?? 1)) * 100)
            );
          } else if (response.type === HttpEventType.Response) {
            const temp: any = file;
            temp.location = response.body.data.uploadMedia.url;
            delete temp.size;

            const existing = this.#files$.getValue();
            const filtered = existing.filter(
              _file => _file['name'] !== file.name
            );

            this.#files$.next([
              ...filtered,
              { name: temp.name, location: temp.location }
            ]);
            return temp.location;
          }
        })
      );

    const { name, type: fileType } = file;
    const updatedBy = localStorage.getItem('id');
    const createdBy = updatedBy;

    getImageUrl$
      .pipe(
        switchMap(location => {
          if (!location) return of(location);

          const mediaFile = {
            data: { name, fileType, location, updatedBy }
          };

          return this._uploadFile.mutate(
            {
              mediaFile,
              productId,
              productMediaImageFileId: this.#_helperStringService.uuid(),
              updatedBy,
              createdBy
            },
            {
              fetchPolicy: 'no-cache'
            }
          );
        })
      )
      .subscribe();
  }

  removeFile(i: number, productMediaImageFileId: string, mediaFileId: string) {
    this._deleteFile
      .mutate(
        {
          productMediaImageFileId,
          mediaFileId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(take(1))
      .subscribe();

    const deleted = this.#files$.getValue();
    deleted.splice(i, 1);
    this.#files$.next(deleted);
  }

  addExistingFiles(files: any) {
    this.#files$.next(files);
  }
}
