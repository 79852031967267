import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EventProductsUpdateComponent } from '../event-product-update/event-products-update.component';
import { EventProductsPullComponent } from '../event-products-pull/event-products-pull.component';
import { EventProductsService } from '../services/event-products.service';

@Component({
  selector: 'tix-event-products',
  templateUrl: './event-products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventProductsComponent implements OnInit {
  //
  #destroyed = new Subject<void>();

  productIds: any[] = [];

  //
  eventProducts$ = this._eventProductsService.eventProducts$.pipe(
    map(pds => {
      this.productIds = pds?.map((ep: any) => ep.productId);
      this._changeDetectorRef.markForCheck();
      return pds;
    })
  );

  //
  #companyId = '';
  #eventId = '';

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    //
    private _eventProductsService: EventProductsService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.#companyId = params.companyId;
      this.#eventId = params.eventId;

      this._eventProductsService
        .eventProducts(this.#eventId)
        .pipe(takeUntil(this.#destroyed))
        .subscribe(() => {
          this._changeDetectorRef.markForCheck();
        });
    });
  }

  ngOnDestroy() {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  // start event products
  pullProducts() {
    const dialogRef = this._dialog.open(EventProductsPullComponent, {
      delayFocusTrap: true,
      data: {
        companyId: this.#companyId,
        eventId: this.#eventId,
        productIds: this.productIds
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this._changeDetectorRef.markForCheck();
      // action here !
    });
  }

  updateProduct(eventProduct: any) {
    const dialogRef = this._dialog.open(EventProductsUpdateComponent, {
      delayFocusTrap: true,
      data: { ...eventProduct, eventId: this.#eventId }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this._eventProductsService
      //   .eventProducts(this.#eventId)
      //   .pipe(take(1))
      //   .subscribe();
      // this._changeDetectorRef.markForCheck();
      // action here !
    });
  }
  // end event products

  trackByFn(index: number, item: any) {
    return item.quantityRemaining || item.quantity;
  }
}
