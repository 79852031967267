import { createAction, props } from '@ngrx/store';
import { TixAddress, TixPhoneNumber, TixRole, TixUser } from '@tix/data-access';
import {
  UserAddress,
  UserCredentials,
  UserPhoneNumbers
} from '@tix/shared/models';
import { User } from '@tix/shared/models';
import { Maybe } from '@tix/data-access';

type userUpdateInfo = {
  contactId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  profilePicture: string;
};

export const userAuthInitializedAction = createAction(
  '[Page] User Auth Retrieved',
  props<{ idk: string }>()
);

export const initAction = createAction('[User] Init User');
export const initializeSuccessAction = createAction(
  '[User] Initialize Success',
  props<{ user: User | null; returnUrl?: string }>()
);
export const initializeFailureAction = createAction(
  '[User] Initialize Failed',
  props<{ error: string }>()
);

export const retrieveUser = createAction('[User] Retrieve User');

export const loginAction = createAction(
  '[User] Login',
  props<{ credentials: UserCredentials; returnUrl?: string }>()
);
export const loginWihMediaAction = createAction(
  '[User] Login with media',
  props<{ media: 'google' | 'fb' | 'apple' | 'twitter'; returnUrl?: string }>()
);
export const logoutAction = createAction('[User] Logout');

export const registerAction = createAction(
  '[User] Register',
  props<{ credentials: UserCredentials; returnUrl?: string }>()
);
export const registerSuccessAction = createAction(
  '[User] Register success',
  props<{ user: User | null; returnUrl?: string }>()
);
export const loginSuccessAction = createAction(
  '[User] Login Success with media',
  props<{ user: User | null; returnUrl?: string }>()
);
export const loginWithMediaSuccessAction = createAction(
  '[User] Login Success',
  props<{ user: User | null; returnUrl?: string }>()
);

export const loginFailureAction = createAction(
  '[User] Login Failed',
  props<{ error: string }>()
);
export const loginWithMediaFailureAction = createAction(
  '[User] Login Failed with media',
  props<{ error: string }>()
);
export const registerFailureAction = createAction(
  '[User] Register Failed',
  props<{ error: string }>()
);

export const updateUserInfoAction = createAction(
  '[User] Update user info',
  props<{ user: userUpdateInfo }>()
);
export const updateUserInfoFailure = createAction(
  '[User] Update User Info Failure',
  props<{ error: string }>()
);

export const saveUserPhoneNumbers = createAction(
  '[User] Save Phone Numbers',
  props<{ phoneNumbers: TixPhoneNumber[]; userId: string }>()
);
export const upsertContactPhoneNumbersSuccess = createAction(
  '[User] Update Phone Numbers Success',
  props<{ contactPhoneNumbers: UserPhoneNumbers[] | undefined }>()
);
export const upsertContactPhoneNumbersFailure = createAction(
  '[User] Update Phone Numbers Failure',
  props<{ error: any }>()
);
export const deleteContactPhoneNumber = createAction(
  '[User] Delete Contact Phone Number',
  props<{ phoneNumber: TixPhoneNumber }>()
);
export const deleteContactPhoneNumberSuccess = createAction(
  '[User] Delete Contact Phone Number Success',
  props<{ contactId: string; phoneNumberId: string }>()
);

export const saveUserAddress = createAction(
  '[User] Save Address',
  props<{ address: TixAddress; userId: string }>()
);
export const updateUserAddressSuccess = createAction(
  '[User] Update User Address Success',
  props<{ address: UserAddress }>()
);
export const updateUserAddressFailure = createAction(
  '[User] Update User Address Failure',
  props<{ error: any }>()
);

export const setUserRolesSuccess = createAction(
  '[User] Set User roles Success',
  props<{ userRoles: TixUser }>()
);
export const setUserRolesFailure = createAction(
  '[User] Set User roles Failure',
  props<{ error: any }>()
);

// Whenever user create new company, need to add into company and also should have userId then this action should be trigger
export const assignedAdminRoleSuccess = createAction(
  '[Company Admin Page] Logged-in user assign as admin of newly created company success',
  props<{ userRoles: TixUser; userId: string }>()
);
export const assignedAdminRoleFailure = createAction(
  '[Company Admin Page] Logged-in user assign as admin of newly created company failure',
  props<{ error: any }>()
);

export const fetchUserRoles = createAction(
  '[Staff Edit Page] Fetch users available roles'
);

export const fetchRoleSuccess = createAction(
  '[Staff Api] fetch roles of user success',
  props<{ roles: Maybe<TixRole>[] }>()
);
export const fetchRoleFailure = createAction(
  '[Staff Api] fetch roles of user Failure',
  props<{ error: any }>()
);
