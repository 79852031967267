import { Injectable } from '@angular/core';
import {
  TixAddOrderToHoldGQL,
  TixGetOtherOrdersOnHoldGQL,
  TixRemoveOrderFromHoldGQL,
  TixTicketOnHoldInput
} from '@tix/data-access';

@Injectable({
  providedIn: 'root'
})
export class TicketHoldService {
  constructor(
    private addOrderToHoldMutation: TixAddOrderToHoldGQL,
    private removeOrderFromHoldMutation: TixRemoveOrderFromHoldGQL,
    private getOtherOrdersOnHoldQuery: TixGetOtherOrdersOnHoldGQL
  ) {}

  public async getTicketsOnHoldByOtherUsers(
    userSessionId: string,
    ticketConfigIds: string[]
  ) {
    const response = await this.getOtherOrdersOnHoldQuery
      .fetch(
        { tickets: ticketConfigIds, userSessionId },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    return response.data.getOtherUsersOnHoldTickets;
  }

  public async addOrderToHoldForUserSession(
    userSessionId: string,
    tickets: TixTicketOnHoldInput[]
  ) {
    const response = await this.addOrderToHoldMutation
      .mutate({ userSessionId, tickets })
      .toPromise();

    if (!response.data?.addOrderToHold)
      throw new Error('Failed to add the order to hold');
  }

  public async removeOrderFromHoldForUserSession(userSessionId: string) {
    const response = await this.removeOrderFromHoldMutation
      .mutate({ userSessionId })
      .toPromise();

    if (!response.data?.removeOrderFromHold)
      throw new Error('Failed to remove the order from hold');
  }
}
