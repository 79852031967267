import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EventBuyerEffects } from './effects/event-buyer.effects';
import * as fromVenueBuyerEvents from './reducers/event-buyer.reducer';
import { TixFeesModule } from '@tix/shared/utils';
import { OrderService } from './services';

@NgModule({
  imports: [
    TixFeesModule,
    CommonModule,
    StoreModule.forFeature(
      fromVenueBuyerEvents.BUYER_FEATURE_KEY,
      fromVenueBuyerEvents.reducer
    ),
    EffectsModule.forFeature([EventBuyerEffects])
  ],
  providers: [OrderService]
})
export class TixEventBuyerStateModule {}
