import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

type Option<T> = {
  label: string;
  value: T;
  selected?: boolean;
};

@Component({
  templateUrl: './checkbox-select-dialog.component.html',
  styleUrls: ['./checkbox-select-dialog.component.scss'],
  selector: 'tix-checkbox-select-dialog'
})
export class CheckboxSelectDialogComponent implements OnInit {
  @Input()
  options: Option<any>[] = [];

  @Input()
  title: string;
  @Input()
  description?: string;
  @Input()
  confirmText?: string;

  @Output()
  valuesSelectedChanged = new EventEmitter<any[]>();

  @Output()
  proceed = new EventEmitter<void>();
  @Output()
  cancel = new EventEmitter<void>();

  get selectedValues(): any[] {
    return this.options.filter(o => o.selected).map(o => o.value);
  }

  get allSelected(): boolean {
    return this.options.every(o => o.selected);
  }

  get noneSelected(): boolean {
    return this.options.every(o => !o.selected);
  }

  selectAll() {
    this.options.forEach(o => (o.selected = true));
    this.handleOptionsChanged();
  }

  deselectAll() {
    this.options.forEach(o => (o.selected = false));
    this.handleOptionsChanged();
  }

  ngOnInit(): void {}

  handleCancel() {
    this.cancel.emit();
  }

  handleProceed() {
    this.proceed.emit();
  }
  handleOptionsChanged() {
    this.valuesSelectedChanged.emit(this.selectedValues);
  }
}
