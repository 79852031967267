import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TixIconModule, TixIconRegistry } from '../icon';
import { HeroChevronDown, HeroChevronUp } from './../icon/heroicons/outline';
import { TixExpandChevronComponent } from './expand-chevron.component';

@NgModule({
  declarations: [TixExpandChevronComponent],
  imports: [CommonModule, TixIconModule],
  exports: [TixExpandChevronComponent],
  providers: []
})
export class TixExpandChevronModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([HeroChevronDown, HeroChevronUp]);
  }
}
