import { Injectable } from '@angular/core';
import { State as UserState } from '@tix/auth/state';
import {
  Scalars,
  TixGetVenueTicketByPkGQL,
  TixInsertVenueTicketConfigGQL,
  TixInsertVenueTicketGQL,
  TixUpdateTicketConfigByPkGQL,
  TixUpdateVenueTicketByPkGQL,
  TixVenueTicket,
  TixVenueTicketConfig,
  TixVenueTicketConfigInsertInput,
  TixVenueTicketInsertInput
} from '@tix/data-access';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as VenuesApiActions from '../../actions/venues-api.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VenueTicketHelperService {
  constructor(
    private readonly insertVenueTicketConfiguration: TixInsertVenueTicketConfigGQL,
    private readonly insertVenueTicket: TixInsertVenueTicketGQL,
    private readonly updateVenueTicketMutation: TixUpdateVenueTicketByPkGQL,
    private readonly updateVenueTicketConfiguration: TixUpdateTicketConfigByPkGQL,
    private readonly getVenueTicketByPK: TixGetVenueTicketByPkGQL,
    private router: Router
  ) {}

  saveVenueTicket(
    ticket: Partial<TixVenueTicket>,
    userState: UserState,
    venueId: string
  ) {
    if (!ticket.venueTicketId) {
      return this.insertNewVenueTicket(ticket, userState, venueId).pipe(
        tap(() => {
          // setTimeout(() => {
          //   console.log('Reloading');
          //   window.location.reload();
          // }, 300);
        })
      );
    }

    return this.updateVenueTicket(ticket, userState).pipe(
      tap(() => {
        // if (ticket.Configurations?.some(config => (config as any).isNew))
        //   setTimeout(() => {
        //     console.log('Reloading');
        //     window.location.reload();
        //   }, 300);
      })
    );
  }

  private updateVenueTicket(
    ticket: Partial<TixVenueTicket>,
    userState: UserState
  ) {
    const updatingConfigs =
      ticket.Configurations?.filter(config => !(config as any)?.isNew)?.map(
        config =>
          this.updateVenueTicketConfiguration.mutate(
            {
              ticketConfigId: config.TicketConfigurations?.ticketConfigId,
              _set: {
                includeFees: config.TicketConfigurations?.includeFees,
                name: config.TicketConfigurations?.name,
                noOfSeats: config.TicketConfigurations?.noOfSeats,
                salesChannel: config.TicketConfigurations?.salesChannel,
                perOrderMax: config.TicketConfigurations?.perOrderMax,
                perOrderMin: config.TicketConfigurations?.perOrderMin,
                row: config.TicketConfigurations?.row,
                seat: config.TicketConfigurations?.seat,
                section: config.TicketConfigurations?.section,
                state: config.TicketConfigurations?.state,
                ticketConfigId: config.TicketConfigurations?.ticketConfigId,
                platformFee: config.TicketConfigurations?.platformFee,
                venueFee: config.TicketConfigurations?.venueFee,
                parentTicketId:
                  config.TicketConfigurations?.parentTicketId ?? null,
                processingFee: config.TicketConfigurations?.processingFee,
                ticketPrice: config.TicketConfigurations?.ticketPrice,
                ticketType: config.TicketConfigurations?.ticketType,
                startSellingDate: config.TicketConfigurations?.startSellingDate,
                stopSellingDate: config.TicketConfigurations?.stopSellingDate,
                startSellingTime: config.TicketConfigurations?.startSellingTime,
                stopSellingTime: config.TicketConfigurations?.stopSellingTime,
                helpText: config.TicketConfigurations?.helpText,
                helpTextTitle: config.TicketConfigurations?.helpTextTitle,
                updatedAt: 'now()',
                updatedBy:
                  userState.authenticatedUser?.uid ??
                  '00fda0cf-5a5d-4821-9e05-d995720212ab'
              }
            },
            {
              fetchPolicy: 'no-cache'
            }
          )
      ) || [];

    console.log(ticket);

    const creatingConfigs =
      ticket.Configurations?.filter(config => (config as any)?.isNew)?.map(
        config =>
          this.insertVenueTicketConfiguration.mutate({
            objects: createVenueTicketConfigurationInput(
              config,
              ticket.venueTicketId,
              userState.authenticatedUser?.uid ??
                '00fda0cf-5a5d-4821-9e05-d995720212ab'
            )
          })
      ) || [];

    return combineLatest([
      this.updateVenueTicketMutation.mutate(
        {
          venueTicketId: ticket.venueTicketId,
          _set: {
            description: ticket.description,
            name: ticket.name,
            state: ticket.state,
            totalSeats: ticket.totalSeats,
            updatedBy: ticket.updatedBy,
            updatedAt: 'now()'
          }
        },
        {
          fetchPolicy: 'no-cache'
        }
      ),
      ...updatingConfigs,
      ...creatingConfigs
    ]).pipe(
      switchMap(() => {
        return this.getVenueTicketByPK
          .fetch(
            { venueTicketId: ticket.venueTicketId },
            { fetchPolicy: 'no-cache' }
          )
          .pipe(
            map(res =>
              VenuesApiActions.insertVenueTicketSuccess({
                venueTicket: res.data.VenueTicketByPK as any
              })
            )
          );
      })
    );
  }

  private insertNewVenueTicket(
    ticket: Partial<TixVenueTicket>,
    userState: UserState,
    venueId: string
  ) {
    return this.insertVenueTicket
      .mutate({
        objects: [
          createVenueTicketInsert(
            ticket,
            venueId,
            userState.authenticatedUser?.uid ??
              '00fda0cf-5a5d-4821-9e05-d995720212ab'
          )
        ]
      })
      .pipe(
        map(res => {
          if (!res.data?.InsertVenueTicket?.returning)
            return VenuesApiActions.insertVenueTicketFailure({
              error: {
                message: 'invalid response, missing attributes',
                object: res
              }
            });

          return VenuesApiActions.insertVenueTicketSuccess({
            venueTicket: res.data.InsertVenueTicket.returning[0] as any
          });
        })
      );
  }
}

function createVenueTicketConfigurationInput(
  venueTicketConfig: Partial<TixVenueTicketConfig>,
  venueTicketId: Scalars['uuid'],
  updatedBy: Scalars['uuid']
): TixVenueTicketConfigInsertInput {
  return {
    ticketConfigId: venueTicketConfig.ticketConfigId,
    venueTicketConfig: venueTicketConfig.venueTicketConfig,
    state: 'ACTIVE',
    updatedBy,
    updatedAt: 'now()',
    venueTicketId,
    TicketConfigurations: {
      data: {
        includeFees: venueTicketConfig.TicketConfigurations?.includeFees,
        name: venueTicketConfig.TicketConfigurations?.name,
        noOfSeats: venueTicketConfig.TicketConfigurations?.noOfSeats,
        salesChannel: venueTicketConfig.TicketConfigurations?.salesChannel,
        perOrderMax: venueTicketConfig.TicketConfigurations?.perOrderMax,
        perOrderMin: venueTicketConfig.TicketConfigurations?.perOrderMin,
        row: venueTicketConfig.TicketConfigurations?.row,
        seat: venueTicketConfig.TicketConfigurations?.seat,
        section: venueTicketConfig.TicketConfigurations?.section,
        ticketConfigId: venueTicketConfig.TicketConfigurations?.ticketConfigId,
        platformFee: venueTicketConfig.TicketConfigurations?.platformFee,
        parentTicketId:
          venueTicketConfig.TicketConfigurations?.parentTicketId ?? null,
        processingFee: venueTicketConfig.TicketConfigurations?.processingFee,
        venueFee: venueTicketConfig.TicketConfigurations?.venueFee,
        ticketPrice: venueTicketConfig.TicketConfigurations?.ticketPrice,
        ticketType: venueTicketConfig.TicketConfigurations?.ticketType,
        startSellingDate:
          venueTicketConfig.TicketConfigurations?.startSellingDate,
        stopSellingDate:
          venueTicketConfig.TicketConfigurations?.stopSellingDate,
        startSellingTime:
          venueTicketConfig.TicketConfigurations?.startSellingTime,
        stopSellingTime:
          venueTicketConfig.TicketConfigurations?.stopSellingTime,
        helpText: venueTicketConfig.TicketConfigurations?.helpText,
        helpTextTitle: venueTicketConfig.TicketConfigurations?.helpTextTitle,
        updatedAt: 'now()',
        updatedBy
      }
    }
  };
}

export function createVenueTicketInsert(
  venueTicket: Partial<TixVenueTicket>,
  venueId: Scalars['uuid'],
  updatedBy: Scalars['uuid']
): TixVenueTicketInsertInput {
  return {
    description: venueTicket.description,
    totalSeats: venueTicket.totalSeats,
    name: venueTicket.name,
    venueTicketId: venueTicket.venueTicketId,
    venueId,
    updatedBy,
    state: 'ACTIVE',
    updatedAt: 'now()',
    Configurations: {
      data:
        venueTicket.Configurations?.map(config =>
          createVenueTicketConfigurationInput(
            config,
            venueTicket.venueTicketId,
            updatedBy
          )
        ) ?? []
    }
  };
}
