import { fadeInRight400ms } from '@tix/ui-common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tix-buyer-product-swiper',
  templateUrl: './buyer-product-swiper.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .mat-dialog-container,
      .mat-dialog-content {
        padding: 0px !important;
        margin: 0px !important;
        border-radius: 0.375rem !important;
      }
    `
  ],
  animations: [fadeInRight400ms]
})
export class BuyerProductSwiperComponent implements OnInit {
  //
  title = '';
  //
  images: string[] = [];
  idx = 0;

  constructor(
    public dialogRef: MatDialogRef<BuyerProductSwiperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.title = this.data.name;
    if (this.data?.EventProductMedia) {
      this.images = this.data.EventProductMedia.map(
        (addon: any) => addon.MediaFile.location
      );
    }
  }

  next() {
    if (this.idx === this.images.length - 1) this.idx = 0;
    else this.idx += 1;
  }

  previous() {
    if (this.idx === 0) this.idx = this.images.length - 1;
    else this.idx -= 1;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
