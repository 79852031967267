import { NgModule } from '@angular/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  GraphQLRequest,
  InMemoryCache
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'apps/purchasing-portal/src/environments/environment';

const https = environment.backendUrl; // <-- add the URL of the GraphQL server here
const wss = environment.wss; // <-- add the URL of the GraphQL server here
const secret = environment.hasuraSecret;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  // Create an http link:
  const headers = new HttpHeaders('x-hasura-admin-secret').append(
    'x-hasura-admin-secret',
    secret
  );

  const headersContext = setContext((operation: GraphQLRequest, context) => {
    return {
      headers: {
        'x-hasura-admin-secret': environment.hasuraSecret
      }
    };
  });

  const http = ApolloLink.from([
    headersContext,
    httpLink.create({ uri: https })
  ]);

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: wss,

    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          'x-hasura-admin-secret': secret
        }
      }
    }
    // webSocketImpl: ws,
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as any;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    http
  );

  return {
    link,
    cache: new InMemoryCache()
    // ... options
  };
}

@NgModule({
  providers: [
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class TixCoreApolloModule {}
