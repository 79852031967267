import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, navigation } from '@nrwl/angular';
import { UserSelector } from '@tix/auth/state';
import { TixCompanyPartialState } from '@tix/company/state';
import * as TixCompanySelectors from '@tix/company/state/selectors';
import {
  TixCreateRecurringEventsGQL,
  TixDeleteEventPerformersByPkGQL,
  TixEventCompanyVenue,
  TixGetEventAdminsByEventInstanceIdGQL,
  TixGetEventByEventInstanceIdGQL,
  TixGetEventsByCompanyIdGQL,
  TixGetTicketConfigDropdownListByVenueIdGQL,
  TixGetVenueDropdownListByCompanyIdGQL,
  TixInsertEventInstanceGQL,
  TixInsertEventPerformersGQL,
  TixUpdateEventInstanceByPkGQL,
  TixVenue,
  TixInsertEmailTemplatesOfEventGQL,
  TixVenueTicket
} from '@tix/data-access';
import { PayoutService } from '@tix/event-buyer/services';
import { EventProductsService } from '@tix/events/components';
import { TixEventAdminPage } from 'libs/events/pages/src/lib/event-admin/event-admin.component';
import * as moment from 'moment';
import { combineLatest, from, of } from 'rxjs';
import {
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';
import * as TixEventApiActions from '../actions/events-api.actions';
import * as TixEventsActions from '../actions/events.actions';
import { CommunicationsService } from 'libs/communications/components/src/lib/services/communications.service';

@Injectable()
export class EventsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.loadEvents),
      withLatestFrom(
        this.store.select(TixCompanySelectors.getSelectedCompanyId)
      ),
      fetch({
        run: (action, companyId) => {
          const { endDate, startDate, query, statuses, limit, offset } = action;
          const orExp = startDate &&
            endDate && [
              {
                filterDate: {
                  _gte: moment(startDate).format('YYYY-MM-DD'),
                  _lte: moment(endDate).format('YYYY-MM-DD')
                }
              }
            ];

          return this.getEventsByCompanyId
            .fetch(
              {
                companyId,
                eventName: query ? '%' + query + '%' : undefined,
                limit: limit,
                offset: offset,
                orExp,
                eventStates: statuses
              },
              { fetchPolicy: 'no-cache' }
            )
            .pipe(
              map(({ data }) => {
                const obj: {
                  [key: string]: TixEventCompanyVenue;
                } = {};
                for (const event of data.EventCompanyVenue) {
                  if (obj[event.eventInstanceId])
                    obj[event.eventInstanceId] = {
                      ...obj[event.eventInstanceId],
                      mediaLocation:
                        obj[event.eventInstanceId].mediaLocation ||
                        event.mediaLocation
                    };
                  obj[event.eventInstanceId] =
                    event as unknown as TixEventCompanyVenue;
                }
                return {
                  data: Object.values(obj),
                  total: data.EventCompanyVenueAggregate.aggregate?.count
                };
              }),
              map(({ data, total }) => {
                return TixEventApiActions.loadEventsSuccess({
                  events: data,
                  total: total || 0
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.loadEventsFailure({ error });
        }
      })
    )
  );

  loadEventDataByEventInstanceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.loadEventById),
      fetch({
        run: ({ eventInstanceId }) => {
          return this.getEventByEventInstanceId
            .fetch({ eventInstanceId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                if (!data.EventInstance && !data.EventInstance[0]) {
                  return TixEventApiActions.loadEventFailure({
                    error: 'No matching company found.'
                  });
                }

                return TixEventApiActions.loadEventSuccess({
                  event: data.EventInstance
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.loadEventFailure({ error });
        }
      })
    )
  );

  selectedEvent$ = createEffect(() =>
    this.actions$.pipe(
      navigation(TixEventAdminPage, {
        run: (route: ActivatedRouteSnapshot) => {
          const eventInstanceId: string = route.params['eventId'];
          if (eventInstanceId === 'add')
            return TixEventsActions.deselectEvent();
          this.store.dispatch(TixEventsActions.clearState());
          return TixEventsActions.loadEventById({ eventInstanceId });
        },
        onError: (route: ActivatedRouteSnapshot, error: any) => {
          console.error('There was a grave error...', error);
          // we can log and error here and return null
          // we can also navigate back
          return null;
        }
      })
    )
  );

  getVenueList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.getVenueList),
      withLatestFrom(
        this.store.select(TixCompanySelectors.getSelectedCompanyId)
      ),
      fetch({
        run: (action, companyId) => {
          return this.getVenueByCompanyId
            .fetch({ companyId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                return TixEventApiActions.getVenueListSuccess({
                  venues: data.Venue as TixVenue[]
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.getVenueListFailure({ error });
        }
      })
    )
  );

  getVenueTicketList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.getVenueTicketConfigurationList),
      fetch({
        run: ({ venueId }) => {
          return this.getVenueTicketsByVenueId
            .fetch({ venueId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) =>
                TixEventApiActions.getVenueTicketConfigurationListSuccess({
                  venueTickets: data.VenueTicket as TixVenueTicket[]
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.getVenueTicketConfigurationListFailure({
            error
          });
        }
      })
    )
  );

  addNewEventCompany = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.addNewEventInCompany),
      withLatestFrom(
        combineLatest([
          this.store.select(UserSelector.getAuthenticatedUser),
          this.store.select(TixCompanySelectors.getSelectedCompanyId)
        ])
      ),
      fetch({
        run: (
          { addEventObj, goToEventPage, products, addTemplateArr },
          [user, companyId]
        ) => {
          return this.addNewEventInCompany
            .mutate({
              objects: {
                ...addEventObj,
                updatedBy: user?.uid,
                updatedAt: 'now()'
              }
            })
            .pipe(
              switchMap(({ data }) => {
                console.log('from effect: ', products);
                const eventId =
                  data?.InsertEvent?.returning[0].eventInstances[0]
                    .eventInstanceId;
                return products?.length
                  ? this._eventProductsService
                      .cloneEventProduct(products, eventId)
                      .pipe(
                        take(1),
                        map(p => data)
                      )
                  : of(data);
              }),
              map(data => {
                console.log(
                  'products obj: ',
                  products,
                  'existing obj: ',
                  addEventObj,
                  'from response: ',
                  data
                );
                const updatedRecord = data?.InsertEvent?.returning[0];
                const eventInstance = updatedRecord?.eventInstances[0];
                if (!updatedRecord)
                  return TixEventApiActions.addNewEventInCompanyFailure({
                    error: 'Records not found!'
                  });
                const updatedEvent: TixEventCompanyVenue = {
                  __typename: 'EventCompanyVenue',
                  eventInstanceId:
                    updatedRecord?.eventInstances[0]?.eventInstanceId,
                  eventId: updatedRecord.eventId,
                  eventName: updatedRecord?.eventInstances[0]?.name,
                  mainEventName: updatedRecord?.name,
                  date: updatedRecord?.eventInstances[0]?.date,
                  startTime:
                    updatedRecord?.eventInstances[0]?.startTime || null,
                  doorsOpen:
                    updatedRecord?.eventInstances[0]?.doorsOpen || null,
                  venueName: updatedRecord?.eventInstances[0]?.venue?.name,
                  eventState: updatedRecord?.eventInstances[0]?.state
                };
                this.store.dispatch(
                  TixEventsActions.selectIdOfClonedEvent({
                    idofClonedEvent:
                      updatedRecord?.eventInstances[0]?.eventInstanceId
                  })
                );
                if (addTemplateArr) {
                  addTemplateArr = addTemplateArr.map(item => {
                    return {
                      eventInstanceId: eventInstance?.eventInstanceId,
                      emailContentId: item.emailContentId
                    };
                  });

                  this.communicationService
                    .insertEmailTemplatesOfEvent(addTemplateArr)
                    .then(res => {
                      console.log('res', res);
                    });
                }
                this.payoutService
                  .createPayout({
                    companyId: eventInstance?.venue?.companyId,
                    venueId: eventInstance?.venueId,
                    eventDate: eventInstance?.date,
                    eventName: eventInstance?.name || '',
                    eventInstanceId: eventInstance?.eventInstanceId,
                    eventStartTime: eventInstance?.startTime
                  })
                  .then(() => {
                    if (goToEventPage) {
                      window.location.href = `/company/${companyId}/events/${data.InsertEvent?.returning[0].eventInstances[0].eventInstanceId}`;
                    }
                  })
                  .catch(() => {
                    if (goToEventPage) {
                      window.location.href = `/company/${companyId}/events/${data.InsertEvent?.returning[0].eventInstances[0].eventInstanceId}`;
                    }
                  });

                return TixEventApiActions.addNewEventInCompanySuccess({
                  newRecord: updatedEvent
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.addNewEventInCompanyFailure({ error });
        }
      })
    )
  );

  addRecurringEventCompany = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.addRecurringEventInCompany),
      withLatestFrom(
        this.store.select(TixCompanySelectors.getSelectedCompanyId)
      ),
      fetch({
        run: ({ addRecurringEventObj }, companyId) => {
          return this.createRecurringEvents
            .mutate({
              data: {
                ...addRecurringEventObj,
                companyId: companyId as string
              }
            })
            .pipe(
              map(({ data }) => {
                return TixEventApiActions.addRecurringEventsSuccess();
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.addRecurringEventFailure({ error });
        }
      })
    )
  );

  addRecurringEventCompanySuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TixEventApiActions.addRecurringEventsSuccess),
        tap(e => {
          this.store.dispatch(TixEventsActions.loadEvents({}));
        })
      ),
    { dispatch: false }
  );

  insertPerformers = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.insertEventPerformers),
      fetch({
        run: ({ performers }) => {
          return this.insertNewPerformer.mutate({ objects: performers }).pipe(
            map(({ data }) => {
              const updatedPerformers = data?.InsertEventPerformers?.returning;
              return TixEventApiActions.insertEventPerformerSuccess({
                performers: updatedPerformers
              });
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.insertEventPerformerFailure({ error });
        }
      })
    )
  );

  updateEventInstanceInComapny = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.updateEventInCompany),
      withLatestFrom(
        combineLatest([this.store.select(UserSelector.getAuthenticatedUser)])
      ),
      fetch({
        run: ({ updatedObj }, [user]) => {
          return this.updateEventInstanceInCompany
            .mutate({ ...updatedObj, updatedAt: 'now()', updatedBy: user?.uid })
            .pipe(
              map(({ data }) => {
                const updatedRecord = data?.UpdateEventInstanceByPK;
                if (!updatedRecord)
                  return TixEventApiActions.addNewEventInCompanyFailure({
                    error: 'Records not found!'
                  });
                const updatedEvent: TixEventCompanyVenue = {
                  __typename: 'EventCompanyVenue',
                  eventInstanceId: updatedRecord?.eventInstanceId,
                  eventId: updatedRecord.eventId,
                  eventName: updatedRecord?.name,
                  mainEventName: updatedRecord?.name,
                  date: updatedRecord?.date,
                  startTime: updatedRecord?.startTime,
                  doorsOpen: updatedRecord?.doorsOpen,
                  venueName: updatedRecord?.venueId,
                  eventState: updatedRecord?.state
                };
                return TixEventApiActions.updateEventInstanceSuccess({
                  updatedRecord: updatedEvent
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TixEventApiActions.addNewEventInCompanyFailure({ error });
        }
      })
    )
  );

  /* #region Delete Effects */
  deleteCompanyMediaFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TixEventsActions.deleteEventPerformer),
      fetch({
        run: ({ performers }) => {
          return this.deletePerformerByPerforermerId
            .mutate({
              eventPerformerId: performers?.eventPerformerId
            })
            .pipe(
              map(({ data }) =>
                TixEventsActions.loadEventById({
                  eventInstanceId:
                    data?.DeleteEventPerformersByPK?.eventInstanceId
                })
              )
            );
        },
        onError: (action, error) => {
          console.error({ action, error });

          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return TixEventApiActions.deleteEventPerformerFailure({
            error
          });
        }
      })
    )
  );

  constructor(
    // start: clone product
    private _eventProductsService: EventProductsService,
    // end: clone product
    private readonly actions$: Actions,
    private readonly store: Store<TixCompanyPartialState>,
    private readonly getEventsByCompanyId: TixGetEventsByCompanyIdGQL,
    private readonly getEventByEventInstanceId: TixGetEventByEventInstanceIdGQL,
    private readonly getVenueByCompanyId: TixGetVenueDropdownListByCompanyIdGQL,
    private readonly getVenueTicketsByVenueId: TixGetTicketConfigDropdownListByVenueIdGQL,
    private readonly addNewEventInCompany: TixInsertEventInstanceGQL,
    private readonly createRecurringEvents: TixCreateRecurringEventsGQL,
    private readonly insertNewPerformer: TixInsertEventPerformersGQL,
    private readonly updateEventInstanceInCompany: TixUpdateEventInstanceByPkGQL,
    private readonly insertEventTemplateMutation: TixInsertEmailTemplatesOfEventGQL,
    private communicationService: CommunicationsService,
    private readonly deletePerformerByPerforermerId: TixDeleteEventPerformersByPkGQL,
    private payoutService: PayoutService,
    private getEventAdmins: TixGetEventAdminsByEventInstanceIdGQL
  ) {}
}
