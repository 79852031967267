import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TixBadgeComponent } from './badge.component';

@NgModule({
  declarations: [TixBadgeComponent],
  imports: [CommonModule],
  exports: [TixBadgeComponent],
  providers: []
})
export class TixBadgeModule {}
