<ng-container
  *ngIf="{
    loaded: loaded$ | async,
    loading: loading$ | async
  } as indicatorState"
>
  <tix-card class="confirm-card">
    <tix-card-header>
      <tix-card-title class="title title-primary">
        <h2>Clone Event</h2>
      </tix-card-title>
    </tix-card-header>
    <tix-card-content>
      <form
        *ngIf="!indicatorState.loaded && !indicatorState.loading"
        [formGroup]="cloneForm"
        class="profile-content-layout"
      >
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error>name is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option
              *ngFor="let status of statusList"
              [value]="status.name"
              >{{ status.name }}</mat-option
            >
          </mat-select>
          <mat-error>Status is required</mat-error>
        </mat-form-field>
      </form>
      <div
        class="dialog-message"
        *ngIf="!indicatorState.loaded && indicatorState.loading"
      >
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <div
        class="dialog-message"
        *ngIf="indicatorState.loaded && !indicatorState.loading"
      >
        <div>
          <tix-icon class="check-icon" svgIcon="check-circle-solid"></tix-icon>
        </div>
        <p class="text-green-500">Event has been cloned</p>
      </div>
    </tix-card-content>
    <tix-card-actions align="end">
      <ng-container
        *ngIf="
          !indicatorState.loaded && !indicatorState.loading;
          else takeMeThere
        "
      >
        <button
          tix-flat-border-button
          color="primary"
          class="mr-4"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          [disabled]="indicatorState.loading || cloneForm.invalid"
          tix-button
          (click)="onClone()"
          color="primary"
        >
          <mat-spinner
            *ngIf="indicatorState.loading"
            color="accent"
            diameter="24"
          ></mat-spinner
          >Clone
        </button>
      </ng-container>
      <ng-template #takeMeThere>
        <button
          tix-flat-border-button
          color="primary"
          class="mr-4"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button tix-button color="primary" (click)="navigate()">
          Take me there
        </button>
      </ng-template>
    </tix-card-actions>
  </tix-card>
</ng-container>
