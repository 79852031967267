import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TixVenueEffects } from './+state/effects/venues.effects';
import * as fromVenues from './+state/venues.reducer';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature(fromVenues.VENUES_FEATURE_KEY, fromVenues.reducer),
    EffectsModule.forFeature([TixVenueEffects])
  ]
})
export class TixVenuesStateModule {}
