import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tix-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class TixInfoDialogComponent implements OnInit {
  @Input() header: string;
  @Input() text: string;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  formattedText: string;

  constructor() {}

  ngOnInit(): void {
    this.formattedText = this.text.replace(/\n/g, '<br/>');
  }

  closeDialogHandler() {
    this.closeDialog.emit(true);
  }
}
