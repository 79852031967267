import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Maybe,
  TixPatronFIlteredEventsList,
  TixPatronFIlteredEventsListAggregateFields,
  TixVenue,
  TixVenueTicket
} from '@tix/data-access';
import * as BuyerActions from '../actions';
import { PortalEventList } from '@tix/event-buyer/services';

export interface EventState extends EntityState<TixPatronFIlteredEventsList> {
  orderItems: Maybe<PortalEventList>;
  totalCount?: Maybe<TixPatronFIlteredEventsListAggregateFields>;
  venueInfo?: Maybe<TixVenue>;
  calendarEventsDates?: Maybe<TixPatronFIlteredEventsList[]>;
  venueId: string;
  loadingEvents: boolean;
}

export function selectEventId(events: TixPatronFIlteredEventsList): string {
  return events.eventInstanceId;
}

export const BUYER_FEATURE_KEY = 'eventBuyer';

export interface TixBuyersPartialState {
  readonly [BUYER_FEATURE_KEY]: EventState;
}

export const eventBuyerAdapter: EntityAdapter<TixPatronFIlteredEventsList> =
  createEntityAdapter<TixPatronFIlteredEventsList>({
    selectId: selectEventId
  });
export const initialState: EventState = eventBuyerAdapter.getInitialState({
  // eventList: null,
  venueInfo: null,
  totalCount: null,
  orderItems: null,
  calendarEventsDates: [],
  venueId: '',
  loadingEvents: false
});

const buyerReducer = createReducer(
  initialState,

  on(BuyerActions.getVenueInfo, (state, { venueId }) => {
    return { ...state, venueId: venueId };
  }),
  on(BuyerActions.getVenueInfoSuccess, (state, { VenueInfo }) => {
    return { ...state, venueInfo: VenueInfo };
  }),
  on(BuyerActions.getVenueInfoFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(BuyerActions.AddQuantityTicket, (state, { orderItems }) => {
    console.log(`orderItems`, orderItems);
    const update: Update<TixPatronFIlteredEventsList> = {
      changes: {
        Tickets: [orderItems?.Tickets] as any
      },
      id: orderItems?.eventInstanceId ?? '-1'
    };
    return eventBuyerAdapter.updateOne(update, state);
  }),
  on(BuyerActions.saveOrderItems, (state, { orderItems }) => {
    return { ...state, orderItems: orderItems };
  }),
  on(BuyerActions.DeleteItemFromCart, (state, { orderItems }) => {
    return { ...state, orderItems: orderItems };
  }),
  on(
    BuyerActions.insertPaymentReservationTicket,
    (state, { paymentReservationObj }) => ({
      ...state,
      paymentData: paymentReservationObj,
      loaded: false,
      error: null
    })
  ),
  on(BuyerActions.AddQuantityTicketFailure, (state, { error }) => ({
    ...state,
    error
  }))
);

export function reducer(state: EventState | undefined, action: Action) {
  return buyerReducer(state, action);
}
