import * as _ from 'lodash';

type TObject = Record<string, any>;
type TArray = Record<string, any>[];

export class HelperArrayService {
  addElement(state: TArray, toInsert: TObject, orderBy?: string[]) {
    const newState = [...state, toInsert];
    return !orderBy?.length ? newState : this.oderBy(newState, orderBy);
  }

  updateElement(
    state: TArray,
    toUpdate: TObject,
    primaryKey: string,
    orderBy?: string[]
  ): TArray {
    const index = state.findIndex(
      element => element[primaryKey] === toUpdate[primaryKey]
    );
    state[index] = toUpdate;
    return !orderBy?.length ? state : this.oderBy(state, orderBy);
  }

  deleteElement(
    state: TArray,
    toDelete: string,
    primaryKey: string,
    orderBy?: string[]
  ): TArray {
    const filtered = state.filter(element => element[primaryKey] !== toDelete);
    return !orderBy?.length ? filtered : this.oderBy(filtered, orderBy);
  }

  oderBy(state: TObject | TArray, props: string[]): TArray {
    const manyAsc: Array<boolean | 'asc' | 'desc'> = props.map(() => 'asc');
    return _.orderBy(state, props, manyAsc);
  }

  pullAllBy(partial: TArray, full: TArray, _prop: string) {
    return _.pullAllBy(partial, full, _prop);
  }

  isEqual<T>(array1: T[], array2: T[]) {
    return _.isEqual(array1, array2);
  }

  omit(obj: Record<string, any>, omitted: string[]) {
    return _.omit(obj, omitted);
  }
}
