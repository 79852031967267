import { Injectable } from '@angular/core';
import {
  TixGetVenueByPrimaryKeyGQL,
  TixGetVenueByPrimaryKeyQuery
} from '@tix/data-access';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export type PortalVenueInfo = NonNullable<
  TixGetVenueByPrimaryKeyQuery['VenueByPK']
>;
export type PortalVenueAddress = PortalVenueInfo['addresses'][0];

@Injectable({
  providedIn: 'root'
})
export class TixPortalService {
  constructor(private getVenueInfoQuery: TixGetVenueByPrimaryKeyGQL) {}

  getVenueInfo(venueId: string): Observable<PortalVenueInfo> {
    return this.getVenueInfoQuery
      .fetch({
        venueId
      })
      .pipe(
        map(res => {
          if (!res.data.VenueByPK) throw new Error('Could not find the venue');

          return res.data.VenueByPK;
        })
      );
  }
}
