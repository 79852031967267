import { Injectable } from '@angular/core';
import {
  OrderQuantityCalculatorService,
  QuantityItem
} from './order-quantity-calculator.service';
import {
  HoldItem,
  HoldProduct,
  HoldTicket,
  OrderHoldService
} from '@tix/order-hold';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const ORDER_STORAGE_KEY = 'generic-hold-order';

export type OrderSelectionItem = QuantityItem & {
  startSellingTime?: string;
  stopSellingTime?: string;
  startSellingDate?: string;
  stopSellingDate?: string;

  type: string;
  minPerOrder?: number;
  maxPerOrder?: number;
};

const TICKET_AVAILABILITY_REFRESH_RATE_IN_SECONDS = 15;

export type OrderItemInput = Pick<
  OrderSelectionItem,
  | 'id'
  | 'type'
  | 'totalQuantity'
  | 'quantitySold'
  | 'isPoolParent'
  | 'pool'
  | 'minPerOrder'
  | 'maxPerOrder'
  | 'startSellingTime'
  | 'startSellingDate'
  | 'stopSellingDate'
  | 'stopSellingTime'
>;

@Injectable()
export class OrderStateManagerService {
  constructor(
    private quantityCalculator: OrderQuantityCalculatorService,
    private orderHoldService: OrderHoldService
  ) {}

  protected orderItems: OrderSelectionItem[] = [];
  availability: { itemId: string; quantityRemaining: number }[];

  orderSelectionInitialized = false;
  holdTrackerInterval: number;

  private orderChangedBS = new Subject<OrderSelectionItem[]>();
  readonly orderChanged$ = this.orderChangedBS
    .asObservable()
    .pipe(debounceTime(800));

  async persistOrder() {
    const toSave = JSON.stringify(this.getOrderItems());
    sessionStorage.setItem(ORDER_STORAGE_KEY, toSave);
  }

  async loadPersistedOrder() {
    const saved = sessionStorage.getItem(ORDER_STORAGE_KEY);
    if (!saved) return;

    try {
      const items = JSON.parse(saved) as OrderSelectionItem[];

      for (const item of items) {
        const existingIndex = this.orderItems.findIndex(i => i.id === item.id);
        if (existingIndex === -1) this.orderItems.push(item);
        else this.orderItems[existingIndex] = item;
      }
    } catch (e) {
      console.error(e);
    }

    this.updateOrderOnHold();
  }

  clearPersistedOrder() {
    sessionStorage.removeItem(ORDER_STORAGE_KEY);
  }

  async addOrderItems(items: OrderItemInput[]) {
    console.log('Adding items', items);
    const existingItems: OrderItemInput[] = [...this.getOrderItems()];

    for (const item of items) {
      const existingIndex = existingItems.findIndex(i => i.id === item.id);

      if (existingIndex === -1) existingItems.push(item);
      else existingItems[existingIndex] = item;
    }

    await this.setOrderItems(existingItems);
  }

  async setOrderItems(items: OrderItemInput[]) {
    const newItems = [...this.orderItems];
    for (const item of items) {
      const existingIndex = newItems.findIndex(i => i.id === item.id);

      if (existingIndex === -1)
        newItems.push({
          ...item,
          quantityOnHold: 0,
          quantitySelected: 0
        });
      else
        newItems[existingIndex] = {
          ...newItems[existingIndex],
          ...item
        };
    }

    this.orderItems = newItems;

    await this.loadOrderFromHold();
    await this.fetchHoldInformation();

    this.updateAvailability();
    this.orderSelectionInitialized = true;
  }

  getOrderItems() {
    return this.orderItems;
  }

  updateOrderItemAmounts(
    itemId: string,
    quantitySold: number,
    totalQuantity: number
  ) {
    const item = this.getOrderItem(itemId);

    item.quantitySold = quantitySold;
    item.totalQuantity = totalQuantity;

    if (item.pool) {
      if (item.isPoolParent) {
        item.pool.quantity = totalQuantity;
      }

      const poolTickets = this.orderItems.filter(
        i => item.pool?.id === i.pool?.id
      );
      item.pool.quantitySold = poolTickets.reduce(
        (total, item) => total + item.quantitySold,
        0
      );
      item.pool.quantity =
        poolTickets.find(i => i.isPoolParent)?.totalQuantity ?? 0;
    }
  }

  private getOrderItem(id: string): OrderSelectionItem {
    const item = this.orderItems?.find(item => item.id === id);
    if (!item) throw new Error('Order item not found');

    return item;
  }

  updateAvailability() {
    this.availability = this.quantityCalculator.getAmountsRemainingForOrder({
      items: this.orderItems
    });
  }

  canIncrementQuantity(itemId: string): boolean {
    const item = this.getOrderItem(itemId);

    const minPerOrder = item.minPerOrder ?? 0;
    const maxPerOrder = item.maxPerOrder ?? Infinity;
    const remaining =
      this.availability?.find(item => item.itemId === itemId)
        ?.quantityRemaining ?? 0;

    if (remaining < minPerOrder) return false;

    if (item.quantitySelected >= maxPerOrder) return false;

    return item.quantitySelected < remaining;
  }
  canDecrementQuantity(itemId: string): boolean {
    const item = this.getOrderItem(itemId);

    return item.quantitySelected !== 0;
  }

  incrementQuantity(itemId: string) {
    if (!this.canIncrementQuantity(itemId)) return;

    const item = this.getOrderItem(itemId);

    const minPerOrder = item.minPerOrder ?? 0;
    if (item.quantitySelected === 0) {
      // Check the min per order
      if (minPerOrder > 1) item.quantitySelected = minPerOrder;
      else item.quantitySelected = 1;

      return;
    }

    item.quantitySelected++;
  }
  decrementQuantity(itemId: string) {
    if (!this.canDecrementQuantity(itemId)) return;

    const item = this.getOrderItem(itemId);
    const minPerOrder = item.minPerOrder ?? 0;

    if (item.quantitySelected <= minPerOrder) item.quantitySelected = 0;
    else item.quantitySelected--;

    this.orderChangedBS.next(this.orderItems);
  }

  resetSelection() {
    this.removeOrderFromHold();

    this.orderItems.forEach(item => (item.quantitySelected = 0));
    this.orderChangedBS.next(this.orderItems);

    this.orderChangedBS.next(this.getOrderItems());
  }

  resetToAvailable() {
    let orderChanged = false;
    this.orderItems.forEach(item => {
      const oldQuantity = item.quantitySelected;
      const available = this.getQuantityAvailableForItem(item.id);
      if (available < (item.minPerOrder ?? 0)) item.quantitySelected = 0;
      else item.quantitySelected = Math.min(available, item.quantitySelected);

      // To handle changes in tickets in the same pool
      this.updateAvailability();

      if (oldQuantity !== item.quantitySelected) orderChanged = true;
    });

    if (orderChanged) this.orderChangedBS.next(this.orderItems);
  }

  getQuantitySelectedForItem(itemId: string): number {
    return this.getOrderItem(itemId).quantitySelected;
  }
  getQuantityAvailableForItem(itemId: string): number {
    if (this.availability == undefined) {
      return -1;
    }
    return Math.max(
      0,
      this.availability?.find(item => item.itemId === itemId)
        ?.quantityRemaining ?? 0
    );
  }
  getMaxSelectableForItem(itemId: string): number {
    const item = this.getOrderItem(itemId);
    const available = this.getQuantityAvailableForItem(itemId);

    return Math.min(item.maxPerOrder ?? Infinity, available);
  }

  async fetchHoldInformation() {
    const hold = await this.orderHoldService.getExternalItemsInHold(
      this.orderItems.map(item => item.id)
    );

    for (const holdItem of hold) {
      const orderItem = this.getOrderItem(holdItem.holdItemId);
      orderItem.quantityOnHold = holdItem.quantity;

      if (orderItem.pool) {
        // This adds the pool children's quantity on hold
        orderItem.pool.quantityOnHold = hold
          .filter(item =>
            orderItem.pool?.childrenItemIds.includes(item.holdItemId)
          )
          .reduce((total, curr) => total + curr.quantity, 0);

        // This adds the pool parent's quantity on hold
        const poolParent = this.orderItems.find(
          item => item.isPoolParent && item.pool?.id === orderItem.pool?.id
        );
        orderItem.pool.quantityOnHold +=
          hold.find(item => item.holdItemId === poolParent?.id)?.quantity ?? 0;
      }
    }
  }
  async loadOrderFromHold() {
    // const hold = await this.orderHoldService.getMyHoldOrder();
    // if (hold) {
    //   for (const item of hold.items) {
    //     const orderItem = this.getOrderItem(item.id);
    //     orderItem.quantitySelected = item.quantity;
    //   }
    //   this.orderChangedBS.next(this.orderItems);
    // }
  }
  async removeOrderFromHold() {
    await this.orderHoldService.deleteMyHoldOrder();
  }
  async updateOrderOnHold() {
    await this.removeOrderFromHold();

    const items: HoldItem[] = this.orderItems.map(item => {
      if (item.type === 'ticket')
        return new HoldTicket(item.id, item.quantitySelected);
      if (item.type === 'product')
        return new HoldProduct(item.id, item.quantitySelected);

      return new HoldItem(item.id, item.quantitySelected, item.type);
    });
    await this.orderHoldService.setMyHoldOrder(items);
  }

  async startTrackingHold(hook?: Function) {
    if (this.holdTrackerInterval) clearInterval(this.holdTrackerInterval);
    await this.fetchHoldInformation();
    if (hook) {
      await hook();
    }
    this.updateAvailability();
    this.resetToAvailable();

    this.holdTrackerInterval = window.setInterval(async () => {
      await this.fetchHoldInformation();
      if (hook) {
        await hook();
      }
      this.updateAvailability();
      this.resetToAvailable();
    }, TICKET_AVAILABILITY_REFRESH_RATE_IN_SECONDS * 1000);
  }

  stopTrackingHold() {
    clearInterval(this.holdTrackerInterval);
  }
}
