import { AbstractControl } from '@angular/forms';

export function minMaxMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const perOrderMax = c.get('perOrderMax');
  const perOrderMin = c.get('perOrderMin');
  const quantity = c.get('quantity');

  //console.log(parent);
  //if(parent.parentTicketId?.value)

  let perOrderMaxValue, perOrderMinValue;

  try {
    perOrderMaxValue = parseInt(perOrderMax?.value);
    perOrderMinValue = parseInt(perOrderMin?.value);
  } catch {
    return { invalid: true };
  }

  if (perOrderMaxValue < perOrderMinValue) {
    perOrderMax?.setErrors({ perOrderMax: true });
    perOrderMin?.setErrors({ perOrderMin: true });
    return { perOrderMax: true, perOrderMin: true };
  }

  perOrderMax?.setErrors(null);
  perOrderMin?.setErrors(null);

  return null;
}
