import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  routerReducer,
  RouterState,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TixAuthModule } from '@tix/auth/module';
import { TixCoreApolloModule } from '@tix/core/apollo';
import { TixLayoutModule } from '@tix/layout';
import { TixButtonModule } from '@tix/shared/ui/components';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestLoggedInComponent } from './test-logged-in/test-logged-in.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { QuillModule } from 'ngx-quill';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS
} from '@angular/material/form-field';
import { AddonsInterceptor } from '@tix/data-access-addons';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [debug];
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    }
  }
};

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddonsInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always'
      } as MatFormFieldDefaultOptions
    }
  ],

  declarations: [AppComponent, TestLoggedInComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TixCoreApolloModule,
    TixLayoutModule,
    NotifierModule.withConfig(customNotifierOptions),
    TixAuthModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {
        router: routerReducer
      },
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: false
        }
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      // This is required for triggering the `@nrwl/angular` `navigation()` function within NgRx Effects
      // serializer: DefaultRouterStateSerializer
      routerState: RouterState.Full
    }),
    provideFirebaseApp(() =>
      initializeApp({
        apiKey: 'AIzaSyA2ooB72MnsUZcWPyvKT5fQaoJZ5vJ7gCI',
        authDomain: 'tickets-8ff85.firebaseapp.com',
        projectId: 'tickets-8ff85',
        storageBucket: 'tickets-8ff85.appspot.com',
        messagingSenderId: '997702436797',
        appId: '1:997702436797:web:3f5350646b8b2e52fd74d4',
        measurementId: 'G-DR8V8ME4VB'
      })
    ),
    TixButtonModule,
    QuillModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
