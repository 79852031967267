import { TixConfirmDialogConfig } from './confirm-dialog.config';

export const tixConfirmDialogDefaultConfig: TixConfirmDialogConfig = {
  title: 'Confirm',
  confirmText: 'Are you sure you want to perform this action?',
  titleTheme: 'primary',
  cancelButton: {
    label: 'Cancel',
    theme: 'primary',
    visible: true
  },
  confirmButton: {
    label: 'Confirm',
    theme: 'primary',
    visible: true
  }
};

export const tixConfirmDialogDangerConfig: TixConfirmDialogConfig = {
  icon: 'exclamation',
  title: 'Confirm',
  confirmText: 'Are you sure you want to perform this action?',
  titleTheme: 'danger',
  cancelButton: {
    label: 'Cancel',
    theme: 'danger'
  },
  confirmButton: {
    label: 'Confirm',
    theme: 'danger'
  }
};
