import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { BtnLoadingDirectiveModule } from '../../btn-loading/btn-loading.directive';
import { TixButtonModule } from '../../button';
import { TixCardModule } from '../../card';
import { TixDialogModule } from '../../dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TixIconModule } from '../../icon';
import { TixInputModule } from '../../input';
import { MatSelectModule } from '@angular/material/select';

import { TixSocialMediaComponent } from './social-media.component';

@NgModule({
  declarations: [TixSocialMediaComponent],
  imports: [
    CommonModule,
    TixCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TixInputModule,
    TixIconModule,
    TixButtonModule,
    MatSelectModule,
    TextMaskModule,
    TixDialogModule,
    BtnLoadingDirectiveModule
  ],
  exports: [TixSocialMediaComponent],
  providers: []
})
export class TixSocialMediasAdminModule {}
