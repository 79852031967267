import { NgModule } from '@angular/core';
import { TixEmptyStateComponent } from './empty-state.component';
import { CommonModule } from '@angular/common';
import { HeroCalendar, TixIconModule, TixIconRegistry } from '../icon';

@NgModule({
  declarations: [TixEmptyStateComponent],
  exports: [TixEmptyStateComponent],
  imports: [CommonModule, TixIconModule],
  providers: []
})
export class TixEmptyStateModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([HeroCalendar]);
  }
}
