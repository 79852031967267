import { Injectable } from '@angular/core';
import {
  TixGetPerformerSearchByNameGQL,
  TixGetSlugByEventInstanceIdGQL,
  TixUuidComparisonExp,
  TixVenueListItemFragment
} from '@tix/data-access';
import {
  TixFullVenue,
  TixVenueListElement,
  TixVenuesService
} from 'libs/venues/state/src/lib/venues.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private readonly getPerformerSearchByName: TixGetPerformerSearchByNameGQL,
    private readonly getSlugForEvent: TixGetSlugByEventInstanceIdGQL,
    private readonly venueService: TixVenuesService
  ) {}

  getEventSlugForEvent(eventInstanceId: string) {
    return this.getSlugForEvent
      .fetch(
        {
          eventInstanceId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(map(response => response.data.EventInstance[0]));
  }

  getSalesPageURL(
    eventInstanceId: string,
    eventSlug: string | null,
    venue: TixFullVenue | TixVenueListElement,
    onEventPage = false
  ) {
    if (!eventSlug || !venue.slug) {
      return `${window.location.origin}${this.venueService.getVenueRoute(
        venue
      )}?searchEvent=${eventInstanceId}&open=${eventInstanceId}`;
    }
    if (!onEventPage)
      return `${window.location.origin}/${venue.slug}/${eventSlug}`;

    return `${window.location.origin}/${venue.slug}/${eventSlug}?selectedEvent=${eventInstanceId}`;
  }

  getPerformerListByName(companyId: TixUuidComparisonExp, name?: string) {
    return this.getPerformerSearchByName
      .fetch({ companyId: companyId, name: `%${name ? name : ''}%` })
      .pipe(
        map(({ data }) => {
          return data.Performer;
        })
      );
  }
}
