<div class="breadcrumbs-wrapper" [ngClass]="{ expanded: expanded }">
  <div #itemsContainer class="breadcrumbs-items">
    <div
      class="breadcrumb-item"
      *ngFor="let item of breadcrumbs; let first = first; let last = last"
    >
      <tix-icon *ngIf="!first" svgIcon="chevron-right"></tix-icon>
      <a *ngIf="item.label" [routerLink]="item.routerLink">
        <!--tix-flat-button [color]="last ? 'primary' : 'dark'"-->
        <tix-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></tix-icon
        >{{ item.label }}</a
      >
      <a
        *ngIf="!item.label && item.svgIcon"
        tix-icon-button
        [color]="last ? 'primary' : 'dark'"
        [routerLink]="item.routerLink"
        ><tix-icon [svgIcon]="item.svgIcon"></tix-icon
      ></a>
    </div>
  </div>

  <!--  <div class="breadcrumbs-expansion">-->
  <!--    <button class="expand-icon-button" (click)="toggleExpand()"><tix-icon svgIcon="chevron-double-down"></tix-icon></button>-->
  <!--  </div>-->
</div>
