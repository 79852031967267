import { Injectable } from '@angular/core';
import {
  TixGetEventCalendarDatesGQL,
  TixGetEventCalendarDatesQuery
} from '@tix/data-access';
import { PortalMonthCalendarFilter } from './PortalCalendarFilter';
import { map } from 'rxjs/operators';

export type TixCalendarDates =
  TixGetEventCalendarDatesQuery['calendarEventDates'];

@Injectable({
  providedIn: 'root'
})
export class PortalCalendarService {
  constructor(private getCalendarDatesQuery: TixGetEventCalendarDatesGQL) {}

  getCalendarDatesForFilter(
    venueId: string,
    filter: PortalMonthCalendarFilter
  ): Promise<TixCalendarDates> {
    return this.getCalendarDatesQuery
      .fetch({
        venueId,
        monthStartDate: filter.startDate,
        monthEndDate: filter.endDate
      })
      .pipe(map(res => res.data.calendarEventDates))
      .toPromise();
  }
}
