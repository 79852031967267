import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

@Component({
  selector: 'tix-event-multi-dates',
  templateUrl: './event-multi-dates.component.html',
  styleUrls: ['./event-multi-dates.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EventMultiDatesComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;

  subscription: Subscription;

  constructor() {}

  get timesFormArray() {
    return this.formGroup.get('instanceTime') as FormArray;
  }

  get dayTimesFormArray() {
    return this.formGroup.get('dayTimes') as FormArray;
  }

  ngOnInit(): void {
    this.subscription =
      this.formGroup.controls.startDate.valueChanges.subscribe(() =>
        this.initiateTimesFields()
      );
    this.subscription.add(
      this.formGroup.controls.endDate.valueChanges.subscribe(() =>
        this.initiateTimesFields()
      )
    );
    this.subscription.add(
      this.formGroup.controls.repeatsOnDay.valueChanges.subscribe(() =>
        this.initiateTimesFields()
      )
    );
    this.subscription.add(
      this.formGroup.controls.dayTimes.valueChanges.subscribe(() =>
        this.initiateTimesFields()
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initiateTimesFields() {
    this.timesFormArray.clear();

    let startDate = this.formGroup.controls.startDate.value;
    let endDate = this.formGroup.controls.endDate.value;
    const repeatsOnDay = this.formGroup.controls.repeatsOnDay.value;

    if (!(startDate && endDate && repeatsOnDay?.length)) {
      return;
    }

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const datesInBetween = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Check if current date weekday is in repeatsOnDay
      if (
        repeatsOnDay.includes(this.mapToStringWeekday(currentDate.getDay()))
      ) {
        datesInBetween.push(new Date(currentDate));
      }

      currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000);
    }

    datesInBetween.forEach(date =>
      this.formGroup.value.dayTimes.forEach((dayTimes: any) =>
        this.timesFormArray.push(
          new FormGroup({
            doorsOpen: new FormControl(dayTimes.doorsOpen),
            startTime: new FormControl(dayTimes.startTime),
            endTime: new FormControl(dayTimes.endTime),
            date: new FormControl(date)
          })
        )
      )
    );
  }

  removeDayTimes(index: number) {
    this.dayTimesFormArray.removeAt(index);

    if (this.dayTimesFormArray.length === 0) {
      this.addDayTimes();
    }
  }

  addDayTimes() {
    this.dayTimesFormArray.push(
      new FormGroup({
        startTime: new FormControl(''),
        endTime: new FormControl(''),
        doorsOpen: new FormControl('')
      })
    );
  }

  mapToStringWeekday(day: number) {
    return weekdays[day];
  }

  handleSelectionChange($event: string[] | string) {
    this.formGroup.controls.repeatsOnDay.setValue(
      ($event as string[]).map(day => day.toLowerCase())
    );
  }

  onStartTimeUpdate(event: any, groupIndex: number) {
    const group = this.dayTimesFormArray.controls[groupIndex] as FormGroup;
    const value = event.target.value;

    try {
      let hours = parseInt(value.split(':')[0]);
      const minutes = parseInt(value.split(':')[1]);
      if (hours === 0) hours = 23;
      else hours--;

      group.controls['doorsOpen'].setValue(
        `${this.format2Digit(hours)}:${this.format2Digit(minutes)}`
      );
    } catch (e) {
      console.error(e);
    }
  }

  format2Digit(n: number) {
    return ('0' + n.toString()).slice(-2);
  }
}
