import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { CanColor, mixinColor, mixinDisabled } from '../core';

/**
 * List of classes to add to TixButton instances based on host attributes to
 * style as different variants.
 */
const BUTTON_HOST_ATTRIBUTES = [
  'tix-button',
  'tix-flat-button',
  'tix-flat-border-button',
  'tix-icon-button'
];

// Boilerplate for applying mixins to TixButton.
const TixButtonBase = mixinDisabled(
  mixinColor(
    class {
      constructor(public elementRef: ElementRef) {}
    }
  )
);

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector:
    'button[tix-button], button[tix-flat-button], button[tix-flat-border-button], button[tix-icon-button]',
  exportAs: 'tixButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disabled', 'color', 'rounding'],
  host: {
    '[attr.disabled]': 'disabled || null',
    '[class.tix-button-disabled]': 'disabled',
    '[class]': '"tix-rounded-" + (rounding || "md")'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixButtonComponent extends TixButtonBase implements CanColor {
  static ngAcceptInputType_disabled: BooleanInput;

  constructor(elementRef: ElementRef) {
    super(elementRef);

    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding class.
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        (this.getHostElement() as HTMLElement).classList.add(attr);
      }
    }

    // Add a class that applies to all buttons. This makes it easier to target if somebody
    // wants to target all buttons. We do it here rather than `host` to ensure that
    // the class is applied to derived classes.
    elementRef.nativeElement.classList.add('tix-button-base');
  }

  getHostElement() {
    return this.elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes. */
  hasHostAttributes(...attributes: string[]) {
    return attributes.some(attribute =>
      this.getHostElement().hasAttribute(attribute)
    );
  }
}

/**
 * Anchor button.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `a[tix-button], a[tix-flat-button], a[tix-flat-border-button], a[tix-icon-button]`,
  exportAs: 'tixButton, tixAnchor',
  host: {
    // Note that we ignore the user-specified tabindex when it's disabled for
    // consistency with the `tix-button` applied on native buttons where even
    // though they have an index, they're not tabbable.
    '[attr.tabindex]': 'disabled ? -1 : (tabIndex || 0)',
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled.toString()',
    '(click)': 'haltDisabledEvents($event)',
    '[class.tix-button-disabled]': 'disabled'
  },
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disabled', 'color', 'roundness'],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixAnchorComponent extends TixButtonComponent {
  /** Tabindex of the button. */
  @Input() tabIndex: number;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  haltDisabledEvents(event: Event) {
    // A disabled button shouldn't apply any actions
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
