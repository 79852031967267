import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinErrorState
} from '../core';
import { TixFormFieldControl } from '../form-field';
import { TIX_INPUT_VALUE_ACCESSOR } from './input-value-accessor';

let nextUniqueId = 0;

// Invalid input type. Using one of these will throw an error.
const TIX_INPUT_INVALID_TYPES = [
  'button',
  'checkbox',
  'file',
  'hidden',
  'image',
  'radio',
  'range',
  'reset',
  'submit'
];

const TixInputBase = mixinErrorState(
  class {
    constructor(
      public defaultErrorStateMatcher: ErrorStateMatcher,
      public parentForm: NgForm,
      public parentFormGroup: FormGroupDirective,
      public ngControl: NgControl
    ) {}
  }
);

@Directive({
  selector: `input[tixInput], textarea[tixInput]`,
  exportAs: 'tixInput',
  host: {
    class: 'tix-input-element',
    '[attr.id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.readonly]': 'readonly || null',
    // Only mark the input as invalid for assistive technology if it has a value since the
    // state usually overlaps with `aria-required` when the input is empty and can be redundant.
    '[attr.aria-invalid]': '(empty && required) ? null : errorState',
    '[attr.aria-required]': 'required'
  },
  providers: [{ provide: TixFormFieldControl, useExisting: TixInput }]
})
export class TixInput
  extends TixInputBase
  implements
    TixFormFieldControl<any>,
    OnChanges,
    OnDestroy,
    AfterViewInit,
    DoCheck,
    CanUpdateErrorState
{
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_readonly: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;
  // Accept `any` to avoid conflicts with other directives on `<input>` that may accept different types.
  static ngAcceptInputType_value: any;

  protected uid = `tix-input-${nextUniqueId++}`;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input()
  get id(): string {
    return this.inputId;
  }
  set id(value: string) {
    this.inputId = value || this.uid;
  }
  protected inputId: string = this.uid;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this.isDisabled;
  }
  set disabled(value: boolean) {
    this.isDisabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected isDisabled = false;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input()
  get required(): boolean {
    return this.isRequired;
  }
  set required(value: boolean) {
    this.isRequired = coerceBooleanProperty(value);
  }
  protected isRequired = false;

  /** Whether the element is readonly. */
  @Input()
  get readonly(): boolean {
    return this.isReadonly;
  }
  set readonly(value: boolean) {
    this.isReadonly = coerceBooleanProperty(value);
  }
  private isReadonly = false;

  protected previousNativeValue: any;
  private inputValueAccessor: { value: any };

  /** Whether the component is a textarea. */
  readonly isTextarea: boolean;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  focused = false;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  readonly stateChanges: Subject<void> = new Subject<void>();

  /**
   * Implemented as part of TixFormFieldControl.
   */
  controlType = 'tix-input';

  /**
   * Implemented as part of TixFormFieldControl.
   */
  autofilled = false;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input() placeholder: string;

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this.inputType;
  }
  set type(value: string) {
    this.inputType = value || 'text';
    this.validateType();

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this.isTextarea && getSupportedInputTypes().has(this.inputType)) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this.inputType;
    }
  }
  protected inputType = 'text';

  /** An object used to control when error messages are shown. */
  @Input() errorStateMatcher: ErrorStateMatcher;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input('aria-describedby') ariaDescribedby: string;

  /**
   * Implemented as part of TixFormFieldControl.
   */
  @Input()
  get value(): string {
    return this.inputValueAccessor.value;
  }
  set value(value: string) {
    if (value !== this.value) {
      this.inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  protected neverEmptyInputTypes = [
    'date',
    'datetime',
    'datetime-local',
    'month',
    'time',
    'week'
  ].filter(t => getSupportedInputTypes().has(t));

  constructor(
    protected elementRef: ElementRef<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    defaultErrorStateMatcher: ErrorStateMatcher,
    protected platform: Platform,
    private autofillMonitor: AutofillMonitor,
    @Optional()
    @Self()
    @Inject(TIX_INPUT_VALUE_ACCESSOR)
    inputValueAccessor: any
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

    const element = this.elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    // If no input value accessor was explicitly specified, use the element as the input value
    // accessor.
    this.inputValueAccessor = inputValueAccessor || element;

    this.previousNativeValue = this.value;

    this.isTextarea = nodeName === 'textarea';
  }

  ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.autofillMonitor
        .monitor(this.elementRef.nativeElement)
        .subscribe(event => {
          this.autofilled = event.isAutofilled;
          this.stateChanges.next();
        });
    }
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();

    if (this.platform.isBrowser) {
      this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }

    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this.dirtyCheckNativeValue();
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected dirtyCheckNativeValue() {
    const newValue = this.elementRef.nativeElement.value;

    if (this.previousNativeValue !== newValue) {
      this.previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  /** Checks whether the input type is one of the types that are never empty. */
  protected isNeverEmpty() {
    return this.neverEmptyInputTypes.indexOf(this.type) > -1;
  }

  /** Checks whether the input is invalid based on the native validation. */
  protected isBadInput() {
    // The `validity` property won't be present on platform-server.
    const validity = (this.elementRef.nativeElement as HTMLInputElement)
      .validity;
    return validity && validity.badInput;
  }

  /**
   * Implemented as part of TixFormFieldControl.
   */
  get empty(): boolean {
    return (
      !this.isNeverEmpty() &&
      !this.elementRef.nativeElement.value &&
      !this.isBadInput() &&
      !this.autofilled
    );
  }

  /**
   * Implemented as part of TixFormFieldControl.
   */
  setDescribedByIds(ids: string[]) {
    if (ids.length) {
      this.elementRef.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' ')
      );
    } else {
      this.elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  /**
   * Implemented as part of TixFormFieldControl.
   */
  onContainerClick() {
    // Do not re-focus the input element if the element is already focused. Otherwise it can happen
    // that someone clicks on a time input and the cursor resets to the "hours" field while the
    // "minutes" field was actually clicked. See: https://github.com/angular/components/issues/12849
    if (!this.focused) {
      this.focus();
    }
  }

  /** Make sure the input is a supported type. */
  protected validateType() {
    if (TIX_INPUT_INVALID_TYPES.indexOf(this.type) > -1) {
      throw `Unsupported input type: ${this.type}`;
    }
  }
}
