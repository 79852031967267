import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixBusinessCodes,
  TixPhoneNumber
} from '@tix/data-access';
import { TextMaskConfig } from 'angular2-text-mask';
import { TixCheckboxChange } from '../../checkbox';
import { TixConfirmDialogService } from '../../dialog';

@Component({
  selector: 'tix-phone-numbers-edit',
  templateUrl: './phone-numbers.component.html',
  styleUrls: ['./phone-numbers.component.scss']
})
export class TixPhoneNumbersComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private confirmDialogService: TixConfirmDialogService) {}

  @Input() phoneNumbers: Maybe<TixPhoneNumber>[] | null | undefined;
  @Input() phoneTypesBusinessCodeGroup: Maybe<
    Pick<TixBusinessCodeGroup, 'description' | 'name' | 'busCodeGroupId'> & {
      businessCodes: Pick<TixBusinessCodes, 'name' | 'description'>[];
    }
  >;
  @Input() sameAsCompany: Maybe<boolean> = undefined;
  @Input() companyPhoneNumbers: Maybe<TixPhoneNumber[]>;
  @Input() isloading?: boolean | undefined | null;
  @Input() showHeader: boolean = true;

  sameAsCompanyChanged = false;

  @Output() sameAsCompanyChange = new EventEmitter<TixCheckboxChange>();
  @Output() save: EventEmitter<TixPhoneNumber[]> = new EventEmitter<
    TixPhoneNumber[]
  >();
  @Output() delete = new EventEmitter<TixPhoneNumber>();
  @Output() skipEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() showSkipButton = false;
  @Input() pageName:
    | 'registration'
    | 'companyDetails'
    | 'staffScreen'
    | 'venueDetails';

  @Input() keepDisableSaveBtn = false;

  @Input() emitNewOnly = false;

  readonly pages = {
    registration: 'Contact Phone Numbers',
    companyDetails: 'Phone Numbers',
    venueDetails: 'Phone Numbers',
    staffScreen: 'Contact Phone Numbers'
  };

  get phoneTypes() {
    if (this.phoneTypesBusinessCodeGroup) {
      return this.phoneTypesBusinessCodeGroup.businessCodes;
    }
    return [];
  }

  get phonesFormArray(): FormArray {
    return this.phoneNumbersForm.get('phones') as FormArray;
  }

  readonly PHONE_NUMBER_INPUT_MASK = [
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    '-',
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    '-',
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/
  ];

  readonly PHONE_NUMBER_INPUT_MASK_CONFIG: TextMaskConfig = {
    mask: this.PHONE_NUMBER_INPUT_MASK,
    guide: false
  };

  phoneNumbersForm: FormGroup;

  async confirmDelete(phoneGroup: AbstractControl, index: number) {
    // const phoneNumberId = phoneGroup.get('phoneNumberId')?.value;
    // if (phoneNumberId) {
    //   const phoneNumber = this.phoneNumbers?.find(phoneNumber => phoneNumber?.phoneNumberId === phoneNumberId);
    //   if (!phoneNumber) {
    //     return;
    //   }
    //   const confirmText = phoneNumber
    //     ? `Are you sure you want to delete the phone number "${phoneNumber.phoneNumber}" ?`
    //     : `Are you sure you want to delete this phone number?`;
    //   const result = await this.confirmDialogService.promptConfirm({
    //     confirmText,
    //     title: 'Confirm Delete',
    //     icon: 'exclamation',
    //     titleTheme: 'danger',
    //     cancelButton: {
    //       theme: 'danger'
    //     },
    //     confirmButton: {
    //       theme: 'danger'
    //     }
    //   });

    //   if (result) {
    //     this.delete.emit(phoneNumber);
    //   }
    //   return;
    // }
    this.phonesFormArray.removeAt(index);
    this.phoneNumbersForm.markAsDirty();
    if (this.phonesFormArray.length === 0) {
      this.save.emit([]);
      this.addNewPhoneHandler();
    }
  }

  skipThisCard(): void {
    this.skipEvent.emit(true);
  }

  addNewPhoneHandler(): void {
    this.phonesFormArray.push(createPhoneFormGroup());
  }

  ngOnInit(): void {
    if (this.sameAsCompany && this.companyPhoneNumbers) {
      this.setupPhoneNumbersForm(this.companyPhoneNumbers);
    } else {
      this.setupPhoneNumbersForm(this.phoneNumbers ?? []);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.companyPhoneNumbers) return;
    const phoneNumbers: Maybe<Array<Maybe<TixPhoneNumber>>> = changes[
      'phoneNumbers'
    ]?.currentValue as Maybe<TixPhoneNumber[]>;
    if (phoneNumbers) {
      this.setupPhoneNumbersForm(phoneNumbers);
    }
  }

  checkSameAsCompany(event: TixCheckboxChange) {
    this.sameAsCompany = event.checked;
    if (event.checked && this.companyPhoneNumbers) {
      console.log(this.companyPhoneNumbers);
      this.setupPhoneNumbersForm(this.companyPhoneNumbers);
    }
    this.sameAsCompanyChange.emit(event);
    this.sameAsCompanyChanged = true;
  }

  handleSaveClick() {
    if (this.sameAsCompany) {
      this.save.emit([
        {
          sameAsCompany: true
        }
      ] as TixPhoneNumber[]);
      return;
    }

    const phoneNumbersFormValue = this.phoneNumbersForm.value as {
      phones: PhoneNumberFormValue[];
    };

    if (this.emitNewOnly) {
      // Send only the dirty values
      phoneNumbersFormValue.phones = phoneNumbersFormValue.phones.filter(
        (e, index) => {
          if (this.phonesFormArray.at(index).dirty) {
            return true;
          }
          return false;
        }
      );
    }

    const formattedPhoneNumbers = phoneNumbersFormValue.phones.map(
      phoneNumber => ({
        phoneNumber: phoneNumber.phoneNumber,
        type: phoneNumber.phoneType,
        phoneNumberId: phoneNumber.phoneNumberId
      })
    ) as TixPhoneNumber[];

    this.save.emit(formattedPhoneNumbers);
  }

  private setupPhoneNumbersForm(
    phoneNumbers: Array<Maybe<TixPhoneNumber>>
  ): void {
    this.phoneNumbersForm = new FormGroup({
      phones: createPhoneFormArray(phoneNumbers ?? [])
    });
    if (phoneNumbers.length > 0) {
      this.phonesFormArray.clear();
      phoneNumbers.forEach(phoneNumber => {
        this.phonesFormArray.push(createPhoneFormGroup(phoneNumber));
      });
    }
    if (this.sameAsCompanyChanged) {
      this.phoneNumbersForm.markAsDirty();
      this.sameAsCompanyChanged = false;
    }
  }
}

type PhoneNumberFormValue = {
  phoneNumber: string;
  phoneType: string;
  phoneNumberId: string;
};

function createPhoneFormArray(
  phoneNumbers: Array<Maybe<TixPhoneNumber>>
): FormArray {
  console.log(phoneNumbers);
  if (!phoneNumbers || phoneNumbers.length === 0)
    return new FormArray([createPhoneFormGroup()]);
  return new FormArray(
    phoneNumbers.map(phoneNumber => createPhoneFormGroup(phoneNumber))
  );
}

function createPhoneFormGroup(phoneNumber?: Maybe<TixPhoneNumber>): FormGroup {
  return new FormGroup({
    phoneNumber: new FormControl(phoneNumber?.phoneNumber ?? '', [
      Validators.required,
      Validators.minLength(12)
    ]),
    phoneType: new FormControl(phoneNumber?.type ?? '', Validators.required),
    phoneNumberId: new FormControl(phoneNumber?.phoneNumberId ?? '')
  });
}
