import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { TixStaffService } from '@tix/staff/state';
import { Store } from '@ngrx/store';
import { UserActions, UserSelector } from '@tix/auth/state';

export enum Roles {
  CO_ADMIN = 'co_admin',
  BO_USER = 'bo_user',
  MANAGER = 'manager',
  EVENT_ADMINISTRATOR = 'event_admin'
}

export enum Permissions {
  DELETE_COMPANY = 'delete_company',
  DELETE_VENUE = 'delete_venue',
  DELETE_EVENT = 'delete_event',
  EDIT_COMPANY = 'modify_company',
  EDIT_VENUE = 'modify_venue',
  EDIT_EVENT = 'modify_event',
  EDIT_STAFF = 'modify_contact',
  READ_ACCESS_TO_DASHBOARD = 'ro_dashboard',
  CHECK_IN_USERS = 'edit_checkin_users',
  DELETE_STAFF = 'delete_staff',
  EDIT_CUSTOMER = 'edit_customer',
  DELETE_CUSTOMER = 'delete_customer',
  ACCESS_FINANCIALS = 'access_financials',
  ISSUE_REFUND = 'issue_refund',
  REISSUE_TICKET = 'reissue_ticket',
  ALL = 'all'
}

const rolePermissions = new Map<Roles, Permissions[]>();
rolePermissions.set(Roles.BO_USER, [
  Permissions.READ_ACCESS_TO_DASHBOARD,
  Permissions.CHECK_IN_USERS,
  Permissions.REISSUE_TICKET
]);
rolePermissions.set(Roles.MANAGER, [Permissions.ACCESS_FINANCIALS]);
rolePermissions.set(Roles.CO_ADMIN, [Permissions.ALL]);
rolePermissions.set(Roles.EVENT_ADMINISTRATOR, [
  Permissions.DELETE_EVENT,
  Permissions.EDIT_EVENT
]);

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private staffService: TixStaffService, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const requiredPermissions: Permissions[] = route.data.permissions;

    const companyId = route.params['companyId'];
    if (!companyId || companyId === 'add') return of(true);

    return this.store.select(UserSelector.getUserRolesInfo).pipe(
      map(user => {
        const userRolesInCompany =
          (user?.userRoles
            .filter(role => role.companyId === companyId)
            .map(role => role.role?.role) as Roles[]) || [];
        console.log(user);
        console.log(userRolesInCompany);

        const canAccess = requiredPermissions.every(permission =>
          userRolesInCompany.some(
            role =>
              rolePermissions.get(role)?.includes(permission) ||
              rolePermissions.get(role)?.includes(Permissions.ALL)
          )
        );

        return canAccess;
      })
    );
  }
}
