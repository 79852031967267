<div
  mat-dialog-title
  class="w-full pb-4 flex items-center justify-between font-semibold text-lg leading-8"
>
  <div>{{ title }}</div>
  <div
    class="px-3 py-1 border rounded-md bg-light text-xl text-secondary font-medium cursor-pointer hover:border-primary"
    [matTooltip]="'Close'"
    [matTooltipPosition]="'left'"
    (click)="closeDialog()"
  >
    X
  </div>
</div>
<!-- form -->
<form mat-dialog-content [formGroup]="eventProductForm" class="w-full mx-0">
  <!-- grid 2 inputs -->
  <div class="grid sm:grid-cols-1 md:grid-cols-3 md:gap-6 w-full">
    <!-- NAME -->
    <mat-form-field class="sm:col-span-1 md:col-span-2">
      <mat-label> {{ eventProductFormProps.NAME | titlecase }} </mat-label>
      <input
        matInput
        [placeholder]="'Date night dark'"
        type="text"
        [formControlName]="eventProductFormProps.NAME"
      />
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.NAME)!,
            eventProductFormProps.NAME
          )
        }}
      </mat-error>
    </mat-form-field>
    <!-- STATUS -->
    <mat-form-field>
      <mat-label>
        {{ eventProductFormProps.STATE | titlecase }}
      </mat-label>
      <mat-select
        [formControlName]="eventProductFormProps.STATE"
        [placeholder]="'Active'"
      >
        <mat-option *ngFor="let status of stateOptions" [value]="status">
          {{ status | titlecase }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.STATE)!,
            eventProductFormProps.STATE
          )
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- grid 3 inputs -->
  <div class="grid sm:grid-cols-1 md:grid-cols-4 md:gap-4 w-full">
    <!-- PRICE -->
    <mat-form-field>
      <mat-label>
        {{ eventProductFormProps.PRICE | titlecase }}
      </mat-label>
      <div class="flex flex-row w-full">
        <span>$&nbsp;</span>
        <input
          class="w-full"
          matInput
          [placeholder]="'1.99'"
          type="number"
          [formControlName]="eventProductFormProps.PRICE"
          min="1"
        />
      </div>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.PRICE)!,
            eventProductFormProps.PRICE
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- QUANTITY -->
    <mat-form-field>
      <mat-label>
        {{ eventProductFormProps.QUANTITY | titlecase }}
      </mat-label>
      <input
        matInput
        [placeholder]="'128'"
        type="number"
        [formControlName]="eventProductFormProps.QUANTITY"
        min="0"
      />
      <mat-icon
        [matTooltip]="
          defaultValue.quantityRemaining +
          ' Remaining of ' +
          defaultValue.quantity
        "
        [matTooltipPosition]="'above'"
        matSuffix
        class="text-secondary"
        >inventory</mat-icon
      >
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.QUANTITY)!,
            eventProductFormProps.QUANTITY
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- TAX -->
    <mat-form-field>
      <mat-label> {{ eventProductFormProps.TAX | titlecase }} </mat-label>
      <div class="flex flex-row w-full">
        <input
          class="w-full text-right"
          matInput
          [placeholder]="'1.81'"
          type="number"
          [formControlName]="eventProductFormProps.TAX"
          min="0"
        />
        <span>%&nbsp;</span>
      </div>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.TAX)!,
            eventProductFormProps.TAX
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- SALES_CHANNEL -->
    <mat-form-field>
      <mat-label>
        {{ 'Sales channel' | titlecase }}
      </mat-label>
      <mat-select
        [formControlName]="eventProductFormProps.SALES_CHANNEL"
        multiple
      >
        <mat-option *ngFor="let config of salesChannelOptions" [value]="config">
          {{ config | titlecase }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.SALES_CHANNEL)!,
            eventProductFormProps.SALES_CHANNEL
          )
        }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- time -->
  <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
    <!-- START_SELLING_DATE -->
    <mat-form-field>
      <mat-label>
        {{ 'Start selling date' | titlecase }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        type="text"
        [formControlName]="eventProductFormProps.START_SELLING_DATE"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.START_SELLING_DATE)!,
            eventProductFormProps.START_SELLING_DATE
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- START_SELLING_TIME -->
    <mat-form-field>
      <mat-label>
        {{ 'Start selling time' | titlecase }}
      </mat-label>
      <input
        matInput
        type="time"
        [formControlName]="eventProductFormProps.START_SELLING_TIME"
      />
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.START_SELLING_TIME)!,
            eventProductFormProps.START_SELLING_TIME
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- STOP_SELLING_DATE -->
    <mat-form-field>
      <mat-label>
        {{ 'Stop selling date' | titlecase }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="stopDatePicker"
        type="text"
        [formControlName]="eventProductFormProps.STOP_SELLING_DATE"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="stopDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #stopDatePicker></mat-datepicker>
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.STOP_SELLING_DATE)!,
            eventProductFormProps.STOP_SELLING_DATE
          )
        }}
      </mat-error>
    </mat-form-field>

    <!-- STOP_SELLING_TIME -->
    <mat-form-field>
      <mat-label>
        {{ 'Stop selling time' | titlecase }}
      </mat-label>
      <input
        matInput
        type="time"
        [formControlName]="eventProductFormProps.STOP_SELLING_TIME"
        step="60"
      />
      <mat-error>
        {{
          getInputError(
            eventProductForm.get(eventProductFormProps.STOP_SELLING_TIME)!,
            eventProductFormProps.STOP_SELLING_TIME
          )
        }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- description -->
  <div class="border rounded-md p-2 mb-4">
    <span class="text-xs font-light text-gray-500 mb-2">{{
      'Additional information *' | titlecase
    }}</span>
    <quill-editor
      id="uniqueAddonNew"
      style="resize: vertical"
      [formControlName]="eventProductFormProps.DESCRIPTION"
      [placeholder]="'Description' | titlecase"
    ></quill-editor>
  </div>

  <!-- files -->
  <div
    tixUploadFile
    (fileDropped)="onFileDropped($event)"
    class="w-full bg-[#F8F9FB] flex items-center justify-center h-32 border-2 border-dashed rounded-md border-primary mb-4 shadow-sm"
  >
    <div class="flex items-center flex-row">
      <input
        (change)="fileBrowseHandler(fileDropEl.files)"
        #fileDropEl
        class="opacity-0 w-0"
        type="file"
        name="files"
        id="files"
        accept="image/*"
      />
      <mat-icon class="text-primary">add_photo_alternate</mat-icon>
      <span class="font-semibold text-md leading-6"
        >Drag and drop image here, or
        <a
          class="text-primary underline decoration-primary cursor-pointer"
          (click)="fileDropEl.click()"
          >choose image</a
        >
      </span>
    </div>
  </div>
  <!-- -> Selected/Existing files -->
  <div
    *ngIf="files.length > 0"
    class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-3 my-3"
  >
    <div
      *ngFor="let file of files; let i = index; trackBy: trackByFn"
      class="rounded-md bg-[#F8F9FB] flex items-center justify-between p-3 pr-8 shadow-sm border hover:border-primary"
    >
      <!-- image + name -->
      <div class="flex flex-row h-full w-full">
        <a
          *ngIf="file?.location"
          target="_blank"
          [href]="file.location"
          [matTooltip]="'Show image'"
          [matTooltipPosition]="'above'"
        >
          <img
            [src]="file.location"
            class="h-14 w-14 rounded-md bg-primary mr-4 border"
          />
        </a>
        <div
          *ngIf="!file?.location"
          class="h-12 w-14 rounded-sm bg-primary mr-4"
        ></div>
        <div class="flex flex-col content-between w-full h-fit ml-3">
          <div class="p-0 m-0 font-medium">{{ file.name }}</div>
          <div
            *ngIf="file?.size"
            class="p-0 m-0 text-xs text-secondary text-opacity-70"
          >
            {{ formatBytes(file.size) }}
          </div>
          <!-- <a
          *ngIf="!file?.size"
          target="_blank"
          [href]="file.location"
          class="p-0 m-0 text-xs text-secondary text-opacity-70"
        >
          Show image
        </a> -->
          <mat-progress-bar
            *ngIf="!file?.location"
            class="rounded-full"
            mode="determinate"
            [value]="progress$ | async"
          ></mat-progress-bar>
        </div>
      </div>
      <!-- remove -->
      <span
        *ngIf="file?.location && !file?.isProcessing"
        [matTooltip]="'Remove ' + file.name"
        [matTooltipPosition]="'above'"
        class="text-danger cursor-pointer font-extrabold leading-8"
        (click)="
          onRemoveFile(i, file.eventProductMediaImageFileId, file.mediaFileId)
        "
      >
        x
      </span>
      <div
        *ngIf="file?.isProcessing"
        [matTooltip]="'Cancel delete'"
        [matTooltipPosition]="'above'"
      >
        <div
          class="animate-spin h-6 w-6 border-r-2 border-primary rounded-full cursor-pointer"
        ></div>
        <div class="h-2 w-2 bg-secondary rounded-sm -mt-4 ml-2"></div>
      </div>
    </div>
  </div>
</form>
<!-- actions -->
<div
  mat-dialog-actions
  class="bg-light border-t flex items-center justify-between"
>
  <!-- if eventProductForm.value.productId exists then defaultValue.name is not null -->
  <button
    *ngIf="!!defaultValue?.['eventProductId']"
    [matTooltip]="'Delete ' + defaultValue?.name"
    [matTooltipPosition]="'above'"
    (click)="deleteOne(defaultValue?.['eventProductId'])"
  >
    <mat-icon class="cursor-pointer opacity-75 text-danger hover:opacity-90"
      >delete</mat-icon
    >
  </button>
  <div></div>
  <div class="space-x-4 text-sm">
    <button
      class="px-3 py-2 border border-secondary rounded-md hover:bg-slate-100"
      (click)="closeDialog()"
    >
      Close
    </button>
    <button
      (click)="update()"
      [disabled]="eventProductForm.invalid"
      [matTooltip]="eventProductForm.invalid ? 'Invalid form' : ''"
      [matTooltipPosition]="'above'"
      class="px-3 py-2 text-light bg-primary rounded-md hover:bg-matprimary-700"
    >
      Update
    </button>
  </div>
</div>
<!-- </div> -->
