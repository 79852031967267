import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Maybe } from '@tix/data-access';
import * as moment from 'moment';
@Component({
  selector: 'tix-week-select',
  templateUrl: './week-select.component.html',
  styleUrls: ['./week-select.component.scss']
})
export class WeekSelectComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<string[] | string>();

  @Input() selectionMode: 'multi' | 'single' = 'single';
  @Input() value: number | number[] | null;
  @Input() useSansSerif = false;

  weekDays: string[] = moment.weekdays();

  ngOnInit(): void {
    this.initChecks();
  }

  /**
   * Throw error if the params have conflicts
   */
  initChecks() {
    if (this.value) {
      if (this.selectionMode === 'multi' && !Array.isArray(this.value)) {
        throw "value is number while selection mode is 'multi'";
      } else if (this.selectionMode === 'single' && Array.isArray(this.value)) {
        throw "value is an Array while selection mode is 'single'";
      }
    }
  }

  /**
   * Toggle selections
   * @param dayIndex: the index to toggle
   */
  handleDayClick(dayIndex: number) {
    console.log('clicked Something');
    if (this.selectionMode === 'multi') {
      this.toggleArrayItem(dayIndex);
    } else {
      this.toggleSingleItem(dayIndex);
    }
    this.emitChanges();
  }

  emitChanges() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.selectionChange.emit(
          this.value.map(item => (this.weekDays as string[])[item])
        );
      } else {
        this.selectionChange.emit(this.weekDays && this.weekDays[this.value]);
      }
    }
  }

  /**
   * Toggling for 'multi' case
   * @param dayIndex
   * @private
   */
  private toggleArrayItem(dayIndex: number) {
    let value: number[] = this.value as number[];
    value = value ? value : [];
    if (value.includes(dayIndex)) {
      value = value.filter(day => day != dayIndex);
    } else {
      value.push(dayIndex);
    }
    this.value = value;
  }

  /**
   * Toggling for 'single' mode
   * @param dayIndex
   * @private
   */
  private toggleSingleItem(dayIndex: number) {
    let value: Maybe<number> = this.value as number;

    if (value === dayIndex) {
      value = null;
    } else {
      value = dayIndex;
    }
    this.value = value;
  }

  /**
   * return boolean depending on the existence of the dayIndex in the value(s)
   * @param dayIndex: Item to check
   */
  isSelected(dayIndex: number): boolean {
    if (Array.isArray(this.value)) {
      return this.value.includes(dayIndex);
    }
    return this.value === dayIndex;
  }
}
