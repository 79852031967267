import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserPartialState } from '@tix/auth/state';
import { map } from 'rxjs/operators';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { UserStatus } from '@tix/shared/models';
@Component({
  selector: 'tix-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    class: 'tix-header'
  }
})
export class TixHeaderComponent implements OnInit {
  @Input() name = '';
  @Input() date: Date = new Date();
  isLoggedIn: Observable<boolean>;
  constructor(private readonly store: Store<UserPartialState>) {}

  ngOnInit(): void {
    this.isLoggedIn = this.store
      .select(AuthSelectors.getUserStatus)
      .pipe(map(s => s === UserStatus.LOGGED_IN));
  }

  nope(): void {}
}
