import { ChangeDetectorRef, Component } from '@angular/core';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';

interface BreadcrumbLink {
  routerLink: any[] | string | null | undefined;
  label?: string;
  svgIcon?: string;
}

interface RouterPart {
  urlParts: string[];
  data: any;
}

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

function partForRoute(route: ActivatedRouteSnapshot): RouterPart {
  return { data: route.data, urlParts: route.url.map(url => url.path) };
}

export const selectRouteParts = createSelector(selectRouter, router => {
  let currentRoute = router?.state?.root;
  const urlParts = [];
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    urlParts.push(partForRoute(currentRoute));
  }
  return urlParts;
});

@Component({
  selector: 'tix-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class TixBreadcrumbsComponent {
  get breadcrumbs(): BreadcrumbLink[] {
    return this._breadcrumbs;
  }
  private _breadcrumbs: BreadcrumbLink[];

  private subscriptions: Subscription[] = [];

  get expanded(): boolean {
    return this._expanded;
  }

  private _expanded: boolean;

  constructor(private store: Store, private changeRef: ChangeDetectorRef) {
    this.store.select(selectRouteParts).subscribe(routerParts => {
      this.clearSubscriptions();
      this.populateBreadcrumbs(routerParts);
    });
  }

  private clearSubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  private populateBreadcrumbs(parts: RouterPart[]): void {
    const crumbs: BreadcrumbLink[] = [];

    const routerLink: any[] = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (part.data?.breadcrumbSkip) {
        continue;
      }

      // If root url make sure to add the leading slash. This could need to be modified at some point?
      if (i === 0 && part.urlParts.length === 0) {
        routerLink.push('/');
      } else {
        routerLink.push(...part.urlParts);
      }

      const data = part.data?.breadcrumb;

      const crumb: BreadcrumbLink = {
        routerLink: routerLink.slice(0)
      };

      if (data?.label) {
        crumb.label = data.label;
      } else if (data?.svgIcon) {
        crumb.svgIcon = data.svgIcon;
      } else if (data?.labelSelector) {
        const sub: Subscription = this.store
          .select(data?.labelSelector)
          .subscribe(val => {
            crumb.label = val as string;
            this.changeRef.markForCheck();
          });
        this.subscriptions.push(sub);
      }

      crumbs.push(crumb);
    }
    this._breadcrumbs = crumbs;
    this.changeRef.markForCheck();
  }

  toggleExpand(): void {
    this._expanded = !this.expanded;
  }
}
