import { Injectable } from '@angular/core';
import {
  TixBuyerProductFragmentFragment,
  TixOrderedEventProductsGQL,
  TixProductOrderDetailInsertInput,
  TixRemoveOrderFromHoldGQL
} from '@tix/data-access';
import { dollarFigureToNumber } from '@tix/shared/utils';
import { HelperStringService } from '@tix/util-common';
import { take } from 'rxjs/operators';

@Injectable()
export class OrderService {
  #helperStringService = new HelperStringService();

  constructor(
    private _orderedEventProducts: TixOrderedEventProductsGQL,
    private _removeHoldenProducts: TixRemoveOrderFromHoldGQL
  ) {}

  orderedProducts(
    products: (TixBuyerProductFragmentFragment & { selected: number })[],
    orderInfo: {
      reservationId: string;
      contactId: string;
      orderItemsProcessingFee: number;
      totalTax: number;
    }
  ) {
    const len = products?.length ?? 0;
    if (len === 0) return;

    // start
    const reservationId = orderInfo.reservationId;
    const contactId = orderInfo.contactId;
    const orderId = this.#helperStringService.uuid();
    const paidText = 'Paid';

    const updatedEventProducts = [];
    const productOrderDetail: TixProductOrderDetailInsertInput[] = [];

    const now = 'now()';
    // end

    productOrderDetail.push({
      orderDetailId: this.#helperStringService.uuid(),
      orderId,
      amount: orderInfo.orderItemsProcessingFee,
      price: orderInfo.orderItemsProcessingFee,
      quantity: 1,
      state: paidText,
      type: 'Processing fee',
      createdBy: contactId,
      updatedBy: contactId,
      createdAt: now,
      updatedAt: now
    });

    for (let i = 0; i < len; i++) {
      // start
      const product = products[i];
      const { eventProductId, selected, tax, quantityRemaining } = products[i];
      const price = this.#helperStringService.moneyToNumber(product['price']);
      // end

      updatedEventProducts.push({
        where: {
          eventProductId: { _eq: eventProductId }
        },
        _inc: {
          quantitySold: selected,
          quantityRemaining: -selected
        }
      });

      if (product.tax ?? 0 > 0) {
        productOrderDetail.push({
          orderDetailId: this.#helperStringService.uuid(),
          orderId,
          eventProductId: product.eventProductId,
          amount:
            (dollarFigureToNumber(product.price) * selected * product.tax) /
            100,
          price: (dollarFigureToNumber(product.price) * product.tax) / 100,
          quantity: product.selected,
          state: paidText,
          type: 'Tax',
          createdBy: contactId,
          updatedBy: contactId,
          createdAt: now,
          updatedAt: now
        });
      }

      productOrderDetail.push({
        orderDetailId: this.#helperStringService.uuid(),
        eventProductId: eventProductId,
        orderId,
        amount: price * selected,
        price: price,
        quantity: selected,
        state: paidText,
        type: 'Product',
        createdBy: contactId,
        updatedBy: contactId,
        createdAt: now,
        updatedAt: now
      });
    }

    const order = {
      orderId,
      reservationId,
      contactId,
      state: paidText,
      payment_type: 'Credit Card',
      createdBy: contactId,
      updatedBy: contactId,
      createdAt: now,
      updatedAt: now
    };

    this._orderedEventProducts
      .mutate(
        {
          updatedEventProducts,
          order,
          productOrderDetail
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(take(1))
      .subscribe();
  }
}
