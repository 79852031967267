import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TixCheckboxComponent } from './checkbox.component';
import { ObserversModule } from '@angular/cdk/observers';

@NgModule({
  declarations: [TixCheckboxComponent],
  exports: [TixCheckboxComponent],
  imports: [CommonModule, ObserversModule]
})
export class TixCheckboxModule {}
