import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AUTH_LOGIN_ROUTE_SEGMENTS } from '@tix/auth/constants';
import { UserPartialState, UserSelector } from '@tix/auth/state';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, mergeMap, retry, take, takeUntil, tap } from 'rxjs/operators';
import { User, UserStatus } from '@tix/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { TixAuthService } from '@tix/auth/services';
import { TixGetContactByEmailAddressGQL } from '@tix/data-access';
import { TixConfirmDialogService } from '@tix/shared/ui/components';

@Component({
  selector: 'tix-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  host: {
    class: 'tix-profile-menu'
  },
  encapsulation: ViewEncapsulation.None
})
export class TixProfileMenuComponent implements OnDestroy {
  isLoggedIn: Observable<boolean>;

  private isInitialUserLoad = true;

  readonly userState$: Observable<User | null> = this.store
    .select(UserSelector.getAuthenticatedUser)
    .pipe(
      mergeMap(user => {
        return this.getContactByEmailAddress
          .fetch({
            emailAddress: user?.email
          })
          .pipe(
            map(data => {
              if (!this.isInitialUserLoad || user?.profilePicture) {
                return user;
              }
              this.isInitialUserLoad = false;
              return {
                ...user,
                profilePicture: data.data.Contact?.[0]?.profilePicture || ''
              } as User;
            })
          );
      })
    );
  private destroyed$ = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store<UserPartialState>,
    private readonly authService: TixAuthService,
    private readonly getContactByEmailAddress: TixGetContactByEmailAddressGQL,
    private confirmDialog: TixConfirmDialogService
  ) {
    this.isLoggedIn = this.store
      .select(AuthSelectors.getUserStatus)
      .pipe(map(s => s === UserStatus.LOGGED_IN));
  }

  getReturnUrl(): Observable<string> {
    return this.route.queryParams.pipe(
      take(1),
      map(params => params.r)
    );
  }

  login(): void {
    this.getReturnUrl().subscribe(returnUrl =>
      this.router.navigate(AUTH_LOGIN_ROUTE_SEGMENTS, {
        queryParams: { r: returnUrl ?? this.router.url }
      })
    );
  }

  async logout(): Promise<void> {
    const result = await this.confirmDialog.promptConfirm({
      title: 'Log Out',
      confirmText: 'Do you really want to leave? ',
      icon: 'exclamation',
      confirmButton: {
        label: 'Log-out',
        theme: 'danger'
      },
      titleTheme: 'danger'
    });

    if (result) {
      this.authService
        .logout()
        .pipe(retry(3))
        .subscribe(() => (window.location.href = '/auth/login'));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
