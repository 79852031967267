<tix-public-header
  [venueId]="venueId"
  [venueInfo]="venueInfo$ | async"
></tix-public-header>
<div class="tix-layout-content">
  <div class="tix-content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
<tix-footer></tix-footer>
