import { Injectable } from '@angular/core';
import {
  TixGenericAddOrderToHoldGQL,
  TixGenericGetOrderItemsOnHoldByOtherUsersGQL,
  TixGenericGetOrderOnHoldGQL,
  TixGenericRemoveOrderFromHoldGQL,
  TixOrderHoldItemResult
} from '@tix/data-access';
import { UserSessionStorage } from '@tix/shared/utils';
import { TICKET_HOLD_TTL_IN_SECONDS } from '@tix/ticket-hold';

export class HoldItem {
  constructor(
    public id: string,
    public quantity: number,
    public type: string
  ) {}
}

export class HoldTicket extends HoldItem {
  constructor(id: string, quantity: number) {
    super(id, quantity, 'ticket');
  }
}

export class HoldProduct extends HoldItem {
  constructor(id: string, quantity: number) {
    super(id, quantity, 'product');
  }
}

export class HoldOrder {
  constructor(public items: HoldItem[], public expiresAt: number) {}
}

@Injectable()
export class OrderHoldService {
  #heldOrderAt = 0;
  private get userSessionId() {
    return UserSessionStorage.getInstance().getUserSessionId();
  }

  constructor(
    private getOrderOnHoldQuery: TixGenericGetOrderOnHoldGQL,
    private getOrderItemsOnHoldByOtherUsersQuery: TixGenericGetOrderItemsOnHoldByOtherUsersGQL,
    private removeOrderOnHoldMutation: TixGenericRemoveOrderFromHoldGQL,
    private addOrderOnHoldMutation: TixGenericAddOrderToHoldGQL
  ) {}

  private orderHoldItemResultToHoldItem(
    item: TixOrderHoldItemResult
  ): HoldItem {
    if (item.type === 'ticket') return new HoldTicket(item.id, item.quantity);
    if (item.type === 'product') return new HoldProduct(item.id, item.quantity);

    return new HoldItem(item.id, item.quantity, item.type);
  }

  async getMyHoldOrder(): Promise<HoldOrder | null> {
    const response = await this.getOrderOnHoldQuery
      .fetch(
        {
          userSessionId: this.userSessionId
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    if (!response.data.genericGetOrderOnHold.length) return null;

    const holdOrder = new HoldOrder(
      response.data.genericGetOrderOnHold.map(
        this.orderHoldItemResultToHoldItem
      ),
      response.data.genericGetOrderOnHold[0].expiresAt as number
    );
    if (holdOrder.expiresAt)
      this.#heldOrderAt =
        holdOrder.expiresAt - TICKET_HOLD_TTL_IN_SECONDS * 1000;

    return holdOrder;
  }

  async setMyHoldOrder(items: HoldItem[]): Promise<void> {
    await this.addOrderOnHoldMutation
      .mutate({
        userSessionId: this.userSessionId,
        items: items.map(item => ({
          id: item.id,
          quantity: item.quantity,
          type: item.type
        }))
      })
      .toPromise();
    this.#heldOrderAt = new Date().getTime();
  }

  async deleteMyHoldOrder(): Promise<void> {
    await this.removeOrderOnHoldMutation
      .mutate({
        userSessionId: this.userSessionId
      })
      .toPromise();
  }

  async getExternalItemsInHold(
    itemIds: string[]
  ): Promise<{ holdItemId: string; quantity: number }[]> {
    const response = await this.getOrderItemsOnHoldByOtherUsersQuery
      .fetch(
        {
          userSessionId: this.userSessionId,
          itemIds
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    return response.data.genericGetOrderItemsOnHoldByOtherUsers.map(item => ({
      holdItemId: item.id,
      quantity: item.quantity
    }));
  }

  getTimeSinceOrderHeld() {
    if (!this.#heldOrderAt) throw new Error('No order is in hold.');

    return new Date().getTime() - this.#heldOrderAt;
  }
}
