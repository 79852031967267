import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Maybe, TixCompanyContact, TixRole } from '@tix/data-access';
import * as staffApiAction from './actions/staff-api.actions';
import * as staffAction from './actions/staff.actions';

export const STAFF_FEATURE_KEY = 'staff';

export function selectStaffId(staff: TixCompanyContact): string {
  return staff.contact?.contactId;
}

export interface State extends EntityState<TixCompanyContact> {
  loaded: boolean;
  selectedStaffId?: string;
  error?: string | null;
  usersRole?: Maybe<TixRole>[];
  staffLoadingPhone: boolean;
  staffLoadingPremissions: boolean;
}

export interface TixStaffPartialState {
  readonly [STAFF_FEATURE_KEY]: State;
}

export const staffAdapter: EntityAdapter<TixCompanyContact> =
  createEntityAdapter<TixCompanyContact>({
    selectId: selectStaffId
  });

export const initialState: State = staffAdapter.getInitialState({
  loaded: false,
  staffLoadingPhone: false,
  staffLoadingPremissions: false
});

export const reducer = createReducer(
  initialState,
  on(staffApiAction.fetchRoleSuccess, (state, action) => {
    return {
      ...state,
      usersRole: action.roles
    };
  }),
  on(
    staffApiAction.loadContactsForSelectedCompanyFailure,
    (state: any, { error }: any) => ({ ...state, error })
  ),
  on(staffApiAction.loadContactsForSelectedCompanySuccess, (state, action) => {
    return staffAdapter.setAll(action.contacts ?? [], {
      ...state,
      loaded: true
    });
  }),
  on(staffAction.selectStaff, (state, { staffId }) => {
    return { ...state, selectedStaffId: staffId };
  }),
  on(staffAction.deselectStaff, state => {
    delete state.selectedStaffId;
    return { ...state };
  }),
  on(staffApiAction.loadStaffByIdSuccess, (state, { contact }) =>
    staffAdapter.setOne(contact, { ...state, loaded: true })
  ),
  on(staffAction.saveUserPermission, state => ({
    ...state,
    staffLoadingPremissions: true
  })),
  on(staffAction.saveUserPhoneNumbers, state => ({
    ...state,
    staffLoadingPhone: true
  })),
  on(staffApiAction.saveStaffRoleSuccess, (state, action) =>
    staffAdapter.updateOne(action.updatedContact, {
      ...state,
      staffLoadingPremissions: false
    })
  ),
  on(staffApiAction.saveStaffRoleFailure, (state, action) => ({
    ...state,
    error: action.error,
    staffLoadingPremissions: false
  })),
  on(staffApiAction.upsertContactPhoneNumbersSuccess, (state, action) =>
    staffAdapter.updateOne(action.updatedContact, {
      ...state,
      staffLoadingPhone: false
    })
  ),
  on(staffApiAction.upsertContactPhoneNumbersFailure, (state, { error }) => ({
    ...state,
    error,
    staffLoadingPhone: false
  })),
  on(
    staffApiAction.deleteStaffRoleSuccess,
    staffApiAction.deleteContactPhoneNumberSuccess,
    (state, action) =>
      staffAdapter.updateOne(action.updatedContact, { ...state })
  ),
  on(staffApiAction.loadStaffByIdFailure, (state, { error }) => ({
    ...state,
    error
  }))
);
