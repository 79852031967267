<ng-container *ngIf="(userState$ | async) !== 0">
  <ng-template #genericWelcome>
    <ng-container *ngIf="venueId">
      <tix-public-header [venueInfo]="venueInfo$ | async"></tix-public-header>
      <div class="tix-layout-content">
        <div class="tix-content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
      <tix-footer></tix-footer>
    </ng-container>

    <ng-container *ngIf="!venueId">
      <tix-header></tix-header>
      <div class="tix-login-layout-content">
        <div class="tix-login-content-wrapper">
          <div class="p-5 bg-white shadow-2xl rounded-xl">
            <div class="text-xl text-mulberry-500">Welcome to asylumTix</div>
            <br />
            <div>
              We are working on providing the ultimate ticket experience.
            </div>
            <br />
            <div>For the Buyer and the Seller.</div>
            <br />
            <div>During early access you must login to interact.</div>
          </div>
        </div>
      </div>
      <tix-footer></tix-footer>
    </ng-container>
  </ng-template>
  <ng-template tixIfAuth [tixAuthElse]="genericWelcome">
    <div>
      <tix-header></tix-header>

      <div class="tix-login-layout-content">
        <div class="tix-login-content-wrapper">
          <div class="grid h-full my-auto place-items-center">
            <div class="flex flex-col w-3/5 pt-10">
              <div class="p-5 bg-white shadow-2xl rounded-xl">
                <span
                  >Thank you for participating in our early access
                  program.</span
                >
                <br /><br />
                <a tix-button [routerLink]="['/company']" color="primary"
                  >Go to Companies</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <tix-footer hideLogo></tix-footer>
    </div>
  </ng-template>
</ng-container>

<div
  class="flex items-center justify-center h-screen w-100"
  *ngIf="(userState$ | async) === 0"
>
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
