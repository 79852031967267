import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as StaffSelectors from '@tix/staff/state';
import * as StaffAction from '@tix/staff/state';
import { ContactProfileViewModel } from '@tix/shared/models';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixPhoneNumber,
  TixRole,
  TixUserRole
} from '@tix/data-access';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroupsService
} from '@tix/shared/state';
import { ActivatedRoute } from '@angular/router';
import {
  TixFullStaffMember,
  TixStaffPartialState,
  TixStaffService
} from '@tix/staff/state';
import {
  ComponentCanDeactivate,
  TixPhoneNumbersComponent
} from '@tix/shared/ui/components';
import { TixContactProfileComponent } from 'libs/staff/components/src/lib/contact-profile/contact-profile.component';
import { TixContactPermissionsComponent } from 'libs/staff/components/src/lib/contact-permissions/contact-permissions.component';
import { UserSelector } from '@tix/auth/state';

@Component({
  selector: 'tix-company-staff-admin',
  templateUrl: './company-staff-admin.component.html',
  styleUrls: ['./company-staff-admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tix-company-staff-admin'
  }
})
export class TixCompanyStaffAdminPage implements ComponentCanDeactivate {
  isEditMode = false;

  readonly businessPhoneTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.CONTACT_PHONE_TYPE
    );

  staffId$ = this.route.params.pipe(map(params => params['staffId']));
  companyId$ = this.route.params.pipe(
    take(1),
    map(params => params['companyId'])
  );

  readonly phoneNumbers$: Observable<Maybe<TixPhoneNumber>[]> =
    this.store.select(StaffSelectors.getSelectedStaffPhoneNumbers);
  readonly staffLoadingPhone$: Observable<boolean> = this.store.select(
    StaffSelectors.getSatffLoadingPhone
  );
  readonly staffLoadingPremissions$: Observable<boolean> = this.store.select(
    StaffSelectors.getSatffLoadingPremissions
  );

  userRolesList$ = this.staffService.getRoles();
  selectedStaff$ = this.staffId$.pipe(
    take(1),
    switchMap(staffId =>
      staffId != 'add'
        ? this.staffService.getStaffMemberById(staffId)
        : of(null)
    )
  );
  userAssignedRoles$ = this.companyId$.pipe(
    switchMap(companyId =>
      this.selectedStaff$.pipe(
        map(
          contact =>
            contact?.user?.userRoles.filter(
              role => role.companyId === companyId
            ) || []
        )
      )
    )
  );
  userId: string;
  profileViewModel$: Observable<ContactProfileViewModel> =
    this.selectedStaff$.pipe(
      map(contact => {
        this.userId = contact?.user?.userId;
        return {
          contactId: contact?.contactId,
          firstName: contact?.firstName ?? '',
          lastName: contact?.lastName ?? '',
          emailAddress: contact?.emailAddress ?? '',
          profilePicture: contact?.profilePicture,
          userId: contact?.user?.userId
        };
      })
    );
  isLoadingStaffMember$ = this.staffService.isLoadingFullStaff$;
  isLoadingRolesList$ = this.staffService.isLoadingRoles$;
  isSavingPhoneNumbers$ = this.staffService.isSavingStaffPhoneNumbers$;

  contactForm: FormGroup;

  currentUser$ = this.store.select(UserSelector.getUserRolesInfo);
  canEditRole$ = this.currentUser$.pipe(
    mergeMap(user =>
      this.selectedStaff$.pipe(
        map(contact => contact?.user?.userId !== user?.userId)
      )
    )
  );

  @ViewChild(TixContactProfileComponent)
  contactProfileComponent?: TixContactProfileComponent;
  @ViewChild(TixPhoneNumbersComponent)
  phoneNumbersComp?: TixPhoneNumbersComponent;
  @ViewChild(TixContactPermissionsComponent)
  permissionComp?: TixContactPermissionsComponent;

  canDeactivate() {
    if (
      this.contactProfileComponent?.profileForm.dirty ||
      this.phoneNumbersComp?.phoneNumbersForm.dirty ||
      this.permissionComp?.userRolesForm.dirty
    ) {
      return false;
    }
    return true;
  }

  get pristineOrInvalid(): boolean {
    return this.contactForm.pristine || this.contactForm.invalid;
  }

  constructor(
    private readonly store: Store<TixStaffPartialState>,
    private readonly route: ActivatedRoute,
    private staffService: TixStaffService,
    private businessGroupsService: TixBusinessGroupsService
  ) {
    this.route.params.subscribe(params => {
      if (params['staffId'] && params['staffId'] !== 'add')
        this.isEditMode = true;
    });
  }

  deletePhoneNumber(phoneNumber: TixPhoneNumber): void {}

  async savePhoneNumbers(phoneNumbers: TixPhoneNumber[]) {
    this.selectedStaff$.subscribe(async contact => {
      await this.staffService
        .saveStaffMemberPhoneNumbers(
          contact?.contactId,
          phoneNumbers.map(p => ({
            countryCode: p.countryCode || '',
            phoneNumber: p.phoneNumber || '',
            type: p.type || ''
          }))
        )
        .toPromise();
      this.phoneNumbersComp?.phoneNumbersForm.markAsPristine();
    });
  }
}
