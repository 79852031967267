/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unsafe-optional-chaining */
import { Injectable } from '@angular/core';
import {
  TixEventProduct,
  TixEventProductInsertGQL,
  TixEventProductPullProductGQL,
  TixEventProductSelectByPkGQL,
  TixEventProductSelectSubscriptionGQL,
  TixEventProductSoftDeleteGQL,
  TixEventProductUpdateGQL
} from '@tix/data-access';
import {
  ENUM_DATE_FORMAT,
  HelperArrayService,
  HelperDateService,
  HelperStringService
} from '@tix/util-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventProduct } from '../models/event-product.model';

@Injectable({
  providedIn: 'root'
})
export class EventProductsService {
  // [Store]: event product state
  #eventProducts$ = new BehaviorSubject<any>(null);

  // helpers
  #helperStringService = new HelperStringService();
  #helperDateService = new HelperDateService();
  #helperArrayService = new HelperArrayService();

  get eventProducts$() {
    return this.#eventProducts$.asObservable();
  }

  constructor(
    // get
    private _eventProductSelectSubscription: TixEventProductSelectSubscriptionGQL,
    private _eventProductSelectOne: TixEventProductSelectByPkGQL,
    private _productSelectAll: TixEventProductPullProductGQL,
    // insert
    private _eventProductInsert: TixEventProductInsertGQL,
    // update
    private _eventProductUpdate: TixEventProductUpdateGQL,
    // delete
    private _eventProductSoftDelete: TixEventProductSoftDeleteGQL
  ) {}

  eventProducts(eventInstanceId: string) {
    return this._eventProductSelectSubscription
      .subscribe(
        {
          eventInstanceId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(response => {
          const initalProducts = response.data?.EventProductSelect;
          this.#eventProducts$.next(
            initalProducts?.map(p => ({
              ...p,
              price: this.#helperStringService.moneyToNumber(p.price)
            }))
          );
          return initalProducts;
        })
      );
  }

  eventProduct(eventProductId: string) {
    return this._eventProductSelectOne
      .subscribe(
        {
          eventProductId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(response => {
          const { quantityRemaining, quantity } =
            response.data?.EventProductSelectByPK!;
          return { quantity, quantityRemaining };
        })
      );
  }

  products(
    companyId: string,
    eventProductId: string, // maybe the venue id
    productIds?: string[]
  ) {
    const excludedIds = productIds?.map(productId => ({
      productId: { _neq: productId },
      state: { _eq: 'Active' }
    }));

    return this._productSelectAll.fetch(
      {
        companyId,
        _and: excludedIds
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
  }

  insertMany(
    _eventProducts: EventProduct[],
    eventId: string
  ): Observable<boolean> {
    const createdBy = this.#connectedUserId();
    const eventProducts: Array<Record<string, any>> = [];
    const mediaFiles = [];
    const today = this.#helperDateService.format(
      new Date(),
      ENUM_DATE_FORMAT.YYYY_MM_DD
    );
    //
    const startTime = '00:00:00';
    const start = this.#helperDateService.utc(`${today}T${startTime}`);
    const stopTime = '23:59:00';
    const stop = this.#helperDateService.utc(`${today}T${stopTime}`);

    for (let i = 0; i < _eventProducts.length; i++) {
      const _eventProduct = _eventProducts[i];

      const eventProductId = this.#helperStringService.uuid();
      /**
       * TODO: decontructor _eventProduct object
       */
      const eventProduct: any = {
        name: _eventProduct.name,
        price: _eventProduct.price,
        state: _eventProduct.state, // to review
        quantity: _eventProduct.quantity,
        quantityRemaining: _eventProduct.quantity,
        quantitySold: 0,
        productId: _eventProduct.productId,
        // start
        startSellingDate: today,
        startSellingTime: startTime,
        startSelling: start,
        // stop
        stopSellingDate: today,
        stopSellingTime: stopTime,
        stopSelling: stop,
        //
        salesChannel: 'online',
        //
        tax: _eventProduct.tax,
        additionalInfo: _eventProduct.additionalInfo,
        eventProductId,
        eventInstanceId: eventId,
        createdAt: 'now()',
        updatedAt: 'now()',
        createdBy,
        updatedBy: createdBy
      };

      const mediaFile =
        _eventProduct.productMedia.length >= 1
          ? _eventProduct.productMedia.map((product: any) => ({
              data: {
                name: product.mediaFile.name,
                location: product.mediaFile.location,
                fileType: product.mediaFile.fileType
              }
            }))
          : null;

      delete eventProduct.additionalInformation;
      delete eventProduct.__typename;
      delete eventProduct.isSelected;

      if (mediaFile?.length >= 1) {
        for (let i = 0; i < mediaFile.length; i++) {
          const product = mediaFile[i];
          mediaFiles.push({
            eventProductId,
            eventProductMediaImageFileId: this.#helperStringService.uuid(),
            createdBy,
            updatedBy: createdBy,
            updatedAt: 'now()',
            createdAt: 'now()',
            MediaFile: product
          });
        }
      }
      eventProducts.push(eventProduct);
    }

    return this._eventProductInsert
      .mutate(
        {
          eventProducts,
          mediaFiles
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(response => {
          return !!response.data;
        })
      );
  }

  updateOne(eventProduct: TixEventProduct): Observable<boolean> {
    // check if dates are compatible (starting dates)

    //
    eventProduct.salesChannel =
      eventProduct.salesChannel?.length === 2
        ? 'both'
        : eventProduct.salesChannel?.[0];

    // start date
    eventProduct.startSellingDate = this.#helperDateService.format(
      eventProduct.startSellingDate
    );
    eventProduct.startSelling = this.#helperDateService.utc(
      `${eventProduct.startSellingDate}T${eventProduct.startSellingTime}`
    );

    // stop date
    eventProduct.stopSellingDate = this.#helperDateService.format(
      eventProduct.stopSellingDate
    );
    eventProduct.stopSelling = this.#helperDateService.utc(
      `${eventProduct.stopSellingDate}T${eventProduct.stopSellingTime}`
    );

    eventProduct.updatedBy = this.#connectedUserId();
    eventProduct.updatedAt = 'now()';

    return this._eventProductUpdate
      .mutate(
        {
          _set: eventProduct,
          eventProductId: eventProduct.eventProductId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(response => {
          return !!response;
        })
      );
  }

  deleteOne(eventProductId: string): Observable<boolean> {
    return this._eventProductSoftDelete
      .mutate(
        {
          eventProductId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map((response: any) => {
          return !!response;
        })
      );
  }

  /**
   * todo: refactor
   */
  cloneEventProduct(
    _eventProducts: TixEventProduct[],
    eventInstanceId: string
  ) {
    const createdBy = this.#connectedUserId();
    const createdAt = 'now()';
    const eventProducts: Array<Record<string, any>> = [];
    const mediaFiles = [];
    //
    for (let i = 0; i < _eventProducts.length; i++) {
      const _mediaFiles = _eventProducts[i].EventProductMedia;

      const _eventProduct = this.#helperArrayService.omit(_eventProducts[i], [
        'EventProductMedia_aggregate',
        'EventProductMedia',
        '__typename'
      ]);

      const eventProductId = this.#helperStringService.uuid();

      const eventProduct: any = {
        ..._eventProduct,
        eventProductId,
        eventInstanceId,
        createdAt,
        updatedAt: createdAt,
        createdBy,
        updatedBy: createdBy
      };

      if (_mediaFiles?.length >= 1) {
        for (let i = 0; i < _mediaFiles.length; i++) {
          const _file = _mediaFiles[i].MediaFile;
          const MediaFile: any = {
            data: {
              name: _file?.name,
              location: _file?.location,
              fileType: _file?.fileType
            }
          };
          mediaFiles.push({
            eventProductId,
            eventProductMediaImageFileId: this.#helperStringService.uuid(),
            createdBy,
            updatedBy: createdBy,
            createdAt,
            updatedAt: createdAt,
            MediaFile
          });
        }
      }
      eventProducts.push(eventProduct);
    }

    return this._eventProductInsert
      .mutate(
        {
          eventProducts,
          mediaFiles
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(response => {
          return !!response.data;
        })
      );
  }

  //
  #connectedUserId(): string {
    return localStorage.getItem('id') ?? '';
  }
}
