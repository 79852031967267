import { Component, Input } from '@angular/core';

@Component({
  selector: 'tix-event-multi-stepper',
  templateUrl: './event-multi-stepper.component.html'
})
export class EventMultiStepperComponent {
  @Input() currentStep!: number;
  @Input() steps!: string[];
}
