<header class="tix-header-wrapper">
  <div class="tix-header-row">
    <div class="tix-header-branding">
      <div class="tix-logo">
        <img
          onerror="this.src='https://asylumtixmedia.sfo3.digitaloceanspaces.com/asylumTix/default_images/ticket_logo.jpg'"
          [src]="!venueMedias ? companyMedias : venueMedias"
          alt=""
        />
        <a *ngIf="venueUrl" [attr.href]="venueUrl" target="_blank">
          <h1>{{ venueInfo?.name }}</h1>
        </a>
        <h1 *ngIf="!venueUrl">{{ venueInfo?.name }}</h1>
      </div>
      <div class="tix-address">
        <h6>{{ formattedVenueAddress() }}</h6>
      </div>
    </div>
    <div>
      <span
        *ngIf="showClearFilters$ | async"
        class="underline text-primary cursor-pointer"
        (click)="clearFilters()"
      >
        Clear Filters
      </span>
    </div>
    <div *ngIf="showTestMode" class="flex items-center ml-3">
      <tix-icon
        svgIcon="adjustments"
        class="text-red-500 w-4 h-4 rotate-90"
      ></tix-icon>
      <p class="ml-2 text-sm text-red-500 font-medium">Test Mode</p>
    </div>
  </div>

  <div class="block sm:hidden relative">
    <div *ngIf="showTestMode" class="flex items-center absolute top-3 right-0">
      <tix-icon
        svgIcon="adjustments"
        class="text-red-500 w-4 h-4 rotate-90"
      ></tix-icon>
      <p class="ml-2 text-sm text-red-500 font-medium">Test Mode</p>
    </div>

    <div class="tix-header-branding !py-6">
      <div class="tix-logo">
        <img
          onerror="this.src='https://asylumtixmedia.sfo3.digitaloceanspaces.com/asylumTix/default_images/ticket_logo.jpg'"
          [src]="!venueMedias ? companyMedias : venueMedias"
          alt=""
        />
      </div>
      <div class="tix-address space-y-3">
        <h1>{{ venueInfo?.name }}</h1>
        <a class="underline" [href]="getVenueAddressOnMapURL()" target="_blank">
          <h6>{{ formattedVenueAddress() }}</h6>
        </a>

        <div>
          <div
            class="text-gray-900 text-sm"
            *ngFor="let phoneNumber of phoneNumbers"
          >
            <strong>{{ phoneNumber.type }}: </strong
            ><a [href]="getPhoneNumberHref(phoneNumber.phoneNumber)">
              <span class="!text-gray-900 underline sm:no-underline">
                {{ formatPhoneNumber(phoneNumber.phoneNumber) }}
              </span>
            </a>
          </div>
        </div>

        <div>
          <span
            *ngIf="showClearFilters$ | async"
            class="underline text-primary cursor-pointer"
            (click)="clearFilters()"
          >
            Clear Filters
          </span>
        </div>
      </div>
    </div>
  </div>
</header>

<header class="tix-header-wrapper !static opacity-0">
  <div class="tix-header-row">
    <div class="tix-header-branding">
      <div class="tix-logo">
        <img
          onerror="this.src='https://asylumtixmedia.sfo3.digitaloceanspaces.com/asylumTix/default_images/ticket_logo.jpg'"
          [src]="!venueMedias ? companyMedias : venueMedias"
          alt=""
        />
        <a *ngIf="venueUrl" [attr.href]="venueUrl" target="_blank">
          <h1>{{ venueInfo?.name }}</h1>
        </a>
        <h1 *ngIf="!venueUrl">{{ venueInfo?.name }}</h1>
      </div>
      <div class="tix-address">
        <h6>{{ formattedVenueAddress() }}</h6>
      </div>
    </div>
    <div>
      <span
        *ngIf="showClearFilters$ | async"
        class="underline text-primary cursor-pointer"
        (click)="clearFilters()"
      >
        Clear Filters
      </span>
    </div>
    <div *ngIf="showTestMode" class="flex items-center ml-3">
      <tix-icon
        svgIcon="adjustments"
        class="text-red-500 w-4 h-4 rotate-90"
      ></tix-icon>
      <p class="ml-2 text-sm text-red-500 font-medium">Test Mode</p>
    </div>
  </div>

  <div class="block sm:hidden relative">
    <div *ngIf="showTestMode" class="flex items-center absolute top-3 right-0">
      <tix-icon
        svgIcon="adjustments"
        class="text-red-500 w-4 h-4 rotate-90"
      ></tix-icon>
      <p class="ml-2 text-sm text-red-500 font-medium">Test Mode</p>
    </div>

    <div class="tix-header-branding !py-6">
      <div class="tix-logo">
        <img
          onerror="this.src='https://asylumtixmedia.sfo3.digitaloceanspaces.com/asylumTix/default_images/ticket_logo.jpg'"
          [src]="!venueMedias ? companyMedias : venueMedias"
          alt=""
        />
      </div>
      <div class="tix-address space-y-3">
        <h1>{{ venueInfo?.name }}</h1>
        <a class="underline" [href]="getVenueAddressOnMapURL()" target="_blank">
          <h6>{{ formattedVenueAddress() }}</h6>
        </a>

        <div>
          <div
            class="text-gray-900 text-sm"
            *ngFor="let phoneNumber of phoneNumbers"
          >
            <strong>{{ phoneNumber.type }}: </strong
            ><a [href]="getPhoneNumberHref(phoneNumber.phoneNumber)">
              <span class="!text-gray-900 underline sm:no-underline">
                {{ formatPhoneNumber(phoneNumber.phoneNumber) }}
              </span>
            </a>
          </div>
        </div>

        <div>
          <span
            *ngIf="showClearFilters$ | async"
            class="underline text-primary cursor-pointer"
            (click)="clearFilters()"
          >
            Clear Filters
          </span>
        </div>
      </div>
    </div>
  </div>
</header>
