<div class="flex flex-col justify-center mb-0 overflow-hidden">
  <ng-container *ngIf="images.length > 0">
    <div *ngFor="let img of images; index as i">
      <img
        @fadeInRight
        [src]="img"
        class="rounded-t-md border overflow-hidden w-[266px] md:w-[330px] h-64 md:h-80"
        [alt]="'image'"
        [ngClass]="i === idx ? '' : 'hidden'"
      />
    </div>
  </ng-container>

  <div class="flex flex-row justify-between items-center py-2 pr-2 w-full">
    <div class="font-bold pl-2 text-sm md:text-xl line-clamp-1">
      {{ title }}
    </div>
    <div *ngIf="images.length > 1" class="flex justify-center space-x-2">
      <div class="h-full self-center">
        <span
          (click)="previous()"
          class="flex w-8 h-8 p-1 font-semibold cursor-pointer hover:text-primary hover:border hover:border-primary border rounded-full"
        >
          <mat-icon class="h-6 w-6">arrow_back</mat-icon>
        </span>
      </div>
      <div class="h-full self-center">
        <span
          (click)="next()"
          class="flex w-8 h-8 p-1 font-semibold cursor-pointer hover:text-primary hover:border hover:border-primary border rounded-full"
        >
          <mat-icon>arrow_forward</mat-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="ml-2 pt-1 pl-0 w-64 md:w-80 max-h-[22vh] overflow-y-scroll">
    <quill-view-html [content]="data.additionalInfo"></quill-view-html>
  </div>
</div>
