import { Injectable } from '@angular/core';
import {
  TixGetCustomerByEmailGQL,
  TixInsertCustomerGQL,
  TixUpdateCustomerByEmailGQL
} from '../../../../data-access/src';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(
    private getCustomerByEmail: TixGetCustomerByEmailGQL,
    private insertCustomer: TixInsertCustomerGQL,
    private updateCustomerByEmail: TixUpdateCustomerByEmailGQL
  ) {}

  async getCustomer(email: string) {
    const data = await this.getCustomerByEmail
      .fetch({ customerEmail: email })
      .toPromise();

    return data.data.Customer ?? [];
  }

  async insertOneCustomer(data: any) {
    const newCustomer = await this.insertCustomer
      .mutate({
        firstName: data.fname,
        lastName: data.lname,
        contact_id: data.contactId || null,
        emailAddress: data.email,
        dob: data.dob || null,
        phoneNumber: data.phoneNumber,
        sms: data.sms,
        emails: data.emails,
        stAddress: data.address,
        city: data.city,
        countryCode: data.country,
        stateProvince: data.stateProvince,
        postalCode: data.postalCode,
        suiteApartment: data.apartment
      })
      .toPromise();

    return newCustomer;
  }

  async updateCustomer(data: any) {
    const updatedCustomer = await this.updateCustomerByEmail
      .mutate({
        firstName: data.fname,
        lastName: data.lname,
        contact_id: data.contactId,
        emailAddress: data.email,
        dob: data.dob || null,
        phoneNumber: data.phoneNumber,
        sms: data.sms,
        emails: data.emails,
        stAddress: data.address,
        city: data.city,
        countryCode: data.country,
        stateProvince: data.stateProvince,
        postalCode: data.postalCode,
        suiteApartment: data.apartment
      })
      .toPromise();

    return updatedCustomer;
  }
}
