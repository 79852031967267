import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * Todo: this interceptor should be global for all http requests
 */

@Injectable()
export class AddonsInterceptor implements HttpInterceptor {
  #updateOperations = [
    'productMediaFileInsertOne',
    'productInsertOne',
    'productUpdate',
    // ============ //
    'eventProductInsert',
    'eventProductMediaInsertOne',
    'eventProductUpdate',
    // ============ //
    'InsertScheduledEventReport',
    'UpdateScheduledEventReport'
  ];
  #deleteOperations = [
    'productSoftDelete',
    'productMediaFileDeleteByPK',
    // ============ //
    'eventProductSoftDelete',
    'eventProductEventProductMediaDelete',
    // ============ //
    'DeleteScheduledEventReport'
  ];

  constructor(private _snackBar: MatSnackBar) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        return throwError(() => {
          return err.message;
        });
      }),
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.ok) {
            if (this.#isUpdate(req.body?.operationName)) {
              if (event.body?.errors?.[0]?.message) {
                this._snackBar.open(event.body?.errors[0]?.message, '❌', {
                  duration: 4000,
                  horizontalPosition: 'right'
                });
                return;
              }

              this._snackBar.open('Updated successfully', '✅', {
                duration: 1500,
                horizontalPosition: 'right'
              });
            }

            if (this.#isDelete(req.body?.operationName)) {
              if (event.body?.errors?.[0]?.message) {
                this._snackBar.open(event.body?.errors[0]?.message, '❌', {
                  duration: 4000,
                  horizontalPosition: 'right'
                });
                return;
              }

              this._snackBar.open('Deleted successfully', '✅', {
                duration: 1500,
                horizontalPosition: 'right'
              });
            }
          }
        }
      })
    );
  }

  #isUpdate(operationName: string): boolean {
    return this.#updateOperations.includes(operationName);
  }

  #isDelete(operationName: string): boolean {
    return this.#deleteOperations.includes(operationName);
  }
}
