import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixTicketConfig,
  TixVenueTicketConfig
} from '@tix/data-access';
import { TixBusinessGroup } from '@tix/shared/state';
import { TixFeesService } from '@tix/shared/utils';
import { Subscription } from 'rxjs';
import { debounceTime, startWith, take, tap } from 'rxjs/operators';
import { createNumberMask } from 'text-mask-addons';
import { TicketConfigurationExpandContent } from '../ticket-configuration-expand/ticket-configuration-expand.component';
import { calculateTotalTicketsForConfigurationContent } from '../ticket-configuration-admin/ticket-configuration-admin.helpers';
import { TixConfirmDialogService } from '@tix/shared/ui/components';
import * as moment from 'moment';

@Component({
  selector: 'tix-ticket-configuration-section-config',
  templateUrl: './ticket-configuration-section-config.component.html',
  styleUrls: ['./ticket-configuration-section-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketConfigurationSectionConfigComponent
  implements OnInit, OnDestroy
{
  constructor(
    private feesService: TixFeesService,
    private confirmDialogService: TixConfirmDialogService
  ) {}

  @Input()
  companyId: string;

  @Input()
  venueId: string;

  @Input()
  formGroup: FormGroup;

  @Input()
  ticketTypeBusinessCodeGroup: Maybe<TixBusinessGroup>;

  @Input()
  ticketConfigs: TicketConfigurationExpandContent[];

  @Input()
  ticketConfig: Maybe<TixVenueTicketConfig>;

  @Input()
  content: TicketConfigurationExpandContent;
  @Input()
  parentContent: TicketConfigurationExpandContent;

  @Input()
  disablePools = false;

  sub: Subscription;

  get availableTicketPoolConfigs() {
    return this.ticketConfigs
      .filter(t => t.id !== this.content.id)
      .filter(t => !t.formGroup?.value.parentTicketId);
  }
  get availableSaleChannels() {
    const channel = ['online', 'boxoffice'];
    return channel;
  }

  readonly currencyMask = createNumberMask({
    allowDecimal: true
  });

  get perOrderMinFormControl() {
    return this.formGroup.get('perOrderMin') as FormControl;
  }
  get helpTextFormControl() {
    return this.formGroup.get('helpText') as FormControl;
  }
  get helpTextTitleFormControl() {
    return this.formGroup.get('helpTextTitle') as FormControl;
  }
  get perOrderMaxFormControl() {
    return this.formGroup.get('perOrderMax') as FormControl;
  }

  get priceFormControl() {
    return this.formGroup.get('price') as FormControl;
  }

  get processingFeeFormControl() {
    return this.formGroup.get('processingFee') as FormControl;
  }

  get platformFeeFormControl() {
    return this.formGroup.get('platformFee') as FormControl;
  }

  get parentTicketIdFormControl() {
    return this.formGroup.get('parentTicketId') as FormControl;
  }

  get quantityFormControl() {
    return this.formGroup.get('quantity') as FormControl;
  }

  get includeFeesFormControl() {
    return this.formGroup.get('includeFees') as FormControl;
  }

  get startSellingDateFormControl() {
    return this.formGroup.get('startSellingDate') as FormControl;
  }
  get stopSellingDateFormControl() {
    return this.formGroup.get('stopSellingDate') as FormControl;
  }
  get startSellingTimeFormControl() {
    return this.formGroup.get('startSellingTime') as FormControl;
  }
  get stopSellingTimeFormControl() {
    return this.formGroup.get('stopSellingTime') as FormControl;
  }

  fromVenuePage = true;

  get configTicketType() {
    return this.getTheRemainingTickets();
  }

  private getTheRemainingTickets() {
    const sectionConfiguration = this.content.originalValue
      ?.sectionConfiguration as any; /* as Maybe<TixTicketConfig> */

    if (sectionConfiguration && sectionConfiguration.totalTicketsByType) {
      //Get the Parent id from formGroup
      const ticketParentId = this.formGroup.value.parentTicketId;
      //Get All Parent Configuration
      const parentTicketConfigurations =
        this.content.parent?.originalValue?.ticketConfiguration?.Configurations;

      // This condition when we have a child ticket config
      if (
        ticketParentId &&
        sectionConfiguration['TicketConfigurations'].noOfSeats
      ) {
        const parentTicket = parentTicketConfigurations?.find(
          ticket =>
            ticket.TicketConfigurations?.ticketConfigId === ticketParentId
        ) as any;
        if (
          parentTicket &&
          sectionConfiguration.totalTicketsByType.ticketsRemaining >
            parentTicket.totalTicketsByType.ticketsRemaining
        ) {
          return {
            ...sectionConfiguration?.totalTicketsByType,
            ticketsRemaining: parentTicket.totalTicketsByType.ticketsRemaining
          };
        }
      } else if (
        ticketParentId &&
        !sectionConfiguration['TicketConfigurations'].noOfSeats
      ) {
        const ticketFilter: { parent: any[]; children: any[] } = {
          parent: [],
          children: []
        };

        parentTicketConfigurations?.forEach(ticket => {
          if (ticket.TicketConfigurations?.ticketConfigId === ticketParentId) {
            ticketFilter.parent.push(ticket);
          } else if (
            ticket.TicketConfigurations?.parentTicketId === ticketParentId
          ) {
            ticketFilter.children.push(ticket);
          }
        });
        if (ticketFilter && ticketFilter.children.length > 0) {
          const parentTicketRemaining =
            ticketFilter.parent[0]['totalTicketsByType'].ticketsRemaining;
          const totalSoldFromChildren = ticketFilter.children.reduce(
            (acc, ticket) => acc + ticket['totalTicketsByType'].ticketsSold,
            0
          );

          return {
            ...sectionConfiguration?.totalTicketsByType,
            ticketsRemaining: parentTicketRemaining - totalSoldFromChildren
          };
        }
      } else if (
        // This condition when we have a parent ticket config
        // We will need to calculate the remaining ticket from the children also,
        // because it's shared quantity with the children and parent
        !ticketParentId &&
        sectionConfiguration &&
        sectionConfiguration['TicketConfigurations'].noOfSeats
      ) {
        const children = parentTicketConfigurations?.filter(ticket => {
          {
            return (
              ticket.TicketConfigurations?.parentTicketId ===
              sectionConfiguration['TicketConfigurations'].ticketConfigId
            );
          }
        });

        if (children) {
          const totalSoldFromChildren = children.reduce(
            (acc, ticket: any) =>
              acc + ticket['totalTicketsByType'].ticketsSold,
            0
          );

          return {
            ...sectionConfiguration?.totalTicketsByType,
            ticketsRemaining:
              sectionConfiguration?.totalTicketsByType.ticketsRemaining -
              totalSoldFromChildren
          };
        }
      }
    }

    // This default value when we have child ticket with it's owen quantity (limited)
    // But it still need to be calculated also with children (on 2nd condition)
    return sectionConfiguration?.totalTicketsByType;
  }

  async ngOnInit() {
    const venueFeeControl = this.formGroup.controls['venueFee'] as FormControl;

    if (this.disablePools) {
      this.parentTicketIdFormControl.disable();
      this.parentTicketIdFormControl.setValue(undefined);
    }

    this.sub = this.priceFormControl.valueChanges
      .pipe(startWith(this.priceFormControl.value), debounceTime(400))
      .subscribe(price => this.onPriceChange(price));

    this.sub.add(
      venueFeeControl.valueChanges
        .pipe(startWith(venueFeeControl.value), debounceTime(400))
        .subscribe(() => this.onPriceChange(this.priceFormControl.value))
    );

    let lastParentTicketId: string;

    this.sub.add(
      this.parentTicketIdFormControl.valueChanges
        .pipe(
          startWith(this.parentTicketIdFormControl.value),
          tap(value => {
            if (value) {
              //this.quantityFormControl.disable();
              const parentTicket = this.ticketConfigs?.find(
                ticket => ticket.id === value
              );

              parentTicket?.formGroup?.get('parentTicketId')?.disable();

              //this.quantityFormControl.setValue(0);

              this.content.secondarySection.value = `Shared w/ ${parentTicket?.formGroup?.value.name}`;
              this.parentContent.secondarySection.value =
                calculateTotalTicketsForConfigurationContent(
                  this.parentContent
                )?.toString();
            } else {
              this.quantityFormControl.enable();
              this.content.secondarySection.value =
                this.quantityFormControl.value.toString();

              this.parentContent.secondarySection.value =
                calculateTotalTicketsForConfigurationContent(
                  this.parentContent
                )?.toString();
            }

            if (lastParentTicketId) {
              const parentTicket = this.ticketConfigs?.find(
                ticket => ticket?.id === lastParentTicketId
              );

              if (
                this.parentContent?.children?.every(
                  child =>
                    child.formGroup?.get('parentTicketId')?.value !==
                    parentTicket?.id
                )
              ) {
                parentTicket?.formGroup?.get('parentTicketId')?.enable();
              }
            }

            lastParentTicketId = value;
          })
        )
        .subscribe()
    );

    this.sub.add(
      this.quantityFormControl.valueChanges
        .pipe(
          tap(value => {
            this.parentContent.secondarySection.value =
              calculateTotalTicketsForConfigurationContent(
                this.parentContent
              ).toString();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onPriceChange(price: string) {
    const amount = this.feesService.dollarFigureToNumber(price);
    if (amount === 0) {
      this.formGroup.controls['venueFee'].setValue(0);
    }

    this.feesService
      .getPlatformFee(amount, {
        venueId: this.venueId,
        companyId: this.companyId,
        venueFee: this.feesService.dollarFigureToNumber(
          this.formGroup.controls['venueFee'].value
        )
      })
      .then(fee =>
        this.platformFeeFormControl.setValue(
          this.feesService.formatAsDollarFigure(fee)
        )
      );
    this.feesService
      .calculateProcessingFeeForAmount(amount, {
        venueId: this.venueId,
        companyId: this.companyId
      })
      .then(fee =>
        this.processingFeeFormControl.setValue(
          this.feesService.formatAsDollarFigure(fee)
        )
      );
  }

  async onIncludeFeesChange(newValue: boolean) {
    const confirmed = await this.confirmDialogService.promptConfirm({
      title: 'Are you sure about this change?',
      titleTheme: 'warn',
      confirmText: 'This change will affect all the configurations',
      confirmButton: {
        label: 'Yes, I want to modify all the configurations',
        theme: 'warn'
      },
      cancelButton: {
        label: 'No, cancel'
      }
    });

    if (confirmed) {
      this.parentContent?.children?.forEach(child => {
        child.formGroup?.get('includeFees')?.setValue(newValue);
      });
    } else {
      this.includeFeesFormControl.setValue(!newValue);
    }
  }

  submit() {
    console.table(this.formGroup.value);
  }

  markAllAsTouched() {
    this.formGroup.markAllAsTouched();
  }

  clearStartTime() {
    this.startSellingTimeFormControl.setValue(null);
  }
  clearStopTime() {
    this.stopSellingTimeFormControl.setValue(null);
  }
}
