import { Directive, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `TixSuffix`. It serves as
 * alternative token to the actual `TixSuffix` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const TIX_FORM_FIELD_SUFFIX = new InjectionToken<TixFormFieldSuffix>(
  'TixSuffix'
);

/** Suffix to be placed at the end of the form field. */
@Directive({
  selector: '[tixSuffix]',
  providers: [
    { provide: TIX_FORM_FIELD_SUFFIX, useExisting: TixFormFieldSuffix }
  ]
})
export class TixFormFieldSuffix {}
