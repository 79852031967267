import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EVENTS_FEATURE_KEY, State, eventsAdapter } from '../events.reducer';

// Lookup the 'Events' feature state managed by NgRx
export const getEventsState = createFeatureSelector<State>(EVENTS_FEATURE_KEY);

const { selectAll, selectEntities } = eventsAdapter.getSelectors();

export const getEventsLoaded = createSelector(
  getEventsState,
  (state: State) => state.loaded
);

export const getEventsLoading = createSelector(
  getEventsState,
  (state: State) => state.loading
);

export const getTotalOfEventsMatchingSearch = createSelector(
  getEventsState,
  (state: State) => state.totalEventsMatchingSearch
);

export const getRecurringEventsLoading = createSelector(
  getEventsState,
  (state: State) => state.createRecurringEventsLoading
);
export const getRecurringEventsLoaded = createSelector(
  getEventsState,
  (state: State) => state.createRecurringEventsLoaded
);

export const getEventsError = createSelector(
  getEventsState,
  (state: State) => state.error
);

export const getAllEvents = createSelector(getEventsState, (state: State) =>
  selectAll(state)
);

export const getEventsEntities = createSelector(
  getEventsState,
  (state: State) => selectEntities(state)
);
export const getSelectedId = createSelector(
  getEventsState,
  (state: State) => state.selectedEventId
);
export const getClonedId = createSelector(
  getEventsState,
  state => state.idofClonedEvent
);

export const getSelectedEvent = createSelector(
  getEventsState,
  getSelectedId,
  (state: State, selectedId) => {
    return selectedId ? state.entities[selectedId] : undefined;
  }
);

// get update or add event
export const getEvent = createSelector(
  getEventsState,
  (event: any) => event?.event
);

export const getSelectedEventName = createSelector(
  getSelectedEvent,
  (event?: any) => {
    return event?.eventName && `${event?.eventName}`;
  }
);

export const getSelectedPerformers = createSelector(
  getSelectedEvent,
  (event: any) => event?.Performers
);

export const getSelectedTickets = createSelector(
  getSelectedEvent,
  (event: any) => event?.Tickets
);
export const getVenueLists = createSelector(
  getEventsState,
  (state: State) => state.venues
);
export const getVenueTicketLists = createSelector(
  getEventsState,
  (state: State) => state.venuesTickets
);
