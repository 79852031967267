// import { TixConfirmDeleteDialogService } from './../../../../../../shared/ui/components/src/lib/dialog/confirm-delete-dialog/confirm-delete-dialog.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormValidatorError } from '@tix/ui-common';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EventProduct } from '../models/event-product.model';
import { EventProductsService } from '../services/event-products.service';
import { MediaService } from '../services/media.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { TixConfirmDeleteDialogService } from 'libs/shared/ui/components/src/lib/dialog/confirm-delete-dialog/confirm-delete-dialog.service';
import { HelperDateService } from '@tix/util-common';

// form properties
enum ENUM_EVENT_PRODUCTS_FORM {
  ID = 'eventProductId',
  NAME = 'name',
  STATE = 'state',
  QUANTITY = 'quantity',
  PRICE = 'price',
  TAX = 'tax',
  DESCRIPTION = 'additionalInfo',
  SALES_CHANNEL = 'salesChannel',
  START_SELLING_DATE = 'startSellingDate',
  START_SELLING_TIME = 'startSellingTime',
  STOP_SELLING_DATE = 'stopSellingDate',
  STOP_SELLING_TIME = 'stopSellingTime'
}

@Component({
  selector: 'tix-ui-event-products-update',
  templateUrl: './event-products-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MediaService]
})
export class EventProductsUpdateComponent implements OnInit, OnDestroy {
  //
  title = 'Update add-on';
  //
  #helperDateService = new HelperDateService();
  //
  eventProductForm!: FormGroup;
  eventProductFormProps = ENUM_EVENT_PRODUCTS_FORM;
  defaultValue!: any;
  //
  stateOptions = ['Active', 'Inactive'];
  salesChannelOptions = ['online', 'boxoffice'];

  //
  files: Record<string, any>[] = [];
  progress$: Observable<number> = this._mediaService.progress$;

  //
  eventProducts: Array<EventProduct> = [];
  #destroyed = new Subject<void>();

  //
  constructor(
    public dialogRef: MatDialogRef<EventProductsUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _eventProductsService: EventProductsService,
    private _mediaService: MediaService,
    private _confirmDeleteDialogService: TixConfirmDeleteDialogService,
    private _changeDetectorRef: ChangeDetectorRef // to review !
  ) {}

  //
  ngOnInit(): void {
    this.#initForm();
    this.#patchFormValues();
    this.#trackQuantityRemaining();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  //
  update() {
    const updated = this.eventProductForm.value;
    const remaining = this.defaultValue.quantityRemaining;
    const existingQuantity = this.defaultValue.quantity;

    // quantity sold
    if (existingQuantity !== updated.quantity) {
      if (updated.quantity > existingQuantity) {
        updated.quantityRemaining =
          remaining + (updated.quantity - existingQuantity);
      } else if (updated.quantity < existingQuantity) {
        const quantityLeft /* remaining*/ =
          remaining - (existingQuantity - updated.quantity);
        updated.quantityRemaining = quantityLeft < 0 ? 0 : quantityLeft;
      }
    }

    this._eventProductsService.updateOne(updated).pipe(take(1)).subscribe();

    //
    this.closeDialog();
  }

  async deleteOne(eventProductId: string) {
    const result = await this._confirmDeleteDialogService.confirmDelete({
      confirmationText: this.defaultValue.name
    });
    if (result) {
      this._eventProductsService
        .deleteOne(eventProductId)
        .pipe(take(1))
        .subscribe();
      this.closeDialog();
    }
  }

  //
  async onRemoveFile(
    i: number,
    eventProductMediaImageFileId: string,
    mediaFileId: string
  ) {
    const fileName =
      this.files.find(
        file =>
          file['eventProductMediaImageFileId'] == eventProductMediaImageFileId
      )?.name ?? '';

    const result = await this._confirmDeleteDialogService.confirmDelete({
      confirmationText: fileName
    });

    if (result) {
      this.files = this.files.filter((f, index) => index !== i);

      this._mediaService.removeFile(eventProductMediaImageFileId, mediaFileId);

      this.closeDialog();
    }
  }

  fileBrowseHandler(files: any) {
    if (files?.length == 0) return;
    this.files.push(files[0]);
    this._mediaService
      .uploadMedia(files, this.data.eventProductId)
      .subscribe(file => {
        if (file?.location) {
          this.files.pop();
          this.files.push(file);
          this._changeDetectorRef.markForCheck();
        }
      });
  }

  onFileDropped(files: any) {
    this.files.push(files[0]);
    if (files?.length > 0)
      this._mediaService
        .uploadMedia(files, this.data.eventProductId)
        .subscribe(file => {
          if (file?.location) {
            this.files.pop();
            this.files.push(file);
            this._changeDetectorRef.markForCheck();
          }
        });
  }

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  //
  closeDialog() {
    this.dialogRef.close();
  }

  //
  getInputError(ctrl: AbstractControl, name: string) {
    return FormValidatorError.getFormControlErrorText(ctrl, name);
  }

  // -> performance
  trackByFn(index: number, item: any) {
    return item.location;
  }

  //
  #initForm() {
    this.eventProductForm = new FormGroup({
      [ENUM_EVENT_PRODUCTS_FORM.ID]: new FormControl('' /** not required */),
      [ENUM_EVENT_PRODUCTS_FORM.NAME]: new FormControl('Product name', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(24)
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.STATE]: new FormControl('Active', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.QUANTITY]: new FormControl(100, [
        Validators.min(0),
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.PRICE]: new FormControl(1.0, [
        Validators.required,
        Validators.min(1)
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.TAX]: new FormControl(0, [Validators.max(60)]),
      [ENUM_EVENT_PRODUCTS_FORM.DESCRIPTION]: new FormControl('', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.SALES_CHANNEL]: new FormControl('', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.START_SELLING_DATE]: new FormControl('', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.START_SELLING_TIME]: new FormControl('09:00', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.STOP_SELLING_DATE]: new FormControl('', [
        Validators.required
      ]),
      [ENUM_EVENT_PRODUCTS_FORM.STOP_SELLING_TIME]: new FormControl('15:30', [
        Validators.required
      ])
    });
  }

  #patchFormValues() {
    this.defaultValue = this.data;

    if (this.data?.salesChannel) {
      if (this.data.salesChannel === 'both')
        this.data.salesChannel = this.salesChannelOptions;
      else this.data.salesChannel = [this.data.salesChannel];
    }
    this.eventProductForm.patchValue({
      ...this.data,
      startSellingDate: this.getLocalDateAsUTC(
        this.data[ENUM_EVENT_PRODUCTS_FORM.START_SELLING_DATE]
      ),
      stopSellingDate: this.getLocalDateAsUTC(
        this.data[ENUM_EVENT_PRODUCTS_FORM.STOP_SELLING_DATE]
      )
    });

    if (this.data?.EventProductMedia?.length > 0)
      this.files = this.data.EventProductMedia.filter(
        (eventProduct: any) => !!eventProduct.MediaFile
      ).map((eventProduct: any) => ({
        ...eventProduct.MediaFile,
        eventProductMediaImageFileId: eventProduct.eventProductMediaImageFileId
      }));
  }

  #trackQuantityRemaining() {
    this._eventProductsService
      .eventProduct(this.data.eventProductId)
      .pipe(takeUntil(this.#destroyed))
      .subscribe(qty => {
        this.defaultValue.quantityRemaining = qty.quantityRemaining;
        this.defaultValue.quantity = qty.quantity;
        this.eventProductForm
          .get(ENUM_EVENT_PRODUCTS_FORM.QUANTITY)
          ?.patchValue(qty.quantity);
        this._changeDetectorRef.markForCheck();
      });
  }

  getLocalDateAsUTC(date: string) {
    // todo: return this.#helperDateService.utc(date).toDate();
    return this.#helperDateService.getLocalDateAsUTC(date);
  }
}
