import { Component, Input } from '@angular/core';

@Component({
  selector: 'tix-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class TixAlertComponent {
  @Input() alertType: 'success' | 'info' | 'error' | 'warn';
  @Input() alertMessage: string;
  // @Input() alertStatus: 'Important' | 'Secondary' = `Secondary`;

  getAlertIcon(): string {
    if (this.alertType === 'success') {
      return 'check-circle';
    } else if (this.alertType === 'info' || this.alertType === 'error') {
      return 'error-circle';
    } else {
      return 'error-triangle';
    }
  }

  getAlertColor(): string {
    let color: string;
    if (this.alertType === 'success') {
      color = 'green';
    } else if (this.alertType === 'info') {
      color = 'blue';
    } else if (this.alertType === 'warn') {
      color = 'yellow';
    } else {
      color = 'red';
    }
    return `bg-${color}-300 text-${color}-800`;
  }
}
