import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'tix-check-event-passcode',
  templateUrl: './check-event-passcode.component.html'
})
export class TixCheckEventPasscodeComponent {
  @Input()
  passcode: any;

  @Output()
  eventPasscodeVerified = new EventEmitter<boolean>();

  eventPasscodeControl = new FormControl('', Validators.required);

  isLoading = false;

  constructor(private snackbar: MatSnackBar) {}

  handleSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (this.eventPasscodeControl.invalid) return;

    this.isLoading = true;

    try {
      setTimeout(() => {
        if (this.eventPasscodeControl.value === this.passcode) {
          this.eventPasscodeVerified.emit(true);
          this.eventPasscodeControl.setErrors(null);
          this.eventPasscodeControl.updateValueAndValidity();
        } else {
          this.snackbar.open('Invalid passcode');
          this.eventPasscodeControl.setErrors(
            { invalid: true },
            {
              emitEvent: true
            }
          );
          console.log(this.eventPasscodeControl.errors);
        }

        this.isLoading = false;
      }, 500);
    } catch (e) {
      console.error(e);
      this.snackbar.open('Something went wrong!');
    }
  }

  handleCancel() {
    this.eventPasscodeVerified.emit(false);
  }
}
