import { Maybe } from 'graphql/jsutils/Maybe';
import { TixMediaFile, TixSocialMedia } from '@tix/data-access';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export function setupSocialMediasForm(
  socialMedias: Array<Maybe<TixSocialMedia>>
) {
  const socialMediasForm = new FormGroup({
    socialMedia: createSocialMediaArray(socialMedias)
  });

  if (socialMedias.length > 0) {
    (socialMediasForm.get('socialMedia') as FormArray).clear();
    socialMedias.forEach(socialMedia => {
      (socialMediasForm.get('socialMedia') as FormArray).push(
        createSocialMediaFormGroup(socialMedia)
      );
    });
  }

  return socialMediasForm;
}

export function setupMediasForm(medias: Array<Maybe<TixMediaFile>>) {
  const mediasForm = new FormGroup({
    media: createMediaArray(medias)
  });
  if (medias.length > 0) {
    (mediasForm.get('media') as FormArray).clear();
    medias.forEach(media => {
      (mediasForm.get('media') as FormArray).push(createMediaFormGroup(media));
    });
  }

  return mediasForm;
}

export function createSocialMediaArray(
  socialMedias: Array<Maybe<TixSocialMedia>>
) {
  if (!socialMedias || socialMedias.length === 0)
    return new FormArray([createSocialMediaFormGroup()]);
  return new FormArray(
    socialMedias.map(socialMedia => createSocialMediaFormGroup(socialMedia))
  );
}

export function createMediaArray(medias: Array<Maybe<TixMediaFile>>) {
  if (!medias || medias.length === 0)
    return new FormArray([createMediaFormGroup()]);
  return new FormArray(medias.map(media => createMediaFormGroup(media)));
}

export function createSocialMediaFormGroup(
  socialMedia?: Maybe<TixSocialMedia>
): FormGroup {
  return new FormGroup({
    url: new FormControl(socialMedia?.url ?? ''),
    socialMediaType: new FormControl(socialMedia?.socialMediaType ?? '')
    // socialMediaId: new FormControl(socialMedia?.socialMediaId ?? '')
  } as any);
}
export function createMediaFormGroup(media?: Maybe<TixMediaFile>): FormGroup {
  return new FormGroup({
    location: new FormControl(media?.location ?? ''),
    fileType: new FormControl(media?.fileType ?? ''),
    name: new FormControl(media?.name ?? '')
  });
}
