import {
  EmbeddedViewRef,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify
} from '@angular/core';
import { NgIfContext } from '@angular/common';

export class BaseIfDirective<T = unknown> {
  protected context: NgIfContext<T> = new NgIfContext<T>();
  private thenTemplateRef: TemplateRef<NgIfContext<T>> | null = null;
  private elseTemplateRef: TemplateRef<NgIfContext<T>> | null = null;
  private thenViewRef: EmbeddedViewRef<NgIfContext<T>> | null = null;
  private elseViewRef: EmbeddedViewRef<NgIfContext<T>> | null = null;

  constructor(
    private viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<T>>
  ) {
    this.thenTemplateRef = templateRef;
  }

  protected updateView() {
    if (this.context.$implicit) {
      if (!this.thenViewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        if (this.thenTemplateRef) {
          this.thenViewRef = this.viewContainer.createEmbeddedView(
            this.thenTemplateRef,
            this.context
          );
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.thenViewRef = null;
        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(
            this.elseTemplateRef,
            this.context
          );
        }
      }
    }
  }

  /**
   * Assert and set the Then template reference.
   * @param property to use when asserting the template
   * @param templateRef template reference
   */
  protected setThenRef(
    property: string,
    templateRef: TemplateRef<NgIfContext<T>> | null
  ) {
    assertTemplate(property, templateRef);
    this.thenTemplateRef = templateRef;
    this.thenViewRef = null; // clear previous view if any.
    this.updateView();
  }

  /**
   * Assert and set the Else template reference.
   * @param property to use when asserting the template
   * @param templateRef template reference
   */
  protected setElseRef(
    property: string,
    templateRef: TemplateRef<NgIfContext<T>> | null
  ) {
    assertTemplate(property, templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null; // clear previous view if any.
    this.updateView();
  }
}

function assertTemplate(
  property: string,
  templateRef: TemplateRef<any> | null
): void {
  const isTemplateRefOrNull = !!(
    !templateRef || templateRef.createEmbeddedView
  );
  if (!isTemplateRefOrNull) {
    throw new Error(
      `${property} must be a TemplateRef, but received '${stringify(
        templateRef
      )}'.`
    );
  }
}
