import { createAction, props } from '@ngrx/store';
import {
  TixEventCompanyVenue,
  TixEventInsertInput,
  TixEventPerformers,
  TixInsertEventResult,
  TixVenue
} from '@tix/data-access';
import { Maybe } from 'graphql/jsutils/Maybe';

export const loadEventsSuccess = createAction(
  '[Events/API] Load Events Success',
  props<{ events: TixEventCompanyVenue[]; total: number }>()
);

export const loadEventsFailure = createAction(
  '[Events/API] Load Events Failure',
  props<{ error: any }>()
);

export const loadEventSuccess = createAction(
  '[Event/API] Fetch Event Success',
  props<{ event: any }>()
);

export const loadEventFailure = createAction(
  '[Event Admin Page] Load Event Failure',
  props<{ error: any }>()
);

export const getVenueListSuccess = createAction(
  '[Get Venue Dropdown List] Venue List Success',
  props<{ venues: TixVenue[] }>()
);

export const getVenueListFailure = createAction(
  '[Get Venue Dropdown List] Venue List Failure',
  props<{ error: any }>()
);

export const addNewEventInCompanySuccess = createAction(
  '[Add New Event] Add Event Success',
  props<{ newRecord: TixEventInsertInput }>()
);
export const addRecurringEventsSuccess = createAction(
  '[Add New Event] Add Recurring Event Success'
);

export const addNewEventInCompanyFailure = createAction(
  '[Add New Event] Add Event Failure',
  props<{ error: any }>()
);

export const addRecurringEventFailure = createAction(
  '[Add New Event] Add Recurring Event Failure',
  props<{ error: any }>()
);

export const insertEventPerformerSuccess = createAction(
  '[Add New performers] Add New performers Success',
  props<{ performers: Maybe<Array<TixEventPerformers>> | undefined | {} }>()
);

export const insertEventPerformerFailure = createAction(
  '[Add New performers] Add Event performers Failure',
  props<{ error: any }>()
);

export const updateEventInstanceSuccess = createAction(
  '[Update Event] Update Event Success',
  props<{ updatedRecord: TixEventCompanyVenue }>()
);

export const updateEventInstanceFailure = createAction(
  '[Update Event] Update Event Failure',
  props<{ error: any }>()
);

export const getVenueTicketConfigurationListSuccess = createAction(
  '[Get Venue Ticket List] Venue Ticket List Success',
  props<{ venueTickets: any }>()
);

export const getVenueTicketConfigurationListFailure = createAction(
  '[Get Venue Ticket List] Venue  Ticket List Failure',
  props<{ error: any }>()
);

export const deleteEventPerformerSuccess = createAction(
  '[Company API] Delete Event Performer Success',
  props<{ eventPerformerId: string; eventInstanceId: string }>()
);

export const deleteEventPerformerFailure = createAction(
  '[Company API] Delete Event Performer Failure',
  props<{ error: any }>()
);
