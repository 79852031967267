import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  getSelectedEventsAggregate,
  TixCompanyPartialState
} from '@tix/company/state';
import * as CompanySelectors from '@tix/company/state/selectors';
import * as EventsSelectors from '@tix/events/state';
import * as CustomersService from '@tix/customer/components';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { TixStaffService } from '@tix/staff/state';
import { Roles } from '@tix/auth/state';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'tix-company-sidebar',
  templateUrl: './company-sidebar.component.html',
  styleUrls: ['./company-sidebar.component.scss'],
  host: {
    class: 'tix-company-sidebar'
  }
})
export class TixCompanySidebarComponent implements OnInit {
  readonly companyId$: Observable<string | undefined> = this.store.select(
    CompanySelectors.getSelectedCompanyId
  );
  readonly venuesAggregate$: Observable<number | undefined> = this.store.select(
    CompanySelectors.getSelectedVenuesAggregate
  );
  readonly contactsAggregate$: Observable<number | undefined> =
    this.store.select(CompanySelectors.getSelectedContactsAggregate);
  readonly eventsAggregate$: Observable<number | undefined> = this.store.select(
    CompanySelectors.getSelectedEventsAggregate
  );
  readonly eventsLiveAggregate$: Observable<number | undefined> = this.store
    .select(EventsSelectors.getAllEvents)
    .pipe(map(events => events.length));
  readonly customersAggregate$: Observable<number | undefined> =
    this.companyId$.pipe(
      mergeMap(companyId =>
        this.customerService
          .getCustomerByCompanyId(companyId as string)
          .pipe(map(value => value.length))
      )
    );

  userRolesInCompany$ = this.companyId$.pipe(
    mergeMap(companyId =>
      this.staffService.getCurrentUserRolesInCompany(companyId as string)
    )
  );

  @Input() expandSidebar = true;
  matTooltipPosition: TooltipPosition = 'right';

  constructor(
    private readonly store: Store<TixCompanyPartialState>,
    private staffService: TixStaffService,
    private customerService: CustomersService.TixCustomerService
  ) {}

  isBoxOfficeUser(roles: Roles[]) {
    return roles.includes(Roles.BO_USER);
  }

  isManager(roles: Roles[]) {
    return roles.includes(Roles.MANAGER);
  }

  isAdmin(roles: Roles[]) {
    return roles.includes(Roles.CO_ADMIN);
  }
  isEventAdministrator(roles: Roles[]) {
    return roles.includes(Roles.EVENT_ADMINISTRATOR);
  }

  ngOnInit(): void {}
}
