import * as moment from 'moment';

// Abstract base class for the filters
export abstract class PortalEventsFilter {
  abstract withSearch(
    searchQuery: string,
    filterKeyword: string
  ): PortalEventsFilter;
}

// Used for single event pages
export class PortalSingleEventFilter extends PortalEventsFilter {
  constructor(public eventInstanceId: string) {
    super();
  }

  withSearch(
    searchQuery: string,
    filterKeyword: string
  ): PortalEventsDateAndSearchFilter {
    return new PortalInfiniteEventsFilter(searchQuery, 1, filterKeyword);
  }
}

export class PortalEventsDateAndSearchFilter extends PortalEventsFilter {
  constructor(
    public searchQuery: string,
    public pageNumber: number,
    public filterKeyword: string,
    public startDate: Date,
    public endDate?: Date
  ) {
    super();
  }

  static nextPageFrom({
    searchQuery,
    pageNumber,
    filterKeyword,
    startDate,
    endDate
  }: PortalEventsDateAndSearchFilter): PortalEventsDateAndSearchFilter {
    return new PortalEventsDateAndSearchFilter(
      searchQuery,
      pageNumber + 1,
      filterKeyword,
      startDate,
      endDate
    );
  }

  withSearch(
    searchQuery: string,
    filterKeyword: string
  ): PortalEventsDateAndSearchFilter {
    return new PortalEventsDateAndSearchFilter(
      searchQuery,
      1,
      filterKeyword,
      this.startDate,
      this.endDate
    );
  }
}

export class PortalInfiniteEventsFilter extends PortalEventsDateAndSearchFilter {
  constructor(
    searchQuery: string,
    pageNumber: number = 1,
    filterKeyword: string
  ) {
    super(searchQuery, pageNumber, filterKeyword, new Date());
  }
}

export class PortalMonthEventsFilter extends PortalEventsDateAndSearchFilter {
  constructor(
    public year: number,
    public month: number,
    searchQuery: string,
    pageNumber: number,
    filterKeyword: string
  ) {
    super(
      searchQuery,
      pageNumber,
      filterKeyword,
      moment().set('year', year).set('month', month).set('date', 1).toDate(),
      moment()
        .set('year', year)
        .set('month', month + 1)
        .set('date', 1)
        .subtract(1, 'day')
        .toDate()
    );
  }
}

export class PortalSingleDayEventsFilter extends PortalEventsDateAndSearchFilter {
  constructor(
    public year: number,
    public month: number,
    public day: number,
    searchQuery: string,
    pageNumber: number,
    filterKeyword: string
  ) {
    super(
      searchQuery,
      pageNumber,
      filterKeyword,
      moment().set('year', year).set('month', month).set('date', day).toDate(),
      moment().set('year', year).set('month', month).set('date', day).toDate()
    );
  }
}
