import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
  selector: 'tix-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixEmptyStateComponent {
  @Input()
  title: string;
  @Input()
  subTitle: string;
}
