<tix-card class="bg-white px-8 py-8 min-w-[35vw]">
  <tix-card-title>
    <tix-icon
      svgIcon="exclamation-circle"
      class="!text-red-500 !w-10 !h-10"
    ></tix-icon>
    <h2 class="text-red-500">Action Confirmation</h2>
  </tix-card-title>

  <form (submit)="handleSubmit($event)">
    <div class="mt-8 mb-5">
      <p class="text-sm mt-4 mb-2">
        Enter <strong>{{ confirmationText }}</strong> to confirm this
        irreversible delete action:
      </p>
      <mat-form-field>
        <mat-label>Confirmation</mat-label>
        <input matInput [formControl]="confirmationControl" />
      </mat-form-field>
    </div>

    <div class="flex justify-end flex-col-reverse lg:flex-row">
      <button
        tix-flat-border-button
        (click)="handleCancel()"
        class="lg:mr-4 mt-4 lg:mt-0 w-full lg:w-auto"
        type="button"
      >
        Cancel
      </button>
      <button
        tix-button
        color="danger"
        [disabled]="!canDelete"
        type="submit"
        class="w-full lg:w-auto"
      >
        Confirm Delete
      </button>
    </div>
  </form>
</tix-card>
