<label [attr.for]="inputId" class="tix-checkbox-layout" #label>
  <div
    class="tix-checkbox-container"
    [class.tix-checkbox-inner-container-no-side-margin]="
      !checkboxLabel.textContent || !checkboxLabel.textContent.trim()
    "
  >
    <input
      #input
      class="tix-checkbox-input"
      type="checkbox"
      [id]="inputId"
      [required]="required"
      [checked]="checked"
      [attr.value]="value"
      [disabled]="disabled"
      [attr.name]="name"
      [tabIndex]="tabIndex"
      [attr.aria-label]="ariaLabel || null"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-checked]="getAriaChecked()"
      [attr.aria-describedby]="ariaDescribedby"
      (change)="onInteractionEvent($event)"
      (click)="onInputClick($event)"
    />
    <svg
      focusable="false"
      class="tix-checkbox-checkmark"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
    </svg>
  </div>

  <span
    class="tix-checkbox-label"
    #checkboxLabel
    (cdkObserveContent)="onLabelTextChange()"
  >
    <!-- Add an invisible span so JAWS can read the label -->
    <span class="hidden">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
