import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { tixConfirmDialogDefaultConfig } from './confirm-dialog.base-values';
import { TixConfirmDialogConfig } from './confirm-dialog.config';

@Component({
  selector: 'tix-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TixConfirmDialogComponent {
  private _config: TixConfirmDialogConfig = tixConfirmDialogDefaultConfig;

  get config() {
    return this._config;
  }

  @Input() set config(confirmDialogConfig: TixConfirmDialogConfig | undefined) {
    this._config = {
      ...this._config,
      ...confirmDialogConfig,
      cancelButton: {
        ...this._config.cancelButton,
        ...confirmDialogConfig?.cancelButton
      },
      confirmButton: {
        ...this._config.confirmButton,
        ...confirmDialogConfig?.confirmButton
      }
    };
  }

  @Output() confirm = new EventEmitter<boolean>();

  confirmClick(value: boolean) {
    this.confirm.emit(value);
  }
}
