import { Directive, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `TixPrefix`. It serves as
 * alternative token to the actual `TixPrefix` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const TIX_FORM_FIELD_PREFIX = new InjectionToken<TixFormFieldPrefix>(
  'TixPrefix'
);

/** Prefix to be placed in front of the form field. */
@Directive({
  selector: '[tixPrefix]',
  providers: [
    { provide: TIX_FORM_FIELD_PREFIX, useExisting: TixFormFieldPrefix }
  ]
})
export class TixFormFieldPrefix {}
