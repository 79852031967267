<tix-card>
  <tix-card-header *ngIf="showHeader">
    <tix-card-title>Address</tix-card-title>
  </tix-card-header>
  <tix-card-content
    [ngClass]="{
      '!px-0': !showHeader
    }"
  >
    <form
      [formGroup]="addressForm"
      class="address-content-layout"
      [class.disabled]="sameAsCompany"
    >
      <!--      <ng-container *ngFor="let address of companyAddresses.CompanyAddressAddresses">-->
      <mat-form-field>
        <mat-label>Street Address</mat-label>
        <input
          matInput
          formControlName="streetAddress"
          type="text"
          name="street-address"
          [readonly]="keepDisableSaveBtn"
        />
        <mat-error>Street Address is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Suite / Apartment</mat-label>
        <input
          matInput
          formControlName="suiteApartment"
          type="text"
          name="suite-apartment"
          [readonly]="keepDisableSaveBtn"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="country"
          name="country"
          [disabled]="keepDisableSaveBtn"
        >
          <mat-option
            *ngFor="let country of countries?.businessCodes"
            [value]="country.name"
            >{{ country.description }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>State / Province</mat-label>
        <mat-select formControlName="stateProvince" name="state-province" [disabled]="keepDisableSaveBtn">
          <mat-option *ngFor="let state of states?.businessCodes" [value]="state.name">{{ state.description
            }}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field class="dashboard-date-picker" appearance="outline">
        <mat-label>State / Province</mat-label>
        <input
          [matAutocomplete]="auto"
          formControlName="stateProvince"
          name="state-province"
          matInput
          type="text"
          [disabled]="keepDisableSaveBtn"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let state of filteredOptions$ | async"
            [value]="state.name"
          >
            {{ state.description }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>City</mat-label>
        <input
          matInput
          formControlName="city"
          type="text"
          name="city"
          [readonly]="keepDisableSaveBtn"
        />
        <mat-error>City is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Zip / Postal Code</mat-label>
        <input
          matInput
          formControlName="postalCode"
          type="text"
          name="postal-code"
          [readonly]="keepDisableSaveBtn"
        />
        <mat-error>Zip / Postal Code is required</mat-error>
      </mat-form-field>

      <!-- </ng-container> -->
    </form>
  </tix-card-content>
  <tix-card-actions
    [ngClass]="{
      '!px-0 !py-0': !showHeader
    }"
    align="end"
  >
    <div
      *ngIf="sameAsCompany !== undefined && companyAddresses.length"
      class="self-center mr-auto"
    >
      <tix-checkbox
        [checked]="sameAsCompany!"
        (change)="checkSameAsCompany($event)"
        [labelPosition]="'before'"
      >
        Is the Address the same as the Company Address?
      </tix-checkbox>
    </div>
    <button *ngIf="showSkipButton" (click)="skipThisCard()" tix-flat-button>
      Skip
    </button>
    <button (click)="onSave()" tix-button [disabled]="!canSave">
      Save
      <span class="ml-1" *tixBtnLoading="isloading"></span>
    </button>
  </tix-card-actions>
</tix-card>
