import { Component, Input } from '@angular/core';

@Component({
  selector: 'tix-expand-chevron',
  templateUrl: './expand-chevron.component.html',
  styleUrls: ['./expand-chevron.component.scss']
})
export class TixExpandChevronComponent {
  @Input() expanded = false;
}
