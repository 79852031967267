import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

import * as LogRocket from 'logrocket';

if (environment.production) {
  LogRocket.init(environment.logrocketAppID);
}

environment.production
  ? Sentry.init({
      dsn: 'https://517e3fe629643307ebcf5e0285deb309@o4506385691967488.ingest.sentry.io/4506388440023040',
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: ['asylumtix.com'],
          routingInstrumentation: Sentry.routingInstrumentation
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true
        }),
        new Sentry.BrowserProfilingIntegration()
      ],
      //tracePropagationTargets: ["localhost"],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      //ignoreErrors: [
      //  /^Uncaught \(in promise\): Error: Http failure response for https:\/\/immense-lunatix\.hasura\.app\/v1\/graphql: 0 Unknown Error\nError: Http failure response for https:\/\/immense-lunatix\.hasura\.app\/v1\/graphql: 0 Unknown Error\n\s+at new ApolloError \(http:\/\/loca\.\.\.\)$/
      //],
      //ignoreErrors:["Uncaught (in promise): Error: Http failure response for https://immense-lunatix.hasura.app/v1/graphql: 0 Unknown Error"],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
      beforeSend(event) {
        if (event.message?.includes('Checkout Error')) {
          // Set a tag that can be used for alert routing in Sentry
          event.tags = { ...event.tags, type: 'checkout' };
        }
        return event;
      }
    })
  : null;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
