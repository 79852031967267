import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor } from './constructor';

export interface CanDisable {
  /** Whether the component is disabled. */
  disabled: boolean;
}

type CanDisableCtor = Constructor<CanDisable> & AbstractConstructor<CanDisable>;

/** Mixin to augment a directive with a `disabled` property. */
export function mixinDisabled<T extends AbstractConstructor<any>>(
  base: T
): CanDisableCtor & T;
export function mixinDisabled<T extends Constructor<any>>(
  base: T
): CanDisableCtor & T {
  return class extends base {
    private isDisabled = false;

    get disabled() {
      return this.isDisabled;
    }
    set disabled(value: any) {
      this.isDisabled = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
