import { Injectable } from '@angular/core';

export class QuantityPool {
  constructor(
    public id: string,
    public quantity: number,
    public quantitySold: number,
    public quantityOnHold: number,
    public childrenItemIds: string[]
  ) {}
}

class QuantityCalculatorOrder {
  constructor(public items: QuantityItem[]) {}
}

export class QuantityItem {
  constructor(
    public id: string,
    public totalQuantity: number | null,
    public quantitySold: number,
    public quantityOnHold: number,
    public quantitySelected: number,
    public pool?: QuantityPool,
    public isPoolParent = false
  ) {
    if (totalQuantity === null && !pool) {
      throw new Error("Total quantity can't be null if pool is undefined");
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrderQuantityCalculatorService {
  constructor() {}

  /**
   * Calculates the quantity remaining for an item
   * If an item is standalone an is not part of a pool, then we consider the item itself as its own pool
   *
   * @param {number} poolTotalQuantity - total pool quantity
   * @param {number} poolSoldQuantity - total quantity sold from the pool
   * @param {number} poolHoldQuantity - total quantity on hold for the pool
   * @param {number} otherPoolItemsSelectedQuantity - total quantity selected for other items in the same pool
   * @return {number} the quantity remaining
   */
  getAmountRemainingForItem(
    poolTotalQuantity: number,
    poolSoldQuantity: number,
    poolHoldQuantity: number,
    otherPoolItemsSelectedQuantity = 0
  ): number {
    return (
      poolTotalQuantity -
      poolSoldQuantity -
      poolHoldQuantity -
      otherPoolItemsSelectedQuantity
    );
  }

  /**
   * Calculates the quantities remaining for an entire order selection
   *
   * @param {QuantityCalculatorOrder} order
   * @return {Array<{itemId: string, remaining: number}>} The amounts remaining
   */
  getAmountsRemainingForOrder(
    order: QuantityCalculatorOrder
  ): { itemId: string; quantityRemaining: number }[] {
    return order.items
      .filter(item => item.totalQuantity !== null)
      .map(item => {
        if (item.pool) {
          if (item.isPoolParent) {
            const poolChildrenQuantitySelected = order.items
              // This gets the pool children items
              .filter(i => item.pool?.childrenItemIds.includes(i.id))
              // This adds up the selected quantities
              .reduce((total, cur) => total + cur.quantitySelected, 0);

            return {
              itemId: item.id,
              quantityRemaining: this.getAmountRemainingForItem(
                item.pool.quantity,
                item.pool.quantitySold,
                item.pool.quantityOnHold,
                poolChildrenQuantitySelected
              )
            };
          } else {
            // This is a child item in the pool
            const otherPoolChildren = order.items.filter(
              i =>
                item.id !== i.id &&
                i.pool?.id === item.pool?.id &&
                !i.isPoolParent
            );
            const poolParent = order.items.find(
              i => i.isPoolParent && i.pool?.id === item.pool?.id
            );

            const otherPoolItemsSelectedQuantity =
              otherPoolChildren.reduce(
                (total, cur) => total + cur.quantitySelected,
                0
              ) + (poolParent?.quantitySelected ?? 0);

            const remainingInPool = this.getAmountRemainingForItem(
              item.pool.quantity,
              item.pool.quantitySold,
              item.pool.quantityOnHold,
              otherPoolItemsSelectedQuantity
            );

            if (!item.totalQuantity)
              return { itemId: item.id, quantityRemaining: remainingInPool };
            else {
              // Child ticket has a max quantity
              const remainingForTicket = this.getAmountRemainingForItem(
                item.totalQuantity,
                item.quantitySold,
                item.quantityOnHold,
                0
              );
              return {
                itemId: item.id,
                quantityRemaining: Math.min(remainingInPool, remainingForTicket)
              };
            }
          }
        } else {
          // This is an independent ticket
          return {
            itemId: item.id,
            quantityRemaining: this.getAmountRemainingForItem(
              item.totalQuantity!,
              item.quantitySold,
              item.quantityOnHold,
              0
            )
          };
        }
      });
  }
}
