import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserPartialState } from '@tix/auth/state';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { map, skipWhile, tap } from 'rxjs/operators';
import { UserStatus } from '@tix/shared/models';

@Injectable()
export class TixAuthorizedGuard implements CanActivate {
  constructor(
    private readonly store: Store<UserPartialState>,
    private readonly router: Router
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const queryParams: Params = {
      r: window.location.pathname + window.location.search
    };

    return this.store.select(AuthSelectors.getUserStatus).pipe(
      skipWhile(status => status === UserStatus.UNKNOWN),
      map(status =>
        status !== UserStatus.LOGGED_IN
          ? this.router.createUrlTree(['auth', 'login'], { queryParams })
          : true
      )
    );
  }
}
