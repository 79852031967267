import { AbstractConstructor, Constructor } from './constructor';
import { ElementRef } from '@angular/core';

export interface CanColor {
  /** Theme color palette for the component. */
  color: ThemePalette;

  /** Default color to fall back to if no value is set. */
  defaultColor: ThemePalette | undefined;
}

type CanColorCtor = Constructor<CanColor> & AbstractConstructor<CanColor>;

export interface HasElementRef {
  elementRef: ElementRef;
}

/** Possible color palette values. */
export type ThemePalette =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warn'
  | 'info'
  | 'dark'
  | 'light'
  | undefined;

/** Mixin to augment a directive with a `color` property. */
export function mixinColor<T extends AbstractConstructor<HasElementRef>>(
  base: T,
  defaultColor?: ThemePalette
): CanColorCtor & T;
export function mixinColor<T extends Constructor<HasElementRef>>(
  base: T,
  defaultColor: ThemePalette = 'primary'
): CanColorCtor & T {
  return class extends base {
    private colorScheme: ThemePalette;
    defaultColor = defaultColor;

    get color(): ThemePalette {
      return this.colorScheme;
    }
    set color(value: ThemePalette) {
      const colorPalette = value || this.defaultColor;

      if (colorPalette !== this.colorScheme) {
        if (this.colorScheme) {
          this.elementRef.nativeElement.classList.remove(
            `tix-${this.colorScheme}`
          );
        }
        if (colorPalette) {
          this.elementRef.nativeElement.classList.add(`tix-${colorPalette}`);
        }

        this.colorScheme = colorPalette;
      }
    }

    constructor(...args: any[]) {
      super(...args);

      // Set the default color that can be specified from the mixin.
      this.color = defaultColor;
    }
  };
}
