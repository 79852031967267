<form class="promocode-search" (submit)="handleSubmit($event)">
  <input
    tixInput
    type="text"
    placeholder="Discount Code"
    [formControl]="discountControl"
    [readonly]="isApplied"
  />
  <button
    tix-button
    type="submit"
    class="apply-btn"
    [disabled]="discountControl.invalid || isApplied"
  >
    <span *ngIf="!isLoading">{{ isApplied ? 'Applied!' : 'Apply' }} </span>
    <span *tixBtnLoading="isLoading"></span>
  </button>
</form>
