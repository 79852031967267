import { createAction, props } from '@ngrx/store';
import {
  TixEventData,
  TixEventEmailInsertInput,
  TixEventInsertInput,
  TixEventInstanceInsertInput,
  TixEventPerformers,
  TixEventPerformersInsertInput,
  TixEventProduct
} from '@tix/data-access';

export const loadEvents = createAction(
  '[Events List Page] Load Events',
  props<{
    query?: string;
    statuses?: string[];
    startDate?: Date;
    endDate?: Date;
    limit?: number;
    offset?: number;
  }>()
);

export const loadEventById = createAction(
  '[Event Admin Page] Load Event',
  props<{ eventInstanceId: string }>()
);

export const getVenueList = createAction(
  '[Get Venue Dropdown List ] Load Venue Dropdown List'
);

export const getVenueTicketConfigurationList = createAction(
  '[Get Venue] Get Venue Ticket List',
  props<{ venueId: string }>()
);

// insert new event action
export const addNewEventInCompany = createAction(
  '[Add New Event] Add Event',
  props<{
    addEventObj: TixEventInsertInput;

    goToEventPage?: boolean;
    addTemplateArr?: TixEventEmailInsertInput[];

    products?: TixEventProduct[];
  }>()
);
export const addRecurringEventInCompany = createAction(
  '[Add New Event] Add Recurring Event',
  props<{ addRecurringEventObj: TixEventData }>()
);

// insert event performers
export const insertEventPerformers = createAction(
  '[Add new performer] Add Performer',
  props<{ performers: TixEventPerformersInsertInput[] }>()
);
//update existing event instance
export const updateEventInCompany = createAction(
  '[Update Event instance] Update Event',
  props<{ updatedObj: TixEventInstanceInsertInput }>()
);

// seleted event and deselct to that event actions
export const selectedEvent = createAction(
  '[Events List Page] Select event',
  props<{ eventInstanceId: string }>()
);

export const deselectEvent = createAction('[Events List Page] Deselect event');

/* #region  Delete Actions */
export const deleteEventPerformer = createAction(
  '[Event Admin Page] Delete Event Performer',
  props<{ performers: TixEventPerformers }>()
);

export const selectIdOfClonedEvent = createAction(
  '[Event Admin Page] select Eventid of clone event',
  props<{ idofClonedEvent: string }>()
);

export const resetLoadStateEvent = createAction(
  '[Event Admin Page] reset loaded loading state'
);

export const clearState = createAction('[Event Admin Page] Clear State');
