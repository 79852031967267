/**
 * Data representing the 'Company' Entity
 */
import { TixCompany } from '@tix/data-access';

export type TixCompanyProfile = Pick<
  TixCompany,
  'companyId' | 'name' | 'url' | 'state' | 'updatedAt' | 'updatedBy'
>;

// export interface CompanyEntity {
//   companyId: string;
//   name: string;
//   url: string;
//   state: string;
//   updatedBy: string;
//   updatedAt: string;
// }

/**
 * Needed by {@link createEntityAdapter} to find the primary id of a {@link CompanyEntity}
 * @param {CompanyEntity} company
 * @returns {string}
 */
export function selectCompanyId(company: TixCompany): string {
  return company.companyId;
}

export function sortCompanies(a: TixCompany, b: TixCompany): number {
  return a.name.localeCompare(b.name);
}

export enum CompanyEditSection {
  PROFILE,
  ADDRESS,
  PHONE_NUMBERS,
  MEDIA,
  SOCIAL_MEDIA,
  CONTACTS
}
