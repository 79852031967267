import { NgModule } from '@angular/core';
import {
  TixCardActions,
  TixCardComponent,
  TixCardContent,
  TixCardFooter,
  TixCardHeaderComponent,
  TixCardSubtitle,
  TixCardTitle
} from './card.component';

@NgModule({
  declarations: [
    TixCardActions,
    TixCardComponent,
    TixCardContent,
    TixCardFooter,
    TixCardHeaderComponent,
    TixCardSubtitle,
    TixCardTitle
  ],
  exports: [
    TixCardActions,
    TixCardComponent,
    TixCardContent,
    TixCardFooter,
    TixCardHeaderComponent,
    TixCardSubtitle,
    TixCardTitle
  ]
})
export class TixCardModule {}
