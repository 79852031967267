import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  TixBusinessCodeGroup,
  TixBusinessCodes,
  TixSocialMedia
} from '@tix/data-access';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TixConfirmDialogService } from '../../dialog';

@Component({
  selector: 'tix-social-media-edit',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class TixSocialMediaComponent implements OnInit, OnChanges {
  constructor(private confirmDialogService: TixConfirmDialogService) {}

  @Input() socialMedias: Maybe<TixSocialMedia>[] | null | undefined;
  @Input() socialMediaBusinessCodeGroup: Maybe<
    Pick<TixBusinessCodeGroup, 'description' | 'name' | 'busCodeGroupId'> & {
      businessCodes: Pick<TixBusinessCodes, 'name' | 'description'>[];
    }
  >;

  @Output() save: EventEmitter<Partial<TixSocialMedia>[]> = new EventEmitter();
  @Output() delete = new EventEmitter<TixSocialMedia>();
  @Input() isloading?: boolean | undefined | null;

  @Input() keepDisableSaveBtn = false;

  @Input() showHeader: boolean = true;

  socialMediasForm: FormGroup;

  get socialMediasFormArray() {
    return this.socialMediasForm.get('socialMedias') as FormArray;
  }

  get socialMediaTypes() {
    if (this.socialMediaBusinessCodeGroup) {
      return this.socialMediaBusinessCodeGroup.businessCodes;
    }
    return [];
  }

  get socialMediaValueFormatted() {
    return (this.socialMediasFormArray.value as SocialMediaValue[]).map(
      formGroup =>
        ({
          socialMediaId: formGroup.socialMediaId,
          socialMediaType: formGroup.socialMediaType,
          url: formGroup.socialMediaUrl
        } as Partial<TixSocialMedia>)
    );
  }

  ngOnInit(): void {
    this.socialMediasForm = setupSocialMediasForm(this.socialMedias || []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const socialMedias: Maybe<Array<Maybe<TixSocialMedia>>> = changes[
      'socialMedias'
    ]?.currentValue as Maybe<TixSocialMedia[]>;
    if (socialMedias) {
      this.socialMediasForm = setupSocialMediasForm(socialMedias);
    }
  }

  addNewSocialMedia() {
    this.socialMediasFormArray.push(createSocialMediaFormGroup());
  }

  handleSaveClick() {
    const socialMediasFormValue = this.socialMediasForm.value as {
      socialMedias: SocialMediaValue[];
    };

    // Send only the dirty values
    socialMediasFormValue.socialMedias =
      socialMediasFormValue.socialMedias.filter((_e, index) => {
        if (this.socialMediasFormArray.at(index).dirty) {
          return true;
        }
        return false;
      });

    const formattedSocialMedias = socialMediasFormValue.socialMedias.map(
      formatSocialMediaValue
    );

    this.save.emit(formattedSocialMedias);
  }

  async confirmDelete(formGroup: AbstractControl, index: number) {
    const socialMediaId = formGroup.get('socialMediaId')?.value;
    if (socialMediaId) {
      const socialMedia = this.socialMedias?.find(
        socialMedia => socialMedia?.socialMediaId === socialMediaId
      );
      if (!socialMedia) {
        return;
      }
      const confirmText = socialMedia
        ? `Are you sure you want to delete the social media "${socialMedia.url}" ?`
        : `Are you sure you want to delete this social media?`;
      const result = await this.confirmDialogService.promptConfirm({
        confirmText,
        title: 'Confirm Delete',
        icon: 'exclamation',
        titleTheme: 'danger',
        cancelButton: {
          theme: 'danger'
        },
        confirmButton: {
          theme: 'danger'
        }
      });

      if (result) {
        this.delete.emit(socialMedia);
      }
      return;
    }
    this.socialMediasFormArray.removeAt(index);
    if (this.socialMediasFormArray.length === 0) {
      this.addNewSocialMedia();
    }
  }
}

// ----------------------------------------------------------------------------------- #
// ---------------------------------  # functions #  --------------------------------- #
// ----------------------------------------------------------------------------------- #

export type SocialMediaValue = {
  socialMediaUrl: any;
  socialMediaType: any;
  socialMediaId: any;
};

function formatSocialMediaValue(socialMedia: SocialMediaValue) {
  return {
    url: socialMedia.socialMediaUrl,
    socialMediaId: socialMedia.socialMediaId,
    socialMediaType: socialMedia.socialMediaType
  } as TixSocialMedia;
}

function setupSocialMediasForm(socialMedias: Array<Maybe<TixSocialMedia>>) {
  const socialMediasForm = new FormGroup({
    socialMedias: createSocialMediaArray(socialMedias)
  });

  if (socialMedias.length > 0) {
    (socialMediasForm.get('socialMedias') as FormArray).clear();
    socialMedias.forEach(socialMedia => {
      (socialMediasForm.get('socialMedias') as FormArray).push(
        createSocialMediaFormGroup(socialMedia)
      );
    });
  }

  return socialMediasForm;
}

function createSocialMediaArray(socialMedias: Array<Maybe<TixSocialMedia>>) {
  if (!socialMedias || socialMedias.length === 0)
    return new FormArray([createSocialMediaFormGroup()]);
  return new FormArray(
    socialMedias.map(socialMedia => createSocialMediaFormGroup(socialMedia))
  );
}

function createSocialMediaFormGroup(
  socialMedia?: Maybe<TixSocialMedia>
): FormGroup {
  return new FormGroup({
    socialMediaUrl: new FormControl(socialMedia?.url ?? '', [
      Validators.required
    ]),
    socialMediaType: new FormControl(socialMedia?.socialMediaType ?? '', [
      Validators.required
    ]),
    socialMediaId: new FormControl(socialMedia?.socialMediaId ?? '')
  } as SocialMediaValue);
}
