<div class="hidden lg:block">
  <mat-calendar
    [dateClass]="getDateClass()"
    [headerComponent]="header"
    class="tix-event-calendar"
    [(selected)]="selectedDate"
  ></mat-calendar>
</div>

<div class="lg:hidden">
  <!-- 
  <div [formGroup]="dateRange">
    <mat-form-field>
    <mat-label> Events between dates </mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="startDate" />
      <input matEndDate formControlName="endDate" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
   -->

  <mat-form-field>
    <mat-label>Event date</mat-label>

    <mat-datepicker
      #picker
      ngDefaultControl
      [formControl]="mobileSelectedDate"
    ></mat-datepicker>

    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="mobileSelectedDate"
      ngDefaultControl
      placeholder="Choose a date"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>
</div>
