<tix-card class="bg-white px-8 py-8 min-w-[35vw]">
  <tix-card-title>
    <h2 class="text-primary">{{ title }}</h2>
  </tix-card-title>

  <div>
    <div *ngIf="description" class="mt-8 mb-5">
      <p class="text-sm mt-4 mb-2">{{ description }}</p>
    </div>

    <div class="mb-4">
      <mat-checkbox
        class="col-span-full mb-1"
        [checked]="allSelected"
        (change)="allSelected ? deselectAll() : selectAll()"
      >
        Select all
      </mat-checkbox>
      <div class="overflow-y-auto max-h-56 grid grid-cols-1 md:grid-cols-2">
        <ng-container *ngFor="let opt of options; let i = index">
          <div>
            <mat-checkbox
              [(ngModel)]="options[i].selected"
              (ngModelChange)="handleOptionsChanged()"
              >{{ opt.label }}</mat-checkbox
            >
          </div>
        </ng-container>
      </div>
    </div>

    <div class="flex justify-end flex-col-reverse lg:flex-row">
      <button
        tix-flat-border-button
        (click)="handleCancel()"
        class="lg:mr-4 mt-4 lg:mt-0 w-full lg:w-auto"
      >
        Cancel
      </button>
      <button tix-button class="w-full lg:w-auto" (click)="handleProceed()">
        {{ confirmText ?? 'Proceed' }}
      </button>
    </div>
  </div>
</tix-card>
