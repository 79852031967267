import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  InjectionToken,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import {
  TixFormFieldAppend,
  TIX_FORM_FIELD_APPEND
} from './form-field-append.directive';
import { TixFormFieldControl } from './form-field-control.directive';
import {
  TixFormFieldError,
  TIX_FORM_FIELD_ERROR
} from './form-field-error.directive';
import {
  TixFormFieldHint,
  TIX_FORM_FIELD_HINT
} from './form-field-hint.directive';
import { TixFormFieldLabel } from './form-field-label.directive';
import {
  TixFormFieldPrefix,
  TIX_FORM_FIELD_PREFIX
} from './form-field-prefix.directive';
import {
  TixFormFieldSuffix,
  TIX_FORM_FIELD_SUFFIX
} from './form-field-suffix.directive';

let nextUniqueId = 0;

const HINT_VAL_MAP = {
  start: -1,
  end: 1
};

/**
 * Injection token that can be used to inject an instances of `TixFormField`. It serves
 * as alternative token to the actual `MatFormField` class which would cause unnecessary
 * retention of the `TixFormField` class and its component metadata.
 */
export const TIX_FORM_FIELD = new InjectionToken<TixFormFieldComponent>(
  'TixFormField'
);

@Component({
  selector: 'tix-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  host: {
    class: 'tix-form-field',
    '[class.tix-form-field-invalid]':
      'control.errorState' || 'appendChild.errorState'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TIX_FORM_FIELD, useExisting: TixFormFieldComponent }]
})
export class TixFormFieldComponent implements AfterViewInit, AfterContentInit {
  // Unique id for the label element.
  readonly labelId = `tix-form-field-label-${nextUniqueId++}`;

  private readonly destroyed: Subject<void> = new Subject<void>();

  @ContentChild(TixFormFieldControl, { static: true })
  control: TixFormFieldControl<any>;
  @ContentChild(TixFormFieldLabel, { static: true })
  labelChild: TixFormFieldLabel;
  get hasLabel(): boolean {
    return !!this.labelChild;
  }

  @ContentChild(TIX_FORM_FIELD_APPEND, { static: true })
  appendChild: TixFormFieldAppend;
  get hasAppended(): boolean {
    return !!this.appendChild;
  }

  @ContentChildren(TIX_FORM_FIELD_ERROR, { descendants: true })
  errorChildren: QueryList<TixFormFieldError>;
  @ContentChildren(TIX_FORM_FIELD_HINT, { descendants: true })
  hintChildren: QueryList<TixFormFieldHint>;
  @ContentChildren(TIX_FORM_FIELD_PREFIX, { descendants: true })
  prefixChildren: QueryList<TixFormFieldPrefix>;
  @ContentChildren(TIX_FORM_FIELD_SUFFIX, { descendants: true })
  suffixChildren: QueryList<TixFormFieldSuffix>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    const control = this.control;

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.pipe(startWith(null)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => this.changeDetectorRef.markForCheck());
    }

    // Run change detection and update the outline if the suffix or prefix changes.
    merge(this.prefixChildren.changes, this.suffixChildren.changes).subscribe(
      () => {
        this.changeDetectorRef.markForCheck();
      }
    );

    // Re-validate when the number of hints changes.
    this.hintChildren.changes.pipe(startWith(null)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this.errorChildren.changes.pipe(startWith(null)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /** Determines whether to display hints or errors. */
  getDisplayedMessages(): 'error' | 'hint' {
    return this.errorChildren &&
      this.errorChildren.length > 0 &&
      this.control.errorState
      ? 'error'
      : 'hint';
  }

  /**
   * Sets the list of element IDs that describe the child control. This allows the control to update
   * its `aria-describedby` attribute accordingly.
   */
  private syncDescribedByIds() {
    if (this.control) {
      const ids: string[] = [];

      if (this.control.userAriaDescribedBy) {
        ids.push(...this.control.userAriaDescribedBy.split(' '));
      }

      if (this.getDisplayedMessages() === 'hint') {
        const sortedHints: TixFormFieldHint[] = this.hintChildren.toArray();

        if (sortedHints.length > 0) {
          sortedHints.sort((hintA, hintB) => {
            const aVal = HINT_VAL_MAP[hintA.align] ?? 0;
            const bVal = HINT_VAL_MAP[hintB.align] ?? 0;
            return aVal === bVal
              ? hintA.id.localeCompare(hintB.id)
              : aVal - bVal;
          });
        }

        ids.push(...sortedHints.map(hint => hint.id));
      } else if (this.errorChildren) {
        ids.push(...this.errorChildren.map(error => error.id));
      }

      this.control.setDescribedByIds(ids);
    }
  }
}
