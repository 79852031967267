import {
  InputMaybe,
  Maybe,
  TixContactObjRelInsertInput,
  TixPatronFIlteredEventsList,
  TixReservationPaymentObjRelInsertInput,
  TixTicketArrRelInsertInput,
  TixTicketConfig,
  TixTicketInsertInput,
  TixTicketReservationInsertInput,
  TixTicketReservationObjRelInsertInput,
  TixVenueTicket,
  TixVenueTicketConfig
} from '@tix/data-access';
export function createVenueTicket(data: any): TixVenueTicket {
  return {
    __typename: 'VenueTicket',
    Configurations:
      data.map(
        (config: any) =>
          ({
            __typename: 'VenueTicketConfig',
            TicketConfigurations: {
              ticketConfigId: config.ticketConfigId,
              name: config.name,
              ticketType: config.ticketType,
              row: '',
              seat: config.seat,
              processingFee: config.processingFee,
              venueFee: config.venueFee,
              state: config.state,
              ticketPrice: config.ticketPrice,
              platformFee: config.platformFee,
              ticketQty: config.ticketQty,
              updatedAt: '',
              updatedBy: '',
              showFees: config.showFees
            } as TixTicketConfig
          } as TixVenueTicketConfig)
      ) ?? ([] as Array<TixVenueTicketConfig>)
  } as TixVenueTicket;
}

export function createTicket(
  data: any,
  eventId: string,
  userContact: any
): Array<TixTicketInsertInput> {
  return data.map(
    (config: any) =>
      ({
        ticketConfigId: config.ticketConfigId,
        ticketType: config.ticketType,
        section: config.section,
        row: '',
        seat: config.seat,
        processingFee: config.processingFee,
        venueFee: config.venueFee,
        ticketPrice: config.ticketPrice,
        platformFee: config.platformFee,
        eventInstanceId: config.eventInstanceId,
        contact: userContact,
        discountAmount: config.discountAmount
      } ?? ([] as Array<TixVenueTicketConfig>))
  );
}

export function createReservationData(
  orderDetails: Maybe<TixPatronFIlteredEventsList[]>,
  contactDetails: InputMaybe<TixContactObjRelInsertInput>
): TixReservationPaymentObjRelInsertInput {
  return {
    data: {
      reservation: {
        data: createTicketReservation(
          orderDetails,
          contactDetails
        ) as TixTicketReservationInsertInput
      } as InputMaybe<TixTicketReservationObjRelInsertInput>
    }
  } as TixReservationPaymentObjRelInsertInput;
}

export function createTicketReservation(
  ticketReservation: any,
  contactInfo: InputMaybe<TixContactObjRelInsertInput>
): TixTicketReservationInsertInput {
  return {
    tickets: createEventTickets(
      ticketReservation,
      contactInfo
    ) as InputMaybe<TixTicketArrRelInsertInput>,
    eventInstanceId: ticketReservation[0].eventInstanceId,
    additionalComments: '',
    isGroup: ticketReservation[0].isGroup,
    groupCode: ticketReservation[0].groupCode,
    reservationStatus: 'Paid',
    contact: contactInfo
  } as TixTicketReservationInsertInput;
}

export function createEventTickets(
  tickets: any,
  contactData: any
): InputMaybe<TixTicketArrRelInsertInput> {
  return {
    data: createTicket(
      tickets,
      tickets.eventInstanceId,
      contactData
    ) as Array<TixTicketInsertInput>
  } as InputMaybe<TixTicketArrRelInsertInput>;
}
