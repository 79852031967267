import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, INIT, on } from '@ngrx/store';
import { UserActions } from '@tix/auth/state';
import { TixCompany } from '@tix/data-access';
import {
  CompanyEditSection,
  selectCompanyId,
  sortCompanies
} from '@tix/shared/models';
import * as CompanyApiActions from './actions/company-api.actions';
import * as CompanyPageActions from './actions/company.actions';
import { upsertCompanyWarningFailure } from './actions/company-api.actions';

export const COMPANY_FEATURE_KEY = 'company';

export interface State extends EntityState<TixCompany> {
  selectedCompanyId?: string; // which Company record has been selected
  selectedContactId?: string;
  loaded: boolean; // has the Company list been loaded
  error?: string | null; // last known error (if any)
  editSections: CompanyEditSection[]; // sections in edit mode
  loadingProfile: boolean;
  loadingPhone: boolean;
  loadingAdress: boolean;
  loadingMedia: boolean;
  loadingMediaFiles: boolean;
  searchText: string;
}

export interface TixCompanyPartialState {
  readonly [COMPANY_FEATURE_KEY]: State;
}

export const companyAdapter: EntityAdapter<TixCompany> =
  createEntityAdapter<TixCompany>({
    selectId: selectCompanyId,
    sortComparer: sortCompanies
  });

export const initialState: State = companyAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  editSections: [],
  loadingProfile: false,
  loadingPhone: false,
  loadingAdress: false,
  loadingMedia: false,
  loadingMediaFiles: false,
  searchText: ''
});

const companyReducer = createReducer(
  initialState,
  on(CompanyPageActions.loadCompanies, state => ({ ...state, error: null })),
  on(CompanyApiActions.loadCompaniesSuccess, (state, { companies }) => {
    if (companies) {
      return companyAdapter.upsertMany(companies, { ...state, loaded: true });
    }
    return { ...state, loaded: true };
  }),
  on(CompanyApiActions.loadCompaniesFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true
  })),
  on(CompanyPageActions.filterCompanies, (state, { searchText }) => ({
    ...state,
    searchText
  })),
  on(CompanyPageActions.deselectCompany, state => {
    const newState = { ...state };
    delete newState.selectedCompanyId;
    return newState;
  }),
  on(CompanyApiActions.deleteCompanySuccess, (state, { companyId }) => {
    return companyAdapter.removeOne(companyId, state);
  }),
  on(CompanyApiActions.deleteCompanyFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(
    CompanyApiActions.loadVenuesForSelectedCompanySuccess,
    (state, { companyId, venues }) => {
      if (venues) {
        return companyAdapter.updateOne(
          {
            id: companyId,
            changes: {
              venues
            }
          },
          { ...state }
        );
      }
      return { ...state };
    }
  ),
  on(
    CompanyApiActions.loadVenuesForSelectedCompanyFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(CompanyPageActions.loadCompanyById, (state, { companyId }) => {
    return { ...state, selectedCompanyId: companyId };
  }),
  on(CompanyApiActions.loadCompanySuccess, (state, { company }) => {
    return companyAdapter.upsertOne(company, { ...state });
  }),
  on(CompanyPageActions.editCompanySection, (state, { section }) => ({
    ...state,
    editSections: state.editSections.includes(section)
      ? state.editSections
      : [...state.editSections, section]
  })),
  on(CompanyPageActions.selectCompany, (state, { companyId }) => ({
    ...state,
    selectedCompanyId: companyId
  })),
  on(CompanyPageActions.selectContact, (state, { staffId }) => ({
    ...state,
    selectedContactId: staffId
  })),
  on(CompanyPageActions.saveCompanyProfile, (state, props) => ({
    ...state,
    loadingProfile: true
  })),
  on(CompanyPageActions.saveCompanyAddress, (state, props) => ({
    ...state,
    loadingAdress: true
  })),
  on(CompanyPageActions.saveCompanyMediaFiles, (state, props) => ({
    ...state,
    loadingMediaFiles: true
  })),
  on(CompanyPageActions.saveCompanyPhoneNumbers, (state, props) => ({
    ...state,
    loadingPhone: true
  })),
  on(CompanyPageActions.saveCompanySocialMedias, (state, props) => ({
    ...state,
    loadingMedia: true
  })),
  on(CompanyApiActions.updateCompanyProfileSuccess, (state, props) => {
    if (!state.selectedCompanyId) {
      return { ...state, loadingProfile: false };
    }
    const update: Update<TixCompany> = {
      id: state.selectedCompanyId,
      changes: {
        ...props
      }
    };
    return companyAdapter.updateOne(update, {
      ...state,
      loadingProfile: false
    });
  }),

  on(CompanyApiActions.updateCompanyAddressSuccess, (state, { address }) => {
    // When we have more than one address, we'll update only the changed one
    let newAddresses = state.entities[
      state.selectedCompanyId ?? '0'
    ]?.addresses.map(stateAddress => {
      if (stateAddress.address?.addressId === address.address?.addressId) {
        return address;
      }
      return stateAddress;
    });

    if (newAddresses?.length === 0) {
      newAddresses = [address];
    }

    const update: Update<TixCompany> = {
      id: state.selectedCompanyId,
      changes: {
        addresses: newAddresses
      }
    } as Update<TixCompany>;

    return companyAdapter.updateOne(update, { ...state, loadingAdress: false });
  }),
  on(CompanyApiActions.updateCompanyAddressFailure, (state, { error }) => ({
    ...state,
    error,
    loadingAdress: false
  })),
  on(CompanyApiActions.updateCompanyProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loadingProfile: false
  })),
  /* #region  Upsert Actions */
  on(CompanyApiActions.insertCompanyProfileSuccess, (state, { company }) =>
    companyAdapter.addOne(company, { ...state })
  ),
  on(
    CompanyApiActions.upsertCompanyPhoneNumbersSuccess,
    (state, { companyPhoneNumbers }) => {
      const update: Update<TixCompany> = {
        id: state.selectedCompanyId,
        changes: {
          phoneNumbers: (
            state.entities[state.selectedCompanyId ?? '']?.phoneNumbers ?? []
          )
            // When we have more than one phone number, we'll update only the changed one
            .map(statePhoneNumber => {
              const foundPhoneNumber = companyPhoneNumbers.find(
                phone =>
                  phone.phoneNumber?.phoneNumberId ===
                  statePhoneNumber.phoneNumber?.phoneNumberId
              );
              if (foundPhoneNumber) {
                return foundPhoneNumber;
              }
              return statePhoneNumber;
            })
            // And then add the new added ones to the state
            .concat(
              companyPhoneNumbers.filter(newPhoneNumber => {
                return !state.entities[
                  state.selectedCompanyId ?? ''
                ]?.phoneNumbers.find(
                  e =>
                    e.phoneNumber?.phoneNumberId ===
                    newPhoneNumber.phoneNumber?.phoneNumberId
                );
              })
            )
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, {
        ...state,
        loadingPhone: false
      });
    }
  ),
  on(
    CompanyApiActions.upsertCompanyPhoneNumbersFailure,
    (state, { error }) => ({ ...state, error, loadingPhone: false })
  ),
  on(
    CompanyApiActions.upsertCompanySocialMediaSuccess,
    (state, { companySocialMedias }) => {
      const update: Update<TixCompany> = {
        id: state.selectedCompanyId,
        changes: {
          socialMediaLinks: (
            state.entities[state.selectedCompanyId ?? '']?.socialMediaLinks ??
            []
          )
            // When we have more than one phone number, we'll update only the changed one
            .map(stateSocialMedia => {
              const foundCompanySocialMedia = companySocialMedias.find(
                companySocialMedia =>
                  companySocialMedia.socialMedia?.socialMediaId ===
                  stateSocialMedia.socialMedia?.socialMediaId
              );
              if (foundCompanySocialMedia) {
                return foundCompanySocialMedia;
              }
              return stateSocialMedia;
            })
            // And then add the new added ones to the state
            .concat(
              companySocialMedias.filter(newSocialMedia => {
                return !state.entities[
                  state.selectedCompanyId ?? ''
                ]?.socialMediaLinks.find(
                  e =>
                    e.socialMedia?.socialMediaId ===
                    newSocialMedia.socialMedia?.socialMediaId
                );
              })
            )
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, {
        ...state,
        loadingMedia: false
      });
    }
  ),
  on(
    CompanyApiActions.upsertCompanyWarningSuccess,
    (state, { companyWarnings }) => {
      const update: Update<TixCompany> = {
        id: state.selectedCompanyId,
        changes: {
          warnings: (
            state.entities[state.selectedCompanyId ?? '']?.warnings ?? []
          )
            // When we have more than one phone number, we'll update only the changed one
            .map(stateWarning => {
              const foundCompanyWarning = companyWarnings.find(
                companySocialMedia =>
                  companySocialMedia.warning?.warningId ===
                  stateWarning.warning?.warningId
              );
              if (foundCompanyWarning) {
                return foundCompanyWarning;
              }
              return stateWarning;
            })
            // And then add the new added ones to the state
            .concat(
              companyWarnings.filter(newSocialMedia => {
                return !state.entities[
                  state.selectedCompanyId ?? ''
                ]?.warnings.find(
                  e =>
                    e.warning?.warningId === newSocialMedia.warning?.warningId
                );
              })
            )
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, {
        ...state,
        loadingMedia: false
      });
    }
  ),
  on(CompanyApiActions.upsertCompanySocialMediaFailure, (state, { error }) => ({
    ...state,
    error,
    loadingMedia: false
  })),
  on(CompanyApiActions.upsertCompanyWarningFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(
    CompanyApiActions.upsertCompanyMediaFileSuccess,
    (state, { companyMediaFiles }) => {
      const update: Update<TixCompany> = {
        id: state.selectedCompanyId,
        changes: {
          mediaFiles: (
            state.entities[state.selectedCompanyId ?? '']?.mediaFiles ?? []
          )
            // When we have more than one phone number, we'll update only the changed one
            .map(mediaFile => {
              const foundCompanyMediaFile = companyMediaFiles.find(
                companyMediaFile =>
                  companyMediaFile.mediaFile?.mediaFileId ===
                  mediaFile.mediaFile?.mediaFileId
              );
              if (foundCompanyMediaFile) {
                return foundCompanyMediaFile;
              }
              return mediaFile;
            })
            // And then add the new added ones to the state
            .concat(
              companyMediaFiles.filter(newMediaFile => {
                return !state.entities[
                  state.selectedCompanyId ?? ''
                ]?.mediaFiles.find(
                  e =>
                    e.mediaFile?.mediaFileId ===
                    newMediaFile.mediaFile?.mediaFileId
                );
              })
            )
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, {
        ...state,
        loadingMediaFiles: false
      });
    }
  ),
  on(CompanyApiActions.upsertCompanyMediaFileFailure, (state, { error }) => ({
    ...state,
    error,
    loadingMediaFiles: false
  })),

  /* #endregion */
  /* #region  Delete Actions */
  on(
    CompanyApiActions.deleteCompanyPhoneNumberSuccess,
    (state, { companyId, companyPhoneNumberId }) => {
      const update: Update<TixCompany> = {
        id: companyId,
        changes: {
          phoneNumbers: (state.entities[companyId ?? '']?.phoneNumbers ?? [])
            // When we have more than one phone number, we'll update only the changed one
            .filter(statePhoneNumber => {
              return (
                statePhoneNumber.companyPhoneNumberId !== companyPhoneNumberId
              );
            })
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, state);
    }
  ),
  on(CompanyApiActions.deleteCompanyPhoneNumberFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(
    CompanyApiActions.deleteCompanySocialMediaSuccess,
    (state, { companyId, companySocialMediaId }) => {
      const update: Update<TixCompany> = {
        id: companyId,
        changes: {
          socialMediaLinks: (
            state.entities[state.selectedCompanyId ?? '']?.socialMediaLinks ??
            []
          )
            // When we have more than one phone number, we'll update only the changed one
            .filter(stateSocialMedia => {
              return (
                stateSocialMedia.companySocialMediaId !== companySocialMediaId
              );
            })
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, state);
    }
  ),
  on(
    CompanyApiActions.deleteCompanyWarningSuccess,
    (state, { companyId, companyWarningId }) => {
      const update: Update<TixCompany> = {
        id: companyId,
        changes: {
          warnings: (
            state.entities[state.selectedCompanyId ?? '']?.warnings ?? []
          )
            // When we have more than one phone number, we'll update only the changed one
            .filter(stateSocialMedia => {
              return stateSocialMedia.companyWarningId !== companyWarningId;
            })
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, state);
    }
  ),
  on(CompanyApiActions.deleteCompanySocialMediaFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(CompanyApiActions.deleteCompanyWarningFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(
    CompanyApiActions.deleteCompanyMediaFileSuccess,
    (state, { companyId, companyMediaFileId }) => {
      const update: Update<TixCompany> = {
        id: companyId,
        changes: {
          mediaFiles: (
            state.entities[state.selectedCompanyId ?? '']?.mediaFiles ?? []
          )
            // When we have more than one phone number, we'll update only the changed one
            .filter(stateMediaFile => {
              return stateMediaFile.companyImageFileId !== companyMediaFileId;
            })
        }
      } as Update<TixCompany>;

      return companyAdapter.updateOne(update, state);
    }
  ),
  on(CompanyApiActions.deleteCompanyMediaFileFailure, (state, { error }) => ({
    ...state,
    error
  }))
  /* #endregion */
);

export function reducer(state: State | undefined, action: Action) {
  if (action != null && action.type === UserActions.logoutAction.type) {
    return companyReducer(undefined, { type: INIT });
  }
  return companyReducer(state, action);
}
