import { unparse } from 'papaparse';

export class CSVColumn<T> {
  name: string;
  propertyName?: string;
  format: (value: T) => string;
}

export class CSVStringSanitizer {
  sanitize(value: string) {
    return value.replace(/"/g, '""');
  }
}

export class CSVExporter<T> {
  prepareCsvString(data: T[], columns: CSVColumn<T>[]): string {
    const csv = unparse({
      fields: columns.map(col => col.name),
      data: data.map(element => columns.map(col => col.format(element)))
    });

    return csv;
  }

  saveCSVFile(csv: string, fileName: string): void {
    const data = new Blob([csv], {
      type: 'text/csv'
    });
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
