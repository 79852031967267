import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, navigation, pessimisticUpdate } from '@nrwl/angular';
import { State as UserState } from '@tix/auth/state';
import * as UserSelectors from '@tix/auth/state/selectors';
import * as CompanySelectors from '@tix/company/state/selectors';
import {
  TixCreateNewVenueGQL,
  TixDeleteTicketConfigurationGQL,
  TixDeleteVenueMediaFileByMediaFilePkGQL,
  TixDeleteVenuePhoneNumberByPhoneNumberPkGQL,
  TixDeleteVenueSocialMediaBySocialMediaPkGQL,
  TixDeleteVenueTicketGQL,
  TixDeleteVenueWarningByWarningPkGQL,
  TixGetVenueByIdGQL,
  TixGetVenuesByCompanyIdGQL,
  TixGetVenueTicketByPkGQL,
  TixInsertAddressGQL,
  TixInsertMediaFileGQL,
  TixInsertPhoneNumberGQL,
  TixInsertSocialMediaGQL,
  TixInsertWarningGQL,
  TixSetVenuePhoneNumbersSameAsCompanyGQL,
  TixUpdateAddressGQL,
  TixUpdateMediaFileByPkGQL,
  TixUpdatePhoneNumberByPkGQL,
  TixUpdateSocialMediaByPkGQL,
  TixUpdateVenueByPkGQL,
  TixUpdateVenueTicketByPkGQL,
  TixUpdateWarningByPkGQL,
  TixVenue
} from '@tix/data-access';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as VenuesApiActions from '../actions/venues-api.actions';
import * as VenuesActions from '../actions/venues.actions';
import * as VenuesSelectors from '../selectors';
import { VenueTicketHelperService } from './tickets/venue.tickets.helpers';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TixVenueEffects {
  loadVenuesForSelectedCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenuesActions.loadVenuesForSelectedCompany),
      withLatestFrom(this.store.select(CompanySelectors.getSelectedCompanyId)),
      fetch({
        run: (action, companyId) => {
          return this.getVenuesByCompanyId
            .fetch({ companyId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) =>
                VenuesApiActions.loadVenuesForSelectedCompanySuccess({
                  venues: data.Venue as TixVenue[]
                })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return VenuesApiActions.loadVenuesForSelectedCompanyFailure({
            error
          });
        }
      })
    )
  );

  upsertVenueTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenuesActions.saveVenueTicketConfiguration),
      withLatestFrom(
        combineLatest([this.store.select(UserSelectors.getUserState)])
      ),
      pessimisticUpdate({
        run: ({ ticket, venueId }, state) => {
          const [userState] = state;

          console.log({ venueId, ticket });

          return this.venueTicketHelperService
            .saveVenueTicket(ticket, userState, venueId)
            .pipe(
              tap(() => {
                this.snackbar.open(
                  'Your changes have been saved successfully!'
                );
              })
            );
        },
        onError: (_, error) => {
          this.snackbar.open('An error has ocurred');

          return VenuesApiActions.insertVenueTicketFailure({ error });
        }
      })
    )
  );

  deleteVenueTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenuesActions.deleteVenueTicket),
      pessimisticUpdate({
        run: ({ venueTicket }) => {
          return of(
            venueTicket.Configurations?.map(e =>
              this.deleteTicketConfigurationById.mutate({
                ticketConfigId: e.TicketConfigurations?.ticketConfigId ?? ''
              })
            ) ?? []
          ).pipe(
            switchMap(() =>
              this.deleteVenueTicketById.mutate({
                venueTicketId: venueTicket.venueTicketId
              })
            ),
            map(() =>
              VenuesApiActions.deleteVenueTicketSuccess({
                venueTicketId: venueTicket.venueTicketId
              })
            )
          );
        },
        onError: error => {
          return VenuesApiActions.deleteVenueTicketFailure({ error });
        }
      })
    )
  );

  deleteVenueTicketConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenuesActions.deleteVenueTicketConfiguration),

      pessimisticUpdate({
        run: ({ venueTicketConfiguration, venueTicket }) => {
          return this.deleteTicketConfigurationById
            .mutate({
              ticketConfigId:
                venueTicketConfiguration.TicketConfigurations?.ticketConfigId ??
                ''
            })
            .pipe(
              switchMap(() => {
                if (venueTicket) {
                  return this.updateVenueTicketMutation.mutate(
                    {
                      venueTicketId: venueTicket.venueTicketId,
                      _set: {
                        totalSeats:
                          (venueTicket.totalSeats ?? 0) -
                          (venueTicketConfiguration.TicketConfigurations
                            ?.noOfSeats ?? 0)
                      }
                    },
                    {
                      fetchPolicy: 'no-cache'
                    }
                  );
                }
                return of(null);
              }),
              map(() =>
                VenuesApiActions.deleteVenueTicketConfigSuccess({
                  venueTicketConfigId:
                    venueTicketConfiguration.venueTicketConfig
                })
              ),
              tap(() =>
                this.store.dispatch(
                  VenuesApiActions.reloadVenueTicketById({
                    venueTicketId: venueTicket?.venueTicketId
                  })
                )
              )
            );
        },
        onError: error => {
          return VenuesApiActions.deleteVenueTicketConfigFailure({ error });
        }
      })
    )
  );

  loadVenueTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenuesApiActions.reloadVenueTicketById),
      pessimisticUpdate({
        run: ({ venueTicketId }) => {
          return this.getVenueTicketById
            .fetch({ venueTicketId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(e =>
                VenuesApiActions.reloadVenueTicketByIdSuccess({
                  venueTicket: e.data.VenueTicketByPK as any
                })
              )
            );
        },
        onError: error => {
          return VenuesApiActions.reloadVenueTicketByIdFailure({ error });
        }
      })
    )
  );

  /* #endregion */

  constructor(
    private route: ActivatedRoute,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly getVenuesByCompanyId: TixGetVenuesByCompanyIdGQL,
    private readonly getVenueById: TixGetVenueByIdGQL,
    private readonly updateVenueById: TixUpdateVenueByPkGQL,
    private readonly updatePhoneNumberById: TixUpdatePhoneNumberByPkGQL,
    private readonly insertPhoneNumber: TixInsertPhoneNumberGQL,
    private readonly updateSocialMediaById: TixUpdateSocialMediaByPkGQL,
    private readonly insertSocialMedia: TixInsertSocialMediaGQL,
    private readonly updateMediaFileById: TixUpdateMediaFileByPkGQL,
    private readonly insertMediaFile: TixInsertMediaFileGQL,
    private readonly updateVenueAddressById: TixUpdateAddressGQL,
    private readonly insertVenueAddress: TixInsertAddressGQL,
    private readonly setVenuePhoneNumbersSameAsCompany: TixSetVenuePhoneNumbersSameAsCompanyGQL,
    private readonly venueTicketHelperService: VenueTicketHelperService,
    private readonly updateVenueTicketMutation: TixUpdateVenueTicketByPkGQL,
    private readonly getVenueTicketById: TixGetVenueTicketByPkGQL,
    private readonly createNewVenue: TixCreateNewVenueGQL,
    private readonly updateVenueWarning: TixUpdateWarningByPkGQL,
    private readonly insertVenueWarning: TixInsertWarningGQL,
    /* #region  Delete GQLs */
    private readonly deleteVenuePhoneNumberByPhoneNumberId: TixDeleteVenuePhoneNumberByPhoneNumberPkGQL,
    private readonly deleteVenueSocialMediaBySocialMediaId: TixDeleteVenueSocialMediaBySocialMediaPkGQL,
    private readonly deleteVenueMediaFileByMediaFileId: TixDeleteVenueMediaFileByMediaFilePkGQL,
    private readonly deleteVenueTicketById: TixDeleteVenueTicketGQL,
    private readonly deleteTicketConfigurationById: TixDeleteTicketConfigurationGQL,
    private readonly deleteVenueWarningByWarningId: TixDeleteVenueWarningByWarningPkGQL,
    /* #endregion */
    private readonly store: Store,

    private readonly snackbar: MatSnackBar
  ) {}
}
