import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserPartialState } from '@tix/auth/state';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { filter, map } from 'rxjs/operators';
import { UserStatus } from '@tix/shared/models';

@Injectable()
export class TixUnauthorizedGuard implements CanActivate {
  constructor(
    private readonly store: Store<UserPartialState>,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(AuthSelectors.getUserStatus).pipe(
      filter(status => status !== UserStatus.UNKNOWN),
      map(status => {
        if (status !== UserStatus.LOGGED_IN) {
          return true;
        }
        if (this.router.routerState?.snapshot?.root?.fragment === '') {
          if (route.queryParams.r) {
            // Redirect
            return this.router.parseUrl(route.queryParams.r);
          }
          return this.router.createUrlTree(['']);
        }
        return false;
      })
    );
  }
}
