import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {
  TixProductCompanyVenuesGQL,
  TixProductInsertOneGQL,
  TixProductSelectAllGQL,
  TixProductSelectOneGQL,
  TixProductSoftDeleteGQL,
  TixProductUpdateGQL
} from '@tix/data-access';
import { HelperStringService } from '@tix/util-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Addon } from '../models/addon.model';

@Injectable({ providedIn: 'root' })
export class AddonsService implements Resolve<Observable<any> | undefined> {
  // private goes here
  #companyId = '';

  // [stores]
  #addons = new BehaviorSubject<any>([]);
  #addon = new BehaviorSubject<any>(null);

  // helpers
  #helperStringService = new HelperStringService();

  constructor(
    // get
    private _productSelectAll: TixProductSelectAllGQL,
    private _productSelectOne: TixProductSelectOneGQL,
    private _productCompanyVenues: TixProductCompanyVenuesGQL,
    // insert
    private _productInsertOne: TixProductInsertOneGQL,
    // update
    private _productUpdate: TixProductUpdateGQL,
    // delete
    private _productSoftDelete: TixProductSoftDeleteGQL,
    // ng
    private _router: Router
  ) {}

  get addons$(): Observable<any> {
    return this.#addons.asObservable();
  }

  get addon$(): Observable<any> {
    return this.#addon.asObservable();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | undefined {
    return this.#handleResolver(route, state);
  }

  getOne(productId: string) {
    return this._productSelectOne
      .fetch(
        {
          productId,
          companyId: this.#companyId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(v => {
          const firstUnique = v.data.ProductSelect[0];
          const addon = {
            ...firstUnique,
            price: firstUnique.price // why: coz response's price is readonly
          };

          return addon;
        }),
        tap(addon => {
          addon.price = this.#helperStringService.moneyToNumber(
            `${addon.price}`
          );
          this.#addon.next(addon);
        })
      );
  }

  getAllProducts() {
    return this._productSelectAll
      .fetch(
        {
          companyId: this.#companyId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(v => {
          this.#addons.next(v.data.ProductSelect);
          return v.data.ProductSelect;
        })
      );
  }

  insert(addon: Addon, activeRoute: ActivatedRoute) {
    const {
      additionalInformation,
      name,
      state,
      tax,
      price,
      quantity,
      venueIds
    } = addon;
    // user id
    const id = this.#connectedUserId();
    const createdBy = id;
    const updatedBy = id;

    const data: Record<string, any>[] = [];

    for (let i = 0; i < venueIds.length; i++) {
      const venue = venueIds[i];

      data.push({
        createdAt: 'now()',
        createdBy: createdBy,
        updatedAt: 'now()',
        venueId: venue,
        updatedBy: createdBy,
        venueProductId: this.#helperStringService.uuid()
      });
    }

    return this._productInsertOne
      .mutate(
        {
          additionalInformation,
          name,
          state,
          tax,
          price,
          quantity,
          createdBy,
          updatedBy,
          companyId: this.#companyId,
          data
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(v => {
          const added = v.data?.ProductInsertOne;
          const _v = Object.assign([], this.#addons.getValue());
          _v.push(added);

          this.#addons.next(_v);

          this.navigateTo(['../edit', added?.productId], activeRoute);

          return added;
        })
      );
  }

  update(addon: Addon) {
    const {
      productId,
      additionalInformation,
      name,
      state,
      tax,
      price,
      quantity,
      venueIds
    } = addon;

    const updatedBy = this.#connectedUserId();

    const data: Record<string, any>[] = [];

    for (let i = 0; i < venueIds.length; i++) {
      const venue = venueIds[i];
      data.push({
        createdAt: 'now()',
        productId, //
        createdBy: updatedBy,
        updatedAt: 'now()',
        venueId: venue,
        updatedBy: updatedBy, // to fix
        venueProductId: this.#helperStringService.uuid()
      });
    }

    return this._productUpdate
      .mutate(
        {
          productId,
          additionalInformation,
          name,
          state,
          tax,
          price,
          quantity,
          updatedBy,
          data
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(v => {
          const updated = v.data?.ProductUpdate?.returning[0];

          this.#addons.next(
            this.#addons
              .getValue()
              .map((ad: { productId: string | undefined }) =>
                ad.productId !== addon.productId ? ad : updated
              )
          );

          this.#addon.next({
            ...addon,
            // --------
            price: /** why: coz price is readonly */ Number(
              this.#helperStringService.moneyToNumber(updated?.price)
            ).toFixed(2),
            // ----------
            tax: Number(updated?.tax).toFixed(2)
          });

          return updated;
        })
      );
  }

  delete(productId: string, activeRoute: ActivatedRoute) {
    const updatedBy = this.#connectedUserId();
    return this._productSoftDelete
      .mutate({
        productId,
        updatedBy
      })
      .pipe(
        map(v => {
          const deleted =
            this.#addons
              .getValue()
              .filter(
                (ad: { productId: string }) => ad.productId !== productId
              ) ?? [];
          this.#addons.next(deleted);
          this.navigateTo(['./../../list'], activeRoute);

          return v.data?.ProductUpdate?.returning[0];
        })
      );
  }

  // -> venues
  getAllVenues() {
    return this._productCompanyVenues
      .fetch({
        companyId: this.#companyId
      })
      .pipe(
        map(venues => {
          return venues.data?.Venue ?? [];
        })
      );
  }
  // end http methods

  // helpers
  freeAddonStore() {
    this.#addon.next(null);
  }

  navigateTo(route: string[], activeRoute: ActivatedRoute) {
    this._router.navigate(route, {
      relativeTo: activeRoute
    });
  }

  // private methods
  #handleResolver(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | undefined {
    // company id
    this.#companyId = route.params['companyId'];

    // company id is mandatory for this class/service
    if (!this.#companyId)
      throw new Error('The company id is not provided in the route.');

    // when new no data needed
    if (state.url.includes('Add-Ons/new')) return;

    // free store
    this.freeAddonStore();

    // return the actual selected product
    if (route.params['addonId']) {
      return this.getOne(route.params['addonId']);
    }

    // return all company's products
    return this.getAllProducts();
  }

  #connectedUserId(): string {
    return localStorage.getItem('id') ?? '';
  }
}
