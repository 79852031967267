import {
  Attribute,
  Directive,
  ElementRef,
  InjectionToken,
  Input
} from '@angular/core';

let nextUniqueId = 0;

/**
 * Injection token that can be used to reference instances of `TixError`. It serves as
 * alternative token to the actual `TixError` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const TIX_FORM_FIELD_ERROR = new InjectionToken<TixFormFieldError>(
  'TixFormFieldError'
);

/** Single error message to be shown underneath the form field. */
@Directive({
  selector: 'tix-error',
  host: {
    class: 'tix-error',
    '[attr.id]': 'id',
    'aria-atomic': 'true'
  },
  providers: [{ provide: TIX_FORM_FIELD_ERROR, useExisting: TixFormFieldError }]
})
export class TixFormFieldError {
  @Input() id = `tix-error-${nextUniqueId++}`;

  constructor(
    @Attribute('aria-live') ariaLive: string,
    elementRef: ElementRef
  ) {
    // If no aria-live value is set add 'polite' as a default. This is preferred over setting
    // role='alert' so that screen readers do not interrupt the current task to read this aloud.
    if (!ariaLive) {
      elementRef.nativeElement.setAttribute('aria-live', 'polite');
    }
  }
}
