import { createAction, props } from '@ngrx/store';
import {
  TixAddress,
  TixMediaFile,
  TixPhoneNumber,
  TixSocialMedia,
  TixVenue,
  TixVenueTicket,
  TixVenueTicketConfig,
  TixWarning
} from '@tix/data-access';

export const loadVenuesForSelectedCompany = createAction(
  '[Venues List Page] Load Venues for selected company'
);

export const filterCompanies = createAction(
  '[Venues List Page] Filter Venues',
  props<{ searchText: string }>()
);

export const selectVenue = createAction(
  '[Venues List Page] Select venue',
  props<{ venueId: string }>()
);

export const deselectVenue = createAction('[Venues List Page] Deselect venue');

/* #region  save actions */

export const saveVenueTicketConfiguration = createAction(
  '[Venues] Save Venue Ticket Configuration',
  props<{ ticket: Partial<TixVenueTicket>; venueId: string }>()
);

/* #endregion */

export const deleteVenueTicket = createAction(
  '[Venues] Delete Venue Ticket',
  props<{ venueTicket: Partial<TixVenueTicket> }>()
);

export const deleteVenueTicketConfiguration = createAction(
  '[Venues] Delete Venue Ticket Configuration',
  props<{
    venueTicketConfiguration: Partial<TixVenueTicketConfig>;
    venueTicket?: Partial<TixVenueTicket>;
  }>()
);

export const clearState = createAction('[Venues] Clear State');
