import { Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import {
  CalculateAvailableTicketsStatsDocument,
  GetEventInstanceByPkDocument,
  GetReservationStatsByPkDocument,
  GetTicketsBreakdownByEventInstanceIdDocument,
  GetTicketTypeUsersStatsDocument,
  TixMarkEventAsDeletedGQL
} from '@tix/data-access';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';

const GET_EVENTS = gql`
  query getEventListSearch(
    $city: String = "%%"
    $search: String = "%%"
    $fromDate: String = "01/01/0001"
    $toDate: String = "12/31/9999"
    $limit: Int = 100
    $offset: Int = 0
  ) {
    event_events_view_aggregate(
      limit: $limit
      offset: $offset
      where: {
        venueCity: { _like: $city }
        date: { _gte: $fromDate }
        _or: {
          date: { _lte: $toDate }
          _and: {
            search: { _ilike: $search }
            eventState: { _neq: "Inactive" }
          }
        }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        eventInstanceId
        eventId
        eventType
        eventName
        eventDescription
        eventAdditionalInfo
        date
        doorsOpen
        startTime
        endTIme
        hidden
        eventPasscode
        eventState
        venueId
        venueName
        venueCity
        venueStateProvince
        venueCityStateProvince
        search
      }
    }
  }
`;

const GET_TICKET_STAT = gql`
  query getTicketTypeDetails($event_instance_id: uuid) {
    pendingGA: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: {
          # _eq:"Checked In"
          _eq: "Pending"
        }
        ticketType: { _like: "%GA%" }
      }
    ) {
      aggregate {
        count
      }
    }
    pendingVIP: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: {
          # _eq:"Checked In"
          _eq: "Pending"
        }
        ticketType: { _like: "%VIP%" }
      }
    ) {
      aggregate {
        count
      }
    }
    pendingSpecial: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: {
          # _eq:"Checked In"
          _eq: "Pending"
        }
        ticketType: { _like: "%Special%" }
      }
    ) {
      aggregate {
        count
      }
    }

    checkedInVIP: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: { _eq: "Checked In" }
        ticketType: { _like: "%VIP%" }
      }
    ) {
      aggregate {
        count
      }
    }
    checkedInGA: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: { _eq: "Checked In" }
        ticketType: { _like: "%GA%" }
      }
    ) {
      aggregate {
        count
      }
    }
    checkedInSpecial: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: { _eq: "Checked In" }
        ticketType: { _like: "%Special%" }
      }
    ) {
      aggregate {
        count
      }
    }
    reservationPending: ReservationAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        tickets: { state: { _eq: "Pending" } }
      }
    ) {
      nodes {
        reservationId
        tickets_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    reservationCheckedIn: ReservationAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        tickets: { state: { _eq: "Checked In" } }
      }
    ) {
      nodes {
        reservationId
        tickets_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    ticketTypeUsersStatsPending: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: { _eq: "Pending" }
      }
    ) {
      nodes {
        contact {
          contactId
          firstName
          lastName
          emailAddress
        }
        reservation {
          reservationDate
        }
        ticketPrice
      }
    }
    ticketTypeUsersStatsCheckedIn: TicketAggregate(
      where: {
        eventInstanceId: { _eq: $event_instance_id }
        state: { _eq: "Checked In" }
      }
    ) {
      nodes {
        contact {
          contactId
          firstName
          lastName
          emailAddress
        }
        reservation {
          reservationDate
        }
        ticketPrice
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private apollo: Apollo,
    private tixMarkEventAsDeletedGQL: TixMarkEventAsDeletedGQL,
    private snackbar: MatSnackBar,
    private store: Store
  ) {}

  getEvents(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: GET_EVENTS,
        variables: {
          search: '%secret%'
        }
      })
      .valueChanges.pipe(
        map(
          (result: ApolloQueryResult<any>) =>
            result.data.event_events_view_aggregate.nodes
        )
      );
  }

  getTicketsBreakDown(
    eventInstanceId: string,
    ticketName: string
  ): Observable<any> {
    return this.apollo.watchQuery({
      query: GetTicketsBreakdownByEventInstanceIdDocument,
      variables: {
        eventInstanceId,
        ticketName
      }
    }).valueChanges;
  }

  getUsersTicketsStats(
    eventInstanceId: string,
    fullName?: string,
    emailAddress?: string,
    phoneNumber?: string
  ): Observable<any> {
    return this.apollo.watchQuery({
      query: GetTicketTypeUsersStatsDocument,
      variables: {
        eventInstanceId,
        name: fullName,
        emailAddress,
        phoneNumber
      }
    }).valueChanges;
  }

  calculateAvailableTicketsStats(eventInstanceId: string): Observable<any> {
    return this.apollo.watchQuery({
      query: CalculateAvailableTicketsStatsDocument,
      variables: {
        eventInstanceId
      }
    }).valueChanges;
  }

  getEventState(
    eventInstanceId: string
  ): QueryRef<unknown, { eventInstanceId: string }> {
    return this.apollo.watchQuery({
      query: GetEventInstanceByPkDocument,
      variables: {
        eventInstanceId
      }
    });
  }

  getReservationState(eventInstanceId: string): Observable<any> {
    return this.apollo.watchQuery({
      query: GetReservationStatsByPkDocument,
      variables: {
        eventInstanceId
      }
    }).valueChanges;
  }

  getTicketStatistiques(event_instance_id: string): Observable<any> {
    return this.apollo.watchQuery({
      query: GET_TICKET_STAT,
      variables: {
        event_instance_id
      }
    }).valueChanges;
  }

  public async deleteEvent(eventInstanceId: string): Promise<void> {
    try {
      await this.tixMarkEventAsDeletedGQL
        .mutate({
          eventInstanceId,
          updatedBy: (
            await this.store
              .select(UserSelector.getAuthenticatedUser)
              .pipe(take(1))
              .toPromise()
          )?.uid
        })
        .toPromise()
        .then(() => {
          this.snackbar.open(
            'Event deleted successfully! redirect to events list'
          );
        });
    } catch (error) {
      this.snackbar.open('An error has ocurred');
    }
  }
}
