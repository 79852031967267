import { createAction, props } from '@ngrx/store';
import {
  TixCompany,
  TixCompanyAddress,
  TixCompanyContact,
  TixCompanyMediaFile,
  TixCompanyPhoneNumber,
  TixCompanySocialMedia,
  TixCompanyWarning,
  TixVenue
} from '@tix/data-access';

export const loadCompanyDetailsSuccess = createAction(
  '[Company API] Fetch Company Details Success',
  props<{ company: Partial<TixCompany> }>()
);

export const loadCompaniesSuccess = createAction(
  '[Company API] Fetch Companies Success',
  props<{ companies?: TixCompany[] }>()
);

export const loadCompanySuccess = createAction(
  '[Company API] Fetch Company Success',
  props<{ company: TixCompany }>()
);

export const deleteCompanySuccess = createAction(
  '[Company API] Delete Company Success',
  props<{ companyId: string }>()
);

export const loadCompaniesFailure = createAction(
  '[Company API] Fetch Companies Failure',
  props<{ error: any }>()
);

export const loadCompanyFailure = createAction(
  '[Company API] Fetch Company Failure',
  props<{ error: any }>()
);

export const deleteCompanyFailure = createAction(
  '[Company API] Delete Company Failure',
  props<{ error: any }>()
);

export const loadVenuesForSelectedCompanySuccess = createAction(
  '[Company API] Fetch Venues for selected Company success',
  props<{ companyId: string; venues?: TixVenue[] }>()
);
export const loadVenuesForSelectedCompanyFailure = createAction(
  '[Company API] Fetch Companies for selected Company failure',
  props<{ error: any }>()
);

export const loadContactsForSelectedCompanySuccess = createAction(
  '[Company API] Fetch Contacts for selected Company success',
  props<{ companyId: string; contacts?: TixCompanyContact[] }>()
);
export const loadContactsForSelectedCompanyFailure = createAction(
  '[Company API] Fetch Contacts for selected Company failure',
  props<{ error: any }>()
);

export const insertCompanyProfileSuccess = createAction(
  '[Company API] Insert Profile Success',
  props<{ company: TixCompany }>()
);
export const insertCompanyProfileFailure = createAction(
  '[Company API] Insert Profile Failure',
  props<{ error: any }>()
);

export const updateCompanyProfileSuccess = createAction(
  '[Company API] Update Profile Success',
  props<{
    name: string;
    url: string;
    state: string;
    updatedAt: string;
    updatedBy: string;
  }>()
);

export const updateCompanyProfileFailure = createAction(
  '[Company API] Update Profile Failure',
  props<{ error: any }>()
);

/* #region  Upsert Actions */
export const upsertCompanyPhoneNumbersSuccess = createAction(
  '[Company API] Update Phone Numbers Success',
  props<{ companyPhoneNumbers: TixCompanyPhoneNumber[] }>()
);

export const upsertCompanyPhoneNumbersFailure = createAction(
  '[Company API] Update Phone Numbers Failure',
  props<{ error: any }>()
);

export const upsertCompanySocialMediaSuccess = createAction(
  '[Company API] Update Social Media Success',
  props<{ companySocialMedias: TixCompanySocialMedia[] }>()
);
export const upsertCompanyWarningSuccess = createAction(
  '[Company API] Update Warning Success',
  props<{ companyWarnings: TixCompanyWarning[] }>()
);
export const upsertCompanyWarningFailure = createAction(
  '[Company API] Update Warning Failure',
  props<{ error: any }>()
);

export const upsertCompanySocialMediaFailure = createAction(
  '[Company API] Update Social Media Failure',
  props<{ error: any }>()
);

export const upsertCompanyMediaFileSuccess = createAction(
  '[Company API] Update Media File Success',
  props<{ companyMediaFiles: TixCompanyMediaFile[] }>()
);

export const upsertCompanyMediaFileFailure = createAction(
  '[Company API] Update Media File Failure',
  props<{ error: any }>()
);
/* #endregion */

/* #region  Delete Actions */
export const deleteCompanyPhoneNumberSuccess = createAction(
  '[Company API] Delete Phone Numbers Success',
  props<{ companyId: string; companyPhoneNumberId: string }>()
);

export const deleteCompanySocialMediaSuccess = createAction(
  '[Company API] Delete Social Media Success',
  props<{ companyId: string; companySocialMediaId: string }>()
);

export const deleteCompanyMediaFileSuccess = createAction(
  '[Company API] Delete Media File Success',
  props<{ companyId: string; companyMediaFileId: string }>()
);

export const deleteCompanyWarningSuccess = createAction(
  '[Company API] Delete Warning Success',
  props<{ companyId: string; companyWarningId: string }>()
);

export const deleteCompanyPhoneNumberFailure = createAction(
  '[Company API] Delete Phone Numbers Failure',
  props<{ error: any }>()
);

export const deleteCompanySocialMediaFailure = createAction(
  '[Company API] Delete Social Media Failure',
  props<{ error: any }>()
);

export const deleteCompanyWarningFailure = createAction(
  '[Company API] Delete Warning Failure',
  props<{ error: any }>()
);

export const deleteCompanyMediaFileFailure = createAction(
  '[Company API] Delete Media File Failure',
  props<{ error: any }>()
);
/* #endregion */

export const updateCompanyAddressSuccess = createAction(
  '[Company API] Update Address Success',
  props<{ address: TixCompanyAddress }>()
);

export const updateCompanyAddressFailure = createAction(
  '[Company API] Update Address Failure',
  props<{ error: any }>()
);
