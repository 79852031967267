import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'tix-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class TixConfirmDeleteDialogComponent {
  @Input()
  confirmationText: string;

  @Output()
  confirm = new EventEmitter<boolean>();

  confirmationControl = new FormControl('');

  get canDelete() {
    return this.confirmationControl.value === this.confirmationText;
  }

  handleSubmit(event: SubmitEvent) {
    event.preventDefault();
    if (!this.canDelete) return;

    this.confirm.emit(true);
  }

  handleCancel() {
    this.confirm.emit(false);
  }
}
