import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TixInfoDialogComponent } from './info-dialog.component';
// import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TixButtonModule } from '../../button';
import { TixCardModule } from '../../card';

@NgModule({
  imports: [CommonModule, TixButtonModule, TixCardModule],
  declarations: [TixInfoDialogComponent],
  exports: [TixInfoDialogComponent],
  providers: []
})
export class TixInfoDialogModule {
  constructor() {}
}
