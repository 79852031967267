<tix-card class="confirm-card">
  <tix-card-header>
    <tix-card-title class="title title-{{ config?.titleTheme }}">
      <tix-icon
        class="mr-2"
        *ngIf="config?.icon"
        [svgIcon]="config?.icon ?? ''"
      >
      </tix-icon>
      <h2>{{ config?.title }}</h2>
    </tix-card-title>
  </tix-card-header>
  <tix-card-content>
    {{ config?.confirmText }}
  </tix-card-content>
  <tix-card-actions align="end">
    <button
      tix-flat-border-button
      (click)="confirmClick(false)"
      *ngIf="config?.cancelButton?.visible"
      [color]="config?.cancelButton?.theme"
      class="mr-0 md:mr-4 mb-4 md:mb-0"
    >
      {{ config?.cancelButton?.label }}
    </button>
    <button
      tix-button
      (click)="confirmClick(true)"
      [color]="config?.confirmButton?.theme"
    >
      {{ config?.confirmButton?.label }}
    </button>
  </tix-card-actions>
</tix-card>
