<tix-card>
  <form
    class="bg-white"
    [formGroup]="eventDetailForm"
    (keydown.enter)="$event.preventDefault()"
  >
    <tix-card-header>
      <tix-card-title>
        <div class="flex items-center">
          <span>Event Info</span>
          <ng-container *ngIf="selectedEventData">
            <button
              [matTooltip]="
                showCopiedSuccess ? 'Copied!' : 'Copy URL to sales page'
              "
              (click)="handleSalesPageURLCopy()"
              class="flex items-center justify-center ml-2 rounded-md h-9 w-9 hover:bg-gray-100"
            >
              <tix-icon
                [svgIcon]="showCopiedSuccess ? 'check' : 'clipboard'"
                class="icon-btn__icon"
              ></tix-icon>
            </button>
            <button
              matTooltip="Go to sales page"
              (click)="goSalesPage()"
              class="flex items-center justify-center w-8 h-8 ml-1 text-black rounded-md md:h-9 md:w-9 hover:bg-gray-100"
            >
              <tix-icon
                svgIcon="open-external"
                class="icon-btn__icon"
              ></tix-icon>
            </button>
          </ng-container>
        </div>
      </tix-card-title>
    </tix-card-header>

    <tix-card-content>
      <div class="profile-content-layout">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error>The name is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Type of Event</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of eventTypes" [value]="type.name">{{
              type.name
            }}</mat-option>
          </mat-select>
          <mat-error>The type is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option
              *ngFor="let status of statusList"
              (click)="onChangeStatus(status.name)"
              [value]="status.name"
              >{{ status.name }}</mat-option
            >
          </mat-select>
          <mat-error>The status is required</mat-error>
        </mat-form-field>
      </div>
      <div class="grid lg:grid-cols-3 gap-x-6">
        <mat-form-field class="lg:col-span-2">
          <mat-label>Description</mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Slug</mat-label>
          <input matInput type="text" formControlName="slug" />
          <!--  <mat-error *ngIf="slugFormControl.errors?.notAllowedWord">
          Using "{{ slugFormControl.value }}" as the slug is not allowed
        </mat-error>
        <mat-error *ngIf="slugFormControl.errors?.invalidFormat">
          The value given does not comply with the slug format conditions
        </mat-error>
        <mat-error *ngIf="slugFormControl.errors?.notUnique">
          This slug has already been used
        </mat-error> -->
        </mat-form-field>
      </div>
      <div class="mb-5 quill-editor-field">
        <label for="editor">Additional Information</label>
        <quill-editor
          id="editor"
          style="resize: vertical"
          formControlName="additionalInfo"
        ></quill-editor>
      </div>

      <div class="profile-content-layout">
        <mat-form-field>
          <mat-label>Additional Information</mat-label>
          <input matInput type="text" formControlName="additionalInfo" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Minimum Age</mat-label>
          <input matInput type="number" formControlName="minimumAge" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Passcode</mat-label>
          <input matInput type="text" formControlName="passcode" />
        </mat-form-field>
      </div>
    </tix-card-content>

    <tix-card-header>
      <tix-card-title>Event Date</tix-card-title>
    </tix-card-header>

    <tix-card-content class="dates">
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-3 gap-x-6"
      >
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Date</mat-label>
          <input
            formControlName="date"
            class="!pl-0"
            matInput
            [matDatepicker]="picker"
            (dateChange)="onChangeDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error class="mt-1.5 -ml-2">Event Date is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Time</mat-label>
          <input
            matInput
            type="time"
            formControlName="startTime"
            value="startTime"
            (change)="onStartTimeUpdate($event)"
            step="60"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Time</mat-label>
          <input matInput type="time" formControlName="endTime" step="60" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Doors Open At</mat-label>
          <input matInput type="time" formControlName="doorsOpen" step="60" />
        </mat-form-field>

        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Start Selling Date</mat-label>
          <input
            formControlName="startSellingDate"
            matInput
            [matDatepicker]="pickerStart"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerStart"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
          <mat-error class="mt-1.5 -ml-2"
            >Start selling date is required</mat-error
          >
        </mat-form-field>

        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Stop Selling Date</mat-label>
          <input
            formControlName="stopSellingDate"
            matInput
            [matDatepicker]="pickerEnd"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error class="mt-1.5 -ml-2"
            >Stop selling date is required</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Selling Time</mat-label>
          <input
            matInput
            type="time"
            formControlName="startSellingTime"
            step="60"
          />
          <mat-error>Start selling time is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Stop Selling Time</mat-label>
          <input
            matInput
            type="time"
            formControlName="stopSellingTime"
            step="60"
          />
          <mat-error>Stop selling time is required</mat-error>
        </mat-form-field>
      </div>
      <mat-hint *ngIf="genericEventValidator === null" class="text-[0.85rem]"
        >Not available for this event type</mat-hint
      >
    </tix-card-content>

    <tix-card-header>
      <tix-card-title>Venue Info</tix-card-title>
    </tix-card-header>

    <tix-card-content>
      <div class="venue-content-layout">
        <mat-form-field>
          <mat-label>Venue</mat-label>
          <mat-select formControlName="venueId" name="venueId">
            <ng-container *ngFor="let venue of eventVenueList">
              <mat-option
                (click)="onSelectVenue(venue)"
                [value]="venue.venueId"
                *ngIf="venue.state !== 'Deleted'"
                >{{ venue.name }} ({{
                  venue.tickets_aggregate.aggregate?.count
                }})</mat-option
              >
            </ng-container>
          </mat-select>
          <mat-error>{{ venueErrorMessage }} </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ticket Configuration</mat-label>
          <mat-select formControlName="venueTicketId" name="ticket-config">
            <mat-option
              *ngFor="let ticket of eventTicketLists"
              [value]="ticket.venueTicketId"
              >{{ ticket.name }}</mat-option
            >
          </mat-select>
          <mat-hint *ngIf="genericEventValidator === null"
            >Not available for this event type</mat-hint
          >
        </mat-form-field>
      </div>

      <tix-ticket-configuration-admin
        class="block -mt-4"
        *ngIf="selectedTicketConfig"
        [inline]="true"
        [eventInstanceTicketsInput]="[selectedTicketConfig]"
        (eventInstanceTicketUpdate)="eventInstanceTicketUpdate($event)"
        (isValidUpdate)="ticketConfigValidUpdate($event)"
        [hideSaveBtn]="true"
        [companyId]="selectedCompanyId"
        [venueId]="selectedVenueId"
      ></tix-ticket-configuration-admin>
    </tix-card-content>

    <!-- ===========================Keywords: PRODUCTS, products, Product========================= -->

    <tix-event-products *ngIf="isEdit"></tix-event-products>

    <!-- ============================End======================== -->

    <tix-card-header></tix-card-header>

    <tix-card-content>
      <tix-event-multi-media
        [separate]="true"
        [mediaInfoForm]="eventDetailForm"
        [socialMediaBusinessCodeGroup]="businessSocialMediaTypes$ | async"
        [mediaFilesBusinessCodeGroup]="businessMediaFilesTypes$ | async"
      ></tix-event-multi-media>
    </tix-card-content>
    <div class="mt-4">
      <tix-card-header>
        <tix-card-title>Communications</tix-card-title></tix-card-header
      >
      <tix-card-content>
        <tix-communications-edit
          (setEmailTemplates)="setEmailTemplates($event)"
          [id]="eventInstanceId"
          type="event"
        >
        </tix-communications-edit>
      </tix-card-content>
    </div>
    <div class="mt-4">
      <tix-card-header>
        <tix-card-title
          >Additional Configuration</tix-card-title
        ></tix-card-header
      >
      <tix-card-content>
        <tix-checkbox
          *ngIf="sendEmailNotificationConfigItem$ | async as configItem"
          [checked]="wantsToReceiveEmailNotification"
          labelPosition="before"
          (change)="
            wantsToReceiveEmailNotification = !wantsToReceiveEmailNotification
          "
          [disabled]="isGenericEvent"
        >
          {{ configItem.name }}
          <tix-icon
            [matTooltip]="configItem.helpText ?? ''"
            class="ml-2 !w-5 !h-5 translate-y-1"
            svgIcon="information-circle"
          ></tix-icon>
        </tix-checkbox>

        <div
          *ngIf="emailNotificationConfigItem$ | async as configItem"
          [ngClass]="{
            'mt-4': true,
            'opacity-30 pointer-events-none': !wantsToReceiveEmailNotification
          }"
        >
          <mat-form-field>
            <mat-label>{{ configItem.name }}</mat-label>
            <input
              type="email"
              placeholder="{{ configItem.placeholder }}"
              matInput
              [formControl]="notificationEmailControl"
            />
            <mat-hint>{{ configItem.helpText }}</mat-hint>
          </mat-form-field>
        </div>
      </tix-card-content>
      <tix-event-report-subscription
        *ngIf="eventInstanceId !== null"
        [eventId]="eventInstanceId"
        [userId]="(userId$ | async)!"
      >
        <!-- key words: report-event, event report, report event -->
      </tix-event-report-subscription>
    </div>

    <div>
      <tix-card-header>
        <tix-card-title>Event Admins</tix-card-title></tix-card-header
      >
      <tix-card-content>
        <div class="p-2 border rounded-md focus:text-primary">
          <mat-chip-list
            #chipList
            aria-label="User selection"
            class="flex items-center w-full"
          >
            <mat-chip
              *ngFor="let adminId of selectedEventAdmins; let i = index"
              (removed)="removeEventAdmin(adminId)"
            >
              {{ getAdminFullName(adminId) }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="New event admin"
              class="text-md w-full"
              [formControl]="eventAdmins"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectedUserChip($event)"
          >
            <mat-option
              *ngFor="let user of filteredCompanyUser$ | async"
              [value]="user.contact?.user?.userId"
            >
              {{ getUser(user) }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </tix-card-content>
    </div>

    <div>
      <tix-card-header>
        <tix-card-title>Campaign Tracking</tix-card-title></tix-card-header
      >
      <tix-card-content>
        <tix-checkbox
          [checked]="showCampaignPixels"
          labelPosition="before"
          (change)="checkCampaign()"
        >
          Do you want to add campaign tracking information?
        </tix-checkbox>
        <div *ngIf="showCampaignPixels">
          <tix-campaign-pixel
            *ngFor="let pixel of pixelsList; let i = index"
            [pixel]="pixel"
            [campaignType]="campaignTypes"
            (deleteEvent)="deletePixelFromPixelList(i)"
          ></tix-campaign-pixel>
          <div class="flex items-center justify-end">
            <button tix-button (click)="addNewPixel()" class="!ml-auto">
              Add Tracking ID
            </button>
          </div>
        </div>
      </tix-card-content>
    </div>

    <div class="mt-4" *ngIf="isEdit">
      <tix-card-header>
        <tix-card-title>Import Customers</tix-card-title></tix-card-header
      >
      <tix-card-content>
        <tix-checkbox
          [checked]="sendEmail"
          labelPosition="before"
          (change)="sendEmail = !sendEmail"
          [disabled]="isGenericEvent"
        >
          Do you want the customers to get an E ticket
          <tix-icon
            matTooltip="check so the customers imported get the E ticket"
            class="ml-2 !w-5 !h-5 translate-y-1"
            svgIcon="information-circle"
          ></tix-icon>
        </tix-checkbox>
        <div class="mt-2 ml-2">
          <a
            [ngClass]="{ 'pointer-events-none': isGenericEvent }"
            href="https://asylumtixmedia.sfo3.digitaloceanspaces.com/IMPORT_TEMPLATE.CSV"
            download
            class="underline transition-all text-primary hover:text-blue-700"
            >Download The CSV Template</a
          >
        </div>

        <div
          class="flex flex-col items-center justify-center px-4 py-12 mt-4 bg-gray-50 sm:px-6 lg:px-8"
        >
          <div class="w-full max-w-md space-y-8">
            <input
              type="file"
              id="file"
              class="hidden"
              (change)="onFileUpload($event)"
              [disabled]="!selectedTicketConfig"
              accept=".csv"
            />
            <label
              [class]="
                errorList.length > 0
                  ? 'mt-3 rounded-md shadow-sm flex items-center justify-between px-5 py-2 text-base font-medium text-white bg-red-500 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 cursor-pointer'
                  : fileUploadSuccess
                  ? 'mt-3 rounded-md shadow-sm flex items-center justify-between px-5 py-2 text-base font-medium text-white bg-[#4BB543] hover:bg-[#4BB543] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 cursor-pointer'
                  : 'mt-3 rounded-md shadow-sm flex items-center justify-between px-5 py-2 text-base font-medium text-white bg-[#4B9CD3] hover:bg-[#4B9CD3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer'
              "
              for="file"
            >
              <span>{{
                errorList.length > 0
                  ? 'Error importing CSV'
                  : fileUploadSuccess
                  ? 'CSV Imported Successfully'
                  : 'Upload a CSV file'
              }}</span>
              <svg
                *ngIf="errorList.length > 0"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
              <svg
                *ngIf="
                  count === numberOfCustomers &&
                  !importCustomerLoading &&
                  errorList.length === 0
                "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                *ngIf="
                  !importCustomerLoading &&
                  count !== numberOfCustomers &&
                  errorList.length === 0
                "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 10l7-7m0 0l7 7m-7-7v18"
                ></path>
              </svg>
              <div
                class="flex flex-row items-center gap-4"
                *ngIf="importCustomerLoading"
              >
                <p>{{ count }}/{{ numberOfCustomers }}</p>
                <div class="spinner"></div>
              </div>
            </label>
          </div>
          <div
            *ngIf="errorList.length > 0"
            class="p-4 mt-2 text-red-500 text-base overflow-auto h-[360px] bg-red-100 rounded"
          >
            <h2 class="font-bold text-md">Errors:</h2>
            <ul class="text-sm">
              <li *ngFor="let error of errorList" class="mb-[16px]">
                <strong>Error in operation:</strong> {{ error.operation }}<br />
                <strong>Message:</strong> {{ error.error }}
              </li>
            </ul>
          </div>
        </div>
      </tix-card-content>
    </div>
  </form>

  <tix-card-actions align="end">
    <a class="mb-3 clone-event md:mb-0" *ngIf="!isAdd" (click)="cloneEvent()"
      >Clone Event</a
    >
    <div class="flex flex-col-reverse justify-end md:flex-row">
      <button
        tix-flat-border-button
        color="danger"
        class="mt-3 mr-0 md:mr-5 md:mt-0"
        (click)="deleteEvent()"
        *ngIf="!isAdd && selectedEventData.state !== 'Deleted'"
      >
        Delete Event
      </button>
      <button
        tix-button
        (click)="confirmSave()"
        [disabled]="formInvalid || isSaving"
      >
        <span [class.mr-1]="isSaving">Save</span>
        <span *tixBtnLoading="isSaving"></span>
      </button>
    </div>
  </tix-card-actions>
</tix-card>
