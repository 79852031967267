<div
  mat-dialog-title
  class="w-full flex items-center justify-between font-semibold text-lg leading-8"
>
  <div>{{ title }}</div>
  <div
    class="px-3 py-1 border rounded-md bg-light text-xl text-secondary font-medium cursor-pointer hover:border-primary"
    [matTooltip]="'Close'"
    [matTooltipPosition]="'left'"
    (click)="closeDialog()"
  >
    X
  </div>
</div>
<div
  mat-dialog-content
  [ngClass]="eventProducts.length > 0 ? 'lg:w-full' : 'lg:w-80'"
  class="sm:w-96 lg:w-full pr-1 h-full"
>
  <div
    *ngIf="eventProducts.length > 0"
    class="flex w-full flex-row items-center justify-between h-full mb-3"
  >
    <div
      class="border flex items-center p-3 rounded-md border-primary h-full mr-2"
    >
      <input
        class="outline-none h-full mr-2 bg-inherit border-none p-0 m-0"
        placeholder="Search by name"
        [formControl]="searchCtrl"
        type="search"
      />
      <mat-icon class="text-primary">search</mat-icon>
    </div>
    <div></div>
  </div>
  <!-- list -->
  <div class="grid pb-2 w-full h-full sm:grid-cols-1 lg:grid-cols-3 gap-4">
    <ng-container *ngIf="eventProducts.length > 0">
      <tix-event-product-card
        *ngFor="
          let eventProduct of filteredEventProduct$ | async;
          let i = index;
          trackBy: trackBy
        "
        [addon]="eventProduct"
        [isPulling]="true"
        (click)="selectProduct(i)"
        [ngClass]="
          eventProduct?.isSelected
            ? 'ring-2 rounded-md ring-success'
            : 'border-none'
        "
      ></tix-event-product-card>
    </ng-container>
  </div>
  <div
    *ngIf="eventProducts.length === 0"
    class="w-full h-20 flex justify-center items-center font-semibold"
  >
    No products yet.
  </div>
</div>
<div mat-dialog-actions class="bg-light border-t flex items-center justify-end">
  <div class="space-x-4 text-sm">
    <button
      class="px-3 py-2 border border-secondary rounded-md hover:bg-slate-100"
      (click)="closeDialog()"
    >
      Close
    </button>
    <button
      *ngIf="eventProducts.length > 0"
      (click)="pullProducts()"
      [disabled]="selectedProducts.length === 0"
      [matTooltip]="selectedProducts.length === 0 ? 'No product selected' : ''"
      [matTooltipPosition]="'above'"
      class="px-3 py-2 text-light bg-primary rounded-md hover:bg-matprimary-700"
    >
      Pull
      <span *ngIf="selectedProducts.length > 0" class="font-semibold"
        >( {{ selectedProducts.length }} ) </span
      >products
    </button>
  </div>
</div>
