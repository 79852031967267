import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EventReportSubscriptionFacade } from './event-report-subscription.facade';

@Component({
  selector: 'tix-event-report-subscription',
  templateUrl: './event-report-subscription.component.html',
  providers: [EventReportSubscriptionFacade],
  encapsulation: ViewEncapsulation.None
  // todo: OnPush
})
export class EventReportSubscriptionComponent implements OnInit {
  // inputs
  @Input() eventId = '';
  @Input() userId = '';

  // form
  eventReportForm: FormGroup;
  initForm: Record<number, any>;
  defaultValue: Record<string, any> = {};
  //
  isLoading = true;
  isSubscribed = false;
  isDeleting = false;
  isRequesting = false;
  //
  zones = ['Africa/Casablanca', 'America/New_York', 'Europe/Dublin'];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  recipients: string[] = [];
  initialValue: any = {};

  constructor(
    private _eventReportSubscriptionFacade: EventReportSubscriptionFacade,
    private _formBuilder: FormBuilder
  ) {}

  // hooks
  ngOnInit(): void {
    this._eventReportSubscriptionFacade.get$(this.eventId).subscribe(res => {
      this.initForm = res;
      //
      Object.keys(res).map(key => {
        this.defaultValue[res[+key].name] = res[+key].defaultValue;
      });
      this.eventReportForm = this._formBuilder.group({
        [res[0].name]: new FormControl(res[0].value ?? res[0].defaultValue, [
          // default: support@asylumtix.com
          res[0].required == true
            ? Validators.required
            : Validators.nullValidator
        ]),
        [res[1].name]: new FormControl(res[1].value ?? res[1].defaultValue, [
          res[1].required == true
            ? Validators.required
            : Validators.nullValidator
        ]), // default: 15:00
        [res[2].name]: new FormControl(res[2].value ?? res[2].defaultValue, [
          res[2].required == true
            ? Validators.required
            : Validators.nullValidator
        ]), // default: America/New_York
        [res[3].name]: new FormControl(res[3].value ?? res[3].defaultValue, [
          res[3].required == true
            ? Validators.required
            : Validators.nullValidator
        ]) // default: ''
      });

      if (res[3].value) {
        this.recipients = res[3].value.split(',');
        this.eventReportForm.get(res[3].name)?.setValue('');
      }
      if (res[0].value) {
        this.isSubscribed = true;
        Object.keys(res).map(key => {
          this.initialValue[res[+key].name] = res[+key].value;
        });
      }
    });
  }

  // http
  save() {
    this.isRequesting = true;
    const reportConfig = this.#mintReportConfig();

    this.initialValue = this.eventReportForm.value;

    this._eventReportSubscriptionFacade
      .save(reportConfig, this.eventId, this.userId, {
        ...this.eventReportForm.value,
        otherRecipients: this.recipients.join(','),
        eventId: this.eventId
      })
      .subscribe({
        next: res => {
          if (!res.errors) {
            // init values id
            for (let i = 0; i < res.values.length; i++) {
              const element = res.values[i];
              this.initForm[+i].value = element.value;
              this.initForm[+i].valueId = element.valueId;
            }
          }
          this.isSubscribed = true;
          this.isRequesting = false;
        }
      });
  }

  update() {
    if (!this.initForm[0]?.valueId) return;

    this.isRequesting = true;

    const updated = this.#updatedReportConfig();
    this.eventReportForm.disable();
    this._eventReportSubscriptionFacade
      .update(updated, this.userId, {
        old: { ...this.initialValue, eventId: this.eventId },

        newValue: {
          ...this.eventReportForm.value,
          otherRecipients: this.recipients.join(','),
          eventId: this.eventId
        }
      })
      .subscribe({
        next: res => {
          if (!res.errors) {
            this.initialValue = this.eventReportForm.value;
          }

          this.eventReportForm.enable();

          this.isRequesting = false;
        }
      });
  }

  softDeleted() {
    if (!this.initForm[0]?.valueId) return;

    this.isDeleting = true;
    const idsValues = Object.keys(this.initForm).map(
      key => this.initForm[+key].valueId
    );

    this._eventReportSubscriptionFacade
      .softDelete(idsValues, this.eventId, this.userId, {
        ...this.initialValue,
        eventId: this.eventId
      })
      .subscribe({
        next: res => {
          this.isSubscribed = false;
          this.eventReportForm.patchValue(this.defaultValue);
          this.recipients = [];
          this.isDeleting = false;
        }
      });
  }

  // public template
  add(event: MatChipInputEvent): void {
    const value = (event?.value || '').trim();

    // Add recipient
    if (value) {
      this.recipients.push(value);
    }

    // Clear the input value
    event.chipInput?.clear();
  }

  remove(recipient: string): void {
    const index = this.recipients.indexOf(recipient);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  #mintReportConfig() {
    return [
      {
        configurationItemId: this.initForm[0].configurationItemId,
        value: this.eventReportForm.get(this.initForm[0].name)?.value
      },
      {
        configurationItemId: this.initForm[1].configurationItemId,
        value: this.eventReportForm.get(this.initForm[1].name)?.value
      },
      {
        configurationItemId: this.initForm[2].configurationItemId,
        value: this.eventReportForm.get(this.initForm[2].name)?.value
      },
      {
        configurationItemId: this.initForm[3].configurationItemId,
        value: this.recipients.join(',')
      }
    ];
  }

  #updatedReportConfig() {
    return [
      {
        id: this.initForm[0].valueId,
        value: this.eventReportForm.get(this.initForm[0].name)?.value
      },
      {
        id: this.initForm[1].valueId,
        value: this.eventReportForm.get(this.initForm[1].name)?.value
      },
      {
        id: this.initForm[2].valueId,
        value: this.eventReportForm.get(this.initForm[2].name)?.value
      },
      {
        id: this.initForm[3].valueId,
        value: this.recipients.join(',')
      }
    ];
  }
}
