<div class="tix-weeky-container">
  <div
    class="tix-weeky-day"
    (click)="handleDayClick(i)"
    [ngClass]="{
      'tix-weeky-selected': isSelected(i),
      'tix-serif': !useSansSerif
    }"
    *ngFor="let weekDay of weekDays; let i = index"
  >
    <span>{{ weekDay }}</span>
  </div>
</div>
