import { NgModule } from '@angular/core';
import { TixBreadcrumbsComponent } from './breadcrumbs.component';
import {
  HeroChevronDoubleDown,
  HeroChevronRight,
  HeroHomeSolid,
  TixIconModule,
  TixIconRegistry
} from '../icon';
import { TixButtonModule } from '../button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TixBreadcrumbsComponent],
  exports: [TixBreadcrumbsComponent],
  imports: [CommonModule, RouterModule, TixIconModule, TixButtonModule]
})
export class TixBreadcrumbsModule {
  constructor(private iconRegistry: TixIconRegistry) {
    iconRegistry.addSvgIconConfigs([
      HeroHomeSolid,
      HeroChevronRight,
      HeroChevronDoubleDown
    ]);
  }
}
